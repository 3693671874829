import ResidentMedHistoryClient from "../../../api/compliance/resident/residentMedHistoryClient";

const residentMedHistoryClient = new ResidentMedHistoryClient();

class ResidentMedHistoryService {
  async delete(id) {
    try {
      const response = await residentMedHistoryClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    let response = "";
    try {
      if (repo.hasOwnProperty("id") && repo.id != undefined) {
        response = await residentMedHistoryClient.update(repo, repo.id);
      } else {
        response = await residentMedHistoryClient.insert(repo);
      }

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  getAll() {
    return residentMedHistoryClient.getAll();
  }

  async getResidentMedHistoryByResidentId(id) {
    try {
      const response = await residentMedHistoryClient.getResidentMedHistoryByResidentId(
        id
      );

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new ResidentMedHistoryService();
