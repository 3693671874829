import React from "react";
import { Row, Col, Modal, Button, Container } from "react-bootstrap";
import ScheduleCheckMobile from "./scheduleCheckMobile";
import "../checklist.css";
//Services
import CheckListControlListMobile from "./checkListControlListMobile";
import CompleteCheckMobile from "./completeCheckMobile";
import PassedCheckListControMobile from "./passedCheckListControlMobile";
import ViewCheckResult from "../viewCheckResult";
import { TiPlus } from "react-icons/ti";

const MainCheckListMobile = ({
  onHideScheduleCheckList,
  onSelectCheck,
  onGoToSchedule,
  onSaveScheduleCheckListControl,
  onSaveCheckResult,
  onHideCheckCompleted,
  onDeleteCheckResult,
  onViewCheckCompleted,
  onSetControlStatus,
  onSetComment,
  onHideCheckDetails,
  onViewCompletedDetails,
  onShowScheduleCheckList,
  onFilterCheckList,
  onChangeTodo,
  onChangeTodoDates,
  onOpenCheckListView,
  checkListState,
}) => {
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <Button
              size="sm"
              variant="success"
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
              onClick={onShowScheduleCheckList}
            >
              <TiPlus
                style={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  verticalAlign: "middle",
                }}
              />{" "}
              Schedule check
            </Button>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Button
              style={
                checkListState && checkListState.checkListView == "active"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={(selectedKey) => onOpenCheckListView("active")}
            >
              Upcoming
            </Button>
          </Col>
          <Col>
            {" "}
            <Button
              style={
                checkListState && checkListState.checkListView == "passed"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={(selectedKey) => onOpenCheckListView("passed")}
            >
              Completed
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {checkListState.checkResults != undefined &&
            checkListState.checkResults.length > 0 ? (
              checkListState && checkListState.checkListView == "active" ? (
                <CheckListControlListMobile
                  checkResults={checkListState.checkResults}
                  viewCheckCompleted={onViewCheckCompleted}
                  deleteCheckResult={onDeleteCheckResult}
                />
              ) : (
                <PassedCheckListControMobile
                  checkResults={checkListState.checkResults}
                  viewCompletedDetails={onViewCompletedDetails}
                />
              )
            ) : (
              <b style={{ color: "#b22234" }}>Please schedule check!</b>
            )}
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        animation="true"
        show={checkListState.showScheduleCheckList}
        onHide={onHideScheduleCheckList}
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule check</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleCheckMobile
            onFilterCheckList={onFilterCheckList}
            onSelectCheck={onSelectCheck}
            onHideScheduleCheckList={onHideScheduleCheckList}
            onGoToSchedule={onGoToSchedule}
            onSaveScheduleCheckListControl={onSaveScheduleCheckListControl}
            onChangeTodo={onChangeTodo}
            onChangeTodoDates={onChangeTodoDates}
            checkListState={checkListState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        show={checkListState.showCheckCompleted}
        onHide={onHideCheckCompleted}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark check as completed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompleteCheckMobile
            onSetControlStatus={onSetControlStatus}
            onSetComment={onSetComment}
            onHideCheckCompleted={onHideCheckCompleted}
            onSaveCheckResult={onSaveCheckResult}
            checkListState={checkListState}
          />
        </Modal.Body>
      </Modal>
      <Modal show={checkListState.showCheckDetails} onHide={onHideCheckDetails}>
        <Modal.Header closeButton>
          <Modal.Title>Check details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewCheckResult checkResult={checkListState.currCheckResult} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  fontSize: "12px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  fontSize: "12px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainCheckListMobile;
