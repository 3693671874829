import React, { useEffect } from "react";
import useMyDocumentsState from "./hook/useMyDocumentsState";
import useWindowSize from "../common/hook/useWindowSize";
import MainMyDocumentsDesktop from "./desktop/mainMyDocumentsDesktop";
import MainMyDocumentsMobile from "./mobile/mainMyDocumentsMobile";

const MainMyDocuments = () => {
  const size = useWindowSize();
  const {
    refreshPage,
    onShowFolder,
    onHideFolder,
    onOpenFacilityFolder,
    onSelectFormMenu,
    onSaveFolder,
    onChangeFolder,
    formatList,
    onCloseDocuments,
    onSetFile,
    onDownload,
    onDeleteDocument,
    onShowShareDocument,
    onHideShareDocument,
    onChangeEmail,
    onBlurEmail,
    onChangeContent,
    onSendEmail,
    myDocumentState,
  } = useMyDocumentsState({});

  useEffect(() => {
    refreshPage();
  }, []);

  return size.width > 970 ? (
    <MainMyDocumentsDesktop
      onShowFolder={onShowFolder}
      onHideFolder={onHideFolder}
      onOpenFacilityFolder={onOpenFacilityFolder}
      onSelectFormMenu={onSelectFormMenu}
      onSaveFolder={onSaveFolder}
      onChangeFolder={onChangeFolder}
      formatList={formatList}
      onCloseDocuments={onCloseDocuments}
      onSetFile={onSetFile}
      onDownload={onDownload}
      onDeleteDocument={onDeleteDocument}
      onShowShareDocument={onShowShareDocument}
      onHideShareDocument={onHideShareDocument}
      onChangeEmail={onChangeEmail}
      onBlurEmail={onBlurEmail}
      onChangeContent={onChangeContent}
      onSendEmail={onSendEmail}
      myDocumentState={myDocumentState}
    />
  ) : (
    <MainMyDocumentsMobile
      onShowFolder={onShowFolder}
      onHideFolder={onHideFolder}
      onOpenFacilityFolder={onOpenFacilityFolder}
      onSelectFormMenu={onSelectFormMenu}
      onSaveFolder={onSaveFolder}
      onChangeFolder={onChangeFolder}
      formatList={formatList}
      onCloseDocuments={onCloseDocuments}
      onSetFile={onSetFile}
      onDownload={onDownload}
      onDeleteDocument={onDeleteDocument}
      onShowShareDocument={onShowShareDocument}
      onHideShareDocument={onHideShareDocument}
      onChangeEmail={onChangeEmail}
      onBlurEmail={onBlurEmail}
      onChangeContent={onChangeContent}
      onSendEmail={onSendEmail}
      myDocumentState={myDocumentState}
    />
  );
};

export default MainMyDocuments;
