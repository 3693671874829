import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";

const DoctorInfoMobile = ({
  onChangeDoc,
  onAddResidentDoctor,
  onDeleteResidentDoctor,
  onExitDoctorPhone,
  residentState,
}) => {
  console.log("currResidentDoctor", residentState.currResidentDoctor);
  console.log("currResidentDoctors", residentState.residentDoctors);

  const formatLabel = (residentDoctor) => {
    return (
      (residentDoctor.name == undefined ? "" : residentDoctor.name) +
      (residentDoctor.phone == undefined || residentDoctor.phone == ""
        ? ""
        : ", " + residentDoctor.phone) +
      (residentDoctor.email == undefined || residentDoctor.email == ""
        ? ""
        : ", " + residentDoctor.email)
    );
  };

  return (
    <Container
      style={{
        border: "solid",
        borderWidth: "1px",
        borderColor: "lightgray",
        height: "650px",
        marginBottom: "10px",
        marginTop: "20px",
      }}
    >
      {residentState.isErrorResDoc ? (
        <Row>
          <Col>
            <div style={errorMsgStyle}>
              <b>ERROR:</b> {residentState.errorMsg}
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <Form.Group style={{ marginTop: "20px" }}>
            <Form.Label size="sm">
              <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>
              Name:
            </Form.Label>
            <Form.Control
              size="sm"
              name="name"
              style={{ width: "100%" }}
              onChange={(e) => onChangeDoc(e)}
              placeholder="Doctor full name"
              autoComplete="nope"
              value={
                residentState.currResidentDoctor &&
                residentState.currResidentDoctor.name
              }
              autoComplete="nope"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label size="sm">Phone:</Form.Label>
            <Form.Control
              size="sm"
              name="phone"
              style={{ width: "100%" }}
              onBlur={onExitDoctorPhone}
              onChange={(e) => onChangeDoc(e)}
              placeholder="Doctor phone"
              autoComplete="nope"
              value={
                residentState.currResidentDoctor &&
                residentState.currResidentDoctor.phone
              }
              autoComplete="nope"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label size="sm">Email:</Form.Label>
            <Form.Control
              size="sm"
              name="email"
              style={{ width: "100%" }}
              onChange={(e) => onChangeDoc(e)}
              placeholder="Doctor e-mail"
              autoComplete="nope"
              value={
                residentState.currResidentDoctor &&
                residentState.currResidentDoctor.email
              }
              autoComplete="nope"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            size="sm"
            variant="success"
            style={{ width: "100%" }}
            onClick={() => onAddResidentDoctor()}
          >
            <TiPlus
              style={{
                color: "white",
                width: "25px",
                height: "25px",
                verticalAlign: "middle",
              }}
            />
            {"  "}Add doctor
          </Button>
        </Col>
      </Row>
      <Row>
        <Form.Control
          as="select"
          onDoubleClick={(e) => onDeleteResidentDoctor(e)}
          multiple
          style={{
            height: "212px",
            maxHeight: "212px",
            marginTop: "70px",
          }}
        >
          {residentState.residentDoctors &&
            residentState.residentDoctors.map((residentDoctor) => {
              return (
                <option key={residentDoctor.id} value={residentDoctor.id}>
                  {formatLabel(residentDoctor)}
                </option>
              );
            })}
        </Form.Control>
        <Form.Text id="passwordHelpBlock" muted>
          TO DELETE DOCTOR JUST DOUBLE CLICK IT
        </Form.Text>
      </Row>
    </Container>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default DoctorInfoMobile;
