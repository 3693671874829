import React, { useEffect } from "react";
import { Container, Modal, Button, Col, Row } from "react-bootstrap";
import AddTodoDesktop from "./add-todo/desktop/addtodoDesktop";
import "react-datepicker/dist/react-datepicker.css";
import TodoListDesktop from "./desktop/todoListDesktop";
import moment from "moment";
import CalendarViewDesktop from "./calendar/desktop/calendarViewDesktop";
import ScheduleDektop from "./calendar/desktop/scheduleDektop";
import { TiArrowBack, TiPlus } from "react-icons/ti";
import useTodoState from "./hook/useTodoState";
import MainTodosDesktop from "./desktop/mainTodosDesktop";
import TodoComments from "./comments/todoComments";
import TodoReschedule from "./todoReschedule";
import useWindowSize from "../common/hook/useWindowSize";
import MainTodosMobile from "./mobile/mainTodosMobile";

const MainTodos = () => {
  const size = useWindowSize();
  const {
    refreshPage,
    onDeleteTodo,
    onAddTodo,
    onShowTaskDetails,
    onBackToCalendar,
    onNextMonth,
    onPreviousMonth,
    onChangeView,
    onSetCurrentDate,
    onChangeMonth,
    onChangeYear,
    onRescheduleTodo,
    onShowReminder,
    onChange,
    onChangeDates,
    onCloseTodoReschedule,
    onCloseTodoDetails,
    onShowTodoDetails,
    onShowTodoReschedule,
    onChangeComment,
    onSaveComment,
    todoState,
  } = useTodoState({});
  const months = [
    { id: 0, name: "January" },
    { id: 1, name: "February" },
    { id: 2, name: "March" },
    { id: 3, name: "April" },
    { id: 4, name: "May" },
    { id: 5, name: "June" },
    { id: 6, name: "July" },
    { id: 7, name: "August" },
    { id: 8, name: "September" },
    { id: 9, name: "October" },
    { id: 10, name: "November" },
    { id: 11, name: "December" },
  ];

  const weekdays = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ];

  useEffect(() => {
    refreshPage();
  }, []);

  return size.width > 970 ? (
    <MainTodosDesktop
      onDeleteTodo={onDeleteTodo}
      onAddTodo={onAddTodo}
      onShowTaskDetails={onShowTaskDetails}
      onBackToCalendar={onBackToCalendar}
      onNextMonth={onNextMonth}
      onPreviousMonth={onPreviousMonth}
      onChangeView={onChangeView}
      onSetCurrentDate={onSetCurrentDate}
      onChangeMonth={onChangeMonth}
      onChangeYear={onChangeYear}
      onRescheduleTodo={onRescheduleTodo}
      onShowReminder={onShowReminder}
      onChange={onChange}
      onChangeDates={onChangeDates}
      onCloseTodoReschedule={onCloseTodoReschedule}
      onCloseTodoDetails={onCloseTodoDetails}
      onShowTodoDetails={onShowTodoDetails}
      onShowTodoReschedule={onShowTodoReschedule}
      onChangeComment={onChangeComment}
      onSaveComment={onSaveComment}
      months={months}
      weekdays={weekdays}
      todoState={todoState}
    />
  ) : (
    <MainTodosMobile
      onDeleteTodo={onDeleteTodo}
      onAddTodo={onAddTodo}
      onShowTaskDetails={onShowTaskDetails}
      onBackToCalendar={onBackToCalendar}
      onNextMonth={onNextMonth}
      onPreviousMonth={onPreviousMonth}
      onChangeView={onChangeView}
      onSetCurrentDate={onSetCurrentDate}
      onChangeMonth={onChangeMonth}
      onChangeYear={onChangeYear}
      onRescheduleTodo={onRescheduleTodo}
      onShowReminder={onShowReminder}
      onChange={onChange}
      onChangeDates={onChangeDates}
      onCloseTodoReschedule={onCloseTodoReschedule}
      onCloseTodoDetails={onCloseTodoDetails}
      onShowTodoDetails={onShowTodoDetails}
      onShowTodoReschedule={onShowTodoReschedule}
      onChangeComment={onChangeComment}
      onSaveComment={onSaveComment}
      months={months}
      weekdays={weekdays}
      todoState={todoState}
    />
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "200px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "200px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainTodos;
