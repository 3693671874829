import React from "react";
import DatePicker from "react-datepicker";
import { Button, Container, Form, Row, Col } from "react-bootstrap";

const MarkAsPassedMobile = ({
  onChangePassDate,
  onChangeStartDate,
  onChangeHours,
  onSaveMarkAsPassed,
  onHideMarkAsPassed,
  trainingState,
}) => {
  return (
    <React.Fragment>
      <Container>
        {trainingState.isError ? (
          <Row style={errorMsgStyle}>
            <Col>
              <b>ERROR:</b> {trainingState.errorMsg}
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col>
            <Form.Label size="sm">Start date:</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <DatePicker
              selected={
                trainingState.markAsPassedDetails &&
                trainingState.markAsPassedDetails.startDate
              }
              placeholderText="Start date"
              name="startDate"
              onChange={(e) => onChangeStartDate(e)}
              dateFormat="MM/dd/yyyy"
              disabledKeyboardNavigation
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label size="sm">End date:</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <DatePicker
              selected={
                trainingState.markAsPassedDetails &&
                trainingState.markAsPassedDetails.passDate
              }
              placeholderText="End date"
              name="passDate"
              onChange={(e) => onChangePassDate(e)}
              dateFormat="MM/dd/yyyy"
              disabledKeyboardNavigation
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label size="sm">Hours of training:</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              type="number"
              className="form-control"
              size="sm"
              style={{ width: "70px" }}
              onChange={(e) => onChangeHours(e)}
              value={
                trainingState.markAsPassedDetails &&
                trainingState.markAsPassedDetails.durationHours
              }
              autoComplete="none"
            />
          </Col>
        </Row>
        <Row>
          <br />
        </Row>
        <Row>
          <Col>
            <Button
              size="sm"
              style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}
              variant="success"
              onClick={() => onSaveMarkAsPassed()}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="outline-success"
              size="sm"
              style={{ width: "100%" }}
              variant="primary"
              onClick={() => onHideMarkAsPassed()}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default MarkAsPassedMobile;
