import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AuthenticationService from "../../api/authenticationService";

class HeaderCompliance extends React.Component {
  onHome() {
    window.open("/", "_self");
  }

  onCompliance() {
    window.open("/compliance", "_self");
  }

  onBack() {
    window.history.back();
  }

  render() {
    return (
      <React.Fragment>
        <table
          style={{
            width: "100%",
            color: "#B22234",
            borderBottom: "solid",
            borderWidth: "1px",
            marginBottom: "10px",
            borderColor: "#3C3B6E",
          }}
        >
          <tbody>
            <tr>
              <td style={{ width: "500px" }}>
                <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-"bottom"`}>
                      Click to go on home page
                    </Tooltip>
                  }
                >
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => this.onHome()}
                    style={{ color: "#B22234" }}
                  >
                    <b>Home</b>
                  </Button>
                </OverlayTrigger>
                /
                <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-"bottom"`}>
                      Click to go on compliance page
                    </Tooltip>
                  }
                >
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => this.onCompliance()}
                    style={{ color: "#B22234" }}
                  >
                    <b>Compliance</b>
                  </Button>
                </OverlayTrigger>
              </td>
              <td align="right">
                <Button
                  variant="link"
                  size="sm"
                  style={{ color: "#B22234" }}
                  onClick={AuthenticationService.logout}
                >
                  <b>Logout {sessionStorage.getItem("authenticatedUser")}</b>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default HeaderCompliance;
