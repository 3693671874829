import React from "react";
import { Card, Button, Badge } from "react-bootstrap";

const MainPageItem = ({ index, onOpenMenu, item, mainPageState }) => {
  return (
    <Card key={index} className="text-center">
      <Card.Header style={{ alignContent: "center" }}>
        <Button
          variant="outline-light"
          block
          style={{ color: "black" }}
          onClick={() => onOpenMenu(item.uri)}
          disabled={item.disabled}
        >
          {" "}
          <img
            src={item.img}
            className="App-logo"
            alt="logo"
            style={{ width: "50px", height: "50px" }}
          ></img>
          <b>{item.name.toUpperCase()}</b>{" "}
          {item.name.toUpperCase() == "COLLABORATION" ? (
            <Badge
              variant="dark"
              size="lg"
              style={{ backgroundColor: "#B22234", color: "#f2f2f2" }}
            >
              {mainPageState.countAnnouncements}
            </Badge>
          ) : (
            ""
          )}
        </Button>
      </Card.Header>
      <Card.Body
        style={{
          padding: "0px",
          margin: "0px",
        }}
      >
        <Button
          variant="outline-light"
          block
          style={{
            color: "black",
            minHeight: "100px",
            maxHeight: "100px",
            padding: "0px",
            margin: "0px",
          }}
          onClick={() => onOpenMenu(item.uri)}
          disabled={item.disabled}
        >
          {item.description}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default MainPageItem;
