import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import moment from "moment";

const GeneralInfoDesktop = ({ residentState, onEditResident }) => {
  return (
    <Container className="bg-warning" fluid>
      <Row style={{ verticalAlign: "middle" }}>
        <Col
          className="bg-warning"
          style={{
            fontWeight: "bold",
            width: "100%",
            fontSize: "20px",
            verticalAlign: "middle",
            marginTop: "15px",
            color: "black",
          }}
        >
          {residentState.currResident.firstName.toUpperCase()}{" "}
          {residentState.currResident.lastName.toUpperCase()}
          {" - "}
          {residentState.currResident &&
            moment().diff(
              moment(residentState.currResident.dob).format("YYYY-MM-DD"),
              "years"
            )}{" "}
          years old, admitted on{" "}
          {moment(residentState.currResident.admissionDate).format(
            "DD/MM/YYYY"
          )}
        </Col>
        <Col className="bg-warning" style={{ textAlign: "right" }}>
          <Button
            variant="warning"
            disabled={
              residentState &&
              residentState.currResident &&
              !residentState.currResident.isActive
            }
            style={{
              width: "150px",
              fontSize: "20px",
              marginTop: "5px",
              marginBottom: "5px",
              backgroundColor: "white",
              color: "#f0ad4e",
            }}
            onClick={() => onEditResident(residentState.currResident)}
          >
            Edit
            {/* {resident.firstName.toUpperCase()} {resident.lastName.toUpperCase()}{" "}
            {resident &&
              moment().diff(
                moment(resident.dob).format("YYYY-MM-DD"),
                "years"
              )}{" "}
            years old, admitted{" "}
            {moment(resident.admissionDate).format("DD/MM/YYYY")} */}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneralInfoDesktop;
