import React from "react";
import { Container, Form, Row, Col } from "react-bootstrap";

const GeneralInfo = ({ onChangeInput, lic622State, complianceFormsState }) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };
  return (
    <React.Fragment>
      <Container fluid style={{ marginTop: "20px" }}>
        <Row>
          <Col md={6}>
            <Form.Label>
              <b>Facility name:</b>
            </Form.Label>
            <Form.Control
              name="facilityName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityName", e)}
              value={lic622State && lic622State.facilityName}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={3}>
            <Form.Label>
              <b>Facility file number</b>
            </Form.Label>
            <Form.Control
              name="facilityLicenseNum"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityLicenseNum", e)}
              value={lic622State && lic622State.facilityLicenseNum}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={3}>
            <Form.Label>
              <b>Administrator</b>
            </Form.Label>
            <Form.Control
              name="facilityAdministrator"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityAdministrator", e)}
              value={lic622State && lic622State.facilityAdministrator}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Label>
              <b>First name:</b>
            </Form.Label>
            <Form.Control
              name="residentFirstName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentFirstName", e)}
              value={lic622State && lic622State.residentFirstName}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={3}>
            <Form.Label>
              <b>Last name:</b>
            </Form.Label>
            <Form.Control
              name="residentLastName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentLastName", e)}
              value={lic622State && lic622State.residentLastName}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Label>
              <b>Middle name:</b>
            </Form.Label>
            <Form.Control
              name="residentMiddleName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentMiddleName", e)}
              value={lic622State && lic622State.residentMiddleName}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Label>
              <b>Admission date:</b>
            </Form.Label>
            <Form.Control
              name="admissionDate"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("admissionDate", e)}
              value={lic622State && lic622State.admissionDate}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={3}>
            <Form.Label>
              <b>Resident physician:</b>
            </Form.Label>
            <Form.Control
              name="residentPhysician"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentPhysician", e)}
              value={lic622State && lic622State.residentPhysician}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GeneralInfo;
