import ResidentFormSignClient from "../../../api/compliance/documents/residentFormSignClient";

const residentFormSignClient = new ResidentFormSignClient();

class ResidentFormSignService {
  async getByTemplateAndSignatureType(reportType) {
    try {
      const response =
        await residentFormSignClient.getByTemplateAndSignatureType(reportType);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAvailableSignatureKeys(repo) {
    try {
      const response = await residentFormSignClient.getAvailableSignatureKeys(
        repo
      );

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new ResidentFormSignService();
