import React, { useRef } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import JoditEditor from "jodit-react";

const ShareDocumentMobile = ({
  onChangeEmail,
  onBlurEmail,
  onHideShareDocument,
  onSendEmail,
  onChangeContent,
  myDocumentState,
}) => {
  const editor = useRef(null);

  const config = {
    askBeforePasteHTML: false,
    processPasteHTML: true, // all options from https://xdsoft.net/jodit/doc/
    height: "400",
  };
  return (
    <Container>
      <Row>
        <Col>
          {myDocumentState.isError ? (
            <div style={errorMsgStyle}>
              <b>ERROR:</b> {myDocumentState.errorMsg}
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: "20px" }}>
          <Form.Control
            size="sm"
            name="email"
            placeholder="ca.gov email"
            onChange={(e) => onChangeEmail(e)}
            onBlur={(e) => onBlurEmail(e)}
            required
            style={{ width: "100%" }}
            value={
              myDocumentState && myDocumentState.currEmail
                ? myDocumentState.currEmail
                : ""
            }
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: "20px" }}>
          <JoditEditor
            ref={editor}
            value={
              myDocumentState && myDocumentState.currMessage
                ? myDocumentState.currMessage
                : ""
            }
            style={{ width: "100%" }}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) =>
              onChangeContent(newContent.target.innerHTML)
            } // preferred to use only this option to update the content for performance reasons
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            size="sm"
            variant="success"
            style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}
            onClick={() => onSendEmail()}
          >
            Send
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="outline-success"
            size="sm"
            style={{
              width: "100%",
            }}
            onClick={onHideShareDocument}
          >
            Close
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default ShareDocumentMobile;
