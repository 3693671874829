import React from "react";
import { Button } from "react-bootstrap";
import { TiArrowBack } from "react-icons/ti";

const BackButton = ({ onBack }) => {
  return (
    <Button
      variant="warning"
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        width: "150px",
        color: "#ffc107",
        backgroundColor: "white",
        float: "right",
      }}
      onClick={() => onBack()}
    >
      <TiArrowBack
        style={{
          color: "#ffc107",
          backgroundColor: "white",
          width: "25px",
          height: "25px",
          verticalAlign: "middle",
        }}
      />
      {"  "}
      Go Back
    </Button>
  );
};

export default BackButton;
