import React from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";

const TodoReschedule = ({ onRescheduleTodo, onChangeDates, todoState }) => {
  return (
    <div style={defaultStyle}>
      {todoState.isError ? (
        <div style={errorMsgStyle}>
          <b>ERROR:</b> {todoState.errorMsg}
        </div>
      ) : (
        ""
      )}
      <p>
        <b>{todoState.currTodo && todoState.currTodo.title}</b>
      </p>
      <p>{todoState.currTodo && todoState.currTodo.description}</p>
      <br></br>
      <p>
        <DatePicker
          name="startDate"
          selected={
            todoState.currTodo &&
            todoState.currTodo.startDate &&
            new Date(todoState.currTodo.startDate)
          }
          placeholderText="Reminder Date and Time*"
          onChange={(e) => onChangeDates(e, "startDate")}
          showTimeSelect
          timeIntervals={15}
          dateFormat="MM/dd/yyyy h:mm aa"
          disabledKeyboardNavigation
          autoComplete="off"
        />
      </p>
      <Button
        variant="success"
        size="sm"
        style={{ width: "100px" }}
        onClick={() => onRescheduleTodo()}
      >
        Reschedule
      </Button>
    </div>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

const defaultStyle = {
  color: "#3C3B6E",
};

export default TodoReschedule;
