import { useState } from "react";
import medication from "../../../img/medication_colored.png";
import product from "../../../img/product_colored.png";
import todo from "../../../img/reminder_colored.png";
import people from "../../../img/people_colored.png";
import resident from "../../../img/residents_colored.jpg";
import training from "../../../img/training_colored.jpg";
import checklist from "../../../img/checklist_colored.jpg";
import document from "../../../img/document_colored.png";
import forms from "../../../img/forms.png";
import ViewService from "../../../services/viewService";
import AnnouncementService from "../../../services/collaboration/announcementService";
import AnnouncementReadService from "../../../services/collaboration/announcementsReadService";
import StaffService from "../../../services/user/staffService";
import AuthenticationService from "../../../api/authenticationService";
import BillingService from "../../../services/billing/billingService";
import FacilityModulesService from "../../../services/facility/facilityModulesService";
import moment from "moment";

const useMainPageState = () => {
  const [mainPageState, setMainPageState] = useState({
    countAnnouncements: 0,
    showMenu: true,
    currView: "/",
    changePassword: {},
    card: {
      cardYear: moment().year(),
      cardMonth: 1,
    },
  });

  const refreshPage = async () => {
    const staffFacility = await ViewService.getStaffFacilityByUserName();
    const announcements = await AnnouncementService.getAll();
    const announcementsRead = await AnnouncementReadService.getAll();
    const facilityModules = await FacilityModulesService.getByFacilityId();
    console.log("facilityModules", facilityModules);
    const currUserList = await StaffService.getByUserName(
      sessionStorage.getItem("authenticatedUser")
    );

    const currStaffFacility = staffFacility && staffFacility[0];
    let isFirstLogIn = false;
    if (
      currStaffFacility.facilityId == undefined ||
      currStaffFacility.facilityId == null
    ) {
      isFirstLogIn = true;
    }

    let currUser = currUserList[0];

    currUser["password"] = "";

    var countAnnouncements = 0;
    countAnnouncements =
      announcements &&
      announcements.filter((ann) => {
        var isNew = true;
        if (announcementsRead != undefined) {
          announcementsRead.forEach(function (announcementsRead) {
            if (announcementsRead["announcement"]["id"] == ann.id) {
              isNew = false;
            }
          });
        }

        if (isNew) return ann;
      }).length;

    var sideBarMenu = [];
    var dropDownMenu = [];
    if (currStaffFacility != undefined && currStaffFacility.role == "admin") {
      sideBarMenu = [
        { name: "Home", uri: "/" },
        {
          name: "Compliance",
          img: forms,
          uri: "/complianceForms",
          disabled:
            !facilityModules.length > 0 &&
            !facilityModules.includes("compliance"),
          description: "Create, share and sign compliance reports",
        },
        {
          name: "Checklist",
          img: checklist,
          uri: "/checklist",
          disabled:
            !facilityModules.length > 0 &&
            !facilityModules.includes("checklist"),
          description:
            "Prepare checklist for compliance requirements - Coming soon",
        },
        {
          name: "Reminders",
          img: todo,
          uri: "/todo",
          disabled:
            !facilityModules.length > 0 && !facilityModules.includes("todo"),
          description: "Calendar of important events - Coming soon",
        },
        {
          name: "Medications",
          img: medication,
          uri: "/medication",
          disabled:
            !facilityModules.length > 0 &&
            !facilityModules.includes("medication"),
          description: "Medication ordering - Coming soon",
        },
        {
          name: "Shopping",
          img: product,
          uri: "/product",
          disabled:
            !facilityModules.length > 0 && !facilityModules.includes("product"),
          description: "Manage shopping cart - Coming soon",
        },
        {
          name: "Collaboration",
          img: people,
          uri: "/collaboration",
          disabled:
            !facilityModules.length > 0 &&
            !facilityModules.includes("collaboration"),
          description: "Share messages with other facilities - Coming soon",
        },
        {
          name: "My Documents",
          img: document,
          uri: "/myDocuments",
          disabled:
            !facilityModules.length > 0 &&
            !facilityModules.includes("myDocuments"),
          description: "My digital archive - Coming soon",
        },
        {
          name: "Training",
          img: training,
          uri: "/training",
          disabled:
            !facilityModules.length > 0 &&
            !facilityModules.includes("training"),
          description: "Manage trainings and issue certificates - Coming soon",
        },
        //{ name: "Residents", img: resident, uri: "/resident" },
        // { name: "Users", img: staff, uri: "/staff" },
        // { name: "Facility", img: facility, uri: "/facility" },
      ];
      dropDownMenu = [
        { name: "Residents", eventKey: "openResident" },
        { name: "Staff", eventKey: "openUser" },
        { name: "Facility", eventKey: "openFacility" },
        //{ name: "Guardians", eventKey: "openFamMember" },
        //{ name: "Billing Information", eventKey: "billInfo" },
      ];
    }

    if (
      currStaffFacility != undefined &&
      currStaffFacility.role != "admin" &&
      currStaffFacility.userRole == "A"
    ) {
      sideBarMenu = [
        { name: "Home", uri: "/" },
        { name: "Reminders", img: todo, uri: "/todo" },
        { name: "Medications", img: medication, uri: "/medication" },
        { name: "Shopping", img: product, uri: "/product" },
        { name: "Collaboration", img: people, uri: "/collaboration" },
        // { name: "Residents", img: resident, uri: "/resident" },
        { name: "Staff Training", img: training, uri: "/training" },
        { name: "Checklist", img: checklist, uri: "/checklist" },
      ];
    }

    if (
      currStaffFacility != undefined &&
      currStaffFacility.role != "admin" &&
      currStaffFacility.userRole == "E"
    ) {
      sideBarMenu = [
        { name: "Home", uri: "/" },
        { name: "Reminders", img: todo, uri: "/todo" },
        { name: "Shopping", img: product, uri: "/product" },
        { name: "My Training", img: training, uri: "/mytraining" },
      ];
    }

    if (currStaffFacility != undefined && currStaffFacility.userRole == "F") {
      sideBarMenu = [
        { name: "Home", uri: "/" },
        {
          name: "Compliance",
          img: document,
          uri: "/complianceForms",
          disabled: false,
          description: "Manage, share and sign compliance reports",
        },
      ];
    }

    const rowCompliance = [
      { name: "Residents", img: resident, uri: "/resident" },
      { name: "Staff Training", img: training, uri: "/training" },
      { name: "Checklist", img: checklist, uri: "/checklist" },
    ];

    const rowNotSupportedOnMobile = [
      { name: "Residents", img: resident, uri: "/resident" },
      { name: "Training", img: training, uri: "/training" },
      { name: "Checklist", img: checklist, uri: "/checklist" },
      { name: "My Documents", img: document, uri: "/myDocuments" },
    ];

    setMainPageState({
      ...mainPageState,
      currStaffFacility: currStaffFacility,
      sideBarMenu: sideBarMenu,
      dropDownMenu: dropDownMenu,
      rowCompliance: rowCompliance,
      countAnnouncements: countAnnouncements,
      showChangePassword: currUser.isFirstLogIn,
      showBillInfo: !currUser.isFirstLogIn && currUser.isProvideBillingInfo,
      isFirstLogIn: isFirstLogIn,
      currUser: currUser,
      rowNotSupportedOnMobile: rowNotSupportedOnMobile,
      // nextTwoReminders: sortedReminders && sortedReminders.slice(0, 2),
    });
  };

  const onOpenMenu = (item) => {
    setMainPageState({ ...mainPageState, currView: item });
  };

  const onToggleMenu = () => {
    setMainPageState({ ...mainPageState, showMenu: !mainPageState.showMenu });
  };

  const onGotoHome = () => {
    setMainPageState({ ...mainPageState, currView: "/" });
  };

  const onSelectAvatarList = async (key) => {
    if (key == "logout") {
      AuthenticationService.logout();
    } else if (key == "openUser") {
      onOpenMenu("/staff");
    } else if (key == "openFacility") {
      onOpenMenu("/facility");
    } else if (key == "openResident") {
      onOpenMenu("/resident");
    } else if (key == "openFamMember") {
      onOpenMenu("/famMember");
    } else if (key == "chgpass") {
      setMainPageState({ ...mainPageState, showChangePassword: true });
    } else if (key == "chgsignature") {
      onOpenMenu("/chgsignature");
    } else if (key == "billInfo") {
      await onOpenBillInfo();
    }
  };

  const onHideChangePassword = () => {
    setMainPageState({
      ...mainPageState,
      showChangePassword: false,
      changePassword: {},
      isError: false,
      errorMsg: "",
    });
  };

  const onHideBillInfo = () => {
    setMainPageState({
      ...mainPageState,
      billingInfo: {},
      customerInfo: {},
      paymentMethodInfo: {},
      showBillInfo: false,
      billInfo: {},
      isError: false,
      errorMsg: "",
      editBillInfo: false,
    });
  };

  const onChangeChangePassword = (e) => {
    const changePassword = mainPageState.changePassword;
    changePassword[e.target.name] = e.target.value;
    setMainPageState({
      ...mainPageState,
      changePassword: changePassword,
      isError: false,
      errorMsg: "",
    });
  };

  const onSaveChangePassword = async () => {
    const validatedChangePassword = await StaffService.validateChangePassword(
      mainPageState.changePassword
    );

    if (validatedChangePassword.isError) {
      setMainPageState({
        ...mainPageState,
        isError: true,
        errorMsg: validatedChangePassword.errMessage,
      });
      return;
    }

    const savedStaff = await StaffService.changePassword(
      mainPageState.changePassword
    );

    alert("Your password is changed successfully!");

    setMainPageState({
      ...mainPageState,
      showChangePassword: false,
      currUser: savedStaff,
    });
  };

  const onOpenBillInfo = async () => {
    const billingInfo = await BillingService.getAll();
    var customerInfo = {};
    var paymentMethodInfo = {};

    if (billingInfo.customerId != undefined) {
      customerInfo = await BillingService.getCustomer();
    }

    if (billingInfo.customerId != undefined) {
      paymentMethodInfo = await BillingService.getPaymentMethod();
    }

    setMainPageState({
      ...mainPageState,
      billingInfo: billingInfo,
      customerInfo: customerInfo,
      paymentMethodInfo: paymentMethodInfo,
      showBillInfo: true,
    });
  };

  const onShowPassword = (inputField) => {
    setMainPageState({ ...mainPageState, showPassword: inputField });
  };

  const onHidePassword = () => {
    setMainPageState({
      ...mainPageState,
      showPassword: "",
    });
  };

  const onEditBillInfo = () => {
    setMainPageState({ ...mainPageState, editBillInfo: true });
  };

  const onCancelEdit = () => {
    if (
      mainPageState &&
      mainPageState.currUser &&
      mainPageState.currUser.isProvideBillingInfo
    ) {
      alert("Please save your credit card info !");
      return;
    }
    setMainPageState({
      ...mainPageState,
      showBillInfo: false,
      isError: false,
      errorMsg: "",
      card: { cardYear: moment().year(), cardMonth: 1 },
      editBillInfo: false,
    });
  };

  const onChange = (e) => {
    let value = e.target.value;
    const card = { ...mainPageState.card };
    card[e.target.name] = value;

    setMainPageState({
      ...mainPageState,
      card: card,
      isError: false,
      errorMsg: "",
    });
  };

  const onSaveCard = async () => {
    const validatedCard = BillingService.validateBillingInfo(
      mainPageState.card
    );

    if (validatedCard.isError) {
      setMainPageState({
        ...mainPageState,
        isError: true,
        errorMsg: validatedCard.msg,
      });
      return;
    }

    let customerFormData = new FormData();

    customerFormData.append(
      "name",
      mainPageState.card && mainPageState.card.name
    );
    customerFormData.append(
      "email",
      mainPageState.card && mainPageState.card.email
    );
    customerFormData.append(
      "cardNum",
      mainPageState.card &&
        mainPageState.card.cardNum1
          .concat(mainPageState.card.cardNum2)
          .concat(mainPageState.card.cardNum3)
          .concat(mainPageState.card.cardNum4)
    );
    customerFormData.append(
      "cardMonth",
      mainPageState.card && mainPageState.card.cardMonth
    );
    customerFormData.append(
      "cardYear",
      mainPageState.card && mainPageState.card.cardYear
    );
    customerFormData.append(
      "cardCvc",
      mainPageState.card && mainPageState.card.cardCvc
    );

    const customerBillingInfo = await BillingService.createCustomer(
      customerFormData
    );

    if (customerBillingInfo.isError) {
      setMainPageState({
        ...mainPageState,
        isError: true,
        errorMsg: customerBillingInfo.msg,
      });

      return;
    }

    alert("Billing information is saved successfully!");

    let currUser = mainPageState.currUser;
    currUser["isProvideBillingInfo"] = false;

    setMainPageState({
      ...mainPageState,
      showBillInfo: false,
      isError: false,
      errorMsg: "",
      card: { cardYear: moment().year(), cardMonth: 1 },
      editBillInfo: false,
      currUser: currUser,
    });
  };

  const onHideDepositSignature = () => {
    onOpenMenu("/");
  };

  const onSaveSignature = async (base64Signature) => {
    if (
      base64Signature ==
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAABkCAYAAABwx8J9AAAAAXNSR0IArs4c6QAABEZJREFUeF7t1QENAAAIwzDwbxodLMXBe5LvOAIECBAgQOC9wL5PIAABAgQIECAwBt0TECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQOEexAGVgyV5WAAAAAElFTkSuQmCC"
    ) {
      alert("Signature field is empty, please write your signature!");
      return;
    }

    await StaffService.uploadSignature({
      base64Content: base64Signature,
    });

    await onHideDepositSignature();
  };

  const onGetSignature = async () => {
    return await StaffService.getSignature();
  };

  return {
    refreshPage,
    onOpenMenu,
    onToggleMenu,
    onGotoHome,
    onSelectAvatarList,
    onHideChangePassword,
    onChangeChangePassword,
    onSaveChangePassword,
    onShowPassword,
    onHidePassword,
    onHideBillInfo,
    onEditBillInfo,
    onCancelEdit,
    onChange,
    onSaveCard,
    onHideDepositSignature,
    onSaveSignature,
    mainPageState,
  };
};

export default useMainPageState;
