import APIClient from "../apiClient";

const FACILITY_CARE = "/facility";

class FacilityClient extends APIClient {
  delete(id) {
    return super.perform(
      "delete",
      super.getBaseContext() + FACILITY_CARE + "/" + id
    );
  }

  async update(repo, id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + FACILITY_CARE + "/" + id,
      repo
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + FACILITY_CARE,
      repo
    );
  }

  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + FACILITY_CARE
    );
  }

  async getFromToken() {
    return super.performAsync(
      "get",
      super.getBaseContext() + FACILITY_CARE + "/fromToken"
    );
  }
}

export default FacilityClient;
