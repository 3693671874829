import React from "react";
import { Button, Form, Table } from "react-bootstrap";
import CheckListMobile from "./checkListMobile";
import AddTodoMobile from "../../../todo/add-todo/mobile/addtodoMobile";

const ScheduleCheckMobile = ({
  onFilterCheckList,
  onSelectCheck,
  onHideScheduleCheckList,
  onGoToSchedule,
  onSaveScheduleCheckListControl,
  onChangeTodo,
  onChangeTodoDates,
  checkListState,
}) => {
  console.log("scheduleView", checkListState.scheduleView);
  return (
    <React.Fragment>
      {checkListState.scheduleView == "todo" ? (
        <AddTodoMobile
          onAddTodo={onSaveScheduleCheckListControl}
          onClose={onHideScheduleCheckList}
          onChange={onChangeTodo}
          onChangeDates={onChangeTodoDates}
          todo={checkListState.currTodo}
          isError={checkListState.isError}
          errorMsg={checkListState.errorMsg}
        />
      ) : (
        <CheckListMobile
          filterCheckList={onFilterCheckList}
          filteredCheckLists={checkListState.filteredCheckLists}
          // criteria={criteria}
          handleSelected={onSelectCheck}
          selectedCheckList={checkListState.selectedCheckList}
          hideScheduleCheckList={onHideScheduleCheckList}
          goToSchedule={onGoToSchedule}
        />
      )}
    </React.Fragment>
  );
};

export default ScheduleCheckMobile;
