import React from "react";
import Button from "react-bootstrap/Button";
import useWindowSize from "../../common/hook/useWindowSize";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsFillPersonFill } from "react-icons/bs";
import { ImHome } from "react-icons/im";
import { isMobile } from "react-device-detect";

const MainPageHeaderDesktop = ({
  onToggleMenu,
  onSelectAvatarList,
  onGotoHome,
  mainPageState,
  currView,
}) => {
  const size = useWindowSize();
  function getCurrViewName(currView) {
    if (currView == "/todo") {
      return "Reminders";
    }

    if (currView == "/medication") {
      return "Medication order";
    }

    if (currView == "/product") {
      return "Shopping planner";
    }

    if (currView == "/collaboration") {
      return "Messages";
    }

    if (currView == "/staff") {
      return "My staff";
    }

    if (currView == "/facility") {
      return "Facility details";
    }

    if (currView == "/mytraining") {
      return "My training";
    }

    if (currView == "/resident") {
      return "Residents";
    }

    if (currView == "/training") {
      return "Training";
    }

    if (currView == "/checklist") {
      return "Checklist";
    }

    if (currView == "/famMember") {
      return "Guardians";
    }

    if (currView == "/") {
      return "Home";
    }

    if (currView == "/myDocuments") {
      return "My Documents";
    }

    if (currView == "/compliance") {
      return "Compliance reports";
    }

    return "";
  }

  return (
    <React.Fragment>
      <Container
        fluid
        style={{
          margin: "0px",
          borderBottom: "solid",
          borderBottomWidth: "1px",
          marginBottom: "0px",
          marginTop: "3px",
        }}
      >
        <Row style={{}}>
          <Col xs="auto">
            {!mainPageState.showMenu ? (
              <React.Fragment>
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderColor: "black",
                    verticalAlign: "middle",
                    width: "45px",
                    height: "35px",
                  }}
                  onClick={() => onToggleMenu()}
                >
                  <GiHamburgerMenu
                    style={{ width: "20px", height: "20px", color: "black" }}
                  />
                </Button>
                {"   "}
              </React.Fragment>
            ) : (
              ""
            )}
            <Button
              style={{
                backgroundColor: "transparent",
                borderColor: "black",
                verticalAlign: "middle",
                width: "45px",
                height: "35px",
              }}
              onClick={() => onGotoHome()}
            >
              <ImHome
                style={{ width: "20px", height: "20px", color: "black" }}
              />
            </Button>
          </Col>
          {size.width > 400 ? (
            <Col style={{ marginTop: "6px", textAlign: "center" }}>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {getCurrViewName(currView).toUpperCase()}
              </div>
            </Col>
          ) : (
            ""
          )}
          <Col
            xs="auto"
            style={{ textAlign: "right", verticalAlign: "center" }}
          >
            <Dropdown
              onSelect={(selectedKey) => onSelectAvatarList(selectedKey)}
            >
              <Dropdown.Toggle
                variant="success"
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  borderColor: "transparent",
                }}
                id="dropdown-basic"
              >
                <BsFillPersonFill
                  style={{
                    color: "black",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {mainPageState.dropDownMenu != undefined &&
                mainPageState.dropDownMenu.length > 0
                  ? mainPageState.dropDownMenu.map((item) => (
                      <Dropdown.Item
                        key={item.eventKey}
                        eventKey={item.eventKey}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))
                  : ""}
                {mainPageState.dropDownMenu != undefined &&
                mainPageState.dropDownMenu.length > 0 ? (
                  <Dropdown.Divider />
                ) : (
                  ""
                )}
                {/* <Dropdown.Item eventKey="billInfo">
                  Billing Information
                </Dropdown.Item> */}
                <Dropdown.Item eventKey="chgsignature">
                  Change signature
                </Dropdown.Item>
                <Dropdown.Item eventKey="chgpass">
                  Change password
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="logout">
                  Logout {sessionStorage.getItem("authenticatedUser")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MainPageHeaderDesktop;
