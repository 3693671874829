import React, { useState } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { RiFileEditLine } from "react-icons/ri";
import { AiOutlineMinusSquare } from "react-icons/ai";
import { GiAlarmClock } from "react-icons/gi";
import TableSort from "../../../common/tableSort";
import ViewTraining from "../viewTraining";
import "../table.css";

const TrainingListMobile = ({
  onStaffTraining,
  onDeleteTraining,
  onEditTraining,
  trainingState,
}) => {
  const [byNameType, setByNameType] = useState("");
  const [byDescType, setByDescType] = useState("");
  const [seletedTraining, setSeletedTraining] = useState({});
  const [show, setShow] = useState(false);

  function sortByName(type) {
    resetSortType();
    setByNameType(type);
  }

  function sortByDesc(type) {
    resetSortType();
    setByDescType(type);
  }

  function resetSortType() {
    setByNameType("");
    setByDescType("");
  }

  function viewTraining(training) {
    setSeletedTraining(training);
    setShow(true);
  }

  function hideModal() {
    setShow(false);
  }

  const trainingsSorted =
    trainingState &&
    trainingState.trainings &&
    trainingState.trainings
      .filter(
        (training) => training.active == undefined || training.active == true
      )
      .sort((A, B) => {
        if (byNameType != "" && byNameType != undefined) {
          if (A.name > B.name) {
            if (byNameType == "asc") {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (byNameType == "asc") {
              return -1;
            } else {
              return 1;
            }
          }
        }

        if (byDescType != "" && byDescType != undefined) {
          if (A.description > B.description) {
            if (byDescType == "asc") {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (byDescType == "asc") {
              return -1;
            } else {
              return 1;
            }
          }
        }

        return 0;
      });

  return (
    <React.Fragment>
      {trainingState.trainings &&
      trainingState.trainings.filter(
        (training) => training.active == undefined || training.active == true
      ).length > 0 ? (
        <div class="tableFixHead">
          <Table style={{ fontSize: "12px" }}>
            <thead>
              <tr>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "30px",
                  }}
                ></th>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "30%",
                  }}
                >
                  Training
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                >
                  <TableSort sort={sortByName} type={byNameType} />
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "70%",
                  }}
                >
                  Description
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                >
                  <TableSort sort={sortByDesc} type={byDescType} />
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "30px",
                  }}
                ></th>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "30px",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {trainingsSorted.map((training) => (
                <tr key={training.id}>
                  <td>
                    <Button
                      size="sm"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                      onClick={() => onStaffTraining(training)}
                    >
                      <GiAlarmClock
                        style={{
                          color: "green",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      {/* )} */}
                    </Button>
                  </td>
                  <td colSpan={2}>{training.name}</td>
                  <td colSpan={2}>{training.description}</td>
                  <td style={{ width: "25px" }}>
                    <Button
                      variant="outline-light"
                      size="sm"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                      onClick={() => onDeleteTraining(training)}
                    >
                      <BsTrash
                        style={{
                          color: "#b22234",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Button>
                  </td>
                  <td style={{ width: "25px" }}>
                    <Button
                      variant="outline-light"
                      size="sm"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                      onClick={() => onEditTraining(training)}
                    >
                      <RiFileEditLine
                        style={{
                          color: "orange",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <b style={{ color: "#b22234" }}>
          Your training list is empty, please create trainings!
        </b>
      )}
      {/* <Modal show={show} onHide={() => hideModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Training Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewTraining training={seletedTraining} />
        </Modal.Body>
      </Modal> */}
    </React.Fragment>
  );
};

const thStyle = {
  backgroundColor: "lightgray",
  color: "black",
  border: "none",
  verticalAlign: "middle",
};
export default TrainingListMobile;
