import React from "react";
import { Form } from "react-bootstrap";

const ViewCheckResult = ({ checkResult }) => {
  return (
    <React.Fragment>
      <Form.Label size="sm">Comment:</Form.Label>
      <Form.Control
        as="textarea"
        rows="3"
        size="sm"
        disabled="true"
        style={{ width: "100%" }}
        defaultValue={checkResult.comment}
        autoComplete="none"
      />
    </React.Fragment>
  );
};

export default ViewCheckResult;
