import React, { useEffect } from "react";
import { Container, Row, Col, Button, Modal, Dropdown } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import AddFolderDesktop from "./addFolderDesktop";
import DocumentsDesktop from "./documentsDesktop";
import useMyDocumentsState from "../hook/useMyDocumentsState";
import { FcOpenedFolder } from "react-icons/fc";
import { BsThreeDotsVertical } from "react-icons/bs";
import ShareDocumentDesktop from "./shareDocumentDesktop";

const MainMyDocumentsDesktop = ({
  refreshPage,
  onShowFolder,
  onHideFolder,
  onOpenFacilityFolder,
  onSelectFormMenu,
  onSaveFolder,
  onChangeFolder,
  formatList,
  onCloseDocuments,
  onSetFile,
  onDownload,
  onDeleteDocument,
  onShowShareDocument,
  onHideShareDocument,
  onChangeEmail,
  onBlurEmail,
  onChangeContent,
  onSendEmail,
  myDocumentState,
}) => {
  const facilityFolderMap = formatList(myDocumentState.folders);

  return (
    <React.Fragment>
      {myDocumentState.showDocuments ? (
        <DocumentsDesktop
          onCloseDocuments={onCloseDocuments}
          onSetFile={onSetFile}
          onDownload={onDownload}
          onDeleteDocument={onDeleteDocument}
          onShowShareDocument={onShowShareDocument}
          myDocumentState={myDocumentState}
        />
      ) : (
        <Container fluid>
          <Row
            style={{
              borderBottom: "solid",
              borderBottomColor: "black",
              borderBottomWidth: "1px",
              marginBottom: "10px",
            }}
          >
            <Col>
              <Button
                variant="success"
                style={{ marginBottom: "10px", width: "150px" }}
                onClick={() => onShowFolder()}
              >
                <TiPlus
                  style={{
                    color: "white",
                    width: "25px",
                    height: "25px",
                    verticalAlign: "middle",
                  }}
                />
                {"  "}
                Create Folder
              </Button>
            </Col>
          </Row>
          {facilityFolderMap &&
            Object.entries(facilityFolderMap).map((facFolderMap, i) => {
              return (
                <Row style={{ marginBottom: "20px" }}>
                  {Object.values(facFolderMap[1]).map((facFolder) => {
                    console.log("facFolder", facFolder);
                    return facFolder.name ? (
                      <Col style={{ width: "30%" }}>
                        <Container>
                          <Row>
                            <Col>
                              <Button
                                onClick={() => onOpenFacilityFolder(facFolder)}
                                style={{
                                  backgroundColor: "transparent",
                                  borderColor: "transparent",
                                  color: "black",
                                  verticalAlign: "middle",
                                  textAlign: "left",
                                }}
                              >
                                <div style={{ maxWidth: "100%" }}>
                                  <FcOpenedFolder
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  {"  "}
                                  {facFolder.name.length > 20
                                    ? facFolder.name.substring(0, 20) + "..."
                                    : facFolder.name}
                                </div>
                              </Button>
                            </Col>
                            <Col
                              style={{
                                verticalAlign: "bottom",
                                textAlign: "right",
                              }}
                            >
                              <Dropdown
                                onSelect={(selectedKey) =>
                                  onSelectFormMenu(selectedKey, facFolder)
                                }
                                style={{ verticalAlign: "bottom" }}
                              >
                                <Dropdown.Toggle
                                  variant="success"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "black",
                                    borderColor: "transparent",
                                  }}
                                  id="dropdown-basic"
                                >
                                  <BsThreeDotsVertical
                                    style={{
                                      color: "black",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item eventKey="delete">
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    ) : (
                      <Col></Col>
                    );
                  })}
                </Row>
              );
            })}
        </Container>
      )}
      <Modal show={myDocumentState.showFolder} onHide={onHideFolder}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>CREATE FOLDER</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddFolderDesktop
            onHideFolder={onHideFolder}
            onSaveFolder={onSaveFolder}
            onChangeFolder={onChangeFolder}
            myDocumentState={myDocumentState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={myDocumentState.showShareDocument}
        onHide={onHideShareDocument}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>
              SHARE DOCUMENT WITH CA.GOV E-MAILs
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareDocumentDesktop
            onChangeEmail={onChangeEmail}
            onBlurEmail={onBlurEmail}
            onChangeContent={onChangeContent}
            onHideShareDocument={onHideShareDocument}
            onSendEmail={onSendEmail}
            myDocumentState={myDocumentState}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default MainMyDocumentsDesktop;
