import React from "react";
import { Col, Container, Row, Form } from "react-bootstrap";

const StoredMedications = ({
  onChangeInput,
  lic622State,
  complianceFormsState,
}) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  function getRow(i) {
    const medicationName = "medicationName" + i
    const strength = "strength" + i
    const instructions = "instructions" + i
    const expirationDate = "expirationDate" + i
    const dateFilled = "dateFilled" + i
    const dateStarted = "dateStarted" + i
    const prescribingPhysician = "prescribingPhysician" + i
    const prescriptionNumber = "prescriptionNumber" + i
    const noOfRefils = "noOfRefils" + i
    const nameOfPharmacy = "nameOfPharmacy" + i
    return (
        <Row style={{marginTop: "2px"}}>
          <Col md={2}>
            <Form.Control
              name={medicationName}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(medicationName, e)}
              value={lic622State && lic622State.[medicationName]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={strength}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(strength, e)}
              value={lic622State && lic622State.[strength]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={instructions}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(instructions, e)}
              value={lic622State && lic622State.[instructions]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={expirationDate}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(expirationDate, e)}
              value={lic622State && lic622State.[expirationDate]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={dateFilled}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(dateFilled, e)}
              value={lic622State && lic622State.[dateFilled]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={dateStarted}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(dateStarted, e)}
              value={lic622State && lic622State.[dateStarted]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={prescribingPhysician}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(prescribingPhysician, e)}
              value={lic622State && lic622State.[prescribingPhysician]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={prescriptionNumber}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(prescriptionNumber, e)}
              value={lic622State && lic622State.[prescriptionNumber]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={noOfRefils}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(noOfRefils, e)}
              value={lic622State && lic622State.[noOfRefils]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name={nameOfPharmacy}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(nameOfPharmacy, e)}
              value={lic622State && lic622State.[nameOfPharmacy]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
    )}
  return (
    <Container fluid style={{marginBottom: "60px"}}>
      <Row>
        <Col md={2}>
          <b>
            <small>MEDICATION NAME</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>STRENGTH / QUANTITY</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>INSTRUCTIONS CONTROL / CUSTODY</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>EXPIRATION DATE</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>DATE FILLED</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>DATE STARTED</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>PRESCRIBING PHYSICIAN</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>PRESCRIPTION NUMBER</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>NO. OF REFILLS</small>
          </b>
        </Col>
        <Col md={2}>
          <b>
            <small>NAME OF PHARMACY</small>
          </b>
        </Col>
      </Row>
      {[...Array(28)].map((e, i) => getRow(i))}
    </Container>
  );
};

export default StoredMedications;
