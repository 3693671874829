import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import moment from "moment";

class Comment extends Component {
  state = {};

  calcualteDuration = (date) => {
    console.log("calcualteDuration-date", date);
    const today = moment();
    console.log("calcualteDuration-today", today);
    const diffSec = Math.floor(moment.duration(today.diff(date)).asSeconds());
    const diffMin = Math.floor(moment.duration(today.diff(date)).asMinutes());
    const diffHours = Math.floor(moment.duration(today.diff(date)).asHours());
    const diffDays = Math.floor(moment.duration(today.diff(date)).asDays());

    if (diffDays >= 1) {
      if (diffDays > 1) {
        return diffDays.toString().concat(" days ago");
      } else {
        return diffDays.toString().concat(" day ago");
      }
    }

    if (diffHours >= 1) {
      if (diffHours > 1) {
        return diffHours.toString().concat(" hours ago");
      } else {
        return diffHours.toString().concat(" hour ago");
      }
    }

    if (diffMin >= 1) {
      if (diffMin > 1) {
        return diffMin.toString().concat(" minutes ago");
      } else {
        return diffMin.toString().concat(" minute ago");
      }
    }

    if (diffSec >= 1) {
      if (diffSec != 1) {
        return diffSec.toString().concat(" seconds ago");
      } else {
        return diffSec.toString().concat(" second ago");
      }
    }

    return "0 seconds ago";
  };
  render() {
    console.log(this.props.comm);
    const duration = this.calcualteDuration(this.props.comm.commentDate);
    return (
      <div style={defaultStyle}>
        <Card>
          <Card.Header>
            <table style={{ width: "100%" }}>
              <tr>
                <td>
                  <div style={{ textAlign: "left" }}>
                    <b>{this.props.comm && this.props.comm.commentBy}</b>
                  </div>
                </td>
                <td>
                  <div style={{ textAlign: "right" }}>
                    <b>{duration}</b>
                  </div>
                </td>
              </tr>
            </table>
          </Card.Header>
          <Card.Body>{this.props.comm.comment}</Card.Body>
        </Card>
      </div>
    );
  }
}

const defaultStyle = {
  color: "#3C3B6E",
};

export default Comment;
