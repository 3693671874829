import React from "react";
import { Container, Row, Form, Col } from "react-bootstrap";

const ResidentsInvolved = ({
  onChangeInput,
  lic624State,
  complianceFormsState,
}) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col>
            <b>CLIENTS/RESIDENTS INVOLVED</b>
          </Col>
          <Col md={2}>
            <b>DATE OCCURRED</b>
          </Col>
          <Col md={2}>
            <b>AGE</b>
          </Col>
          <Col md={2}>
            <b>SEX</b>
          </Col>
          <Col md={2}>
            <b>DATE OF ADMISSION</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              name="residentsInvolvedName1"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedName1", e)}
              value={lic624State && lic624State.residentsInvolvedName1}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedDate1"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedDate1", e)}
              value={lic624State && lic624State.residentsInvolvedDate1}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedAge1"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedAge1", e)}
              value={lic624State && lic624State.residentsInvolvedAge1}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsSex1"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsSex1", e)}
              value={lic624State && lic624State.residentsSex1}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedAdmissionDate1"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) =>
                onChangeInput("residentsInvolvedAdmissionDate1", e)
              }
              value={lic624State && lic624State.residentsInvolvedAdmissionDate1}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              name="residentsInvolvedName2"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedName2", e)}
              value={lic624State && lic624State.residentsInvolvedName2}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedDate2"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedDate2", e)}
              value={lic624State && lic624State.residentsInvolvedDate2}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedAge2"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedAge2", e)}
              value={lic624State && lic624State.residentsInvolvedAge2}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsSex2"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsSex2", e)}
              value={lic624State && lic624State.residentsSex2}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedAdmissionDate2"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) =>
                onChangeInput("residentsInvolvedAdmissionDate2", e)
              }
              value={lic624State && lic624State.residentsInvolvedAdmissionDate2}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              name="residentsInvolvedName3"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedName3", e)}
              value={lic624State && lic624State.residentsInvolvedName3}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedDate3"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedDate3", e)}
              value={lic624State && lic624State.residentsInvolvedDate3}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedAge3"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedAge3", e)}
              value={lic624State && lic624State.residentsInvolvedAge3}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsSex3"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsSex3", e)}
              value={lic624State && lic624State.residentsSex3}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedAdmissionDate3"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) =>
                onChangeInput("residentsInvolvedAdmissionDate3", e)
              }
              value={lic624State && lic624State.residentsInvolvedAdmissionDate3}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              name="residentsInvolvedName4"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedName4", e)}
              value={lic624State && lic624State.residentsInvolvedName4}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedDate4"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedDate4", e)}
              value={lic624State && lic624State.residentsInvolvedDate4}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedAge4"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsInvolvedAge4", e)}
              value={lic624State && lic624State.residentsInvolvedAge4}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsSex4"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentsSex4", e)}
              value={lic624State && lic624State.residentsSex4}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              name="residentsInvolvedAdmissionDate4"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) =>
                onChangeInput("residentsInvolvedAdmissionDate4", e)
              }
              value={lic624State && lic624State.residentsInvolvedAdmissionDate4}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ResidentsInvolved;
