import APIClient from "../../apiClient";

const BASE_CHECH_RESULTS = "/checkResults";

class CheckResultsClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_CHECH_RESULTS + "/" + id
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_CHECH_RESULTS,
      repo
    );
  }

  async schedule(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_CHECH_RESULTS + "/schedule",
      repo
    );
  }

  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_CHECH_RESULTS
    );
  }

  getById(id) {
    return super.perform(
      "get",
      super.getBaseContext() + BASE_CHECH_RESULTS + "/" + id
    );
  }
}

export default CheckResultsClient;
