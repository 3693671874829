import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

const Miscellaneous = ({
  onChangeInput,
  lic603aState,
  complianceFormsState,
}) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Form.Label style={{ marginBottom: "10px" }}>
            <b>STATUS </b> (An exception must be obtained to admit or retain a
            resident who will be temporarily bedridden more than 14 days.
            Permanently bedridden residents are prohibited):
          </Form.Label>
          <br />
          <div style={{ display: "inline-flex" }}>
            <div>
              <Form.Check
                style={{ marginBottom: "20px" }}
                type="radio"
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                id="bedStatus1"
                label="OUT OF BED ALL DAY"
                onChange={(e) => onChangeInput("bedStatus", "1", "true")}
                checked={
                  lic603aState != undefined && lic603aState.bedStatus == "1"
                }
              />
              <Form.Check
                type="radio"
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                id="bedStatus2"
                label="IN BED PART OF THE TIME"
                onChange={(e) => onChangeInput("bedStatus", "2", "true")}
                checked={
                  lic603aState != undefined && lic603aState.bedStatus == "2"
                }
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Form.Check
                style={{ marginBottom: "20px" }}
                type="radio"
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                id="bedStatus3"
                label="IN BED MOST OF THE TIME"
                onChange={(e) => onChangeInput("bedStatus", "3", "true")}
                checked={
                  lic603aState != undefined && lic603aState.bedStatus == "3"
                }
              />
              <Form.Check
                type="radio"
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                id="bedStatus4"
                label="IN BED ALL OF THE TIME"
                onChange={(e) => onChangeInput("bedStatus", "4", "true")}
                checked={
                  lic603aState != undefined && lic603aState.bedStatus == "4"
                }
              />
            </div>
            <div style={{ marginLeft: "20px", width: "400px" }}>
              <Form.Label>
                <b>COMMENT:</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("bedStatusComment", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic603aState && lic603aState.bedStatusComment}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <Form.Label style={{ marginBottom: "10px" }}>
            <b>TUBERCULOSIS INFORMATION:</b>
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Label style={{ marginBottom: "10px" }}>
            ANY HISTORY OF TUBERCULOSIS IN APPLICANT’S FAMILY?
          </Form.Label>
          <br />
          <div style={{ display: "inline-flex" }}>
            <Form.Check
              style={{ marginRight: "20px" }}
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="tuberculosisHistory"
              label="YES"
              onChange={(e) =>
                onChangeInput("tuberculosisHistory", "1", "true")
              }
              checked={
                lic603aState != undefined &&
                lic603aState.tuberculosisHistory == "1"
              }
            />
            <Form.Check
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="tuberculosisHistory"
              label="NO"
              onChange={(e) =>
                onChangeInput("tuberculosisHistory", "2", "true")
              }
              checked={
                lic603aState != undefined &&
                lic603aState.tuberculosisHistory == "2"
              }
            />
          </div>
        </Col>
        <Col xs="auto">
          <Form.Label style={{ marginBottom: "10px" }}>
            DATE OF TB TEST/TYPE OF TEST
          </Form.Label>
          <br />
          <Form.Control
            name="tuberculosisTestDate"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("tuberculosisTestDate", e)}
            value={lic603aState && lic603aState.tuberculosisTestDate}
            autoComplete="off"
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            style={{ marginLeft: "20px" }}
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="tuberculosisTestResult"
            label="POSITIVE"
            onChange={(e) =>
              onChangeInput("tuberculosisTestResult", "1", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.tuberculosisTestResult == "1"
            }
          />
          <Form.Check
            style={{ marginTop: "10px", marginLeft: "20px" }}
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="tuberculosisTestResult"
            label="NEGATIVE"
            onChange={(e) =>
              onChangeInput("tuberculosisTestResult", "2", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.tuberculosisTestResult == "2"
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Label style={{ marginBottom: "10px" }}>
            ANY RECENT EXPOSURE TO ANYONE WITH TUBERCULOSIS?
          </Form.Label>
          <br />
          <div style={{ display: "inline-flex" }}>
            <Form.Check
              style={{ marginRight: "20px" }}
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="tuberculosisExposure"
              label="YES"
              onChange={(e) =>
                onChangeInput("tuberculosisExposure", "1", "true")
              }
              checked={
                lic603aState != undefined &&
                lic603aState.tuberculosisExposure == "1"
              }
            />
            <Form.Check
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="tuberculosisExposure"
              label="NO"
              onChange={(e) =>
                onChangeInput("tuberculosisExposure", "2", "true")
              }
              checked={
                lic603aState != undefined &&
                lic603aState.tuberculosisExposure == "2"
              }
            />
          </div>
        </Col>
        <Col>
          <Form.Label style={{ marginBottom: "10px" }}>
            ACTION TAKEN (IF POSITIVE)
          </Form.Label>
          <br />
          <Form.Control
            style={{ width: "500px" }}
            name="tuberculosisActionTaken"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("tuberculosisActionTaken", e)}
            value={lic603aState && lic603aState.tuberculosisActionTaken}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label style={{ marginBottom: "10px" }}>
            GIVEN DETAILS
          </Form.Label>
          <br />
          <Form.Control
            style={{ width: "1000px" }}
            name="givenDetails"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("givenDetails", e)}
            value={lic603aState && lic603aState.givenDetails}
            autoComplete="off"
          />
        </Col>
      </Row>
      <hr />
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <Form.Label>
            <b>AMBULATORY STATUS</b> (this person is:
          </Form.Label>
          <div style={{ display: "inline-flex" }}>
            {"  "}
            <Form.Check
              style={{ marginRight: "20px", marginLeft: "20px" }}
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="ambulatoryStatus"
              label="ambulatory"
              onChange={(e) => onChangeInput("ambulatoryStatus", "1", "true")}
              checked={
                lic603aState != undefined &&
                lic603aState.ambulatoryStatus == "1"
              }
            />
            {"  "}
            <Form.Check
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="ambulatoryStatus"
              label="nonambulatory"
              onChange={(e) => onChangeInput("ambulatoryStatus", "2", "true")}
              checked={
                lic603aState != undefined &&
                lic603aState.ambulatoryStatus == "2"
              }
            />
            )
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          Ambulatory means able to demonstrate the mental and physical ability
          to leave a building without the assistance of a person or the use of a
          mechanical device other than a cane. An ambulatory person must be able
          to do the following:
        </Col>
      </Row>
      <Row style={{ marginTop: "20px", marginBottom: "60px" }}>
        <Col>
          <Container fluid>
            <Row>
              <Col xs="auto">YES</Col>
              <Col xs="auto">NO</Col>
            </Row>
            <Row>
              <Col xs="auto">
                <Form.Check
                  type="radio"
                  disabled={
                    complianceFormsState.currResidentFileMetadata &&
                    complianceFormsState.currResidentFileMetadata.status ==
                      "SIGNED"
                  }
                  id="ableToWalkAlone"
                  onChange={(e) =>
                    onChangeInput("ableToWalkAlone", "1", "true")
                  }
                  checked={
                    lic603aState != undefined &&
                    lic603aState.ableToWalkAlone == "1"
                  }
                />
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="radio"
                  disabled={
                    complianceFormsState.currResidentFileMetadata &&
                    complianceFormsState.currResidentFileMetadata.status ==
                      "SIGNED"
                  }
                  id="ableToWalkAlone"
                  onChange={(e) =>
                    onChangeInput("ableToWalkAlone", "2", "true")
                  }
                  checked={
                    lic603aState != undefined &&
                    lic603aState.ableToWalkAlone == "2"
                  }
                />
              </Col>
              <Col>
                Able to walk without any physical assistance (e.g., walker,
                crutches, other person), or able to walk with a cane.
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <Form.Check
                  type="radio"
                  disabled={
                    complianceFormsState.currResidentFileMetadata &&
                    complianceFormsState.currResidentFileMetadata.status ==
                      "SIGNED"
                  }
                  id="ableToFollowSignals"
                  onChange={(e) =>
                    onChangeInput("ableToFollowSignals", "1", "true")
                  }
                  checked={
                    lic603aState != undefined &&
                    lic603aState.ableToFollowSignals == "1"
                  }
                />
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="radio"
                  disabled={
                    complianceFormsState.currResidentFileMetadata &&
                    complianceFormsState.currResidentFileMetadata.status ==
                      "SIGNED"
                  }
                  id="ableToFollowSignals"
                  onChange={(e) =>
                    onChangeInput("ableToFollowSignals", "2", "true")
                  }
                  checked={
                    lic603aState != undefined &&
                    lic603aState.ableToFollowSignals == "2"
                  }
                />
              </Col>
              <Col>
                Mentally and physically able to follow signals and instructions
                for evacuation.
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <Form.Check
                  type="radio"
                  disabled={
                    complianceFormsState.currResidentFileMetadata &&
                    complianceFormsState.currResidentFileMetadata.status ==
                      "SIGNED"
                  }
                  id="ableToUseEvacuationRoutes"
                  onChange={(e) =>
                    onChangeInput("ableToUseEvacuationRoutes", "1", "true")
                  }
                  checked={
                    lic603aState != undefined &&
                    lic603aState.ableToUseEvacuationRoutes == "1"
                  }
                />
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="radio"
                  disabled={
                    complianceFormsState.currResidentFileMetadata &&
                    complianceFormsState.currResidentFileMetadata.status ==
                      "SIGNED"
                  }
                  id="ableToUseEvacuationRoutes"
                  onChange={(e) =>
                    onChangeInput("ableToUseEvacuationRoutes", "2", "true")
                  }
                  checked={
                    lic603aState != undefined &&
                    lic603aState.ableToUseEvacuationRoutes == "2"
                  }
                />
              </Col>
              <Col>
                Able to use evacuation routes including stairs if necessary.
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <Form.Check
                  type="radio"
                  disabled={
                    complianceFormsState.currResidentFileMetadata &&
                    complianceFormsState.currResidentFileMetadata.status ==
                      "SIGNED"
                  }
                  id="ableToEvacuationQuickly"
                  onChange={(e) =>
                    onChangeInput("ableToEvacuationQuickly", "1", "true")
                  }
                  checked={
                    lic603aState != undefined &&
                    lic603aState.ableToEvacuationQuickly == "1"
                  }
                />
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="radio"
                  disabled={
                    complianceFormsState.currResidentFileMetadata &&
                    complianceFormsState.currResidentFileMetadata.status ==
                      "SIGNED"
                  }
                  id="ableToEvacuationQuickly"
                  onChange={(e) =>
                    onChangeInput("ableToEvacuationQuickly", "2", "true")
                  }
                  checked={
                    lic603aState != undefined &&
                    lic603aState.ableToEvacuationQuickly == "2"
                  }
                />
              </Col>
              <Col>
                Able to evacuate reasonably quickly (e.g., walk directly the
                route without hesitation)
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Miscellaneous;
