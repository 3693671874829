import React from "react";
import { Modal } from "react-bootstrap";
import MenuCollaboratioMobile from "../menu/mobile/menuCollaborationMobile";
import AddMessageMobile from "../message/mobile/addMessageMobile";
import MessagesMobile from "../message/mobile/messagesMobile";

const MainCollaborationMobile = ({
  onShowMessage,
  onHideMessage,
  onViewMessage,
  onSendMessage,
  onChangeMessage,
  onChangeContent,
  onSetView,
  collaborationState,
}) => {
  return (
    <React.Fragment>
      <MenuCollaboratioMobile
        onShowMessage={onShowMessage}
        onSetView={onSetView}
        view={collaborationState.view}
      />
      <hr></hr>
      <MessagesMobile
        onViewMessage={onViewMessage}
        messagesMy={
          collaborationState.messages &&
          collaborationState.messages.filter(
            (message) => message.facility.id == collaborationState.facility.id
          )
        }
        messagesOther={
          collaborationState.messages &&
          collaborationState.messages.filter(
            (message) => message.facility.id != collaborationState.facility.id
          )
        }
        messagesRead={
          collaborationState.messagesRead && collaborationState.messagesRead
        }
        view={collaborationState.view}
      />
      <Modal
        dialogClassName="width-90w"
        show={collaborationState.showMessage}
        onHide={onHideMessage}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <small>
              <b
                style={{
                  color: "#3C3B6E",
                }}
              >
                Message all facilities using ALFIS
              </b>
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <AddMessageMobile
              onSendMessage={onSendMessage}
              onHideMessage={onHideMessage}
              onChangeMessage={onChangeMessage}
              onChangeContent={onChangeContent}
              collaborationState={collaborationState}
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default MainCollaborationMobile;
