import React from "react";
import Table from "react-bootstrap/Table";
import TodoMobile from "./todoMobile";
import moment from "moment";

const TodoListMobile = ({
  onDeleteTodo,
  onShowTodoDetails,
  onShowTodoReschedule,
  todos,
  selectedDate,
  months,
  weekdays,
}) => {
  return (
    <React.Fragment>
      <div
        style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "10px" }}
      >
        {weekdays.filter(
          (weekday) =>
            weekday.id ==
            moment(
              selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2),
              "YYYY-MM-DD"
            ).weekday()
        )[0].name +
          ", " +
          months.filter((month) => month.id == selectedDate.getMonth())[0]
            .name +
          " " +
          ("0" + selectedDate.getDate()).slice(-2) +
          " " +
          selectedDate.getFullYear()}
      </div>
      {/* <div
        id=""
        style={{
          overflow: "scroll",
          overflowX: "hidden",
          height: "70vh",
        }}
      > */}
      <Table>
        <tbody>
          {todos.filter((todo) => todo.status != true).length == 0
            ? "No reminders"
            : todos
                .filter((todo) => todo.status != true)
                .map((todo, index) => (
                  <tr key={todo.id}>
                    <TodoMobile
                      onDeleteTodo={onDeleteTodo}
                      onShowTodoDetails={onShowTodoDetails}
                      onShowTodoReschedule={onShowTodoReschedule}
                      todo={todo}
                    />
                  </tr>
                ))}
        </tbody>
      </Table>
      {/* </div> */}
    </React.Fragment>
  );
};

export default TodoListMobile;
