import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { BsCheck, BsTrash } from "react-icons/bs";
import moment from "moment";
import "../../../common/table.css";
import TableSort from "../../../common/tableSort";

const ActiveStaffTrainingsDesktop = ({
  trainingState,
  onMarkAsPassed,
  onDeleteStaffTraining,
  onViewDetails,
}) => {
  const activeStaffTraining =
    trainingState != undefined &&
    trainingState.selectedStaffTrainings != undefined &&
    trainingState.selectedStaffTrainings.filter(
      (staffTraining) => staffTraining.passDate == undefined
    );
  const [byNameType, setByNameType] = useState("");
  const [byDescType, setByDescType] = useState("");
  const [byScheduledType, sortByScheduledType] = useState("");

  function sortByName(type) {
    resetSortType();
    setByNameType(type);
  }

  function sortByDesc(type) {
    resetSortType();
    setByDescType(type);
  }

  function sortByScheduled(type) {
    resetSortType();
    sortByScheduledType(type);
  }

  function resetSortType() {
    setByNameType("");
    setByDescType("");
    sortByScheduledType("");
  }

  if (activeStaffTraining != undefined && activeStaffTraining.length > 0) {
    const activeStaffTrainingSorted =
      activeStaffTraining != undefined &&
      activeStaffTraining.sort((A, B) => {
        if (byScheduledType != "" && byScheduledType != undefined) {
          if (A.scheduledDate > B.scheduledDate) {
            if (byScheduledType == "asc") {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (byScheduledType == "asc") {
              return -1;
            } else {
              return 1;
            }
          }
        }

        return 0;
      });
    return (
      <React.Fragment>
        <div class="tableFixHead">
          <Table>
            <thead>
              <tr>
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Completed
                </th>
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Scheduled
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                >
                  <TableSort sort={sortByScheduled} type={byScheduledType} />
                </th>
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Training
                </th>
                {/* <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                >
                  <TableSort sort={sortByName} type={byNameType} />
                </th> */}
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Description
                </th>
                {/* <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                > */}
                {/* <TableSort sort={sortByDesc} type={byDescType} />
                </th> */}
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Details
                </th>
                <th style={{ ...thStyle }}></th>
              </tr>
            </thead>
            <tbody>
              {activeStaffTrainingSorted.map((staffTraining) => (
                <tr key={staffTraining.id}>
                  <td>
                    <Button
                      variant="outline-light"
                      size="sm"
                      onClick={() => onMarkAsPassed(staffTraining)}
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                    >
                      <BsCheck
                        style={{
                          color: "green",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Button>
                  </td>
                  <td colSpan={2}>
                    {moment(staffTraining.scheduledDate).format("DD/MM/YYYY")}
                  </td>
                  <td>{staffTraining.training.name}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: staffTraining.training.description,
                      }}
                    />
                  </td>
                  <td>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => onViewDetails(staffTraining.training)}
                    >
                      View Details ...
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="outline-light"
                      size="sm"
                      onClick={() => onDeleteStaffTraining(staffTraining)}
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                    >
                      <BsTrash
                        style={{
                          color: "#b22234",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* <Table striped bordered hover>
          <thead>
            <tr style={{ fontWeight: "bold" }}>
              <td style={{ width: "20px" }}>Completed</td>
              <td style={{ width: "50px" }}>Scheduled</td>
              <td style={{ width: "100px" }}>
                <TableSort sort={sortByScheduled} />
              </td>
              <td>Training</td>
              <td style={{ width: "100px" }}>
                <TableSort sort={sortByName} />
              </td>
              <td>Description</td>
              <td style={{ width: "100px" }}>
                <TableSort sort={sortByDesc} />
              </td>
              <td>Details</td>
              <td style={{ width: "20px" }}></td>
            </tr>
          </thead>
          <tbody>
            {activeStaffTrainingSorted.map((staffTraining) => (
              <tr key={staffTraining.id}>
                <td>
                  <Button
                    variant="outline-light"
                    size="sm"
                    onClick={() => onMarkAsPassed(staffTraining)}
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                    }}
                  >
                    <BsCheck
                      style={{
                        color: "green",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </Button>
                </td>
                <td colSpan={2}>
                  {moment(staffTraining.scheduledDate).format("DD/MM/YYYY")}
                </td>
                <td colSpan={2}>{staffTraining.training.name}</td>
                <td colSpan={2}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: staffTraining.training.description,
                    }}
                  />
                </td>
                <td>
                  <Button
                    size="sm"
                    variant="link"
                    onClick={() => viewDetails(staffTraining.training)}
                  >
                    View Details ...
                  </Button>
                </td>
                <td>
                  <Button
                    variant="outline-light"
                    size="sm"
                    onClick={() => onDeleteStaffTraining(staffTraining)}
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                    }}
                  >
                    <BsTrash
                      style={{
                        color: "#b22234",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}
      </React.Fragment>
    );
  } else {
    return <b style={{ color: "#df4759" }}>There are no upcoming trainings </b>;
  }
};

const thStyle = {
  backgroundColor: "lightgray",
  color: "black",
  border: "none",
  verticalAlign: "middle",
};

export default ActiveStaffTrainingsDesktop;
