import React from "react";

const WeekDaysDesktop = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        fontWeight: "bold",
        marginBottom: "10px",
      }}
    >
      <div style={{ textAlign: "center", color: "#b22234" }}>Sun</div>
      <div style={{ textAlign: "center" }}>Mon</div>
      <div style={{ textAlign: "center" }}>Tue</div>
      <div style={{ textAlign: "center" }}>Wed</div>
      <div style={{ textAlign: "center" }}>Thu</div>
      <div style={{ textAlign: "center" }}>Fri</div>
      <div style={{ textAlign: "center", color: "#b22234" }}>Sat</div>
    </div>
  );
};

export default WeekDaysDesktop;
