import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import AddResidentMobile from "./addResidentMobile";
import AddTodoMobile from "../../../todo/add-todo/mobile/addtodoMobile";
import { TiPlus } from "react-icons/ti";
import AddFormMobile from "../compliance/mobile/addFormMobile";
import ShareReport from "../compliance/shareReport";
import AskForSignatureMobile from "../compliance/mobile/askForSignatureMobile";
import ResidentDashboardMobile from "./residentDashboardMobile";
import ResidentListMobile from "./residentListMobile";

const MainResidentMobile = ({
  onChangeResident,
  onChangeResidentDates,
  onChangeDoc,
  onOpenResident,
  onNewResident,
  onHideResident,
  onSaveResident,
  onGetAllResidents,
  onBackDashboard,
  onAddResidentDoctor,
  onOpenResidentForm,
  onDeleteResidentDoctor,
  onCloseResidentForm,
  onShowAddTodo,
  onAddTodo,
  onCloseTodo,
  onSetFile,
  onDownload,
  onDeleteFiles,
  onShowAddMedRecord,
  onAddMedicalRecord,
  onChangeMedHistory,
  onChangeMedRecordDate,
  onOpenResidentView,
  onSaveLegalRepInfo,
  onChangeTodo,
  onChangeTodoDates,
  onEditResident,
  onSaveEditResident,
  onHideEditResident,
  onShowForm,
  onHideForm,
  onChangeForm,
  onSaveForm,
  onExitLegRepPhone,
  onExitDoctorPhone,
  onDeleteMedicalRecord,
  onShowShareReport,
  onHideShareReport,
  onChangeGuardian,
  onSelectFormMenu,
  onDeleteGuardianShare,
  formatList,
  onShowSignFile,
  onHideSignFile,
  onSaveSignFile,
  onSelectGuardianForSignature,
  onSelectResidentMenu,
  onShowDeactivated,
  onHideGuardian,
  onShowGuardian,
  onSaveGuardian,
  onChangeGuardianField,
  onChangeGuardianDates,
  onChangePassword,
  onToggleChanged,
  onShowPassword,
  onHidePassword,
  residentState,
}) => {
  console.log("residentState: ", residentState);
  return (
    <React.Fragment>
      {residentState.showResident ? (
        <AddResidentMobile
          onHideResident={onHideResident}
          onChangeResident={onChangeResident}
          onChangeResidentDates={onChangeResidentDates}
          onSaveResident={onSaveResident}
          residentState={residentState}
        />
      ) : residentState.showResidentDashboard ? (
        <ResidentDashboardMobile
          onChangeResident={onChangeResident}
          onChangeDoc={onChangeDoc}
          onAddResidentDoctor={onAddResidentDoctor}
          onDeleteResidentDoctor={onDeleteResidentDoctor}
          onBackDashboard={onBackDashboard}
          onOpenResidentForm={onOpenResidentForm}
          onShowAddTodo={onShowAddTodo}
          onCloseResidentForm={onCloseResidentForm}
          onSetFile={onSetFile}
          onDownload={onDownload}
          onDeleteFiles={onDeleteFiles}
          onShowAddMedRecord={onShowAddMedRecord}
          onAddMedicalRecord={onAddMedicalRecord}
          onChangeMedHistory={onChangeMedHistory}
          onChangeMedRecordDate={onChangeMedRecordDate}
          onOpenResidentView={onOpenResidentView}
          onSaveLegalRepInfo={onSaveLegalRepInfo}
          onEditResident={onEditResident}
          onShowForm={onShowForm}
          onExitLegRepPhone={onExitLegRepPhone}
          onExitDoctorPhone={onExitDoctorPhone}
          onDeleteMedicalRecord={onDeleteMedicalRecord}
          onShowShareReport={onShowShareReport}
          onSelectFormMenu={onSelectFormMenu}
          formatList={formatList}
          onShowSignFile={onShowSignFile}
          residentState={residentState}
        />
      ) : (
        <React.Fragment>
          <Button
            onClick={() => onNewResident()}
            variant="success"
            style={{
              width: "100%",
              verticalAlign: "middle",
              color: "white",
            }}
          >
            <TiPlus
              style={{
                color: "#28a745",
                backgroundColor: "white",
                width: "25px",
                height: "25px",
                verticalAlign: "middle",
              }}
            />
            {"  "}
            Add resident
          </Button>
          <hr></hr>
          <Form.Check
            name="showDeactivated"
            type="checkbox"
            label="Deactivated residents"
            onChange={(e) => onShowDeactivated(e)}
            defaultChecked={
              residentState ? residentState.showDeactivated : false
            }
          />
          <hr></hr>
          <ResidentListMobile
            onOpenResident={onOpenResident}
            onSelectResidentMenu={onSelectResidentMenu}
            residentState={residentState}
          />
        </React.Fragment>
      )}
      <Modal show={residentState.showTodo} onHide={onCloseTodo}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>REMINDER</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <AddTodoMobile
              onAddTodo={onAddTodo}
              onClose={onCloseTodo}
              onChange={onChangeTodo}
              onChangeDates={onChangeTodoDates}
              todo={residentState.currTodo}
              isError={residentState.isError}
              errorMsg={residentState.errorMsg}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={residentState.showResidentEdit} onHide={onHideEditResident}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>General info</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <AddResidentMobile
              onHideResident={onHideEditResident}
              onChangeResident={onChangeResident}
              onChangeResidentDates={onChangeResidentDates}
              onSaveResident={onSaveEditResident}
              residentState={residentState}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={residentState.showAddForm} onHide={onHideForm}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>Report</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddFormMobile
            onSaveForm={onSaveForm}
            onHideForm={onHideForm}
            onChangeForm={onChangeForm}
            residentState={residentState}
          />
        </Modal.Body>
      </Modal>
      <Modal show={residentState.showShareReport} onHide={onHideShareReport}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>
              Share {residentState.currForm && residentState.currForm.name} with
              guardian
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareReport
            onChangeGuardian={onChangeGuardian}
            onDeleteGuardianShare={onDeleteGuardianShare}
            residentState={residentState}
          />
        </Modal.Body>
      </Modal>
      <Modal show={residentState.showSignFile} onHide={onHideSignFile}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>
              Request signature for{" "}
              {residentState.currFile && residentState.currFile.name} from
              guardian
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AskForSignatureMobile
            onHideSignFile={onHideSignFile}
            onSaveSignFile={onSaveSignFile}
            onSelectGuardianForSignature={onSelectGuardianForSignature}
            onSaveStaff={onSaveGuardian}
            onHideGuardian={onHideGuardian}
            onShowGuardian={onShowGuardian}
            onChangeGuardianField={onChangeGuardianField}
            onChangeGuardianDates={onChangeGuardianDates}
            onChangePassword={onChangePassword}
            onToggleChanged={onToggleChanged}
            onShowPassword={onShowPassword}
            onHidePassword={onHidePassword}
            residentState={residentState}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export const btnStyle = {
  textAlign: "center",
  borderColor: "#3c3b6e",
  borderRadius: "10px",
  color: "#3c3b6e",
  backgroundColor: "#f2f2f2",
  borderWidth: "thin",
};

export default MainResidentMobile;
