import React from "react";
import { Button } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";

const SendEmail = ({ onSendEmail }) => {
  return (
    <Button
      variant="secondary"
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
        width: "200px",
        color: "#9933cc",
        backgroundColor: "white",
        float: "right",
      }}
      onClick={() => onSendEmail()}
    >
      <BsPencilSquare
        style={{
          color: "#9933cc",
          backgroundColor: "white",
          width: "25px",
          height: "25px",
          verticalAlign: "middle",
        }}
      />
      {"  "}
      Send e-mail to
    </Button>
  );
};

export default SendEmail;
