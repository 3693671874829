import React from "react";
import { Button, Table, Container, Row, Col } from "react-bootstrap";
import { BsTrash, BsDownload } from "react-icons/bs";
import { RiShareForwardFill } from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";
import moment from "moment";
import "../../common/table.css";

const DocumentsDesktop = ({
  onCloseDocuments,
  onSetFile,
  onDownload,
  onDeleteDocument,
  onShowShareDocument,
  myDocumentState,
}) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col xs="auto" style={{ textAlign: "left" }}>
            <Button
              variant="success"
              style={{
                width: "150px",
              }}
              onClick={() => handleClick()}
            >
              Upload file
            </Button>
            <input
              type="file"
              ref={hiddenFileInput}
              style={{ display: "none" }}
              onChange={(e) => onSetFile(e)}
            />
          </Col>
          <Col style={{ textAlign: "right", verticalAlign: "center" }}>
            <h3>
              {myDocumentState.currFolder && myDocumentState.currFolder.name}
            </h3>
          </Col>
        </Row>
      </Container>
      <hr></hr>
      <div class="tableFixHead">
        <Table>
          <thead style={{ backgroundColor: "#5bc0de" }}>
            <tr>
              <th>
                <small>
                  <b>FILE NAME</b>
                </small>
              </th>
              <th>
                <small>
                  <b>CREATION DATE</b>
                </small>
              </th>
              <th>
                <small>
                  <b>ACTIONS</b>
                </small>
              </th>
            </tr>
          </thead>
          <tbody>
            {myDocumentState.currFiles &&
              myDocumentState.currFiles
                .sort((a, b) => {
                  if (a.uploadDate > b.uploadDate) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((currFile) => (
                  <tr>
                    <td>{currFile.name}</td>
                    <td>{moment(currFile.uploadDate).format("DD/MM/YYYY")}</td>
                    <td>
                      <Button
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => onDownload(currFile)}
                      >
                        <b>
                          <BsDownload
                            style={{
                              color: "green",
                              width: "15px",
                              height: "15px",
                            }}
                          ></BsDownload>
                        </b>
                      </Button>
                      {"   "}
                      <Button
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => onDeleteDocument(currFile)}
                      >
                        <BsTrash
                          style={{
                            color: "red",
                            width: "15px",
                            height: "15px",
                          }}
                        ></BsTrash>
                      </Button>
                      {"   "}
                      <Button
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => onShowShareDocument(currFile)}
                      >
                        <AiOutlineMail
                          style={{
                            color: "#0275d8",
                            width: "20px",
                            height: "20px",
                          }}
                        ></AiOutlineMail>
                      </Button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
      <Button
        variant="outline-success"
        style={{
          width: "150px",
        }}
        onClick={() => onCloseDocuments()}
      >
        Close
      </Button>
    </React.Fragment>
  );
};

export default DocumentsDesktop;
