import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import ComplianceFormsSearch from "./complianceFormsSearch";
import useComplianceForms from "./hook/useComplianceForms";
import SelectResidentAndForm from "./selectResidentAndForm";
import Lic625 from "./reportTemplates/625/lic625";
import "../common/table.css";
import DepositSignature from "./depositSignature";
import ShareReportModal from "./shareReportModal";
import SelectSignature from "./selectSignature";
import CopyReport from "./copyReport";
import Lic603a from "./reportTemplates/603A/lic603a";
import Lic624 from "./reportTemplates/624/lic624";
import Lic624a from "./reportTemplates/624A/lic624a";
import Lic622 from "./reportTemplates/622/lic622";
import SendEmail from "./sendEmail";
import Lic613c from "./reportTemplates/613C/lic613c";

const MainComplianceForms = () => {
  const {
    onShowSelectResidentAndForm,
    onHideSelectResidentAndForm,
    onChangeResident,
    onChangeForm,
    onCreateReport,
    onShowReportDetails,
    onChangeLic625Input,
    onChangeLic625DateInput,
    onSaveLic625,
    onBack,
    onTabSelected,
    onSignLic625,
    onHideDepositSignature,
    onSelectShared,
    onHideShareReport,
    onShowShareReport,
    onShareReport,
    onSaveSignature,
    onFilterResults,
    onDeleteRecord,
    onChangePage,
    onUnSign,
    onHideSelectSignature,
    onSelectSignature,
    onShowCopy,
    onHideCopy,
    onConfirmCopy,
    onChangeReport,
    onChangeLic603aInput,
    onChangeLic603aDateInput,
    onSaveLic603a,
    onSignLic603a,
    onChangeLic624Input,
    onSaveLic624a,
    onChangeLic624aInput,
    onSaveLic624,
    onChangeLic622Input,
    onSaveLic622,
    onSignLic622,
    onShowSendEmail,
    onHideSendEmail,
    onSendEmail,
    onChangeLic613cInput,
    onChangeLic613cDateInput,
    onSaveLic613c,
    onSignLic613c,
    refreshPage,
    complianceFormsState,
    lic625State,
    lic625Base64ContentState,
    lic603aState,
    lic603aBase64ContentState,
    lic624State,
    lic624Base64ContentState,
    lic624aState,
    lic624aBase64ContentState,
    lic622State,
    lic622Base64ContentState,
    lic613cState,
    lic613cBase64ContentState,
  } = useComplianceForms({});

  console.log("lic624State-main", lic624State);

  useEffect(() => {
    refreshPage();
  }, []);

  console.log("complianceFormsState", complianceFormsState);
  return (
    <React.Fragment>
      {complianceFormsState.showDepositSignature ? (
        <DepositSignature
          onHideDepositSignature={onHideDepositSignature}
          onSaveSignature={onSaveSignature}
        />
      ) : complianceFormsState.reportDetails ? (
        complianceFormsState.currReportType ==
        "625 Appraisal/Needs and Services Plan" ? (
          <Lic625
            onChangeInput={onChangeLic625Input}
            onChangeDateInput={onChangeLic625DateInput}
            onSave={onSaveLic625}
            onBack={onBack}
            onTabSelected={onTabSelected}
            onSignLic625={onSignLic625}
            onUnSign={onUnSign}
            onShowShareReport={onShowShareReport}
            onShowCopy={onShowCopy}
            lic625State={lic625State}
            lic625Base64ContentState={lic625Base64ContentState}
            complianceFormsState={complianceFormsState}
          />
        ) : complianceFormsState.currReportType == "603A Resident Appraisal" ? (
          <Lic603a
            onChangeInput={onChangeLic603aInput}
            onChangeDateInput={onChangeLic603aDateInput}
            onSave={onSaveLic603a}
            onBack={onBack}
            onTabSelected={onTabSelected}
            onSignLic603a={onSignLic603a}
            onUnSign={onUnSign}
            onShowShareReport={onShowShareReport}
            onShowCopy={onShowCopy}
            lic603aState={lic603aState}
            lic603aBase64ContentState={lic603aBase64ContentState}
            complianceFormsState={complianceFormsState}
          />
        ) : complianceFormsState.currReportType ==
          "624 Unusual Incident/Injury Report" ? (
          <Lic624
            onChangeInput={onChangeLic624Input}
            onSave={onSaveLic624}
            onBack={onBack}
            onTabSelected={onTabSelected}
            onShowCopy={onShowCopy}
            onShowSendEmail={onShowSendEmail}
            lic624State={lic624State}
            lic624Base64ContentState={lic624Base64ContentState}
            complianceFormsState={complianceFormsState}
          />
        ) : complianceFormsState.currReportType == "624A Death Report" ? (
          <Lic624a
            onChangeInput={onChangeLic624aInput}
            onSave={onSaveLic624a}
            onBack={onBack}
            onTabSelected={onTabSelected}
            onShowCopy={onShowCopy}
            onShowSendEmail={onShowSendEmail}
            lic624aState={lic624aState}
            lic624aBase64ContentState={lic624aBase64ContentState}
            complianceFormsState={complianceFormsState}
          />
        ) : complianceFormsState.currReportType ==
          "622 Centrally Stored Medication and Destruction Record" ? (
          <Lic622
            onChangeInput={onChangeLic622Input}
            onSave={onSaveLic622}
            onBack={onBack}
            onTabSelected={onTabSelected}
            onShowCopy={onShowCopy}
            onShowSendEmail={onShowSendEmail}
            onSignLic622={onSignLic622}
            onUnSign={onUnSign}
            onShowShareReport={onShowShareReport}
            lic622State={lic622State}
            lic622Base64ContentState={lic622Base64ContentState}
            complianceFormsState={complianceFormsState}
          />
        ) : complianceFormsState.currReportType ==
          "613C Personal Rights Residential Care Facilities For The Elderly" ? (
          <Lic613c
            onChangeInput={onChangeLic613cInput}
            onSave={onSaveLic613c}
            onBack={onBack}
            onTabSelected={onTabSelected}
            onShowCopy={onShowCopy}
            onShowSendEmail={onShowSendEmail}
            onSignLic613c={onSignLic613c}
            onUnSign={onUnSign}
            onShowShareReport={onShowShareReport}
            lic613cState={lic613cState}
            lic613cBase64ContentState={lic613cBase64ContentState}
            complianceFormsState={complianceFormsState}
          />
        ) : (
          ""
        )
      ) : (
        <ComplianceFormsSearch
          onShowSelectResidentAndForm={onShowSelectResidentAndForm}
          onShowReportDetails={onShowReportDetails}
          onFilterResults={onFilterResults}
          onDeleteRecord={onDeleteRecord}
          onChangePage={onChangePage}
          complianceFormsState={complianceFormsState}
        />
      )}
      <Modal
        show={complianceFormsState.showSelectResidentAndForm}
        onHide={onHideSelectResidentAndForm}
      >
        <Modal.Header closeButton style={{ borderColor: "lightgray" }}>
          <Modal.Title>
            <b>CREATE REPORT</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectResidentAndForm
            onChangeResident={onChangeResident}
            onChangeForm={onChangeForm}
            onHideSelectResidentAndForm={onHideSelectResidentAndForm}
            onCreateReport={onCreateReport}
            onShowSelectResidentAndForm={onShowSelectResidentAndForm}
            selectedResident={complianceFormsState.selectedResident}
            residents={complianceFormsState.residents}
            selectedForm={complianceFormsState.selectedForm}
            forms={complianceFormsState.forms}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={complianceFormsState.showShareReport}
        onHide={onHideShareReport}
      >
        <Modal.Header closeButton style={{ borderColor: "lightgray" }}>
          <Modal.Title>
            <b>SHARE REPORT</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareReportModal
            onSelectShared={onSelectShared}
            onHideShareReport={onHideShareReport}
            onShareReport={onShareReport}
            complianceFormsState={complianceFormsState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={complianceFormsState.showSelectSignature}
        onHide={onHideSelectSignature}
        size="lg"
      >
        <Modal.Header closeButton style={{ borderColor: "lightgray" }}>
          <Modal.Title>
            <b>SELECT SIGNATURE FIELD</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectSignature
            onSelectSignature={onSelectSignature}
            onHideSelectSignature={onHideSelectSignature}
            onSignLic625={onSignLic625}
            complianceFormsState={complianceFormsState}
          />
        </Modal.Body>
      </Modal>
      <Modal show={complianceFormsState.showCopy} onHide={onHideCopy} size="lg">
        <Modal.Header closeButton style={{ borderColor: "lightgray" }}>
          <Modal.Title>
            <b>COPY REPORT</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CopyReport
            onHideCopy={onHideCopy}
            onConfirmCopy={onConfirmCopy}
            onChangeReport={onChangeReport}
            complianceFormsState={complianceFormsState}
          />
        </Modal.Body>
      </Modal>
      <Modal show={complianceFormsState.showSendEmail} onHide={onHideSendEmail}>
        <Modal.Header closeButton style={{ borderColor: "lightgray" }}>
          <Modal.Title>
            <b>SEND E-MAIL TO</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SendEmail
            onSelectShared={onSelectShared}
            onSendEmail={onSendEmail}
            onHideSendEmail={onHideSendEmail}
            complianceFormsState={complianceFormsState}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default MainComplianceForms;
