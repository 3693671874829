import React from "react";
import { Button, Form } from "react-bootstrap";
import * as Constants from "../../../../util/constants";
import DatePicker from "react-datepicker";

const AddMedicalRecordMobile = ({
  onAddMedicalRecord,
  onChangeMedHistory,
  onChangeMedRecordDate,
  residentState,
}) => {
  return (
    <React.Fragment>
      <Form>
        <Form.Row>
          {residentState.isError ? (
            <div style={errorMsgStyle}>
              <b>ERROR:</b> {residentState.errorMsg}
            </div>
          ) : (
            ""
          )}
        </Form.Row>
        <Form.Group controlId="formGridState">
          <Form.Label size="sm">Record Type</Form.Label>
          <Form.Control
            name="type"
            as="select"
            size="sm"
            onChange={(e) => onChangeMedHistory(e)}
            defaultValue="d"
            style={{ width: "100%" }}
          >
            {Constants.MEDICAL_HISTORY_TYPE.map((medHistType) => (
              <option key={medHistType.id} value={medHistType.id}>
                {medHistType.value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group style={{ marginTop: "20px" }}>
          <Form.Label size="sm">
            <b style={{ color: "#b22234" }}>
              <sup>*</sup>
            </b>
            Description:
          </Form.Label>
          <Form.Control
            size="sm"
            name="description"
            as="textarea"
            rows="3"
            autoComplete="off"
            style={{
              width: "100%",
              borderColor:
                residentState.errorField &&
                residentState.errorField == "firstName"
                  ? "#b22234"
                  : "",
            }}
            onChange={(e) => onChangeMedHistory(e)}
            placeholder="DESCRIPTION"
          />
          {residentState.errorField &&
          residentState.errorField == "firstName" ? (
            <div style={{ color: "#b22234" }}>{residentState.errorMsg}</div>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Label size="sm">
          <b style={{ color: "#b22234" }}>
            <sup>*</sup>
          </b>
          Date:
        </Form.Label>
        <br></br>
        <DatePicker
          size="sm"
          selected={
            residentState.currMedRecord && residentState.currMedRecord.date
          }
          placeholderText="MED. RECORD DATE"
          onChange={(e) => onChangeMedRecordDate(e)}
          dateFormat="MM/dd/yyyy"
          disabledKeyboardNavigation
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          autoComplete="off"
        />
      </Form>
      <br></br>
      <Button
        size="sm"
        variant="success"
        style={{
          width: "100%",
          verticalAlign: "middle",
          marginBottom: "10px",
          marginTop: "20px",
        }}
        onClick={() => onAddMedicalRecord(residentState.currResident, "S")}
      >
        Save
      </Button>
      <Button
        variant="outline-success"
        size="sm"
        style={{
          width: "100%",
          verticalAlign: "middle",
        }}
        onClick={() => onAddMedicalRecord(residentState.currResident, "C")}
      >
        Close
      </Button>
    </React.Fragment>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default AddMedicalRecordMobile;
