import React from "react";
import { CardColumns } from "react-bootstrap";
import MainPageItem from "../mainPageItem";

const HomeDesktop = ({ onOpenMenu, mainPageState }) => {
  return (
    <CardColumns>
      {mainPageState &&
        mainPageState.sideBarMenu &&
        mainPageState.sideBarMenu
          .filter((item) => item.name.toLowerCase() != "home")
          .map((item, index) => (
            <MainPageItem
              index={index}
              onOpenMenu={onOpenMenu}
              mainPageState={mainPageState}
              item={item}
            />
          ))}
    </CardColumns>
  );
};

export default HomeDesktop;
