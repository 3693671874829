import { useState } from "react";
import AnnouncementService from "../../../services/collaboration/announcementService";
import AnnouncementReadService from "../../../services/collaboration/announcementsReadService";
import FacilityService from "../../../services/facility/facilityService";

const useCollaborationState = () => {
  const [collaborationState, setCollaborationState] = useState({
    view: "received",
  });

  const refreshPage = async () => {
    const facility = await FacilityService.getFromToken();
    const messages = await AnnouncementService.getAll();
    const messagesRead = await AnnouncementReadService.getAll();

    setCollaborationState({
      ...collaborationState,
      facility: facility,
      messages: messages,
      messagesRead: messagesRead,
    });
  };

  const isNew = (message) => {
    var isNew = true;
    if (collaborationState.messagesRead != undefined) {
      collaborationState.messagesRead.forEach((messageRead) => {
        if (messageRead["announcement"]["id"] == message.id) {
          isNew = false;
        }
      });
    }

    return isNew;
  };

  const onShowMessage = () => {
    setCollaborationState({
      ...collaborationState,
      showMessage: true,
      isReadOnly: false,
      currMessage: {},
    });
  };

  const onHideMessage = () => {
    setCollaborationState({
      ...collaborationState,
      showMessage: false,
      isReadOnly: false,
      currMessage: {},
      isError: false,
      errorMsg: "",
    });
  };

  const onViewMessage = async (message) => {
    let currMessageReadArray = collaborationState.messagesRead.filter(
      (messageRead) => messageRead.announcement.id == message.id
    );
    if (currMessageReadArray == undefined || currMessageReadArray.length == 0) {
      const savedMessageRead = await AnnouncementReadService.save({
        announcementId: message.id,
      });

      setCollaborationState({
        ...collaborationState,
        messagesRead:
          collaborationState.messagesRead != undefined
            ? collaborationState.messagesRead.concat(savedMessageRead)
            : [].concat(savedMessageRead),
        showMessage: true,
        isReadOnly: true,
        currMessage: message,
      });
    } else {
      setCollaborationState({
        ...collaborationState,
        showMessage: true,
        isReadOnly: true,
        currMessage: message,
      });
    }
  };

  const onSendMessage = async () => {
    const validatedMessage = AnnouncementService.validateMessage(
      collaborationState.currMessage
    );

    if (!validatedMessage.isValid) {
      setCollaborationState({
        ...collaborationState,
        isError: true,
        errorMsg: validatedMessage.msg,
      });
      return;
    }

    setCollaborationState({
      ...collaborationState,
      isSendMessageActive: true,
    });
    const savedMessage = await AnnouncementService.save(
      collaborationState.currMessage
    );
    const savedMessageRead = await AnnouncementReadService.save({
      announcementId: savedMessage.id,
      facilityId: savedMessage.facility.id,
    });
    AnnouncementService.notify();
    alert("Thanks for your collaboration!");
    setCollaborationState({
      ...collaborationState,
      messages:
        collaborationState.messages != undefined
          ? collaborationState.messages.concat(savedMessage)
          : [].concat(savedMessage),
      messagesRead:
        collaborationState.messagesRead != undefined
          ? collaborationState.messagesRead.concat(savedMessageRead)
          : [].concat(savedMessageRead),
      showMessage: false,
      currMessage: {},
      isSendMessageActive: false,
      isError: false,
      errorMsg: "",
    });
  };

  const onSetView = (view) => {
    setCollaborationState({ ...collaborationState, view: view });
  };

  const onChangeMessage = (event) => {
    let message = collaborationState.currMessage;
    const name = event.target.name;
    const value = event.target.value;
    message[name] = value;
    setCollaborationState({
      ...collaborationState,
      currMessage: message,
      isError: false,
      errorMsg: "",
    });
  };

  const onChangeContent = (content) => {
    let message = collaborationState.currMessage;
    message["description"] = content;
    setCollaborationState({
      ...collaborationState,
      currMessage: message,
      isError: false,
      errorMsg: "",
    });
    console.log("message", message);
  };

  return {
    refreshPage,
    isNew,
    onShowMessage,
    onHideMessage,
    onViewMessage,
    onSendMessage,
    onChangeMessage,
    onChangeContent,
    onSetView,
    collaborationState,
  };
};

export default useCollaborationState;
