import React from "react";
import { Button } from "react-bootstrap";
import AddMedicalRecordDesktop from "./addMedicalRecordDesktop";
import MedicalRecordsDesktop from "./medicalRecordsDesktop";

const MedicalHistoryDesktop = ({
  onShowAddMedRecord,
  onAddMedicalRecord,
  onChangeMedHistory,
  onChangeMedRecordDate,
  onDeleteMedicalRecord,
  residentState,
}) => {
  return (
    <React.Fragment>
      {residentState.showAddMedRecord ? (
        <AddMedicalRecordDesktop
          onAddMedicalRecord={onAddMedicalRecord}
          onChangeMedHistory={onChangeMedHistory}
          onChangeMedRecordDate={onChangeMedRecordDate}
          residentState={residentState}
        />
      ) : (
        <MedicalRecordsDesktop
          onShowAddMedRecord={onShowAddMedRecord}
          onDeleteMedicalRecord={onDeleteMedicalRecord}
          residentState={residentState}
        />
      )}
    </React.Fragment>
  );
};

export default MedicalHistoryDesktop;
