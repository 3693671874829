import APIClient from "../apiClient";

const BASE_ANNOUNCEMENTS = "/announcement";

class AnnouncementClient extends APIClient {
  async update(repo, id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + BASE_ANNOUNCEMENTS + "/" + id,
      repo
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_ANNOUNCEMENTS,
      repo
    );
  }

  async notify() {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_ANNOUNCEMENTS + "/notify"
    );
  }

  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_ANNOUNCEMENTS
    );
  }
}

export default AnnouncementClient;
