import APIClient from "../../apiClient";
import axios from "axios";

const BASE_STAFF_TRAINING = "/staffTraining";

class StaffTrainingClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_STAFF_TRAINING + "/" + id
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_STAFF_TRAINING,
      repo
    );
  }

  getAll() {
    return super.perform("get", super.getBaseContext() + BASE_STAFF_TRAINING);
  }

  getById(id) {
    return super.perform(
      "get",
      super.getBaseContext() + BASE_STAFF_TRAINING + "/" + id
    );
  }

  async getByStaffId(staffId) {
    return await super.performAsync(
      "get",
      super.getBaseContext() +
        BASE_STAFF_TRAINING +
        "/staffTrainingByStaffId/" +
        staffId
    );
  }

  async markAsPassed(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_STAFF_TRAINING + "/markAsPassed",
      repo
    );
  }

  download(repo) {
    return axios({
      url:
        super.getBaseURI() +
        super.getBaseContext() +
        BASE_STAFF_TRAINING +
        "/download",
      method: "POST",
      data: repo,
      responseType: "blob",
      headers: { authorization: localStorage.getItem("token") },
    });
  }

  printCertificate(repo) {
    return axios({
      url:
        super.getBaseURI() +
        super.getBaseContext() +
        BASE_STAFF_TRAINING +
        "/printCertificate",
      method: "POST",
      data: repo,
      responseType: "blob",
      headers: { authorization: localStorage.getItem("token") },
    });
  }
}

export default StaffTrainingClient;
