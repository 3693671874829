import APIClient from "../apiClient";

const BASE_STAFF = "/staff";

class StaffClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_STAFF + "/" + id
    );
  }

  async getSignature() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_STAFF + "/getSignature"
    );
  }

  async update(repo, id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + BASE_STAFF + "/" + id,
      repo
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_STAFF,
      repo
    );
  }

  async uploadSignature(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_STAFF + "/uploadSignature",
      repo
    );
  }

  changePassword(repo) {
    return super.perform(
      "post",
      super.getBaseContext() + BASE_STAFF + "/changePwd",
      repo
    );
  }

  async getAll() {
    return await super.performAsync("get", super.getBaseContext() + BASE_STAFF);
  }

  async getById(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_STAFF + "/" + id
    );
  }

  async getByUserName(userName) {
    return super.performAsync(
      "get",
      super.getBaseContext() + BASE_STAFF + "/getByUserName/" + userName
    );
  }

  async changePassword(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_STAFF + "/changePassword",
      repo
    );
  }

  sysAdminEmail() {
    return super.perform(
      "get",
      super.getBaseContext() + BASE_STAFF + "/sysAdminEmail"
    );
  }

  verify(repo) {
    return super.perform(
      "post",
      super.getBaseContext() + BASE_STAFF + "/verify",
      repo
    );
  }
}

export default StaffClient;
