import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import TableSort from "../common/tableSort";
import TableFilter from "../common/tableFilter";
import UserListFilter from "./userListFilter";
import { FiEdit3 } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import "../common/table.css";
// import "../../../common/table.css";

const UsersList = ({ onDelete, onUpdate, users }) => {
  return (
    <div class="tableFixHead">
      <Table style={{ color: "black" }}>
        <thead>
          <tr>
            <th>
              <b>NAME</b>
            </th>
            {/* <th>
              <b>USERNAME</b>
            </th> */}
            <th style={{ textAlign: "right" }}>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users
              .sort((userA, userB) => {
                if (userA.name.toLowerCase() > userB.name.toLowerCase()) {
                  return 1;
                } else {
                  return -1;
                }
              })
              .map((user) => (
                <tr>
                  <td>{user.name}</td>
                  {/* <td>{user.username}</td> */}
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    <Button
                      variant="dark"
                      size="sm"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                      onClick={() => onUpdate(user)}
                    >
                      <FiEdit3
                        style={{
                          color: "#0275d8",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Button>{" "}
                    <Button
                      variant="danger"
                      size="sm"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Do you really want to delete " + user.name + "?"
                          )
                        )
                          onDelete(user);
                      }}
                    >
                      <BsTrash
                        style={{
                          color: "#d9534f",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UsersList;
