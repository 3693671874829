import React from "react";
import { Button } from "react-bootstrap";
import TrainingListDesktop from "./trainingListDesktop";

const AddStaffTrainingDesktop = ({
  onCreateTraining,
  onEditTraining,
  onStaffTraining,
  onDeleteTraining,
  trainingState,
}) => {
  return (
    <React.Fragment>
      <TrainingListDesktop
        onStaffTraining={onStaffTraining}
        onDeleteTraining={onDeleteTraining}
        onEditTraining={onEditTraining}
        trainingState={trainingState}
      />
      <Button
        variant="success"
        style={{ width: "150px" }}
        onClick={() => onCreateTraining(true)}
      >
        New training
      </Button>
    </React.Fragment>
  );
};

export default AddStaffTrainingDesktop;
