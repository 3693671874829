import CheckListClient from "../../../api/compliance/checklist/checkListClient";

const checkListClient = new CheckListClient();

class CheckListService {
  delete(id) {
    return checkListClient.delete(id);
  }

  save(repo) {
    return checkListClient.insert(repo);
  }

  async getAll() {
    try {
      const response = await checkListClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  getById(id) {
    return checkListClient.getById(id);
  }
}

export default new CheckListService();
