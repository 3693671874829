import React from "react";
import { Container, Row, Form, Col } from "react-bootstrap";

const OtherInfo = ({ onChangeInput, lic624aState, complianceFormsState }) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          <Form.Label>
            <b>NAME OF ATTENDING PHYSICIAN</b>
          </Form.Label>
          <Form.Control
            name="attendingPhysician"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("attendingPhysician", e)}
            value={lic624aState && lic624aState.attendingPhysician}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col>
          <Form.Label>
            <b>NAME OF MORTICIAN</b>
          </Form.Label>
          <Form.Control
            name="mortician"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("mortician", e)}
            value={lic624aState && lic624aState.mortician}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <b>REPORT SUBMITTED BY:</b>
        </Col>
        <Col md={6}>
          <Form.Label>
            <b>NAME AND TITLE</b>
          </Form.Label>
          <Form.Control
            name="submittedByNameAndTitle"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("submittedByNameAndTitle", e)}
            value={lic624aState && lic624aState.submittedByNameAndTitle}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col md={3}>
          <Form.Label>
            <b>DATE</b>
          </Form.Label>
          <Form.Control
            name="submittedByDate"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("submittedByDate", e)}
            value={lic624aState && lic624aState.submittedByDate}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <b>REPORT REVIEWED/APPROVED BY:</b>
        </Col>
        <Col md={6}>
          <Form.Label>
            <b>NAME AND TITLE</b>
          </Form.Label>
          <Form.Control
            name="reviewedByNameAndTitle"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("reviewedByNameAndTitle", e)}
            value={lic624aState && lic624aState.reviewedByNameAndTitle}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col md={3}>
          <Form.Label>
            <b>DATE</b>
          </Form.Label>
          <Form.Control
            name="reviewedByDate"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("reviewedByDate", e)}
            value={lic624aState && lic624aState.reviewedByDate}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <b>
            AGENCIES/INDIVIDUALS NOTIFIED (SPECIFY NAME AND TELEPHONE NUMBER)
          </b>
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <Form.Check
            label="LICENSING"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="licensing"
            onChange={(e) => onChangeInput("licensing", e, false, true)}
            checked={lic624aState != undefined && lic624aState.licensing}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="licensingComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("licensingComment", e)}
            value={lic624aState && lic624aState.licensingComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col xs={2}>
          <Form.Check
            label="ADULT/CHILD PROTECTIVE SERVICES"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="protectiveService"
            onChange={(e) => onChangeInput("protectiveService", e, false, true)}
            checked={
              lic624aState != undefined && lic624aState.protectiveService
            }
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="protectiveServiceComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("protectiveServiceComment", e)}
            value={lic624aState && lic624aState.protectiveServiceComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <Form.Check
            label="LONG TERM CARE OMBUDSMAN"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="longTermCare"
            onChange={(e) => onChangeInput("longTermCare", e, false, true)}
            checked={lic624aState != undefined && lic624aState.longTermCare}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="longTermCareComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("longTermCareComment", e)}
            value={lic624aState && lic624aState.longTermCareComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col xs={2}>
          <Form.Check
            label="PARENT/GUARDIAN/CONSERVATOR"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="conservator"
            onChange={(e) => onChangeInput("conservator", e, false, true)}
            checked={lic624aState != undefined && lic624aState.conservator}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="conservatorComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("conservatorComment", e)}
            value={lic624aState && lic624aState.conservatorComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "60px" }}>
        <Col xs={2}>
          <Form.Check
            label="LAW ENFORCEMENT"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="lawEnforcement"
            onChange={(e) => onChangeInput("lawEnforcement", e, false, true)}
            checked={lic624aState != undefined && lic624aState.lawEnforcement}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="lawEnforcementComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("lawEnforcementComment", e)}
            value={lic624aState && lic624aState.lawEnforcementComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col xs={2}>
          <Form.Check
            label="PLACEMENT AGENCY"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="placementAgency"
            onChange={(e) => onChangeInput("placementAgency", e, false, true)}
            checked={lic624aState != undefined && lic624aState.placementAgency}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="placementAgencyComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("placementAgencyComment", e)}
            value={lic624aState && lic624aState.placementAgencyComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default OtherInfo;
