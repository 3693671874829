import ResidentFileMetadataClient from "../../../api/compliance/documents/residentFileMetadataClient";

const client = new ResidentFileMetadataClient();

class ResidentFileMetadataService {
  async delete(id) {
    console.log("delete", id);
    return await client.delete(id);
  }

  async getById(id) {
    try {
      const response = await client.getById(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async copy(repo) {
    try {
      const response = await client.copy(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getByTemplateName(templateName) {
    try {
      const response = await client.getByTemplateName(templateName);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAllForSigning(id) {
    try {
      const response = await client.getAllForSigning(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async registerForSigning(repo) {
    try {
      const response = await client.registerForSigning(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getReportForCurrentUser() {
    try {
      const response = await client.getReportForCurrentUser();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async shareReport(repo) {
    try {
      const response = await client.shareReport(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async sendReportOnEmail(repo) {
    try {
      const response = await client.sendReportOnEmail(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    try {
      const response = await client.save(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async generatePreview(id) {
    try {
      const response = await client.generatePreview(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getPreview(id) {
    try {
      const response = await client.getPreview(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async signReport(repo) {
    try {
      const response = await client.signReport(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async unSignReport(repo) {
    try {
      const response = await client.unSignReport(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAllByFormId(id) {
    try {
      const response = await client.getAllByFormId(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getResidentFileSearchByFacility() {
    try {
      const response = await client.getResidentFileSearchByFacility();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getByResidentIdAndFormId(residentId, formId) {
    try {
      const response = await client.getByResidentIdAndFormId(
        residentId,
        formId
      );
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async ifFileExists(repo) {
    try {
      const response = await client.ifFileExists(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async notify(id) {
    try {
      const response = await client.notify(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getByResidentFormId(residentFormId) {
    try {
      const response = await client.getByResidentFormId(residentFormId);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async uploadSigned(repo) {
    try {
      const response = await client.uploadSigned(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async deleteSignatureRequest(repo) {
    try {
      const response = await client.deleteSignatureRequest(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async createReport(reportFormId) {
    try {
      const response = await client.createReport(reportFormId);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  getAll() {
    return client.getAll();
  }

  async getResidentFromResidentFileMetadataId(id) {
    try {
      const response = await client.getResidentFromResidentFileMetadataId(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  upload(repo) {
    console.log("upload", repo);
    return client.upload(repo);
  }

  register(repo) {
    console.log("register", repo);
    return client.register(repo);
  }

  download(id, path) {
    console.log("download", id);
    client.download(id).then((response) => {
      console.log("response", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      var pathArr = path.split("/");
      const fileName = pathArr[pathArr.length - 1];
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default new ResidentFileMetadataService();
