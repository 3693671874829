import FacilityFolderClient from "../../api/mydocuments/facilityFolderClient";

const facilityFolderClient = new FacilityFolderClient();

class FacilityFolderService {
  async delete(id) {
    try {
      const response = await facilityFolderClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    try {
      const response = await facilityFolderClient.save(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await facilityFolderClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  validateFolder(folder) {
    if (folder == undefined) {
      return {
        isError: true,
        errorMessage: "Please enter folder name!",
      };
    }

    if (folder.name == undefined || folder.name.length == 0) {
      return {
        isError: true,
        errorMessage: "Please enter folder name!",
      };
    }

    return {
      isError: false,
    };
  }
}

export default new FacilityFolderService();
