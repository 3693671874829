import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";

const MenuCollaborationDesktop = ({ onShowMessage, onSetView, view }) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Button
            variant="success"
            onClick={() => onShowMessage()}
            style={{ width: "170px" }}
          >
            <TiPlus
              style={{
                color: "white",
                width: "25px",
                height: "25px",
                verticalAlign: "middle",
              }}
            />
            {"   "}New message
          </Button>
        </Col>
        <Col>
          <Button
            variant="link"
            style={view == "received" ? navButtonSelectedStyle : navButtonStyle}
            onClick={() => onSetView("received")}
          >
            Received
          </Button>
          <Button
            variant="link"
            style={view == "sent" ? navButtonSelectedStyle : navButtonStyle}
            onClick={() => onSetView("sent")}
          >
            Sent
          </Button>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "200px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "200px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MenuCollaborationDesktop;
