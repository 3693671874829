import React from "react";
import { Button, Table, Container, Row, Col, Form } from "react-bootstrap";
import { BsDownload, BsTrash } from "react-icons/bs";
import { FaFileSignature } from "react-icons/fa";
import moment from "moment";
import "../../../../common/table.css";

const ResidentFormDesktop = ({
  onShowAddTodo,
  onCloseResidentForm,
  onSetFile,
  onDownload,
  onDeleteFiles,
  onShowSignFile,
  residentState,
}) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col xs="auto" style={{ textAlign: "left" }}>
            <Button
              variant="success"
              style={{ width: "150px" }}
              disabled={
                residentState &&
                residentState.currResident &&
                !residentState.currResident.isActive
              }
              onClick={() => onShowAddTodo(residentState.currForm)}
            >
              Create reminder
            </Button>{" "}
            <Button
              variant="success"
              style={{
                width: "150px",
              }}
              disabled={
                residentState &&
                residentState.currResident &&
                !residentState.currResident.isActive
              }
              onClick={() => handleClick()}
            >
              Upload file
            </Button>
          </Col>
          <Col style={{ textAlign: "right", verticalAlign: "center" }}>
            <h3>{residentState.currForm && residentState.currForm.name}</h3>
          </Col>
        </Row>
      </Container>
      <input
        type="file"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        onChange={(e) => onSetFile(e)}
        // onClick={(e) => setFile(e)}
      />
      <hr></hr>
      <div class="tableFixHead">
        <Table>
          <thead style={{ backgroundColor: "#5bc0de" }}>
            <tr>
              <th>
                <small>
                  <b>FILE NAME</b>
                </small>
              </th>
              <th>
                <small>
                  <b>CREATION DATE</b>
                </small>
              </th>
              <th>
                <small>
                  <b>ACTIONS</b>
                </small>
              </th>
            </tr>
          </thead>
          <tbody>
            {residentState.currFiles &&
              residentState.currFiles
                .sort((a, b) => {
                  if (a.uploadDate > b.uploadDate) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((currFile) => (
                  <tr>
                    <td>{currFile.name}</td>
                    <td>{moment(currFile.uploadDate).format("DD/MM/YYYY")}</td>
                    <td>
                      <Button
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => onDownload(currFile)}
                      >
                        <b>
                          <BsDownload
                            style={{
                              color: "green",
                              width: "15px",
                              height: "15px",
                            }}
                          ></BsDownload>
                        </b>
                      </Button>
                      {"   "}
                      <Button
                        disabled={
                          residentState &&
                          residentState.currResident &&
                          !residentState.currResident.isActive
                        }
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => onShowSignFile(currFile)}
                      >
                        <FaFileSignature
                          style={{
                            color: "#0275d8",
                            width: "15px",
                            height: "15px",
                          }}
                        ></FaFileSignature>
                      </Button>
                      {"   "}
                      <Button
                        disabled={
                          residentState &&
                          residentState.currResident &&
                          !residentState.currResident.isActive
                        }
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => onDeleteFiles(currFile)}
                      >
                        <BsTrash
                          style={{
                            color: "red",
                            width: "15px",
                            height: "15px",
                          }}
                        ></BsTrash>
                      </Button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
      <Button
        variant="outline-success"
        style={{
          width: "150px",
        }}
        onClick={() => onCloseResidentForm()}
      >
        Close
      </Button>
    </React.Fragment>
  );
};

export default ResidentFormDesktop;
