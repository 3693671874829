import React from "react";
import { Container, Row, Form, Col } from "react-bootstrap";

const ActionTaken = ({ onChangeInput, lic624State, complianceFormsState }) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>
                EXPLAIN WHAT IMMEDIATE ACTION WAS TAKEN (INCLUDE PERSONS
                CONTACTED):
              </b>
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("immediateAction", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic624State && lic624State.immediateAction}
              as="textarea"
              rows={5}
              style={{ width: "100%" }}
              maxLength={600}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>
            <Form.Label>MEDICAL TREATMENT NECESSARY?</Form.Label>
            <div style={{ display: "inline-flex" }}>
              {"  "}
              <Form.Check
                style={{ marginRight: "20px", marginLeft: "20px" }}
                type="radio"
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                id="medicalTreatment"
                label="YES"
                onChange={(e) => onChangeInput("medicalTreatment", "1", "true")}
                checked={
                  lic624State != undefined &&
                  lic624State.medicalTreatment == "1"
                }
              />
              {"  "}
              <Form.Check
                style={{ marginRight: "20px" }}
                type="radio"
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                id="medicalTreatment"
                label="NO"
                onChange={(e) => onChangeInput("medicalTreatment", "2", "true")}
                checked={
                  lic624State != undefined &&
                  lic624State.medicalTreatment == "2"
                }
              />
              {"   "}
            </div>
            <Form.Label>IF YES, GIVE NATURE OF TREATMENT:</Form.Label>
          </b>
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Control
            onChange={(e) => onChangeInput("natureOfTreatment", e)}
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            value={lic624State && lic624State.natureOfTreatment}
            as="textarea"
            rows={4}
            style={{ width: "100%" }}
            maxLength={480}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>
            <b>WHERE ADMINISTERED</b>
          </Form.Label>
          <Form.Control
            name="whereAdministered"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("whereAdministered", e)}
            value={lic624State && lic624State.whereAdministered}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col>
          <Form.Label>
            <b>ADMINISTERED BY:</b>
          </Form.Label>
          <Form.Control
            name="administeredBy"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("administeredBy", e)}
            value={lic624State && lic624State.administeredBy}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>FOLLOW UP TREATMENT IF ANY:</b>
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("followUpTreatment", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic624State && lic624State.followUpTreatment}
              as="textarea"
              rows={5}
              style={{ width: "100%" }}
              maxLength={600}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>ACTION TAKEN OR PLANNED (BY WHOM AND ANTICIPATED RESULTS:</b>
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("actionTakenOrPlanned", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic624State && lic624State.actionTakenOrPlanned}
              as="textarea"
              rows={6}
              style={{ width: "100%" }}
              maxLength={720}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default ActionTaken;
