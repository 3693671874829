import React from "react";
import ShoppingCartItem from "./shoppingCartItem";
import Table from "react-bootstrap/Table";

const ShoppingCart = ({
  shoppingCartProducts,
  onRemoveShoppingCartProduct,
  onIsPurchased,
}) => {
  function showEmpty() {
    if (shoppingCartProducts.length === 0) {
      return "Cart is empty!";
    }
  }

  return (
    <Table>
      <tbody>
        {shoppingCartProducts
          .filter((shoppingCartItem) => shoppingCartItem.isPurchased != 1)
          .map((shoppingCartItem) => (
            <tr key={shoppingCartItem.id}>
              <ShoppingCartItem
                onIsPurchased={onIsPurchased}
                onRemoveShoppingCartProduct={onRemoveShoppingCartProduct}
                shoppingCartItem={shoppingCartItem}
              />
            </tr>
          ))}
        {shoppingCartProducts
          .filter((shoppingCartItem) => shoppingCartItem.isPurchased == 1)
          .map((shoppingCartItem) => (
            <tr key={shoppingCartItem.id}>
              <ShoppingCartItem
                onIsPurchased={onIsPurchased}
                onRemoveShoppingCartProduct={onRemoveShoppingCartProduct}
                shoppingCartItem={shoppingCartItem}
              />
            </tr>
          ))}
        {showEmpty()}
      </tbody>
    </Table>
  );
};

export default ShoppingCart;
