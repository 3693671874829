import React, { useState } from "react";
import TodoService from "../../../../services/todo/todoService";
import CheckResultsService from "../../../../services/compliance/checklist/checkResultsService";
import CheckListService from "../../../../services/compliance/checklist/checkListService";
import moment from "moment";

const useCheckListState = () => {
  const [checkListState, setCheckListState] = useState({
    checkListView: "active",
  });

  const refreshPage = async () => {
    const checkLists = await CheckListService.getAll();
    const checkResuts = await CheckResultsService.getAll();
    setCheckListState({
      ...checkListState,
      checkLists: checkLists,
      filteredCheckLists: checkLists,
      checkResults: checkResuts,
    });
  };

  const onFilterCheckList = (e) => {
    const criteria = e.target.value;
    console.log("criteria", criteria);
    setCheckListState({
      ...checkListState,
      filteredCheckLists:
        criteria != undefined && criteria.length > 0
          ? checkListState.checkLists.filter((checkList) =>
              checkList.code
                .concat(" - ")
                .concat(checkList.name)
                .toLowerCase()
                .includes(criteria.toString().toLowerCase())
            )
          : checkListState.checkLists,
    });
  };

  const onHideScheduleCheckList = () => {
    setCheckListState({
      ...checkListState,
      showScheduleCheckList: false,
      scheduleView: "checklist",
      selectedCheckList: [],
    });
  };

  const onSelectCheck = (e, id) => {
    const isChecked = e.target.checked;
    console.log("isChecked", isChecked);
    console.log("id", id);
    if (isChecked) {
      setCheckListState({
        ...checkListState,
        selectedCheckList: checkListState.selectedCheckList
          ? checkListState.selectedCheckList.concat(id)
          : [].concat(id),
      });
    } else {
      setCheckListState({
        ...checkListState,
        selectedCheckList: checkListState.selectedCheckList
          ? checkListState.selectedCheckList.filter(
              (selectedId) => selectedId != id
            )
          : [],
      });
    }
  };

  const onGoToSchedule = () => {
    if (
      checkListState.selectedCheckList == undefined ||
      checkListState.selectedCheckList.length == 0
    ) {
      alert("Please select at least one item from the check list!");
      return;
    }
    var desc = checkListState.checkLists
      .filter((chk) => {
        if (
          checkListState.selectedCheckList.filter((id) => id == chk.id).length >
          0
        ) {
          return chk;
        } else {
          return null;
        }
      })
      .map((chk) => chk.name)
      .join("\n");

    setCheckListState({
      ...checkListState,
      currTodo: {
        title: "Please check items from the list",
        description: desc,
        repeated: false,
      },
      scheduleView: "todo",
    });
  };

  const onSaveScheduleCheckListControl = async () => {
    const validatedTodo = TodoService.validateTodo(checkListState.currTodo);
    if (!validatedTodo.isValid) {
      if (validatedTodo.isError) {
        setCheckListState({
          ...checkListState,
          isError: true,
          errorMsg: validatedTodo.msg,
        });

        return;
      }
    }
    const savedTodo = await TodoService.save(checkListState.currTodo);
    const savedCheckResult = await CheckResultsService.schedule({
      todoId: savedTodo && savedTodo.id,
      description: savedTodo && savedTodo.description,
      isOk: true,
      isActive: true,
    });

    setCheckListState({
      ...checkListState,
      checkResults:
        checkListState.checkResults != undefined
          ? checkListState.checkResults
              .filter((checkResult) => checkResult.id != savedCheckResult.id)
              .concat(savedCheckResult)
          : [].concat(savedCheckResult),
      showScheduleCheckList: false,
      scheduleView: "checklist",
      selectedCheckList: [],
    });
  };

  const onSaveCheckResult = async () => {
    if (
      checkListState.currCheckResult.isOk == false &&
      (checkListState.currCheckResult.comment == undefined ||
        checkListState.currCheckResult.comment == "")
    ) {
      // alert("Please enter comment!");
      setCheckListState({
        ...checkListState,
        isError: true,
        errorMsg: "Please enter comment!",
      });
      return;
    }
    const savedCheckResult = await CheckResultsService.save({
      id: checkListState.currCheckResult.id,
      todoId: checkListState.currCheckResult.todo.id,
      facilityId: checkListState.currCheckResult.facility.id,
      description: checkListState.currCheckResult.description,
      comment: checkListState.currCheckResult.comment,
      isOk:
        checkListState.currCheckResult.isOk == undefined
          ? true
          : checkListState.currCheckResult.isOk,
      isActive: false,
      completedDate: new Date(),
    });

    const newCheckResults = checkListState.checkResults
      .filter((chkRes) => chkRes.id != savedCheckResult.id)
      .concat(savedCheckResult);

    setCheckListState({
      ...checkListState,
      checkResults: newCheckResults,
      showCheckCompleted: false,
      isError: false,
    });
  };

  const onHideCheckCompleted = () => {
    setCheckListState({
      ...checkListState,
      showCheckCompleted: false,
      isError: false,
    });
  };

  const onDeleteCheckResult = async (checkResult) => {
    if (
      window.confirm(
        "Do you really want to permanently delete check scheduled for " +
          moment(checkResult.todo.startDate).format("DD/MM/YYYY") +
          "!"
      )
    ) {
      await CheckResultsService.delete(checkResult.id);
      setCheckListState({
        ...checkListState,
        checkResults: checkListState.checkResults.filter(
          (chkRes) => chkRes.id != checkResult.id
        ),
      });
    }
  };

  const onViewCheckCompleted = (checkResult) => {
    setCheckListState({
      ...checkListState,
      currCheckResult: checkResult,
      showCheckCompleted: true,
    });
  };

  const onSetControlStatus = (checked) => {
    setCheckListState({
      ...checkListState,
      currCheckResult: { ...checkListState.currCheckResult, isOk: checked },
      isError: false,
    });
  };

  const onSetComment = (e) => {
    setCheckListState({
      ...checkListState,
      currCheckResult: {
        ...checkListState.currCheckResult,
        comment: e.target.value,
      },
      isError: false,
    });
  };

  const onHideCheckDetails = () => {
    setCheckListState({
      ...checkListState,
      showCheckDetails: false,
    });
  };

  const onViewCompletedDetails = (checkResult) => {
    setCheckListState({
      ...checkListState,
      currCheckResult: checkResult,
      showCheckDetails: true,
    });
  };

  const onShowScheduleCheckList = () => {
    setCheckListState({ ...checkListState, showScheduleCheckList: true });
  };

  const onChangeTodo = (e, isCheckBox) => {
    let value = e.target.value;
    if (isCheckBox) {
      value = e.target.checked;
    }
    const todo = { ...checkListState.currTodo };
    todo[e.target.name] = value;

    setCheckListState({ ...checkListState, currTodo: todo });
  };

  const onChangeTodoDates = (value, fieldName) => {
    const todo = { ...checkListState.currTodo };
    if (fieldName == "startDate") {
      todo["startDate"] = value;
    } else if (fieldName == "endDate") {
      todo["endDate"] = value;
    }
    setCheckListState({ ...checkListState, currTodo: todo });
  };

  const onOpenCheckListView = (eventKey) => {
    setCheckListState({ ...checkListState, checkListView: eventKey });
  };

  return {
    refreshPage,
    onHideScheduleCheckList,
    onSelectCheck,
    onGoToSchedule,
    onSaveScheduleCheckListControl,
    onSaveCheckResult,
    onHideCheckCompleted,
    onDeleteCheckResult,
    onViewCheckCompleted,
    onSetControlStatus,
    onSetComment,
    onHideCheckDetails,
    onViewCompletedDetails,
    onShowScheduleCheckList,
    onFilterCheckList,
    onChangeTodo,
    onChangeTodoDates,
    onOpenCheckListView,
    checkListState,
  };
};

export default useCheckListState;
