import FacilityModulesClient from "../../api/facility/facilityModulesClient";

const facilityModulesClient = new FacilityModulesClient();

class FacilityModulesService {
  async getByFacilityId() {
    try {
      const response = await facilityModulesClient.getByFacilityId();

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new FacilityModulesService();
