import APIClient from "../apiClient";

const BASE_ANNOUNCEMENTS_READ = "/announcementRead";

class AnnouncementReadClient extends APIClient {
  delete(id) {
    return super.perform(
      "delete",
      super.getBaseContext() + BASE_ANNOUNCEMENTS_READ + "/" + id
    );
  }

  async update(repo, id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + BASE_ANNOUNCEMENTS_READ + "/" + id,
      repo
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_ANNOUNCEMENTS_READ,
      repo
    );
  }

  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_ANNOUNCEMENTS_READ
    );
  }
}

export default AnnouncementReadClient;
