import React, { useEffect } from "react";
import useMainPageState from "./hook/useMainPageState";
import useWindowSize from "../common/hook/useWindowSize";
import MainPageDesktop from "./desktop/mainPageDesktop";
import MainPageMobile from "./mobile/mainPageMobile";

const MainPage = () => {
  const size = useWindowSize();
  const {
    refreshPage,
    onOpenMenu,
    onToggleMenu,
    onGotoHome,
    onSelectAvatarList,
    onHideChangePassword,
    onChangeChangePassword,
    onSaveChangePassword,
    onShowPassword,
    onHidePassword,
    onHideBillInfo,
    onEditBillInfo,
    onCancelEdit,
    onChange,
    onSaveCard,
    onHideDepositSignature,
    onSaveSignature,
    mainPageState,
  } = useMainPageState();

  useEffect(() => {
    const fetchData = async () => {
      await refreshPage();
    };
    fetchData();
  }, []);

  // return size.width > 970 ? (
  return (
    <MainPageDesktop
      onOpenMenu={onOpenMenu}
      onToggleMenu={onToggleMenu}
      onSelectAvatarList={onSelectAvatarList}
      onGotoHome={onGotoHome}
      onHideChangePassword={onHideChangePassword}
      onChangeChangePassword={onChangeChangePassword}
      onSaveChangePassword={onSaveChangePassword}
      onShowPassword={onShowPassword}
      onHidePassword={onHidePassword}
      onEditBillInfo={onEditBillInfo}
      onChange={onChange}
      onCancelEdit={onCancelEdit}
      onSaveCard={onSaveCard}
      onHideDepositSignature={onHideDepositSignature}
      onSaveSignature={onSaveSignature}
      mainPageState={mainPageState}
    />
  );
  // ) : (
  //   <MainPageMobile
  //     onOpenMenu={onOpenMenu}
  //     onToggleMenu={onToggleMenu}
  //     onSelectAvatarList={onSelectAvatarList}
  //     onGotoHome={onGotoHome}
  //     onHideChangePassword={onHideChangePassword}
  //     onChangeChangePassword={onChangeChangePassword}
  //     onSaveChangePassword={onSaveChangePassword}
  //     onShowPassword={onShowPassword}
  //     onHidePassword={onHidePassword}
  //     onEditBillInfo={onEditBillInfo}
  //     onChange={onChange}
  //     onCancelEdit={onCancelEdit}
  //     onSaveCard={onSaveCard}
  //     mainPageState={mainPageState}
  //   />
  // );
};

export default MainPage;
