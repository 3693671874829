import React from "react";
import { Button, Table } from "react-bootstrap";
import * as Constants from "../../../../util/constants";
import moment from "moment";
import { TiPlus } from "react-icons/ti";
import "../../../../common/table.css";
import { BsTrash } from "react-icons/bs";

const MedicalRecordsDesktop = ({
  onShowAddMedRecord,
  onDeleteMedicalRecord,
  residentState,
}) => {
  console.log("MedicalRecords", residentState.currMedHistories);
  return (
    <React.Fragment>
      <Button
        variant="success"
        disabled={
          residentState &&
          residentState.currResident &&
          !residentState.currResident.isActive
        }
        style={{
          width: "200px",
          marginBottom: "20px",
        }}
        onClick={() => onShowAddMedRecord()}
      >
        <TiPlus
          style={{
            color: "white",
            width: "25px",
            height: "25px",
            verticalAlign: "middle",
          }}
        />
        {"  "}Add Medical Record
      </Button>
      {/* <hr></hr> */}
      <div class="tableFixHead">
        <Table>
          <thead style={{ backgroundColor: "#5bc0de" }}>
            <tr>
              <th>
                <small>
                  <b>TYPE</b>
                </small>
              </th>
              <th>
                <small>
                  <b>DESCRIPTION</b>
                </small>
              </th>
              <th>
                <small>
                  <b>REG. DATE</b>
                </small>
              </th>
              <th>
                <small>
                  <b>ACTIONS</b>
                </small>
              </th>
            </tr>
          </thead>
          <tbody>
            {residentState.currMedHistories &&
              residentState.currMedHistories
                .sort((currMedHisA, currMedHisB) => {
                  if (currMedHisA.date > currMedHisB.date) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((currMedHistory) => (
                  <tr>
                    <td>
                      {
                        Constants.MEDICAL_HISTORY_TYPE.filter(
                          (medHistoryType) =>
                            medHistoryType.id == currMedHistory.type
                        )[0].value
                      }
                    </td>
                    <td>{currMedHistory.description}</td>
                    <td>
                      {currMedHistory.date != undefined ? (
                        <React.Fragment>
                          {moment(currMedHistory.date).format("MM/DD/YYYY")}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <Button
                        variant="outline-light"
                        size="sm"
                        disabled={
                          residentState &&
                          residentState.currResident &&
                          !residentState.currResident.isActive
                        }
                        onClick={() => onDeleteMedicalRecord(currMedHistory)}
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                      >
                        <BsTrash
                          style={{
                            color: "#b22234",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default MedicalRecordsDesktop;
