import React from "react";
import { Button } from "react-bootstrap";
import { FiSave } from "react-icons/fi";

const SaveButton = ({ onSave }) => {
  return (
    <Button
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
        width: "150px",
        float: "right",
        color: "#007bff",
        backgroundColor: "white",
      }}
      onClick={() => onSave()}
    >
      <FiSave
        style={{
          color: "#007bff",
          backgroundColor: "white",
          width: "25px",
          height: "25px",
          verticalAlign: "middle",
        }}
      />
      {"  "}
      Save
    </Button>
  );
};

export default SaveButton;
