import React from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";

const SelectSignature = ({
  onSelectSignature,
  onHideSelectSignature,
  onSignLic625,
  complianceFormsState,
}) => {
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col>
            <Form.Control
              value={
                complianceFormsState && complianceFormsState.selectedSignature
              }
              name="repeatedCategoryId"
              as="select"
              onChange={(e) => onSelectSignature(e)}
              size="sm"
            >
              <option key={-1} value={-1}>
                Select signature ...
              </option>
              {complianceFormsState.availableSignatureKeys &&
                complianceFormsState.availableSignatureKeys.map((element) => (
                  <option
                    key={element.signatureKey}
                    value={element.signatureKey}
                  >
                    {element.signatureLabel}
                  </option>
                ))}
            </Form.Control>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <Button
              variant="outline-success"
              style={{
                width: "100px",
              }}
              onClick={onHideSelectSignature}
            >
              Close
            </Button>
            {"   "}
            <Button
              variant="success"
              style={{ width: "100px" }}
              onClick={onSignLic625}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SelectSignature;
