import React from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import MainPageHeaderDesktop from "./mainPageHeaderDesktop";
import MainTodos from "../../todo/mainTodos";
import MainMedication from "../../medication/mainMedication";
import MainResident from "../../compliance/resident/mainResident";
import MainCompliance from "../../compliance/mainCompliance";
import MainStaffTraining from "../../compliance/training/mainStaffTraining";
import MainShopping from "../../products/mainShopping";
import MainUser from "../../user/mainUser";
import MainFacility from "../../facility/mainFacility";
import MainCollaboration from "../../collaboration/mainCollaboration";
import MyTraining from "../../compliance/training/myTraining";
import MainCheckList from "../../compliance/checklist/mainCheckList";
import Sidebar from "../sidebar.js";
import Home from "./homeDesktop";
import Brand from "../brand";
import { isMobile } from "react-device-detect";
import ChangePassword from "../changePassword";
import BillInfo from "../billInfo";
import MainFamMember from "../../user/fam-member/mainFamMember";
import MainDocuments from "../../fam-member/documents/mainDocuments";
import MainMyDocuments from "../../my-documents/mainMyDocuments";
import FamilyMemberVisit from "../../fam-member/visit/familyMemberVisit";
import MainDocumentsForSigning from "../../fam-member/document-for-signing/mainDocumentsForSigning";
import MainComplianceForms from "../../complianceForms/mainComplianceForms";
import DepositSignature from "../../complianceForms/depositSignature";

const MainPageDesktop = ({
  onOpenMenu,
  onToggleMenu,
  onSelectAvatarList,
  onGotoHome,
  onHideChangePassword,
  onChangeChangePassword,
  onSaveChangePassword,
  onShowPassword,
  onHidePassword,
  onEditBillInfo,
  onChange,
  onCancelEdit,
  onSaveCard,
  onHideDepositSignature,
  onSaveSignature,
  mainPageState,
}) => {
  return (
    <React.Fragment>
      {mainPageState &&
      mainPageState.currUser &&
      mainPageState.currUser.isFirstLogIn ? (
        ""
      ) : mainPageState &&
        mainPageState.currUser &&
        !mainPageState.currUser.isFirstLogIn &&
        mainPageState.currUser.isProvideBillingInfo ? (
        <BillInfo
          onEditBillInfo={onEditBillInfo}
          onChange={onChange}
          onCancelEdit={onCancelEdit}
          onSaveCard={onSaveCard}
          mainPageState={mainPageState}
        />
      ) : (
        <Container fluid style={{ margin: "0px" }}>
          <Row>
            {/* {mainPageState.showMenu ? (
              <Col
                className="bg-dark"
                xs={2}
                style={{ marginRight: "0px", minWidth: "150px" }}
              >
                <Sidebar
                  menuItems={mainPageState.sideBarMenu}
                  onOpenMenu={onOpenMenu}
                  currView={mainPageState.currView}
                  onToggleMenu={onToggleMenu}
                />
                <Brand />
              </Col>
            ) : (
              ""
            )} */}
            <Col
              // xs={mainPageState.showMenu ? 10 : 12}
              xs={12}
              style={{ marginLeft: "0px", marginBottom: "0px" }}
            >
              <MainPageHeaderDesktop
                onToggleMenu={onToggleMenu}
                onSelectAvatarList={onSelectAvatarList}
                onGotoHome={onGotoHome}
                mainPageState={mainPageState}
                currView={mainPageState.currView}
              />
              {mainPageState.currView == "/todo" ? (
                <MainTodos />
              ) : mainPageState.currView == "/medication" ? (
                <MainMedication />
              ) : mainPageState.currView == "/product" ? (
                <MainShopping />
              ) : mainPageState.currView == "/collaboration" ? (
                <MainCollaboration />
              ) : mainPageState.currView == "/staff" ? (
                <MainUser />
              ) : mainPageState.currView == "/facility" ? (
                <MainFacility />
              ) : mainPageState.currView == "/mytraining" ? (
                <MyTraining />
              ) : mainPageState.currView == "/compliance" ? (
                <MainCompliance
                  onOpenMenu={onOpenMenu}
                  items={mainPageState.rowCompliance}
                />
              ) : mainPageState.currView == "/resident" ? (
                <MainResident onOpenMenu={onOpenMenu} item="/resident" />
              ) : mainPageState.currView == "/training" ? (
                <MainStaffTraining onOpenMenu={onOpenMenu} item="/training" />
              ) : mainPageState.currView == "/checklist" ? (
                <MainCheckList onOpenMenu={onOpenMenu} item="/checklist" />
              ) : mainPageState.currView == "/famMember" ? (
                <MainFamMember onOpenMenu={onOpenMenu} item="/famMember" />
              ) : mainPageState.currView == "/documents" ? (
                <MainDocuments onOpenMenu={onOpenMenu} item="/documents" />
              ) : mainPageState.currView == "/myDocuments" ? (
                <MainMyDocuments onOpenMenu={onOpenMenu} item="/myDocuments" />
              ) : mainPageState.currView == "/complianceForms" ? (
                <MainComplianceForms
                  onOpenMenu={onOpenMenu}
                  item="/complianceForms"
                />
              ) : mainPageState.currView == "/scheduleVisit" ? (
                <FamilyMemberVisit
                  onOpenMenu={onOpenMenu}
                  item="/scheduleVisit"
                />
              ) : mainPageState.currView == "/documentsForSigning" ? (
                <MainDocumentsForSigning
                  onOpenMenu={onOpenMenu}
                  item="/documentsForSigning"
                />
              ) : mainPageState.currView == "/chgsignature" ? (
                <DepositSignature
                  onOpenMenu={onOpenMenu}
                  item="/documentsForSigning"
                  onHideDepositSignature={onHideDepositSignature}
                  onSaveSignature={onSaveSignature}
                  base64Signature={mainPageState.base64Signature}
                />
              ) : (
                <Home onOpenMenu={onOpenMenu} mainPageState={mainPageState} />
              )}
            </Col>
          </Row>
        </Container>
      )}
      <Modal
        show={mainPageState.showChangePassword}
        onHide={() => onHideChangePassword()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "black" }}>Change password</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePassword
            onHideChangePassword={onHideChangePassword}
            onChangeChangePassword={onChangeChangePassword}
            onSaveChangePassword={onSaveChangePassword}
            onShowPassword={onShowPassword}
            onHidePassword={onHidePassword}
            mainPageState={mainPageState}
            isError={mainPageState.isError}
            errorMsg={mainPageState.errorMsg}
          />
        </Modal.Body>
      </Modal>
      {/* <Modal show={mainPageState.showBillInfo} onHide={() => onCancelEdit()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "black" }}>Billing Information</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BillInfo
            onEditBillInfo={onEditBillInfo}
            onChange={onChange}
            onCancelEdit={onCancelEdit}
            onSaveCard={onSaveCard}
            mainPageState={mainPageState}
          />
        </Modal.Body>
      </Modal> */}
    </React.Fragment>
  );
};

export default MainPageDesktop;
