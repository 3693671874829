import StaffTrainingClient from "../../../api/compliance/training/staffTrainingClient";

const staffTrainingClient = new StaffTrainingClient();

class StaffTrainingService {
  async delete(id) {
    try {
      const response = await staffTrainingClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    let response = "";
    try {
      response = await staffTrainingClient.insert(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  getAll() {
    return staffTrainingClient.getAll();
  }

  getById(id) {
    return staffTrainingClient.getById(id);
  }

  async getByStaffId(staffId) {
    try {
      const response = await staffTrainingClient.getByStaffId(staffId);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async markAsPassed(repo) {
    try {
      const response = await staffTrainingClient.markAsPassed(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  validateMarkAsPassed(markAsPassedObject) {
    if (markAsPassedObject.startDate == undefined) {
      return {
        isError: true,
        errMessage: "Please enter start date",
      };
    }

    if (markAsPassedObject.passDate == undefined) {
      return {
        isError: true,
        errMessage: "Please enter end date",
      };
    }

    if (markAsPassedObject.durationHours == undefined) {
      return {
        isError: true,
        errMessage: "Please enter hours of training:",
      };
    }

    return {
      isError: false,
    };
  }

  downloadReport(repo) {
    console.log("downloadReport", repo);
    staffTrainingClient.download(repo).then((response) => {
      console.log("response", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "CompletedTrainings.pdf");
      document.body.appendChild(link);
      link.click();
    });
  }

  printCertificate(repo) {
    console.log("printCertificate", repo);
    staffTrainingClient.printCertificate(repo).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Certificate.pdf");
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default new StaffTrainingService();
