import { useState } from "react";
import TodoService from "../../../services/todo/todoService";
import StaffService from "../../../services/user/staffService";
import TodoCommentService from "../../../services/todo/todoCommentService";
import moment from "moment";

const useTodoState = () => {
  const [todoState, setTodoState] = useState({
    view: "cal",
    show: false,
    selectedDate: new Date(),
    openTask: false,
    selectedMonth: moment(new Date()).month(),
    selectedYear: moment(new Date()).year(),
    showReschedule: false,
    currTodo: initTodo(),
  });

  const refreshPage = async () => {
    const todos = await TodoService.getNotRepeated();
    const staff = await StaffService.getAll();
    setTodoState({
      ...todoState,
      staff: staff
        .filter((stf) => stf.userRole == "A" || stf.userRole == "E")
        .map((stf) => {
          stf.password = "";
          stf.confirmPassword = "";
          return stf;
        }),
      todos:
        todos &&
        todos.map((todo) => {
          return formatTodo(todo);
        }),
    });
  };

  const onDeleteTodo = async (todo) => {
    if (
      window.confirm(
        "Do you really want to delete " + todo.title + " reminder?"
      )
    ) {
      await TodoService.delete(todo.id);
      setTodoState({
        ...todoState,
        todos: todoState.todos.filter((t) => {
          return t.id !== todo.id;
        }),
      });
    }
  };

  const onShowTaskDetails = (year, month, day) => {
    console.log("year", year);
    console.log("month", month);
    console.log("day", day);
    setTodoState({
      ...todoState,
      selectedDate: new Date(year, month, day),
      openTask: true,
    });
  };

  const onBackToCalendar = () => {
    setTodoState({ ...todoState, openTask: false });
  };

  const onNextMonth = () => {
    if (todoState.selectedMonth == 11) {
      setTodoState({
        ...todoState,
        selectedMonth: 0,
        selectedYear: todoState.selectedYear + 1,
      });
    } else {
      var nextmonth = moment(
        todoState.selectedYear +
          "-" +
          ("0" + (todoState.selectedMonth + 1)).slice(-2),
        "YYYY-MM"
      ).add(1, "months");
      setTodoState({
        ...todoState,
        selectedMonth: nextmonth.month(),
        selectedYear: nextmonth.year(),
      });
    }
  };

  const onPreviousMonth = () => {
    if (todoState.selectedMonth == 0) {
      setTodoState({
        ...todoState,
        selectedMonth: 11,
        selectedYear: todoState.selectedYear - 1,
      });
    } else {
      var prevmonth = moment(
        todoState.selectedYear +
          "-" +
          ("0" + (todoState.selectedMonth + 1)).slice(-2),
        "YYYY-MM"
      ).subtract(1, "months");
      setTodoState({
        ...todoState,
        selectedMonth: prevmonth.month(),
        selectedYear: prevmonth.year(),
      });
    }
  };

  const onChangeView = (view) => {
    setTodoState({ ...todoState, view: view });
  };

  const onSetCurrentDate = () => {
    setTodoState({
      ...todoState,
      selectedDate: new Date(),
      selectedMonth: moment(new Date()).month(),
      selectedYear: moment(new Date()).year(),
    });
  };

  const onChangeMonth = (event) => {
    setTodoState({ ...todoState, selectedMonth: parseInt(event.target.value) });
  };

  const onChangeYear = (event) => {
    setTodoState({ ...todoState, selectedYear: parseInt(event.target.value) });
  };

  const onRescheduleTodo = async () => {
    let todo = { ...todoState.currTodo };
    if (todo.startDate == undefined) {
      setTodoState({
        ...todoState,
        isError: true,
        errorMsg: "Please enter date!",
      });
      return;
    }
    const savedTodo = await TodoService.save(todo);
    setTodoState({
      ...todoState,
      currTodo: savedTodo,
      todos: todoState.todos
        .filter((t) => {
          return t.id !== savedTodo.id;
        })
        .concat(savedTodo),
      showTodoReschedule: false,
    });
  };

  const onShowReminder = (val) => {
    setTodoState({ ...todoState, show: val });
  };

  const onChange = (e, isCheckBox) => {
    let value = e.target.value;
    if (isCheckBox) {
      value = e.target.checked;
    }
    const todo = { ...todoState.currTodo };
    todo[e.target.name] = value;
    setTodoState({
      ...todoState,
      currTodo: todo,
      isError: false,
      errorMsg: "",
    });
  };

  const onChangeDates = (value, fieldName) => {
    const todo = { ...todoState.currTodo };
    if (fieldName == "startDate") {
      todo["startDate"] = value;
    } else if (fieldName == "endDate") {
      todo["endDate"] = value;
    }
    setTodoState({
      ...todoState,
      currTodo: todo,
      isError: false,
      errorMsg: "",
    });
  };

  const onAddTodo = async () => {
    const validatedTodo = TodoService.validateTodo(todoState.currTodo);
    if (!validatedTodo.isValid) {
      if (validatedTodo.isError) {
        setTodoState({
          ...todoState,
          isError: true,
          errorMsg: validatedTodo.msg,
        });

        return;
      }
    }

    const savedTodo = await TodoService.save(todoState.currTodo);
    if (savedTodo.repeated) {
      const todos = await TodoService.getNotRepeated();
      setTodoState({
        ...todoState,
        todos: todos,
        show: false,
        currTodo: initTodo(),
      });
    } else {
      setTodoState({
        ...todoState,
        todos: todoState.todos
          .filter((td) => td.id != savedTodo.id)
          .concat(savedTodo)
          .map((todo) => {
            return formatTodo(todo);
          }),
        show: false,
        currTodo: initTodo(),
      });
    }
  };

  const onCloseTodoReschedule = () => {
    setTodoState({ ...todoState, showTodoReschedule: false, currTodo: {} });
  };

  const onCloseTodoDetails = () => {
    setTodoState({ ...todoState, showTodoDetails: false, currTodo: {} });
  };

  const onShowTodoDetails = async (todo) => {
    const todoComments = await TodoCommentService.getByTodo(todo.id);
    setTodoState({
      ...todoState,
      showTodoDetails: true,
      currTodo: todo,
      currTodoComments: todoComments
        ? todoComments.map((comment) => formatComment(comment))
        : [],
    });
  };

  const onShowTodoReschedule = (todo) => {
    setTodoState({ ...todoState, showTodoReschedule: true, currTodo: todo });
  };

  const onChangeComment = (e) => {
    setTodoState({ ...todoState, currTodoComment: e.target.value });
  };

  const onSaveComment = async () => {
    const todoForInsert = {
      comment: todoState.currTodoComment,
      todoId: todoState.currTodo.id,
      commentBy: sessionStorage.getItem("authenticatedUser"),
      commentDate: new Date(),
    };

    const insertedTodoComment = await TodoCommentService.save(todoForInsert);

    setTodoState({
      ...todoState,
      currTodoComments: [...todoState.currTodoComments]
        .concat(insertedTodoComment)
        .sort((commA, commB) => (commA.id < commB.id ? 1 : -1)),
      currTodoComment: "",
    });
  };

  function formatComment(comment) {
    comment["commentDate"] = moment(comment["commentDate"]);
    return comment;
  }

  function formatTodo(todo) {
    return {
      id: todo.id,
      title: todo.title,
      description: todo.description,
      startDate: todo.startDate,
      endDate: todo.endDate,
      status: todo.status,
      repeated: todo.repeated,
      repeatedLabel: todo.repeatedLabel,
      repeatedCategoryValue: todo.repeatedCategoryValue,
      staffId: todo.staff && todo.staff.id,
      repeatedCategoryId: todo.repeatedCategory && todo.repeatedCategory.id,
      repeatedCategoryTypeId:
        todo.repeatedCategoryType && todo.repeatedCategoryType.id,
      taskCategoryId: todo.taskCategory && todo.taskCategory.id,
      parentId: todo.parentId,
      residentId: todo.residentId,
      formId: todo.formId,
    };
  }

  function initTodo() {
    return {
      title: "",
      description: "",
      startDate: undefined,
      endDate: undefined,
      repeated: false,
      repeatedCategoryId: "1",
      repeatedCategoryValue: 2,
      repeatedCategoryTypeId: "d",
      residentId: undefined,
      formId: undefined,
      status: false,
    };
  }

  return {
    refreshPage,
    onDeleteTodo,
    onAddTodo,
    onShowTaskDetails,
    onBackToCalendar,
    onNextMonth,
    onPreviousMonth,
    onChangeView,
    onSetCurrentDate,
    onChangeMonth,
    onChangeYear,
    onRescheduleTodo,
    onShowReminder,
    onChange,
    onChangeDates,
    onCloseTodoReschedule,
    onCloseTodoDetails,
    onShowTodoDetails,
    onShowTodoReschedule,
    onChangeComment,
    onSaveComment,
    todoState,
  };
};

export default useTodoState;
