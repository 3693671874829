import React from "react";
import { Form, Table } from "react-bootstrap";

const Socialization = ({
  onChangeInput,
  lic625State,
  complianceFormsState,
}) => {
  return (
    <Table>
      <tbody>
        <tr>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Needs</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("socializationNeeds", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.socializationNeeds}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Objective/Plan</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("socializationPlan", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.socializationPlan}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td>
            <Form.Group>
              <Form.Label>
                <b>Time frame</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("socializationTimeFrame", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.socializationTimeFrame}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Person(s) responsible for implementation</b>
              </Form.Label>
              <Form.Control
                onChange={(e) =>
                  onChangeInput("socializationPersonResponsible", e)
                }
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={
                  lic625State && lic625State.socializationPersonResponsible
                }
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Method of evaluating progress</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("socializationProgress", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.socializationProgress}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default Socialization;
