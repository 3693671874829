import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";

const DoctorInfoDesktop = ({
  onChangeDoc,
  onAddResidentDoctor,
  onDeleteResidentDoctor,
  onExitDoctorPhone,
  residentState,
}) => {
  console.log("currResidentDoctor", residentState.currResidentDoctor);
  console.log("currResidentDoctors", residentState.residentDoctors);

  const formatLabel = (residentDoctor) => {
    return (
      (residentDoctor.name == undefined ? "" : residentDoctor.name) +
      (residentDoctor.phone == undefined || residentDoctor.phone == ""
        ? ""
        : ", " + residentDoctor.phone) +
      (residentDoctor.email == undefined || residentDoctor.email == ""
        ? ""
        : ", " + residentDoctor.email)
    );
  };

  return (
    <Container
      style={{
        border: "solid",
        borderWidth: "1px",
        borderColor: "lightgray",
        height: "500px",
        marginBottom: "20px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <Container>
            {residentState.isErrorResDoc ? (
              <Row>
                <Col>
                  <div style={errorMsgStyle}>
                    <b>ERROR:</b> {residentState.errorMsg}
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row style={{ marginTop: "20px" }}>
              <Col>
                <Form.Group style={{ marginTop: "20px" }}>
                  <Form.Label>
                    <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>
                    Name:
                  </Form.Label>
                  <Form.Control
                    name="name"
                    style={{ width: "400px" }}
                    onChange={(e) => onChangeDoc(e)}
                    placeholder="Doctor full name"
                    autoComplete="nope"
                    disabled={
                      residentState &&
                      residentState.currResident &&
                      !residentState.currResident.isActive
                    }
                    value={
                      residentState.currResidentDoctor &&
                      residentState.currResidentDoctor.name
                    }
                    autoComplete="nope"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control
                    name="phone"
                    style={{ width: "200px" }}
                    onBlur={onExitDoctorPhone}
                    onChange={(e) => onChangeDoc(e)}
                    placeholder="Doctor phone"
                    autoComplete="nope"
                    disabled={
                      residentState &&
                      residentState.currResident &&
                      !residentState.currResident.isActive
                    }
                    value={
                      residentState.currResidentDoctor &&
                      residentState.currResidentDoctor.phone
                    }
                    autoComplete="nope"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    name="email"
                    style={{ width: "200px" }}
                    onChange={(e) => onChangeDoc(e)}
                    placeholder="Doctor e-mail"
                    autoComplete="nope"
                    disabled={
                      residentState &&
                      residentState.currResident &&
                      !residentState.currResident.isActive
                    }
                    value={
                      residentState.currResidentDoctor &&
                      residentState.currResidentDoctor.email
                    }
                    autoComplete="nope"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="success"
                  style={{ width: "150px" }}
                  onClick={() => onAddResidentDoctor()}
                  disabled={
                    residentState &&
                    residentState.currResident &&
                    !residentState.currResident.isActive
                  }
                >
                  <TiPlus
                    style={{
                      color: "white",
                      width: "25px",
                      height: "25px",
                      verticalAlign: "middle",
                    }}
                  />
                  {"  "}Add doctor
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Form.Control
            as="select"
            onDoubleClick={(e) => onDeleteResidentDoctor(e)}
            multiple
            disabled={
              residentState &&
              residentState.currResident &&
              !residentState.currResident.isActive
            }
            style={{
              height: "212px",
              maxHeight: "212px",
              marginTop: "70px",
            }}
          >
            {residentState.residentDoctors &&
              residentState.residentDoctors.map((residentDoctor) => {
                return (
                  <option key={residentDoctor.id} value={residentDoctor.id}>
                    {formatLabel(residentDoctor)}
                  </option>
                );
              })}
          </Form.Control>
          <Form.Text id="passwordHelpBlock" muted>
            TO DELETE DOCTOR JUST DOUBLE CLICK IT
          </Form.Text>
        </Col>
      </Row>
    </Container>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default DoctorInfoDesktop;
