/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import AddResidentDesktop from "../compliance/resident/desktop/addResidentDesktop";
import useResidentState from "../compliance/resident/hooks/useResidentState";

const SelectResidentAndForm = ({
  onChangeResident: onChangeResidentDropDown,
  onChangeForm: onChangeFormDropDown,
  onHideSelectResidentAndForm,
  onCreateReport,
  onShowSelectResidentAndForm,
  selectedResident,
  residents,
  selectedForm,
  forms,
}) => {
  console.log(residents);
  const {
    onChangeResident,
    onChangeResidentDates,
    onHideResident,
    onSaveResident,
    onChangeForm,
    residentState,
  } = useResidentState();

  const onSaveResidentLocal = async () => {
    await onSaveResident();
    await onShowSelectResidentAndForm();
  };
  if (selectedResident == -2) {
    return (
      <AddResidentDesktop
        onHideResident={onShowSelectResidentAndForm}
        onChangeResident={onChangeResident}
        onChangeResidentDates={onChangeResidentDates}
        onSaveResident={onSaveResidentLocal}
        residentState={residentState}
      />
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Form.Control
              value={selectedResident}
              name="resident"
              as="select"
              onChange={(e) => onChangeResidentDropDown(e)}
              size="sm"
            >
              <option key={-1} value={-1}>
                Select resident ...
              </option>
              {residents &&
                residents
                  .filter((e) => e.isActive)
                  .map((element) => (
                    <option key={element.id} value={element.id}>
                      {element.firstName} {element.lastName}
                    </option>
                  ))}
              <option key={-2} value={-2}>
                Add resident ...
              </option>
            </Form.Control>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col>
            {forms && forms.length > 0 ? (
              <Form.Control
                value={selectedForm}
                name="resident"
                as="select"
                onChange={(e) => onChangeFormDropDown(e)}
                size="sm"
              >
                <option key={-1} value={-1}>
                  Select form ...
                </option>
                {forms &&
                  forms.map((element) => (
                    <option key={element.id} value={element.id}>
                      {element.name}
                    </option>
                  ))}
              </Form.Control>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <Button
              variant="outline-success"
              style={{
                width: "100px",
              }}
              onClick={onHideSelectResidentAndForm}
            >
              Close
            </Button>
            {"   "}
            <Button
              variant="success"
              style={{ width: "100px" }}
              onClick={onCreateReport}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default SelectResidentAndForm;
