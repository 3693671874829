import React, { useEffect } from "react";
import UsersFamMember from "./usersFamMember";
import AddUserFamMemberDesktop from "./desktop/addUserFamMemberDesktop";
import useFamMember from "./hook/useFamMember";

const MainFamMember = () => {
  const {
    refreshPage,
    onDelete,
    onUpdate,
    onShow,
    onHide,
    onSaveStaff,
    onChange,
    onChangeDates,
    onChangePassword,
    onToggleChanged,
    onFilterUsers,
    onShowPassword,
    onHidePassword,
    famMemberState,
  } = useFamMember({});

  useEffect(() => {
    refreshPage();
  }, []);

  if (!famMemberState.show)
    return (
      <React.Fragment>
        <UsersFamMember
          onDelete={onDelete}
          onUpdate={onUpdate}
          onShow={onShow}
          onFilterUsers={onFilterUsers}
          users={
            famMemberState.filteredUsers &&
            famMemberState.filteredUsers.filter((user) => user.role != "admin")
          }
        />
      </React.Fragment>
    );
  else
    return (
      <React.Fragment>
        <AddUserFamMemberDesktop
          onSaveStaff={onSaveStaff}
          onHide={onHide}
          onChange={onChange}
          onChangeDates={onChangeDates}
          onChangePassword={onChangePassword}
          onToggleChanged={onToggleChanged}
          onShowPassword={onShowPassword}
          onHidePassword={onHidePassword}
          usersState={famMemberState}
        />
      </React.Fragment>
    );
};

export default MainFamMember;
