import APIClient from "../../apiClient";

const RESIDENT_FORM_SIGN = "/residentFormSign";

class ResidentFormSignClient extends APIClient {
  async getByTemplateAndSignatureType(reportType) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + RESIDENT_FORM_SIGN + "/" + reportType
    );
  }

  async getAvailableSignatureKeys(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() +
        RESIDENT_FORM_SIGN +
        "/getAvailableSignatureKeys",
      repo
    );
  }
}

export default ResidentFormSignClient;
