import React from "react";
import { Card, CardColumns, CardDeck, Dropdown, Form } from "react-bootstrap";
import { Button, Container, Row, Col } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdMoreHoriz } from "react-icons/md";
import { TiDeleteOutline } from "react-icons/ti";
import { MdVisibilityOff } from "react-icons/md";

const ResidentListDesktop = ({
  onOpenResident,
  onSelectResidentMenu,
  residentState,
}) => {
  console.log(residentState.residents);
  return (
    // <CardColumns style={{ marginTop: "10px" }}>
    <table>
      <tbody>
        {residentState.residents &&
          residentState.residents
            .filter((res) =>
              residentState.showDeactivated ? !res.isActive : res.isActive
            )
            .sort((r1, r2) => {
              if (r1.firstName.toLowerCase() > r2.firstName.toLowerCase())
                return 1;
              else return -1;
            })
            .map((resident) => (
              <tr>
                <td
                  style={{
                    width: "30px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  {resident.isActive ? (
                    <Dropdown
                      onSelect={(selectedKey) =>
                        onSelectResidentMenu(selectedKey, resident)
                      }
                      style={{ verticalAlign: "bottom" }}
                    >
                      <Dropdown.Toggle
                        variant="success"
                        style={{
                          backgroundColor: "transparent",
                          color: "transparent",
                          width: "45px",
                          borderColor: "black",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                        id="dropdown-basic"
                      >
                        <MdMoreHoriz
                          style={{
                            color: "black",
                            width: "20px",
                            // height: "20px",
                          }}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <React.Fragment>
                          <Dropdown.Item eventKey="open">Edit</Dropdown.Item>
                          <Dropdown.Item eventKey="deactivate">
                            Deactivate
                          </Dropdown.Item>
                        </React.Fragment>

                        {/* <Dropdown.Item eventKey="delete">Delete</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {/* <Button
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      color: "black",
                      verticalAlign: "middle",
                      textAlign: "left",
                      width: "80%",
                    }}
                    onClick={() => onOpenResident(resident)}
                  > */}
                  <b style={{ fontSize: "16px" }}>
                    {resident.isActive ? (
                      resident.firstName + " " + resident.lastName
                    ) : (
                      <del>
                        {resident.firstName} {resident.lastName}
                      </del>
                    )}
                  </b>
                  {/* </Button> */}
                </td>
              </tr>
            ))}
      </tbody>
    </table>
    // </CardColumns>
  );
};

export default ResidentListDesktop;
