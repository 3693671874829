import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import moment from "moment";

const CopyReport = ({
  onHideCopy,
  onConfirmCopy,
  onChangeReport,
  complianceFormsState,
}) => {
  return (
    <Container fluid>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          {complianceFormsState.reports &&
          complianceFormsState.reports.length > 0 ? (
            <Form.Control
              value={complianceFormsState.selectedReport}
              name="resident"
              as="select"
              onChange={(e) => onChangeReport(e)}
              size="sm"
            >
              <option key={-1} value={-1}>
                Select report ...
              </option>
              {complianceFormsState.reports &&
                complianceFormsState.reports.map((element) => (
                  <option key={element.id} value={element.id}>
                    Report for {complianceFormsState.currResident.firstName}
                    {"  "}
                    {complianceFormsState.currResident.lastName}, Created at:{" "}
                    {moment(element.createdAt).format("MM-DD-YYYY")}
                  </option>
                ))}
            </Form.Control>
          ) : (
            "There are no previous reports for this resident"
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <Button
            variant="outline-success"
            style={{
              width: "100px",
            }}
            onClick={onHideCopy}
          >
            Close
          </Button>
          {"   "}
          {complianceFormsState.reports &&
          complianceFormsState.reports.length == 0 ? (
            ""
          ) : (
            <Button
              variant="success"
              style={{ width: "100px" }}
              onClick={() => onConfirmCopy()}
            >
              Confirm
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CopyReport;
