import React from "react";
import { Button } from "react-bootstrap";

const ButtonBarDesktop = ({
  onShowMedicationOrderHistory,
  onShowSendOrder,
}) => {
  return (
    <table width="100%" id="TableParent">
      <tbody>
        <tr>
          <td>
            <Button
              variant="primary"
              style={buttonStyle}
              onClick={() => onShowMedicationOrderHistory()}
            >
              View order history
            </Button>{" "}
            <Button
              variant="primary"
              style={buttonStyle}
              onClick={() => onShowSendOrder()}
            >
              Send order
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const buttonStyle = {
  width: "150px",
};

export default ButtonBarDesktop;
