import React from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";

const Services = ({ onChangeInput, lic603aState, complianceFormsState }) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Form.Label style={{ marginBottom: "10px" }}>
            <b>SERVICES NEEDED</b> (Check items and explain):
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <b>YES</b>
        </Col>
        <Col xs="auto">
          <b>NO</b>
        </Col>
      </Row>
      <Row style={{ marginBottom: "0px", paddingBottom: "0px" }}>
        <Col xs="auto" style={{ marginBottom: "0px", paddingBottom: "0px" }}>
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpInTransferring"
            onChange={(e) => onChangeInput("helpInTransferring", "1", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.helpInTransferring == "1"
            }
          />
        </Col>
        <Col xs="auto" style={{ paddingBottom: "0px", marginBottom: "0px" }}>
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpInTransferring"
            onChange={(e) => onChangeInput("helpInTransferring", "2", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.helpInTransferring == "2"
            }
          />
        </Col>
        <Col style={{ marginBottom: "0px", paddingBottom: "0px" }}>
          <InputGroup style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Help in transferring in and out of bed/turning in bed or chair
              (specify)
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpInTransferringComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("helpInTransferringComment", e)}
              value={lic603aState && lic603aState.helpInTransferringComment}
              autoComplete="off"
              maxLength={132}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithBathing"
            onChange={(e) => onChangeInput("helpWithBathing", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithBathing == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithBathing"
            onChange={(e) => onChangeInput("helpWithBathing", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithBathing == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Help with bathing
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpWithBathingComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("helpWithBathingComment", e)}
              value={lic603aState && lic603aState.helpWithBathingComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithDressing"
            onChange={(e) => onChangeInput("helpWithDressing", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithDressing == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithDressing"
            onChange={(e) => onChangeInput("helpWithDressing", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithDressing == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Help with dressing, hair care, and personal hygiene (specify)
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpWithDressingComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("helpWithDressingComment", e)}
              value={lic603aState && lic603aState.helpWithDressingComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithLaundry"
            onChange={(e) => onChangeInput("helpWithLaundry", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithLaundry == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithLaundry"
            onChange={(e) => onChangeInput("helpWithLaundry", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithLaundry == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Does prospective resident desire and is he/she capable of doing
              own personal laundry and other household tasks? (specify)
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpWithLaundryComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("helpWithLaundryComment", e)}
              value={lic603aState && lic603aState.helpWithLaundryComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithMoving"
            onChange={(e) => onChangeInput("helpWithMoving", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithMoving == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithMoving"
            onChange={(e) => onChangeInput("helpWithMoving", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithMoving == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Help with moving about the facility
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpWithMovingComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("helpWithMovingComment", e)}
              value={lic603aState && lic603aState.helpWithMovingComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithEating"
            onChange={(e) => onChangeInput("helpWithEating", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithEating == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithEating"
            onChange={(e) => onChangeInput("helpWithEating", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpWithEating == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Help with eating (need for adaptive devices or assistance from
              another person)
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpWithEatingComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("helpWithEatingComment", e)}
              value={lic603aState && lic603aState.helpWithEatingComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="specialDiet"
            onChange={(e) => onChangeInput("specialDiet", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.specialDiet == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="specialDiet"
            onChange={(e) => onChangeInput("specialDiet", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.specialDiet == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Special diet/observation of food intake
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="specialDietComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("specialDietComment", e)}
              value={lic603aState && lic603aState.specialDietComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="specialToileting"
            onChange={(e) => onChangeInput("specialToileting", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.specialToileting == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="specialToileting"
            onChange={(e) => onChangeInput("specialToileting", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.specialToileting == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Toileting, including assistance equipment, or assistance of
              another person (specify)
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="specialToiletingComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("specialToiletingComment", e)}
              value={lic603aState && lic603aState.specialToiletingComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="continence"
            onChange={(e) => onChangeInput("continence", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.continence == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="continence"
            onChange={(e) => onChangeInput("continence", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.continence == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Continence, bowel or bladder control. Are assistive devices such
              as a catheter required?
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="continenceComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("continenceComment", e)}
              value={lic603aState && lic603aState.continenceComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithMedication"
            onChange={(e) => onChangeInput("helpWithMedication", "1", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.helpWithMedication == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpWithMedication"
            onChange={(e) => onChangeInput("helpWithMedication", "2", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.helpWithMedication == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Help with medication
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpWithMedicationComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("helpWithMedicationComment", e)}
              value={lic603aState && lic603aState.helpWithMedicationComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="needsSpecialObservation"
            onChange={(e) =>
              onChangeInput("needsSpecialObservation", "1", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.needsSpecialObservation == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="needsSpecialObservation"
            onChange={(e) =>
              onChangeInput("needsSpecialObservation", "2", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.needsSpecialObservation == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Needs special observation/night supervision (due to confusion,
              forgetfulness, wandering)
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="needsSpecialObservationComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) =>
                onChangeInput("needsSpecialObservationComment", e)
              }
              value={
                lic603aState && lic603aState.needsSpecialObservationComment
              }
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpManagingCache"
            onChange={(e) => onChangeInput("helpManagingCache", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpManagingCache == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpManagingCache"
            onChange={(e) => onChangeInput("helpManagingCache", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.helpManagingCache == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Help in managing own cash resources
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpManagingCacheComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("helpManagingCacheComment", e)}
              value={lic603aState && lic603aState.helpManagingCacheComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpParticipatingActivity"
            onChange={(e) =>
              onChangeInput("helpParticipatingActivity", "1", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.helpParticipatingActivity == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpParticipatingActivity"
            onChange={(e) =>
              onChangeInput("helpParticipatingActivity", "2", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.helpParticipatingActivity == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Help in participating in activity programs
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpParticipatingActivityComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) =>
                onChangeInput("helpParticipatingActivityComment", e)
              }
              value={
                lic603aState && lic603aState.helpParticipatingActivityComment
              }
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpMedicalAttention"
            onChange={(e) => onChangeInput("helpMedicalAttention", "1", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.helpMedicalAttention == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="helpMedicalAttention"
            onChange={(e) => onChangeInput("helpMedicalAttention", "2", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.helpMedicalAttention == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Special medical attention
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="helpMedicalAttentionComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("helpMedicalAttentionComment", e)}
              value={lic603aState && lic603aState.helpMedicalAttentionComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="assistanceIncidentHealth"
            onChange={(e) =>
              onChangeInput("assistanceIncidentHealth", "1", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.assistanceIncidentHealth == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="assistanceIncidentHealth"
            onChange={(e) =>
              onChangeInput("assistanceIncidentHealth", "2", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.assistanceIncidentHealth == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Assistance in incidental health and medical care
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="assistanceIncidentHealthComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) =>
                onChangeInput("assistanceIncidentHealthComment", e)
              }
              value={
                lic603aState && lic603aState.assistanceIncidentHealthComment
              }
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="otherIncidents"
            onChange={(e) => onChangeInput("otherIncidents", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.otherIncidents == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="otherIncidents"
            onChange={(e) => onChangeInput("otherIncidents", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.otherIncidents == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Other “Services Needed” not identified above
            </InputGroup.Text>
            <Form.Control
              size="sm"
              name="otherIncidentsComment"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("otherIncidentsComment", e)}
              value={lic603aState && lic603aState.otherIncidentsComment}
              autoComplete="off"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="additionalInformation"
            onChange={(e) =>
              onChangeInput("additionalInformation", "1", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.additionalInformation == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="additionalInformation"
            onChange={(e) =>
              onChangeInput("additionalInformation", "2", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.additionalInformation == "2"
            }
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text
              id="basic-addon1"
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              Is there any additional information which would assist the
              facility in determining applicant’s suitability for admission? If
              Yes, please attach comments on separate sheet.
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
