import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import TodoDesktop from "../../desktop/todoDesktop";
import moment from "moment";

const ScheduleDektop = ({
  onDeleteTodo,
  onShowTodoDetails,
  onShowTodoReschedule,
  todos,
}) => {
  const todosPerMonth =
    todos &&
    todos
      .filter((todo) => moment(todo.startDate).diff(moment(new Date())) > -1)
      .reduce((acc, todo) => {
        let existObj = acc.find(
          (obj) =>
            obj.title ===
            moment(todo.startDate).format("MMMM") +
              " " +
              moment(todo.startDate).year()
        );

        if (existObj) {
          existObj.data.push(todo);
        } else {
          acc.push({
            title:
              moment(todo.startDate).format("MMMM") +
              " " +
              moment(todo.startDate).year(),
            data: [todo],
          });
        }
        return acc;
      }, []);

  return (
    <React.Fragment>
      <div
        id=""
        style={{
          overflow: "scroll",
          overflowX: "hidden",
          // height: "300px",
          height: "80vh",
        }}
      >
        <Accordion defaultActiveKey={1}>
          {todosPerMonth &&
            todosPerMonth.map((todoPerMonth, index) => {
              return (
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                    <Button
                      variant="link"
                      style={{
                        color: "#3c3b6e",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {todoPerMonth.title}
                    </Button>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body>
                      {todoPerMonth &&
                        todoPerMonth.data.map((todoForTheMonth, index) => {
                          return (
                            <div
                              style={{
                                display: "inline-block",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TodoDesktop
                                todo={todoForTheMonth}
                                onDeleteTodo={onDeleteTodo}
                                onShowTodoDetails={onShowTodoDetails}
                                onShowTodoReschedule={onShowTodoReschedule}
                              />
                            </div>
                          );
                        })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
        </Accordion>
      </div>
    </React.Fragment>
  );
};

export default ScheduleDektop;
