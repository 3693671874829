import React from "react";
import { Container, Form, Row, Col, InputGroup } from "react-bootstrap";

const GeneralInfo = ({ onChangeInput, lic624State, complianceFormsState }) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };
  return (
    <React.Fragment>
      <Container fluid style={{ marginTop: "20px" }}>
        <Row>
          <Col md={6}>
            <Form.Label>
              <b>Facility name:</b>
            </Form.Label>
            <Form.Control
              name="facilityName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityName", e)}
              value={lic624State && lic624State.facilityName}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={3}>
            <Form.Label>
              <b>Facility file number</b>
            </Form.Label>
            <Form.Control
              name="facilityLicenseNum"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityLicenseNum", e)}
              value={lic624State && lic624State.facilityLicenseNum}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={3}>
            <Form.Label>
              <b>Telephone number:</b>
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="areaCode"
                style={{ width: "20%" }}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                onChange={(e) => onChangeInput("areaCode", e)}
                value={lic624State && lic624State.areaCode}
                autoComplete="off"
                onFocus={(event) => dummyOnFocus(event)}
              />
              <Form.Control
                name="phoneNum"
                style={{ width: "80%" }}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                onChange={(e) => onChangeInput("phoneNum", e)}
                value={lic624State && lic624State.phoneNum}
                autoComplete="off"
                onFocus={(event) => dummyOnFocus(event)}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Label>
              <b>Address:</b>
            </Form.Label>
            <Form.Control
              name="facilityAddress"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityAddress", e)}
              value={lic624State && lic624State.facilityAddress}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={6} style={{ justifyContent: "left" }}>
            <Form.Label>
              <b>CITY, STATE, ZIP:</b>
            </Form.Label>
            <Form.Control
              name="facilityLocation"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityLocation", e)}
              value={lic624State && lic624State.facilityLocation}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GeneralInfo;
