import React, { useState, useRef } from "react";
import { Form, ButtonGroup, Button, Spinner } from "react-bootstrap";
import JoditEditor from "jodit-react";

const AddMessageMobile = ({
  onSendMessage,
  onHideMessage,
  onChangeMessage,
  onChangeContent,
  collaborationState,
}) => {
  const [] = useState("");
  const editor = useRef(null);

  const config = {
    readonly: collaborationState.isReadOnly,
    askBeforePasteHTML: false,
    processPasteHTML: true, // all options from https://xdsoft.net/jodit/doc/
    height: "400",
  };

  return (
    <Form
      style={{
        color: "#004d99",
      }}
    >
      <Form.Row>
        {collaborationState.isError ? (
          <div style={errorMsgStyle}>
            <b>ERROR:</b> {collaborationState.errorMsg}
          </div>
        ) : (
          ""
        )}
      </Form.Row>
      <Form.Group>
        <Form.Label size="sm">
          <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>Subject:
        </Form.Label>
        <Form.Control
          name="subject"
          size="sm"
          readOnly={collaborationState.isReadOnly}
          placeholder="Enter subject"
          onChange={(e) => onChangeMessage(e)}
          defaultValue={
            collaborationState.currMessage &&
            collaborationState.currMessage.subject
          }
          style={{ width: 300 }}
          autoComplete="off"
        />
      </Form.Group>
      <Form.Label size="sm">
        <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>Message:
      </Form.Label>
      {collaborationState.isReadOnly ? (
        <div
          className="html-editor__content"
          dangerouslySetInnerHTML={{
            __html: collaborationState.currMessage.description,
          }}
          style={{
            border: "solid",
            height: "400px",
            borderWidth: "1px",
            overflowX: "auto",
          }}
        ></div>
      ) : (
        <JoditEditor
          ref={editor}
          value={
            collaborationState.currMessage &&
            collaborationState.currMessage.description
          }
          config={config}
          tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => onChangeContent(newContent.target.innerHTML)} // preferred to use only this option to update the content for performance reasons
        />
      )}
      {!collaborationState.isReadOnly ? (
        collaborationState.isSendMessageActive ? (
          <ButtonGroup style={{ marginTop: "15px" }}>
            <Button
              variant="outline-success"
              size="sm"
              disabled={true}
              style={{
                width: "100px",
                marginRight: "10px",
              }}
            >
              Close
            </Button>
            <Button
              size="sm"
              variant="success"
              disabled={true}
              style={{ width: "100px" }}
            >
              <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
              />
              Sending...
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup style={{ marginTop: "15px" }}>
            <Button
              variant="outline-success"
              size="sm"
              style={{
                width: "100px",
                marginRight: "10px",
              }}
              onClick={() => onHideMessage()}
            >
              Close
            </Button>
            {"  "}
            <Button
              size="sm"
              variant="success"
              style={{ width: "100px" }}
              onClick={() => onSendMessage()}
            >
              Send
            </Button>
          </ButtonGroup>
        )
      ) : (
        ""
      )}
    </Form>
  );
};

const errorMsgStyle = {
  color: "#b22234",
};

export default AddMessageMobile;
