import { useState } from "react";
import StaffService from "../../../../services/user/staffService";

const useFamMember = () => {
  const [famMemberState, setFamMemberState] = useState({
    currUser: { isDisabled: true, userRole: "F" },
  });

  const refreshPage = async () => {
    const users = await StaffService.getAll();
    setFamMemberState({
      ...famMemberState,
      users: users
        .filter((user) => user.userRole == "F")
        .map((user) => {
          user["password"] = "";
          user["confirmPassword"] = "";
          if (user.isDisabled == undefined) {
            user["isDisabled"] = false;
          }
          return user;
        }),
      filteredUsers: users
        .filter((user) => user.userRole == "F")
        .map((user) => {
          user["password"] = "";
          user["confirmPassword"] = "";
          if (user.isDisabled == undefined) {
            user["isDisabled"] = false;
          }
          return user;
        }),
    });
  };

  const onDelete = async (user) => {
    await StaffService.delete(user.id);
    const newUsers = famMemberState.users.filter((usr) => usr.id != user.id);
    setFamMemberState({
      ...famMemberState,
      users: newUsers,
      filteredUsers: applyFilter(newUsers, famMemberState.filterCriteria),
    });
  };

  function applyFilter(users, filterCriteria) {
    return filterCriteria != undefined && filterCriteria.length > 0
      ? users.filter(
          (user) =>
            user.name.toLowerCase().includes(filterCriteria.toLowerCase()) ||
            user.username.toLowerCase().includes(filterCriteria.toLowerCase())
        )
      : users;
  }

  const onUpdate = async (user) => {
    // const dbUser = await StaffService.getById(user.id);
    // user["password"] = "";
    // user["confirmPassword"] = "";
    setFamMemberState({
      ...famMemberState,
      currUser: user,
      show: true,
      isChangePassword: false,
    });
  };

  const onShow = async () => {
    setFamMemberState({
      ...famMemberState,
      currUser: { userRole: "F", isDisabled: false },
      isChangePassword: true,
      show: true,
    });
  };

  const onHide = () => {
    setFamMemberState({
      ...famMemberState,
      currUser: { userRole: "F", isDisabled: true },
      isChangePassword: false,
      show: false,
      isError: false,
      msg: "",
    });
  };

  const onSaveStaff = async () => {
    let user = famMemberState.currUser;
    const validation = await StaffService.validateUser(user);
    if (validation.isError) {
      setFamMemberState({
        ...famMemberState,
        isError: true,
        errorMsg: validation.msg,
      });
      return;
    }

    if (famMemberState.isChangePassword) {
      const validatedPassword = StaffService.validatePassword(user);
      if (validatedPassword.isError) {
        setFamMemberState({
          ...famMemberState,
          isError: true,
          errorMsg: validatedPassword.msg,
        });
        return;
      }
    }
    if (user.userRole == undefined || user.userRole == "") {
      user.userRole = "E";
    }

    if (user.id == undefined) {
      user.isFirstLogIn = true;
    }
    const savedUser = await StaffService.save(user);
    savedUser["password"] = "";
    savedUser["confirmPassword"] = "";
    const newUsers =
      famMemberState.users != undefined
        ? famMemberState.users
            .filter((user) => user.id != savedUser.id)
            .concat(savedUser)
        : [].concat(savedUser);
    setFamMemberState({
      ...famMemberState,
      users: newUsers,
      filteredUsers: applyFilter(newUsers, famMemberState.filterCriteria),
      show: false,
    });
  };

  const onChange = (event) => {
    let staff = famMemberState.currUser;
    const value = event.target.value;
    console.log("value", value);
    staff[event.target.name] = value;

    setFamMemberState({ ...famMemberState, currUser: staff, isError: false });
  };

  const onChangeDates = (value, fieldName) => {
    let staff = famMemberState.currUser;

    if (fieldName == "startDate") {
      staff["startDate"] = value;
    }

    setFamMemberState({ ...famMemberState, currUser: staff, isError: false });
  };

  const onToggleChanged = (isEnabled) => {
    let staff = famMemberState.currUser;
    staff["isDisabled"] = !isEnabled;

    setFamMemberState({ ...famMemberState, currUser: staff, isError: false });
  };

  const onChangePassword = (event) => {
    setFamMemberState({
      ...famMemberState,
      isChangePassword: event.target.checked,
      isError: false,
    });
  };

  const onFilterUsers = (e) => {
    const filter = e.target.value;
    console.log("cond", filter != undefined && filter.length > 0);

    setFamMemberState({
      ...famMemberState,
      filterCriteria: filter,
      filteredUsers: applyFilter(famMemberState.users, filter),
    });
  };

  const onShowPassword = (inputField) => {
    setFamMemberState({ ...famMemberState, showPassword: inputField });
  };

  const onHidePassword = () => {
    setFamMemberState({ ...famMemberState, showPassword: "" });
  };

  return {
    refreshPage,
    onDelete,
    onUpdate,
    onShow,
    onHide,
    onSaveStaff,
    onChange,
    onChangeDates,
    onToggleChanged,
    onChangePassword,
    onFilterUsers,
    onShowPassword,
    onHidePassword,
    famMemberState,
  };
};

export default useFamMember;
