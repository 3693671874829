import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import home from "../img/home.png";
import back from "../img/left.png";
import AuthenticationService from "../api/authenticationService";
import "./main/menu.css";
import { slide as Menu } from "react-burger-menu";
import medication from "../img/medication_colored.png";
import product from "../img/product_colored.png";
import todo from "../img/reminder_colored.png";
import training from "../img/training_new.png";
import people from "../img/people_colored.png";
import compliance from "../img/compliance_colored.png";
import staff from "../img/staff_colored.gif";
import facility from "../img/facility_colored.png";

class Header extends React.Component {
  onHome() {
    window.open("/", "_self");
  }

  onBack() {
    window.history.back();
  }

  render() {
    let row = [
      { name: "Reminders", img: todo, uri: "/todo" },
      { name: "Medications", img: medication, uri: "/medication" },
      { name: "Shopping", img: product, uri: "/product" },
      { name: "Collaboration", img: people, uri: "/collaboration" },
      { name: "Compliance", img: compliance, uri: "/compliance" },
      { name: "Users", img: staff, uri: "/staff" },
      { name: "Facility", img: facility, uri: "/facility" },
    ];
    return (
      <React.Fragment>
        <table>
          <tbody>
            <tr>
              <td style={{ width: "30px" }}>
                <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-"bottom"`}>
                      Click to go on home page
                    </Tooltip>
                  }
                >
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => this.onHome()}
                    style={{ color: "#B22234" }}
                  >
                    <b>Home</b>
                  </Button>
                </OverlayTrigger>
              </td>
              <td align="right">
                <Button
                  variant="link"
                  size="sm"
                  style={{ color: "#B22234" }}
                  onClick={AuthenticationService.logout}
                >
                  <b>Logout {sessionStorage.getItem("authenticatedUser")}</b>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default Header;
