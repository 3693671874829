import React from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import UsersList from "../usersList";
import { TiPlus } from "react-icons/ti";

const UsersFamMember = ({
  onDelete,
  onUpdate,
  onShow,
  onFilterUsers,
  users,
}) => {
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col>
            <Button
              variant="success"
              onClick={() => onShow()}
              style={{ width: "200px", marginBottom: "20px" }}
            >
              <TiPlus
                style={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  verticalAlign: "middle",
                }}
              />
              {"   "}Add guardian
            </Button>
          </Col>
          <Col xs="auto">
            <Form.Control
              placeholder="Filter Guardians"
              onChange={onFilterUsers}
              style={{ width: "300px" }}
            />
          </Col>
        </Row>
      </Container>

      <UsersList onDelete={onDelete} onUpdate={onUpdate} users={users} />
    </React.Fragment>
  );
};

export default UsersFamMember;
