import React from "react";
import { Card, Form, Button } from "react-bootstrap";
import AddToCart from "../../../common/addToCart";
import * as Constants from "../../../util/constants";
import walmart from "../../../../img/Walmart.png";
import costco from "../../../../img/costco.png";
import sprouts from "../../../../img/sprouts.png";
import restorantdepot from "../../../../img/restorantdepot.png";
import aldi from "../../../../img/aldi.png";
import ralphs from "../../../../img/Ralphs.png";
import albertson from "../../../../img/albertson.png";

const ShoppingProductDesktop = ({
  onAddRemoveToCart,
  onChangeProductItem,
  onShowProduct,
  onTogglePrices,
  showPricesList,
  product,
  isIntoCart,
  key,
}) => {
  return (
    <div style={{ marginBottom: "10px", marginRight: "auto" }}>
      <Card style={{ width: "18rem" }} id={key}>
        <Card.Body>
          <Button
            size="sm"
            style={{
              color: "black",
              backgroundColor: "lightgray",
              borderColor: "lightgray",
              width: "100%",
            }}
            onClick={() => onShowProduct(product)}
          >
            <b>{product.name}</b>
          </Button>
          <Form.Label size="sm" style={{ marginTop: "15px" }}>
            Quantity:
          </Form.Label>
          <Form inline>
            <Form.Row></Form.Row>
            <Form.Row>
              <Form.Control
                name="purchaseQuantity"
                type="number"
                className="form-control"
                size="sm"
                disabled={isIntoCart}
                style={{
                  width: "70px",
                  marginRight: "5px",
                  marginLeft: "14px",
                }}
                onChange={(e) => onChangeProductItem(e, product)}
                value={product.purchaseQuantity ? product.purchaseQuantity : 0}
              />
              <Form.Control
                name="productMeasure"
                as="select"
                size="sm"
                disabled={isIntoCart}
                style={{ width: "75px" }}
                onChange={(e) => onChangeProductItem(e, product)}
                value={
                  product.productMeasure.id ? product.productMeasure.id : 0
                }
              >
                {Constants.PRODUCT_MEASURE.map((element) =>
                  element.id === product.productMeasure.id ? (
                    <option selected key={element.id} value={element.id}>
                      {element.value}
                    </option>
                  ) : (
                    <option key={element.id} value={element.id}>
                      {element.value}
                    </option>
                  )
                )}
              </Form.Control>
            </Form.Row>
          </Form>
          <AddToCart
            onAddRemoveToCart={onAddRemoveToCart}
            isIntoCart={isIntoCart}
            product={product}
          />
          <Button
            size="sm"
            variant="link"
            style={{
              width: "100%",
              color: "#5bc0de",
            }}
            onClick={() => onTogglePrices(product)}
          >
            {showPricesList.filter((showPrice) => showPrice.id == product.id)
              .length == 0
              ? "Check prices"
              : "Hide prices"}
          </Button>
          {showPricesList.filter((showPrice) => showPrice.id == product.id)
            .length > 0 ? (
            <div>
              <a
                href={
                  "https://www.walmart.com/search/?query=" +
                  product.name.toLowerCase()
                }
                target="_blank"
              >
                <img
                  src={walmart}
                  className="App-logo"
                  alt="logo"
                  style={{ width: "70px", height: "20px" }}
                ></img>
              </a>{" "}
              <a
                href={
                  "https://shop.sprouts.com/search?search_term=" +
                  product.name.toLowerCase()
                }
                target="_blank"
              >
                <img
                  src={sprouts}
                  className="App-logo"
                  alt="logo"
                  style={{ width: "90px", height: "30px" }}
                ></img>
              </a>{" "}
              <a
                href={
                  "https://www.albertsons.com/shop/search-results.html?q=" +
                  product.name.toLowerCase() +
                  "&zipcode=92078"
                }
                target="_blank"
              >
                <img
                  src={albertson}
                  className="App-logo"
                  alt="logo"
                  style={{ width: "40px", height: "40px" }}
                ></img>
              </a>{" "}
              <a
                href={
                  "https://www.costco.com/CatalogSearch?dept=Grocery&keyword=" +
                  product.name.toLowerCase()
                }
                target="_blank"
              >
                <img
                  src={costco}
                  className="App-logo"
                  alt="logo"
                  style={{ width: "70px", height: "20px" }}
                ></img>
              </a>{" "}
              <a
                href={
                  "https://www.restaurantdepot.com/search?q=" +
                  product.name.toLowerCase()
                }
                target="_blank"
              >
                <img
                  src={restorantdepot}
                  className="App-logo"
                  alt="logo"
                  style={{ width: "70px", height: "50px" }}
                ></img>
              </a>{" "}
              <a
                href={
                  "https://shop.aldi.us/store/aldi/search_v3/" +
                  product.name.toLowerCase()
                }
                target="_blank"
              >
                <img
                  src={aldi}
                  className="App-logo"
                  alt="logo"
                  style={{ width: "40px", height: "40px" }}
                ></img>
              </a>{" "}
              <a
                href={
                  "https://www.ralphs.com/search?query=" +
                  product.name.toLowerCase()
                }
                target="_blank"
              >
                <img
                  src={ralphs}
                  className="App-logo"
                  alt="logo"
                  style={{ width: "70px", height: "30px" }}
                ></img>
              </a>{" "}
            </div>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
      {/* <div>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <AddProduct
                onSaveProduct={onSaveProduct}
                handleOnSave={handleOnLocalSave}
                onHideProduct={onHideProduct}
                product={product}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div> */}
    </div>
  );
};

export default ShoppingProductDesktop;
