import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Badge,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { TiDeleteOutline, TiPlus } from "react-icons/ti";
import { MdModeEdit } from "react-icons/md";
import { AiFillFilePdf } from "react-icons/ai";
import moment from "moment";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const ComplianceFormsSearch = ({
  onShowSelectResidentAndForm,
  onShowReportDetails,
  onFilterResults,
  onDeleteRecord,
  onChangePage,
  complianceFormsState,
}) => {
  return (
    <React.Fragment>
      <Container fluid style={{ backgroundColor: "lightgray" }}>
        <Row>
          <Col>
            <Form.Control
              name="searchByName"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                width: "400px",
              }}
              onChange={(e) => onFilterResults(e)}
              value={complianceFormsState && complianceFormsState.searchByName}
              placeholder="Search ..."
              autoComplete="off"
            />
          </Col>
          <Col>
            {complianceFormsState &&
            complianceFormsState.currUserRole == "F" ? (
              ""
            ) : (
              <Button
                variant="success"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "180px",
                  float: "right",
                  color: "#28a745",
                  backgroundColor: "white",
                }}
                onClick={() => onShowSelectResidentAndForm()}
              >
                <TiPlus
                  style={{
                    color: "#28a745",
                    backgroundColor: "white",
                    width: "25px",
                    height: "25px",
                    verticalAlign: "middle",
                  }}
                />
                {"  "}
                New Report
              </Button>
            )}
          </Col>
        </Row>
      </Container>
      {complianceFormsState.residentFileMetadataSearchResult == undefined ||
      complianceFormsState.residentFileMetadataSearchResult.length == 0 ? (
        <Container fluid>
          <Row>
            <Col style={{ textAlign: "center" }}>
              <h3>
                Please select{" "}
                <b>
                  <i style={{ color: "#28a745" }}>New Report</i>
                </b>{" "}
                button to create report
              </h3>
            </Col>
          </Row>
        </Container>
      ) : (
        <table>
          <tbody>
            {complianceFormsState.residentFileMetadataSearchResult &&
              complianceFormsState.residentFileMetadataSearchResult
                .slice(complianceFormsState.start, complianceFormsState.end)
                .map((residentFileMetadata) => (
                  <tr
                    key={residentFileMetadata.id}
                    style={{
                      borderBottom: "solid",
                      borderBottomColor: "lightgray",
                      borderBottomWidth: "1px",
                      marginTop: "10px",
                    }}
                    onDoubleClick={() =>
                      onShowReportDetails(
                        residentFileMetadata.id,
                        residentFileMetadata.reportType
                      )
                    }
                  >
                    <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <table>
                        <tbody>
                          <tr style={{ padding: "0" }}>
                            <td
                              style={{
                                paddingLeft: "0px",
                                width: "30px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              {complianceFormsState.isOpeningReportDetails &&
                              complianceFormsState.openingId ==
                                residentFileMetadata.id ? (
                                <Button
                                  variant="light"
                                  style={{
                                    backgroundColor: "transparent",
                                    float: "left",
                                    borderColor: "transparent",
                                  }}
                                >
                                  <ClipLoader
                                    loading={
                                      complianceFormsState.isOpeningReportDetails
                                    }
                                    size={20}
                                  />
                                </Button>
                              ) : residentFileMetadata &&
                                residentFileMetadata.status == "SIGNED" ? (
                                <Button
                                  variant="light"
                                  style={{
                                    backgroundColor: "transparent",
                                    float: "left",
                                    borderColor: "transparent",
                                  }}
                                  onClick={() =>
                                    onShowReportDetails(
                                      residentFileMetadata.id,
                                      residentFileMetadata.reportType
                                    )
                                  }
                                >
                                  <AiFillFilePdf
                                    style={{
                                      color: "gray",
                                      width: "25px",
                                      height: "25px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Button>
                              ) : (
                                <Button
                                  variant="light"
                                  style={{
                                    backgroundColor: "transparent",
                                    float: "left",
                                    borderColor: "transparent",
                                  }}
                                  onClick={() =>
                                    onShowReportDetails(
                                      residentFileMetadata.id,
                                      residentFileMetadata.reportType
                                    )
                                  }
                                >
                                  <MdModeEdit
                                    style={{
                                      color: "#007bff",
                                      width: "25px",
                                      height: "25px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Button>
                              )}
                            </td>
                            <td style={{ paddingLeft: "0px" }}>
                              <b style={{ fontSize: "16px" }}>
                                {residentFileMetadata.residentFirstName}{" "}
                                {residentFileMetadata.residentLastName}
                                {" - "}
                                {residentFileMetadata.reportType}
                              </b>
                              {"      "}
                              {residentFileMetadata.status == "SIGNED" ? (
                                <Badge
                                  style={{
                                    borderRadius: "30px",
                                    height: "20px",
                                    width: "200px",
                                  }}
                                  disabled
                                  variant="danger"
                                >
                                  signed - view mode
                                </Badge>
                              ) : (
                                <Badge
                                  style={{
                                    borderRadius: "30px",
                                    height: "20px",
                                    width: "200px",
                                  }}
                                  disabled
                                  variant="warning"
                                >
                                  draft - edit mode
                                </Badge>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingLeft: "0px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              {complianceFormsState.currUserRole == "F" ? (
                                ""
                              ) : (
                                <Button
                                  variant="light"
                                  style={{
                                    backgroundColor: "transparent",
                                    float: "left",
                                    borderColor: "transparent",
                                  }}
                                  onClick={() =>
                                    onDeleteRecord(residentFileMetadata.id)
                                  }
                                >
                                  <TiDeleteOutline
                                    style={{
                                      color: "#dc3545",
                                      width: "25px",
                                      height: "25px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Button>
                              )}
                            </td>
                            <td
                              style={{
                                paddingLeft: "0",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <table style={{ width: "700px" }}>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: "100px",
                                        paddingBottom: "0px",
                                        color: "lightgray",
                                        paddingLeft: "0px",
                                        paddingTop: "0px",
                                      }}
                                    >
                                      Created at:{" "}
                                    </td>
                                    <td
                                      style={{
                                        width: "120px",
                                        color: "lightgray",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      Last updated at:{" "}
                                    </td>
                                    <td
                                      style={{
                                        width: "100px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        color: "lightgray",
                                      }}
                                    >
                                      Last updated by:{" "}
                                    </td>
                                    <td
                                      style={{
                                        width: "100px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        color: "lightgray",
                                      }}
                                    >
                                      Shared with:
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        width: "100px",
                                        color: "lightgray",
                                        paddingLeft: "0px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      <small>
                                        {moment(
                                          residentFileMetadata.createdAt
                                        ).format("DD/MM/YYYY")}
                                      </small>
                                    </td>
                                    <td
                                      style={{
                                        width: "100px",
                                        color: "lightgray",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      <small>
                                        {moment(
                                          residentFileMetadata.updatedAt
                                        ).format("DD/MM/YYYY")}
                                      </small>
                                    </td>
                                    <td
                                      style={{
                                        width: "100px",
                                        color: "lightgray",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      <small>
                                        {residentFileMetadata.lastModifiedBy}
                                      </small>
                                    </td>
                                    <td
                                      style={{
                                        width: "100px",
                                        color: "lightgray",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      <small>
                                        {residentFileMetadata.sharedWith
                                          ? residentFileMetadata.sharedWith
                                          : "N/A"}
                                      </small>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
            <tr>
              <td>
                {complianceFormsState.paginationButtonsCount &&
                complianceFormsState.paginationButtonsCount > 1 ? (
                  <Pagination style={{ justifyContent: "center" }}>
                    <Pagination.First onClick={() => onChangePage(0)} />
                    <Pagination.Prev
                      onClick={() =>
                        onChangePage(complianceFormsState.currentPage - 1)
                      }
                    />
                    {Array.from(
                      Array(complianceFormsState.paginationButtonsCount).keys()
                    ).map((x) => (
                      <Pagination.Item
                        active={complianceFormsState.currentPage === x}
                        onClick={() => onChangePage(x)}
                      >
                        {x + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() =>
                        onChangePage(complianceFormsState.currentPage + 1)
                      }
                    />
                    <Pagination.Last
                      onClick={() =>
                        onChangePage(
                          complianceFormsState.paginationButtonsCount
                        )
                      }
                    />
                  </Pagination>
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
};

export default ComplianceFormsSearch;
