import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

const AddFormMobile = ({
  onChangeForm,
  onSaveForm,
  onHideForm,
  residentState,
}) => {
  return (
    <Container fluid>
      {residentState.isAddFormError ? (
        <Row>
          <Col>
            <div style={errorMsgStyle}>
              <b>ERROR:</b> {residentState.errorMsg}
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row>
        <Col>
          <Form.Control
            size="sm"
            name="name"
            placeholder="Report name*"
            onChange={(e) => onChangeForm(e)}
            required
            value={
              residentState && residentState.currForm
                ? residentState.currForm.name
                : ""
            }
            autoComplete="off"
            autoComplete="none"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <Button
            size="sm"
            variant="success"
            style={{ width: "100%" }}
            onClick={() => onSaveForm()}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <Button
            variant="outline-success"
            size="sm"
            style={{
              width: "100%",
            }}
            onClick={onHideForm}
          >
            Close
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default AddFormMobile;
