import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import AuthenticationService from "../../api/authenticationService";
import StaffService from "../../services/user/staffService";
import ResetPassword from "./resetPassword";
import CreateAccount from "./createAccount";
import logo from "../../img/logo_blue.jpg";
import { InputGroup } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";

class LoginComponent extends Component {
  state = {
    show: false,
    showPassword: false,
  };
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      hasLoginFailed: false,
      showSuccessMessage: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.loginClicked = this.loginClicked.bind(this);
  }

  handleChange(event) {
    this.setState({
      hasLoginFailed: false,
      [event.target.name]: event.target.value,
    });
  }

  validateUsername = () => {
    if (this.state.username == undefined || this.state.username.length == 0) {
      // alert("Please enter username!");
      this.setState({
        hasLoginFailed: true,
        errorMsg: "Please enter username!",
      });
      return false;
    }
    return true;
  };

  validatePassword = () => {
    if (this.state.password == undefined || this.state.password.length == 0) {
      // alert("Please enter password!");
      this.setState({
        hasLoginFailed: true,
        errorMsg: "Please enter password!",
      });
      return false;
    }
    return true;
  };

  onShowPassword = () => {
    this.setState({ showPassword: true });
  };

  onHidePassword = () => {
    this.setState({ showPassword: false });
  };

  handleKeyPress = (target) => {
    if (target.charCode == 13) {
      this.loginClicked();
    }
  };

  forgotPassword = () => {
    this.handleShow();
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  loginClicked() {
    if (this.validateUsername() && this.validatePassword()) {
      AuthenticationService.executeJwtAuthenticationService(
        this.state.username,
        this.state.password
      )
        .then((response) => {
          AuthenticationService.registerSuccessfulLoginForJwt(
            this.state.username,
            response.data.token,
            Math.max(
              document.documentElement.clientWidth,
              window.innerWidth || 0
            ) < 1000
              ? "mob"
              : "pc"
          );
          StaffService.sysAdminEmail().then((data) => {
            console.log("data");
            sessionStorage.setItem("CONTACTEMAIL", data[0].email);
          });
          this.props.history.push("/");
        })
        .catch((res) => {
          var errMsg = "Invalid credentials!";
          if (!res.response) {
            errMsg =
              "Service is not avilable, please try again latter or contact us by phone: +1 760 443 1801 or e-mail: contact@alf-is.com ";
          } else if (res.response.data == "USER_DISABLED") {
            errMsg =
              "User is disabled, please contact us by phone: +1 760 443 1801 or e-mail: contact@alf-is.com ";
          } else if (res.response.data == "USER_IS_BLOCKED") {
            errMsg = "You exceeded the number of failed login attempts!";
          }
          this.setState({
            ...this.state,
            showSuccessMessage: false,
            hasLoginFailed: true,
            errorMsg: errMsg,
          });
          // this.setState({ hasLoginFailed: true });
        });
    }
  }

  hideCreateAccount = () => {
    this.setState({ showCreateAccount: false });
  };

  showCreateAccount = () => {
    this.setState({ showCreateAccount: true });
  };

  render() {
    return (
      <div style={defaultStyle}>
        <Container
          style={{
            textAlign: "center",
            align: "center",
          }}
          fluid
        >
          <Row>
            <Col>
              {this.state.hasLoginFailed && (
                <div
                  style={{
                    backgroundColor: "#b22234",
                    color: "white",
                    height: "30px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.errorMsg}
                </div>
              )}
              {this.state.showSuccessMessage && <div>Login Sucessful</div>}
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                src={logo}
                className="App-logo"
                alt="logo"
                style={{ width: "250px", height: "250px" }}
              ></img>
            </Col>
          </Row>
          <Row>
            <Col>
              <br></br>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "5px" }}>
            <Col>
              <div align="center">
                <Form.Control
                  name="username"
                  sm="6"
                  placeholder="e-mail"
                  onChange={this.handleChange}
                  autoComplete="nope"
                  defaultValue={this.state.username}
                  style={{ width: 300 }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div align="center">
                <InputGroup style={{ width: 300 }} className="mb-3">
                  <Form.Control
                    sm="6"
                    name="password"
                    placeholder="password"
                    type={this.state.showPassword ? "text" : "password"}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    autoComplete="nope"
                    defaultValue={this.state.password}
                    style={{ borderRight: "transparent" }}
                  />
                  <InputGroup.Append>
                    <Button
                      style={{
                        cursor: "pointer",
                        // width: "30%",
                        // marginBottom: "30px",
                        color: "#3C3B6E",
                        borderColor: "lightgray",
                        borderLeft: "transparent",
                        backgroundColor: "transparent",
                      }}
                      onMouseDown={this.onShowPassword}
                      onMouseUp={this.onHidePassword}
                    >
                      <AiFillEye></AiFillEye>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "20px" }}>
            <Col>
              <div align="center">
                <div align="right" style={{ width: "300px" }}></div>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "10px", textAlign: "center" }}>
            <Col>
              <Button
                variant="success"
                onClick={this.loginClicked}
                style={buttonStyle}
              >
                Log In
              </Button>
            </Col>
          </Row>
          {/* <Row style={{ paddingBottom: "10px", textAlign: "center" }}>
            <Col>
              <Button
                size="sm"
                variant="success"
                onClick={this.showCreateAccount}
                style={buttonStyleCreateAccount}
              >
                Register for 30 days free trial
              </Button>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <Button
                variant="link"
                onClick={this.forgotPassword}
                style={{ width: 300 }}
              >
                Forgot Password
              </Button>
            </Col>
          </Row>
        </Container>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <small>
                <b
                  style={{
                    color: "#3C3B6E",
                  }}
                >
                  RESET PASSWORD
                </b>
              </small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <ResetPassword handleClose={this.handleClose} />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showCreateAccount}
          onHide={this.hideCreateAccount}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <small>
                <b
                  style={{
                    color: "#3C3B6E",
                  }}
                >
                  REGISTER FOR 30 DAYS FREE TRIAL
                </b>
              </small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <CreateAccount handleClose={this.hideCreateAccount} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const defaultStyle = {
  color: "#ff4444",
};

const buttonStyle = {
  borderColor: "#3C3B6E",
  width: 300,
  borderRadius: "10px",
  color: "#3C3B6E",
  backgroundColor: "#f2f2f2",
  margin: "3px",
};

const buttonStyleCreateAccount = {
  borderColor: "#3C3B6E",
  width: 300,
  borderRadius: "10px",
  color: "#f2f2f2",
  backgroundColor: "#3C3B6E",
  margin: "3px",
};

export default LoginComponent;
