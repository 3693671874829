import React, { useEffect } from "react";
import useResidentState from "./hooks/useResidentState";
import useWindowSize from "../../common/hook/useWindowSize";
import MainResidentDesktop from "./desktop/mainResidentDesktop";
import MainResidentMobile from "./mobile/mainResidentMobile";

const MainResident = () => {
  const size = useWindowSize();

  const {
    onChangeResident,
    onChangeResidentDates,
    onChangeDoc,
    onOpenResident,
    onNewResident,
    onHideResident,
    onSaveResident,
    onGetAllResidents,
    onBackDashboard,
    onAddResidentDoctor,
    onOpenResidentForm,
    onDeleteResidentDoctor,
    onCloseResidentForm,
    onShowAddTodo,
    onAddTodo,
    onCloseTodo,
    onSetFile,
    onDownload,
    onDeleteFiles,
    onShowAddMedRecord,
    onAddMedicalRecord,
    onChangeMedHistory,
    onChangeMedRecordDate,
    onOpenResidentView,
    onSaveLegalRepInfo,
    onChangeTodo,
    onChangeTodoDates,
    onEditResident,
    onSaveEditResident,
    onHideEditResident,
    onShowForm,
    onHideForm,
    onChangeForm,
    onSaveForm,
    onExitLegRepPhone,
    onExitDoctorPhone,
    onDeleteMedicalRecord,
    onShowShareReport,
    onHideShareReport,
    onChangeGuardian,
    onSelectFormMenu,
    onDeleteGuardianShare,
    formatList,
    onShowSignFile,
    onHideSignFile,
    onSaveSignFile,
    onSelectGuardianForSignature,
    onSelectResidentMenu,
    onShowDeactivated,
    onHideGuardian,
    onShowGuardian,
    onSaveGuardian,
    onChangeGuardianField,
    onChangeGuardianDates,
    onChangePassword,
    onToggleChanged,
    onShowPassword,
    onHidePassword,
    residentState,
  } = useResidentState();

  useEffect(() => {
    onGetAllResidents();
  }, []);

  return size.width > 970 ? (
    <MainResidentDesktop
      onChangeResident={onChangeResident}
      onChangeResidentDates={onChangeResidentDates}
      onChangeDoc={onChangeDoc}
      onOpenResident={onOpenResident}
      onNewResident={onNewResident}
      onHideResident={onHideResident}
      onSaveResident={onSaveResident}
      onGetAllResidents={onGetAllResidents}
      onBackDashboard={onBackDashboard}
      onAddResidentDoctor={onAddResidentDoctor}
      onOpenResidentForm={onOpenResidentForm}
      onDeleteResidentDoctor={onDeleteResidentDoctor}
      onCloseResidentForm={onCloseResidentForm}
      onShowAddTodo={onShowAddTodo}
      onAddTodo={onAddTodo}
      onCloseTodo={onCloseTodo}
      onSetFile={onSetFile}
      onDownload={onDownload}
      onDeleteFiles={onDeleteFiles}
      onShowAddMedRecord={onShowAddMedRecord}
      onAddMedicalRecord={onAddMedicalRecord}
      onChangeMedHistory={onChangeMedHistory}
      onChangeMedRecordDate={onChangeMedRecordDate}
      onOpenResidentView={onOpenResidentView}
      onSaveLegalRepInfo={onSaveLegalRepInfo}
      onChangeTodo={onChangeTodo}
      onChangeTodoDates={onChangeTodoDates}
      onEditResident={onEditResident}
      onSaveEditResident={onSaveEditResident}
      onHideEditResident={onHideEditResident}
      onShowForm={onShowForm}
      onHideForm={onHideForm}
      onChangeForm={onChangeForm}
      onSaveForm={onSaveForm}
      onExitLegRepPhone={onExitLegRepPhone}
      onExitDoctorPhone={onExitDoctorPhone}
      onDeleteMedicalRecord={onDeleteMedicalRecord}
      onShowShareReport={onShowShareReport}
      onHideShareReport={onHideShareReport}
      onChangeGuardian={onChangeGuardian}
      onSelectFormMenu={onSelectFormMenu}
      onDeleteGuardianShare={onDeleteGuardianShare}
      formatList={formatList}
      onShowSignFile={onShowSignFile}
      onHideSignFile={onHideSignFile}
      onSaveSignFile={onSaveSignFile}
      onSelectGuardianForSignature={onSelectGuardianForSignature}
      onSelectResidentMenu={onSelectResidentMenu}
      onShowDeactivated={onShowDeactivated}
      onHideGuardian={onHideGuardian}
      onShowGuardian={onShowGuardian}
      onSaveGuardian={onSaveGuardian}
      onChangeGuardianField={onChangeGuardianField}
      onChangeGuardianDates={onChangeGuardianDates}
      onChangePassword={onChangePassword}
      onToggleChanged={onToggleChanged}
      onShowPassword={onShowPassword}
      onHidePassword={onHidePassword}
      residentState={residentState}
    />
  ) : (
    <MainResidentMobile
      onChangeResident={onChangeResident}
      onChangeResidentDates={onChangeResidentDates}
      onChangeDoc={onChangeDoc}
      onOpenResident={onOpenResident}
      onNewResident={onNewResident}
      onHideResident={onHideResident}
      onSaveResident={onSaveResident}
      onGetAllResidents={onGetAllResidents}
      onBackDashboard={onBackDashboard}
      onAddResidentDoctor={onAddResidentDoctor}
      onOpenResidentForm={onOpenResidentForm}
      onDeleteResidentDoctor={onDeleteResidentDoctor}
      onCloseResidentForm={onCloseResidentForm}
      onShowAddTodo={onShowAddTodo}
      onAddTodo={onAddTodo}
      onCloseTodo={onCloseTodo}
      onSetFile={onSetFile}
      onDownload={onDownload}
      onDeleteFiles={onDeleteFiles}
      onShowAddMedRecord={onShowAddMedRecord}
      onAddMedicalRecord={onAddMedicalRecord}
      onChangeMedHistory={onChangeMedHistory}
      onChangeMedRecordDate={onChangeMedRecordDate}
      onOpenResidentView={onOpenResidentView}
      onSaveLegalRepInfo={onSaveLegalRepInfo}
      onChangeTodo={onChangeTodo}
      onChangeTodoDates={onChangeTodoDates}
      onEditResident={onEditResident}
      onSaveEditResident={onSaveEditResident}
      onHideEditResident={onHideEditResident}
      onShowForm={onShowForm}
      onHideForm={onHideForm}
      onChangeForm={onChangeForm}
      onSaveForm={onSaveForm}
      onExitLegRepPhone={onExitLegRepPhone}
      onExitDoctorPhone={onExitDoctorPhone}
      onDeleteMedicalRecord={onDeleteMedicalRecord}
      onShowShareReport={onShowShareReport}
      onHideShareReport={onHideShareReport}
      onChangeGuardian={onChangeGuardian}
      onSelectFormMenu={onSelectFormMenu}
      onDeleteGuardianShare={onDeleteGuardianShare}
      formatList={formatList}
      onShowSignFile={onShowSignFile}
      onHideSignFile={onHideSignFile}
      onSaveSignFile={onSaveSignFile}
      onSelectGuardianForSignature={onSelectGuardianForSignature}
      onSelectResidentMenu={onSelectResidentMenu}
      onShowDeactivated={onShowDeactivated}
      onHideGuardian={onHideGuardian}
      onShowGuardian={onShowGuardian}
      onSaveGuardian={onSaveGuardian}
      onChangeGuardianField={onChangeGuardianField}
      onChangeGuardianDates={onChangeGuardianDates}
      onChangePassword={onChangePassword}
      onToggleChanged={onToggleChanged}
      onShowPassword={onShowPassword}
      onHidePassword={onHidePassword}
      residentState={residentState}
    />
  );
};

export const btnStyle = {
  textAlign: "center",
  borderColor: "#3c3b6e",
  borderRadius: "10px",
  color: "#3c3b6e",
  backgroundColor: "#f2f2f2",
  borderWidth: "thin",
};

export default MainResident;
