import ProductClient from "../../api/products/productClient";

const productClient = new ProductClient();

class ProductService {
  async delete(id) {
    try {
      const response = await productClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    try {
      const response = await productClient.save(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await productClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  validateProduct(product) {
    if (product == undefined) {
      return {
        isError: true,
        errorMessage: "Please enter name of the product!",
      };
    }

    if (product.name == undefined || product.name.length == 0) {
      return {
        isError: true,
        errorMessage: "Please enter name of the product!",
      };
    }

    if (
      product.purchaseQuantity == undefined ||
      product.purchaseQuantity.length == 0
    ) {
      return {
        isError: true,
        errorMessage: "Please enter default purchase quantity!",
      };
    }

    if (
      product.productMeasure == undefined ||
      product.productMeasure.id == undefined ||
      product.productMeasure.id.length == 0
    ) {
      return {
        isError: true,
        errorMessage: "Please select measure!",
      };
    }

    return {
      isError: false,
    };
  }
}

export default new ProductService();
