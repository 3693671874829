import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  CardColumns,
  Card,
  Dropdown,
  Form,
} from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";

const ResidentListMobile = ({
  onOpenResident,
  onSelectResidentMenu,
  residentState,
}) => {
  console.log(residentState.residents);
  return (
    <div
      id=""
      style={{
        overflow: "scroll",
        overflowX: "hidden",
        // height: "300px",
        height: "70vh",
      }}
    >
      <CardColumns>
        {residentState.residents &&
          residentState.residents
            .filter((res) =>
              residentState.showDeactivated ? !res.isActive : res.isActive
            )
            .sort((r1, r2) => {
              if (r1.firstName.toLowerCase() > r2.firstName.toLowerCase())
                return 1;
              else return -1;
            })
            .map((resident) => (
              <Card>
                <Card.Header
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    backgroundColor: resident.isActive
                      ? "transparent"
                      : "#d9534f",
                  }}
                >
                  <Form inline>
                    <Button
                      size="sm"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        color: "black",
                        verticalAlign: "middle",
                        textAlign: "left",
                        width: "80%",
                      }}
                      onClick={() => onOpenResident(resident)}
                    >
                      <small>
                        <b>
                          {resident.firstName} {resident.lastName}
                        </b>
                      </small>
                    </Button>
                    {resident.isActive ? (
                      <Dropdown
                        onSelect={(selectedKey) =>
                          onSelectResidentMenu(selectedKey, resident)
                        }
                        style={{ verticalAlign: "bottom", width: "20px" }}
                      >
                        <Dropdown.Toggle
                          variant="success"
                          style={{
                            backgroundColor: "transparent",
                            color: "black",
                            borderColor: "transparent",
                          }}
                          id="dropdown-basic"
                        >
                          <BsThreeDotsVertical
                            style={{
                              color: "black",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="deactivate" size="sm">
                            Deactivate
                          </Dropdown.Item>

                          {/* <Dropdown.Item eventKey="delete" size="sm">
                          Delete
                        </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                  </Form>
                </Card.Header>
                <Card.Body>
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      color: "black",
                      verticalAlign: "middle",
                      textAlign: "left",
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={() => onOpenResident(resident)}
                  >
                    <Container style={{ color: "black" }}>
                      <Row>
                        <Col>
                          {resident.legRepName != undefined ? (
                            <div>
                              <div style={{ fontSize: "10px" }}>
                                <b>Legal repr.: </b>
                              </div>
                              <div style={{ fontSize: "10px" }}>
                                Name: {resident.legRepName}
                              </div>
                              <div style={{ fontSize: "10px" }}>
                                Phone: {resident.legRepPhone}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col>
                          {resident.residentDoctors != undefined &&
                          resident.residentDoctors.length > 0 ? (
                            <div>
                              <div>
                                <small>
                                  <b>Doctor</b>
                                </small>
                              </div>
                              <div>
                                <small>
                                  Name: {resident.residentDoctors[0].name}
                                </small>
                              </div>
                              <div>
                                <small>
                                  Phone: {resident.residentDoctors[0].phone}
                                </small>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </Button>
                </Card.Body>
              </Card>
            ))}
      </CardColumns>
    </div>
  );
};

export default ResidentListMobile;
