import APIClient from "../apiClient";

const BASE_SHOPPING_CART = "/shoppingCart";

class ShoppingCartClient extends APIClient {
  async getActive() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_SHOPPING_CART + "/active"
    );
  }

  async closeCart(id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + BASE_SHOPPING_CART + "/closeCart/" + id
    );
  }
}

export default ShoppingCartClient;
