import React from "react";
import { Button, Container, Row, Table, Col } from "react-bootstrap";
import StafffTrainingListDesktop from "./stafffTrainingListDesktop";

const StaffDetailsDesktop = ({
  onMarkAsPassed,
  onDeleteStaffTraining,
  onViewDetails,
  onPrintCertificate,
  onShowStaffTrainingView,
  onCreateTraining,
  onEditTraining,
  onHideCreateTraining,
  onStaffTraining,
  onGoBack,
  onSaveTraining,
  onDeleteTraining,
  trainingState,
}) => {
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col
            className="bg-warning"
            style={{
              fontSize: "20x",
              fontWeight: "bold",
              textAlign: "center",
              borderTop: "solid",
              borderTopWidth: "1px",
              borderBottom: "solid",
              borderBottomWidth: "1px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                marginBottom: "15px",
                color: "black",
              }}
            >
              Training plan for{" "}
              {trainingState.currEmployee && trainingState.currEmployee.name}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {trainingState.selectedStaffTrainings ? (
              <React.Fragment>
                <StafffTrainingListDesktop
                  onMarkAsPassed={onMarkAsPassed}
                  onDeleteStaffTraining={onDeleteStaffTraining}
                  onViewDetails={onViewDetails}
                  onPrintCertificate={onPrintCertificate}
                  onShowStaffTrainingView={onShowStaffTrainingView}
                  onCreateTraining={onCreateTraining}
                  onEditTraining={onEditTraining}
                  onHideCreateTraining={onHideCreateTraining}
                  onStaffTraining={onStaffTraining}
                  onGoBack={onGoBack}
                  onSaveTraining={onSaveTraining}
                  onDeleteTraining={onDeleteTraining}
                  trainingState={trainingState}
                />
              </React.Fragment>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default StaffDetailsDesktop;
