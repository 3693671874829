import { useState } from "react";
import StaffService from "../../../../services/user/staffService";
import TrainingService from "../../../../services/compliance/training/trainingService";
import StaffTrainingService from "../../../../services/compliance/training/staffTrainingService";

import moment from "moment";

const useTrainingState = () => {
  const [trainingState, setTrainingState] = useState({
    showSchedule: false,
    selectedStaff: {},
    staff: [],
    mode: "view",
    staffTrainingView: "active",
  });

  const refreshPage = async () => {
    const users = await StaffService.getAll();
    const trainings = await TrainingService.getAll();
    const employees = users
      .filter((user) => user.userRole == "A" || user.userRole == "E")
      .filter((user) => !user.isDisabled);
    const currEmployee = employees.length > 0 ? employees[0] : {};
    const selectedStaffTrainings = await StaffTrainingService.getByStaffId(
      currEmployee.id
    );

    setTrainingState({
      ...trainingState,
      employees: employees,
      currEmployee: currEmployee,
      trainings: trainings,
      selectedStaffTrainings: selectedStaffTrainings,
    });
  };

  const openEmployee = async (employeeId) => {
    const currEmployee = trainingState.employees.filter(
      (emp) => emp.id == employeeId
    )[0];
    const selectedStaffTrainings = await StaffTrainingService.getByStaffId(
      currEmployee.id
    );
    setTrainingState({
      ...trainingState,
      currEmployee: currEmployee,
      selectedStaffTrainings: selectedStaffTrainings,
    });
  };

  const onShowStaffTrainingView = (eventKey) => {
    setTrainingState({ ...trainingState, staffTrainingView: eventKey });
  };

  const onDeleteStaffTraining = async (staffTraining) => {
    if (
      window.confirm(
        "Do you really want to remove " +
          staffTraining.training.name +
          " training from " +
          trainingState.currEmployee.name +
          " upcoming trainings!"
      )
    ) {
      await StaffTrainingService.delete(staffTraining.id);
      setTrainingState({
        ...trainingState,
        selectedStaffTrainings: trainingState.selectedStaffTrainings.filter(
          (stfTrn) => stfTrn.id != staffTraining.id
        ),
      });
    }
  };

  const onStaffTraining = (training) => {
    setTrainingState({
      ...trainingState,
      selectedTraining: training,
      schedule: {},
      showSchedule: true,
    });
  };

  const onSaveStaffTraining = async () => {
    if (trainingState.schedule.scheduleDate == undefined) {
      setTrainingState({
        ...trainingState,
        isError: true,
        errorMsg: "Please select schedule date!",
      });
      return;
    }
    const savedStaffTraining = await StaffTrainingService.save({
      staffId: trainingState.currEmployee.id,
      trainingId: trainingState.selectedTraining.id,
      scheduledDate: trainingState.schedule.scheduleDate,
    });

    setTrainingState({
      ...trainingState,
      selectedStaffTrainings:
        trainingState.selectedStaffTrainings != undefined
          ? trainingState.selectedStaffTrainings.concat(savedStaffTraining)
          : [].concat(savedStaffTraining),
      showSchedule: false,
    });
  };

  const onShowMarkAsPassed = (stfTrn) => {
    setTrainingState({
      ...trainingState,
      showMarkAsPassed: true,
      markAsPassedDetails: {},
      currentStaffTraining: stfTrn,
    });
  };

  const onHideMarkAsPassed = () => {
    setTrainingState({
      ...trainingState,
      showMarkAsPassed: false,
      markAsPassedDetails: {},
    });
  };

  const onSaveMarkAsPassed = async () => {
    const markAsPassedObject = {
      id: trainingState.currentStaffTraining.id,
      passDate: trainingState.markAsPassedDetails.passDate,
      startDate: trainingState.markAsPassedDetails.startDate,
      durationHours: trainingState.markAsPassedDetails.durationHours,
    };
    const validatedMarkAsPassed = StaffTrainingService.validateMarkAsPassed(
      markAsPassedObject
    );
    if (validatedMarkAsPassed.isError) {
      setTrainingState({
        ...trainingState,
        isError: true,
        errorMsg: validatedMarkAsPassed.errMessage,
      });
      return;
    }
    const passedTraining = await StaffTrainingService.markAsPassed({
      id: trainingState.currentStaffTraining.id,
      passDate: trainingState.markAsPassedDetails.passDate,
      startDate: trainingState.markAsPassedDetails.startDate,
      durationHours: trainingState.markAsPassedDetails.durationHours,
    });

    setTrainingState({
      ...trainingState,
      selectedStaffTrainings:
        trainingState.selectedStaffTrainings != undefined
          ? trainingState.selectedStaffTrainings
              .filter((stfTrn) => stfTrn.id != passedTraining.id)
              .concat(passedTraining)
          : [].concat(passedTraining),
      showMarkAsPassed: false,
    });
  };

  const onChangePassDate = (date) => {
    setTrainingState({
      ...trainingState,
      markAsPassedDetails: {
        ...trainingState.markAsPassedDetails,
        passDate: date,
      },
      isError: false,
    });
  };

  const onChangeStartDate = (date) => {
    setTrainingState({
      ...trainingState,
      markAsPassedDetails: {
        ...trainingState.markAsPassedDetails,
        startDate: date,
      },
      isError: false,
    });
  };

  const onChangeHours = (event) => {
    setTrainingState({
      ...trainingState,
      markAsPassedDetails: {
        ...trainingState.markAsPassedDetails,
        durationHours: event.target.value,
      },
      isError: false,
    });
  };

  const onSaveTraining = async () => {
    const training = trainingState.currTraining;
    if (!training.name) {
      alert("Please enter training title!");
      return;
    }
    await TrainingService.save(training);
    const allTrainings = await TrainingService.getAll();

    setTrainingState({
      ...trainingState,
      trainings: allTrainings,
      showCreateTraining: false,
    });
  };

  const onDeleteTraining = async (training) => {
    if (
      window.confirm(
        "Do you really want to permanently delete " + training.name + "!"
      )
    ) {
      await TrainingService.delete(training.id);
      setTrainingState({
        ...trainingState,
        trainings: trainingState.trainings.filter(
          (trn) => trn.id != training.id
        ),
        selectedStaffTrainings: trainingState.selectedStaffTrainings.filter(
          (selectedStaffTraining) => {
            if (selectedStaffTraining.training.id == training.id) {
              if (selectedStaffTraining.passDate != undefined) {
                return selectedStaffTraining;
              }
            } else {
              return selectedStaffTraining;
            }
          }
        ),
      });
    }
  };

  const onViewDetails = (trn) => {
    setTrainingState({
      ...trainingState,
      currTraining: trn,
      showTrainingDetails: true,
    });
  };

  const onHideTrainingDetails = () => {
    setTrainingState({ ...trainingState, showTrainingDetails: false });
  };

  const onAddTraining = () => {
    setTrainingState({ ...trainingState, mode: "add" });
  };

  const onHideSchedule = () => {
    setTrainingState({ ...trainingState, showSchedule: false });
  };

  const onChangeScheduleDate = (date) => {
    setTrainingState({
      ...trainingState,
      isError: false,
      errorMsg: "",
      schedule: { ...trainingState.schedule, scheduleDate: date },
    });
  };

  const onGoBack = () => {
    setTrainingState({ ...trainingState, mode: "view" });
  };

  const onDownloadCompleted = (passedTrainings) => {
    console.log("passedTrainings", passedTrainings);
    if (!passedTrainings) {
      alert("There are no completed trainings yet!");
      return;
    }
    StaffTrainingService.downloadReport(
      passedTrainings.sort((A, B) => {
        if (moment(A.passDate).isBefore(moment(B.passDate))) {
          return 1;
        } else {
          return -1;
        }
      })
    );
  };

  const onPrintCertificate = (stfTrn) => {
    StaffTrainingService.printCertificate(stfTrn);
  };

  const onCreateTraining = () => {
    setTrainingState({
      ...trainingState,
      currTraining: {},
      showCreateTraining: true,
    });
  };

  const onChangeTraining = (event) => {
    let currTraining = { ...trainingState.currTraining };
    currTraining[event.target.name] = event.target.value;
    setTrainingState({ ...trainingState, currTraining: currTraining });
  };

  const onChangeTrainingUrl = (event) => {
    setTrainingState({ ...trainingState, currUrl: event.target.value });
  };

  const onAddTrainingUrl = () => {
    const trnDtl = trainingState.currTraining.trainingDetails
      ? trainingState.currTraining.trainingDetails
      : [];
    if (
      trnDtl.filter(
        (td) => td.url.toLowerCase() == trainingState.currUrl.toLowerCase()
      ).length > 0
    ) {
      alert("The link to the training already exists!");
      return;
    }
    setTrainingState({
      ...trainingState,
      currTraining: {
        ...trainingState.currTraining,
        trainingDetails: [...trnDtl].concat({
          url: trainingState.currUrl,
        }),
      },
      currUrl: "",
    });
  };

  const onRemoveTrainingUrl = (trnDtlArg) => {
    console.log("trnDtlArg", trnDtlArg);
    const trnDtl = trainingState.currTraining.trainingDetails
      ? trainingState.currTraining.trainingDetails
      : [];
    if (trnDtlArg.id) {
      setTrainingState({
        ...trainingState,
        currTraining: {
          ...trainingState.currTraining,
          trainingDetails: [...trnDtl].filter(
            (trnDt) => trnDt.id != trnDtlArg.id
          ),
        },
      });
    } else {
      setTrainingState({
        ...trainingState,
        currTraining: {
          ...trainingState.currTraining,
          trainingDetails: [...trnDtl].filter(
            (trnDt) => trnDt.url.toLowerCase() != trnDtlArg.url.toLowerCase()
          ),
        },
      });
    }
  };

  const onHideCreateTraining = () => {
    setTrainingState({ ...trainingState, showCreateTraining: false });
  };

  const onEditTraining = async (training) => {
    const trn = await TrainingService.getById(training.id);
    setTrainingState({
      ...trainingState,
      currTraining: trn,
      showCreateTraining: true,
    });
  };

  return {
    refreshPage,
    onDeleteStaffTraining,
    onStaffTraining,
    onSaveStaffTraining,
    onShowMarkAsPassed,
    onHideMarkAsPassed,
    onSaveMarkAsPassed,
    onChangePassDate,
    onChangeStartDate,
    onChangeHours,
    onSaveTraining,
    onDeleteTraining,
    onViewDetails,
    onHideTrainingDetails,
    onAddTraining,
    onHideSchedule,
    onChangeScheduleDate,
    onGoBack,
    onDownloadCompleted,
    onPrintCertificate,
    onCreateTraining,
    onEditTraining,
    onChangeTraining,
    onChangeTrainingUrl,
    onAddTrainingUrl,
    onRemoveTrainingUrl,
    onHideCreateTraining,
    openEmployee,
    onShowStaffTrainingView,
    onHideTrainingDetails,
    trainingState,
  };
};

export default useTrainingState;
