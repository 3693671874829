import React from "react";
import {
  Row,
  Col,
  Tab,
  Nav,
  Container,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import BackButton from "../../common/backButton";
import ShareReport from "../../common/shareReport";
import SaveButton from "../../common/saveButton";
import SignButton from "../../common/signButton";
import UnSignButton from "../../common/unSignButton";
import CopyButton from "../../common/copyButton";
import Printout from "../printout";
import GeneralInfo from "./generalInfo";

const Lic613c = ({
  onChangeInput,
  onSave,
  onBack,
  onTabSelected,
  onSignLic613c,
  onUnSign,
  onShowShareReport,
  onShowCopy,
  lic613cState,
  lic613cBase64ContentState,
  complianceFormsState,
}) => {
  return (
    <React.Fragment>
      <Container fluid style={{ backgroundColor: "lightgray" }}>
        <Row>
          <Col xs="auto">
            <BackButton onBack={onBack} />
          </Col>
          <Col
            style={{
              textAlign: "left",
              color: "black",
              marginTop: "10px",
              marginBottom: "10px",
              fontSize: "26px",
            }}
          >
            <b>LIC613C - PERSONAL RIGHTS</b>
          </Col>
          <Col>
            <ButtonGroup style={{ float: "right" }}>
              {complianceFormsState &&
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status &&
              complianceFormsState.currResidentFileMetadata.status ===
                "SIGNED" ? (
                ""
              ) : (
                <React.Fragment>
                  {complianceFormsState.currUserRole === "F" ? (
                    ""
                  ) : (
                    <CopyButton onShowCopy={onShowCopy} />
                  )}
                  <SaveButton onSave={onSave} />
                </React.Fragment>
              )}
              {complianceFormsState &&
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status ===
                "SIGNED" ? (
                complianceFormsState.currUserRole === "F" ? (
                  ""
                ) : (
                  <UnSignButton onUnSign={() => onUnSign("LIC613C")} />
                )
              ) : (
                ""
              )}
              {complianceFormsState &&
              complianceFormsState.isReportSignedByMe ? (
                ""
              ) : complianceFormsState.currUserRole == "F" ? (
                <SignButton onSign={onSignLic613c} />
              ) : (
                ""
              )}
              {complianceFormsState &&
              complianceFormsState.currUserRole == "F" ? (
                ""
              ) : (
                <ShareReport onShowShareReport={onShowShareReport} />
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
      {complianceFormsState &&
      complianceFormsState.currResidentFileMetadata &&
      complianceFormsState.currResidentFileMetadata.status == "SIGNED" ? (
        <Printout base64Content={lic613cBase64ContentState} />
      ) : (
        <Tab.Container
          defaultActiveKey="generalInfo"
          onSelect={(e) => onTabSelected(e)}
        >
          <Row>
            <Col>
              <Nav>
                <Nav.Item>
                  <Nav.Link eventKey="generalInfo">
                    <Button
                      variant="success"
                      style={
                        complianceFormsState &&
                        complianceFormsState.currentTab &&
                        complianceFormsState.currentTab == "generalInfo"
                          ? navButtonSelectedStyle
                          : navButtonStyle
                      }
                    >
                      General Info
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="printout">
                    <Button
                      variant="success"
                      style={
                        complianceFormsState &&
                        complianceFormsState.currentTab &&
                        complianceFormsState.currentTab == "printout"
                          ? navButtonSelectedStyle
                          : navButtonStyle
                      }
                    >
                      Printout
                    </Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Tab.Content style={{ width: "100%" }}>
              <Tab.Pane eventKey="generalInfo">
                <GeneralInfo
                  onChangeInput={onChangeInput}
                  lic613cState={lic613cState}
                  complianceFormsState={complianceFormsState}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="printout">
                <Printout base64Content={lic613cBase64ContentState} />
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      )}
    </React.Fragment>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "150px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "150px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default Lic613c;
