import ViewClient from "../api/viewClient";

const viewClient = new ViewClient();

class ViewService {
  getStaffFacilityByUserName = async () => {
    try {
      const response = await viewClient.getStaffFacilityByUserName(
        sessionStorage.getItem("authenticatedUser")
      );
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  };
}

export default new ViewService();
