import React, { useEffect } from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";
import useShoppingProduct from "./hook/useShoppingProduct";
import useWindowSize from "../common/hook/useWindowSize";
import MainShoppingDesktop from "./desktop/mainShoppingDesktop";
import MainShoppingMobile from "./mobile/mainShoppingMobile";

const MainShopping = () => {
  const size = useWindowSize();
  const {
    refreshPage,
    onFilterProducts,
    onAddRemoveToCart,
    onSetView,
    onShowProduct,
    onHideProduct,
    onChangeProduct,
    onIsPurchased,
    onRemoveShoppingCartProduct,
    onSaveProduct,
    onDeleteProduct,
    onChangeProductItem,
    onTogglePrices,
    shoppingState,
  } = useShoppingProduct({});

  useEffect(() => {
    console.log("useEffect");
    refreshPage();
  }, []);

  return size.width > 970 ? (
    <MainShoppingDesktop
      refreshPage={refreshPage}
      onFilterProducts={onFilterProducts}
      onAddRemoveToCart={onAddRemoveToCart}
      onSetView={onSetView}
      onShowProduct={onShowProduct}
      onHideProduct={onHideProduct}
      onChangeProduct={onChangeProduct}
      onIsPurchased={onIsPurchased}
      onRemoveShoppingCartProduct={onRemoveShoppingCartProduct}
      onSaveProduct={onSaveProduct}
      onDeleteProduct={onDeleteProduct}
      onChangeProductItem={onChangeProductItem}
      onTogglePrices={onTogglePrices}
      shoppingState={shoppingState}
    />
  ) : (
    <MainShoppingMobile
      refreshPage={refreshPage}
      onFilterProducts={onFilterProducts}
      onAddRemoveToCart={onAddRemoveToCart}
      onSetView={onSetView}
      onShowProduct={onShowProduct}
      onHideProduct={onHideProduct}
      onChangeProduct={onChangeProduct}
      onIsPurchased={onIsPurchased}
      onRemoveShoppingCartProduct={onRemoveShoppingCartProduct}
      onSaveProduct={onSaveProduct}
      onDeleteProduct={onDeleteProduct}
      onChangeProductItem={onChangeProductItem}
      onTogglePrices={onTogglePrices}
      shoppingState={shoppingState}
    />
  );
};

export default MainShopping;
