import React from "react";
import { Button } from "react-bootstrap";
import { TiArrowBack } from "react-icons/ti";
import GeneralInfoDesktop from "./generalInfoDesktop";
import ResidentDetailsDesktop from "./residentDetailsDesktop";

const ResidentDashboardDesktop = ({
  onChangeResident,
  onChangeDoc,
  onAddResidentDoctor,
  onDeleteResidentDoctor,
  onBackDashboard,
  onOpenResidentForm,
  onShowAddTodo,
  onCloseResidentForm,
  onSetFile,
  onDownload,
  onDeleteFiles,
  onShowAddMedRecord,
  onAddMedicalRecord,
  onChangeMedHistory,
  onChangeMedRecordDate,
  onSaveLegalRepInfo,
  onOpenResidentView,
  onEditResident,
  onShowForm,
  onExitLegRepPhone,
  onExitDoctorPhone,
  onDeleteMedicalRecord,
  onShowShareReport,
  onSelectFormMenu,
  formatList,
  onShowSignFile,
  residentState,
}) => {
  return (
    <React.Fragment>
      <GeneralInfoDesktop
        onEditResident={onEditResident}
        residentState={residentState}
      />
      <ResidentDetailsDesktop
        onOpenResidentView={onOpenResidentView}
        onChangeResident={onChangeResident}
        onChangeDoc={onChangeDoc}
        onAddResidentDoctor={onAddResidentDoctor}
        onDeleteResidentDoctor={onDeleteResidentDoctor}
        onOpenResidentForm={onOpenResidentForm}
        onShowAddTodo={onShowAddTodo}
        onCloseResidentForm={onCloseResidentForm}
        onSetFile={onSetFile}
        onDownload={onDownload}
        onDeleteFiles={onDeleteFiles}
        onShowAddMedRecord={onShowAddMedRecord}
        onAddMedicalRecord={onAddMedicalRecord}
        onChangeMedHistory={onChangeMedHistory}
        onChangeMedRecordDate={onChangeMedRecordDate}
        onSaveLegalRepInfo={onSaveLegalRepInfo}
        onShowForm={onShowForm}
        onExitLegRepPhone={onExitLegRepPhone}
        onExitDoctorPhone={onExitDoctorPhone}
        onDeleteMedicalRecord={onDeleteMedicalRecord}
        onShowShareReport={onShowShareReport}
        onSelectFormMenu={onSelectFormMenu}
        formatList={formatList}
        onShowSignFile={onShowSignFile}
        residentState={residentState}
      />
      <hr></hr>
      <Button
        style={{
          width: "150px",
        }}
        onClick={() => onBackDashboard()}
      >
        <div>
          <TiArrowBack /> Back
        </div>
      </Button>
      {/* </Tab>
      </Tabs> */}
    </React.Fragment>
  );
};

export default ResidentDashboardDesktop;
