import { useState } from "react";
import ResidentService from "../../../../services/compliance/resident/residentService";
import StaffService from "../../../../services/user/staffService";
import ResidentFileMetadataService from "../../../../services/compliance/documents/residentFileMetadataService";

const useDocumentState = () => {
  const [documentState, setDocumentState] = useState({});

  const refreshPage = async () => {
    const residents = await ResidentService.getAll();
    const allStaff = await StaffService.getByUserName(
      sessionStorage.getItem("authenticatedUser")
    );

    const staff = allStaff && allStaff[0];

    const familyMemeberResidents = residents.filter(
      (resident) =>
        resident &&
        resident.residentFormList &&
        resident.residentFormList.filter(
          (resFormList) =>
            resFormList.staff.filter((stf) => stf.id == staff.id).length > 0
        ).length > 0
    );

    const currResident =
      familyMemeberResidents.length > 0 ? familyMemeberResidents[0] : {};

    setDocumentState({
      ...documentState,
      residents: familyMemeberResidents,
      currStaff: staff,
      currResident: currResident,
    });
  };

  const openResident = async (residentId) => {
    const currResident = documentState.residents.filter(
      (res) => res.id == residentId
    )[0];

    setDocumentState({
      ...documentState,
      currResident: currResident,
    });
  };

  const onCloseResidentForm = () => {
    setDocumentState({
      ...documentState,
      showResidentForm: false,
      currResidentForm: {},
      currFiles: {},
    });
  };

  const onOpenResidentForm = async (residentForm) => {
    const currFiles = await ResidentFileMetadataService.getByResidentFormId(
      residentForm.id
    );
    setDocumentState({
      ...documentState,
      showResidentForm: true,
      currResidentForm: residentForm,
      currFiles: currFiles,
    });
  };

  const onSetFile = async (e) => {
    const files = Array.from(e.target.files);
    console.log("files", files);

    let formData = new FormData();

    formData.append("name", files[0].name);
    formData.append(
      "residentFormId",
      documentState.currResidentForm && documentState.currResidentForm.id
    );
    formData.append("file", files[0]);

    const ifFileExists = await ResidentFileMetadataService.ifFileExists(
      formData
    );

    if (ifFileExists) {
      alert("File " + files[0].name + " already exists!");
      return;
    }

    ResidentFileMetadataService.upload(formData)
      .then((formMetadata) => {
        console.log("files-after-save", formMetadata);
        setDocumentState({
          ...documentState,
          currFiles:
            documentState.currFiles != undefined
              ? [...documentState.currFiles].concat(formMetadata)
              : [].concat(formMetadata),
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onDownload = (files) => {
    ResidentFileMetadataService.getById(files.id).then((file) => {
      ResidentFileMetadataService.download(files.id, file.data.path);
      ResidentFileMetadataService.notify(files.id);
    });
  };

  return {
    refreshPage,
    openResident,
    onOpenResidentForm,
    onCloseResidentForm,
    onSetFile,
    onDownload,
    documentState,
  };
};

export default useDocumentState;
