import React from "react";
import { Container, Tab, Row, Col, Nav, Button } from "react-bootstrap";
import ActiveStaffTrainingsMobile from "./activeStaffTrainingsMobile";
import PassedStaffTrainingsMobile from "./passedStaffTrainingsMobile";
import AddStaffTrainingMobile from "./addStaffTrainingMobile";

const StafffTrainingListMobile = ({
  onMarkAsPassed,
  onDeleteStaffTraining,
  onViewDetails,
  onPrintCertificate,
  onShowStaffTrainingView,
  onCreateTraining,
  onEditTraining,
  onStaffTraining,
  onDeleteTraining,
  trainingState,
  mode,
}) => {
  return (
    <React.Fragment>
      <Container fluid="mb">
        <Row>
          <Col>
            <Button
              size="sm"
              style={
                trainingState &&
                trainingState.staffTrainingView &&
                trainingState.staffTrainingView == "active"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onShowStaffTrainingView("active")}
            >
              Upcoming
            </Button>
            <Button
              size="sm"
              style={
                trainingState &&
                trainingState.staffTrainingView &&
                trainingState.staffTrainingView == "passed"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onShowStaffTrainingView("passed")}
            >
              Completed
            </Button>
            <Button
              size="sm"
              style={
                trainingState &&
                trainingState.staffTrainingView &&
                trainingState.staffTrainingView == "reports"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onShowStaffTrainingView("reports")}
            >
              Schedule
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {trainingState &&
            trainingState.staffTrainingView &&
            trainingState.staffTrainingView == "active" ? (
              <ActiveStaffTrainingsMobile
                onMarkAsPassed={onMarkAsPassed}
                onDeleteStaffTraining={onDeleteStaffTraining}
                onViewDetails={onViewDetails}
                trainingState={trainingState}
              />
            ) : trainingState &&
              trainingState.staffTrainingView &&
              trainingState.staffTrainingView == "passed" ? (
              <PassedStaffTrainingsMobile
                onPrintCertificate={onPrintCertificate}
                trainingState={trainingState}
              />
            ) : trainingState &&
              trainingState.staffTrainingView &&
              trainingState.staffTrainingView == "reports" ? (
              <AddStaffTrainingMobile
                onCreateTraining={onCreateTraining}
                onEditTraining={onEditTraining}
                onStaffTraining={onStaffTraining}
                onDeleteTraining={onDeleteTraining}
                trainingState={trainingState}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  fontSize: "12px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  fontStyle: "underline",
  fontWeight: "bold",
  fontSize: "12px",
};

export default StafffTrainingListMobile;
