import React from "react";
import { Card, Button } from "react-bootstrap";
import moment from "moment";
import { BsTrash, BsArrowClockwise } from "react-icons/bs";

const Todo = ({
  onShowTodoDetails,
  onShowTodoReschedule,
  onDeleteTodo,
  todo,
}) => {
  function renderTodo(todo) {
    const momentDate = moment(todo.startDate).format("DD/MM/YYYY hh:mm A");
    // Regular TODO
    if (todo.status) {
      return (
        <div>
          <Button
            variant="link"
            size="sm"
            style={{ color: "red" }}
            onClick={() => onShowTodoDetails(todo)}
          >
            <b>
              <s>{todo.title}</s>
            </b>{" "}
            <br />
            <div>{momentDate}</div>
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button
            variant="link"
            size="sm"
            style={{ color: "#3c3b6e" }}
            onClick={() => onShowTodoDetails(todo)}
          >
            <div style={{ display: "flex", alignItems: "left" }}>
              <b>{todo.title}</b>{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                fontStyle: "italic",
              }}
            >
              {todo.description != undefined && todo.description.length > 0
                ? todo.description.length > 70
                  ? todo.description.substring(0, 70) + "..."
                  : todo.description
                : "no description"}
            </div>
            <div style={{ display: "flex", alignItems: "left" }}>
              <p>
                Scheduled <b>{momentDate}</b> (e-mail reminder will be sent on{" "}
                {sessionStorage.getItem("CONTACTEMAIL")})
              </p>
            </div>
          </Button>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <td style={{ width: 15, verticalAlign: "middle", textAlign: "center" }}>
        <div>
          <Button
            variant="outline-light"
            onClick={() => onShowTodoReschedule(todo)}
            size="sm"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
          >
            <BsArrowClockwise
              style={{ color: "#5cb85c", width: "15px", height: "15px" }}
            />
          </Button>
          <Button
            variant="outline-light"
            onClick={() => onDeleteTodo(todo)}
            size="sm"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
          >
            <BsTrash
              style={{ color: "#d9534f", width: "15px", height: "15px" }}
            />
          </Button>
        </div>
      </td>
      <td style={{ verticalAlign: "middle", textAlign: "left" }}>
        <Card size="sm" style={{ width: "100%" }} border="light">
          {renderTodo(todo)}
        </Card>
      </td>
    </React.Fragment>
  );
};

export default Todo;
