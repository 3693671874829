import APIClient from "../apiClient";
import axios from "axios";

const BASE_MEDICATION = "/medication";

class MedicationClient extends APIClient {
  async getAll() {
    return super.performAsync("get", super.getBaseContext() + BASE_MEDICATION);
  }

  async getPrefix() {
    return super.performAsync(
      "get",
      super.getBaseContext() + BASE_MEDICATION + "/prefix"
    );
  }

  async save(repo) {
    return super.performAsync(
      "post",
      super.getBaseContext() + BASE_MEDICATION,
      repo
    );
  }

  download(repo) {
    return axios({
      url:
        super.getBaseURI() +
        super.getBaseContext() +
        BASE_MEDICATION +
        "/download",
      method: "POST",
      data: repo,
      responseType: "blob",
      headers: { authorization: localStorage.getItem("token") },
    });
  }

  async getMedicationHistoryView() {
    return super.performAsync(
      "get",
      super.getBaseContext() + BASE_MEDICATION + "/medicationHistoryView"
    );
  }

  async lastMedicationMail() {
    return super.performAsync(
      "get",
      super.getBaseContext() + BASE_MEDICATION + "/lastMedicationMail"
    );
  }

  async sendMail(repo) {
    return super.performAsync(
      "post",
      super.getBaseContext() + BASE_MEDICATION + "/sendMail",
      repo
    );
  }
}

export default MedicationClient;
