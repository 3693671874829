import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "react-bootstrap-typeahead/css/Typeahead.css";
import useSendMailState from "../../hook/useSendMailState";
import { Container, Row, Col } from "react-bootstrap";

const SendMail = ({ onCloseSendMail, medications, onDownloadOrder }) => {
  const {
    refreshPage,
    onSendMail,
    onChange,
    onExitPhone,
    onSelectedForRemoval,
    sendMailState,
  } = useSendMailState();

  useEffect(() => {
    refreshPage(medications);
  }, []);

  return (
    <React.Fragment>
      <Container
        style={{
          width: "100%",
          borderColor: "black",
          color: "#3C3B6E",
        }}
      >
        {sendMailState.isError ? (
          <Row>
            <Col>
              <div style={errorMsgStyle}>
                <b>ERROR:</b> {sendMailState.errorMsg}
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col>
            <Form.Label>Facility name:</Form.Label>
            <Form.Control
              size="sm"
              name="facilityName"
              placeholder="Facility name"
              onChange={onChange}
              readOnly
              value={
                sendMailState.medicationMail &&
                sendMailState.medicationMail.facilityName
              }
              style={{ width: "300px", color: "#3C3B6E", marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Contact address:</Form.Label>
            <Form.Control
              size="sm"
              name="facilityAddress"
              placeholder="Contact address"
              onChange={onChange}
              readOnly
              value={
                sendMailState.medicationMail &&
                sendMailState.medicationMail.facilityAddress
              }
              style={{ width: "400px", color: "#3C3B6E", marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>
              <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>Contact
              phone:
            </Form.Label>
            <Form.Control
              size="sm"
              name="facilityPhone"
              placeholder="Contact phone*"
              onChange={onChange}
              onBlur={onExitPhone}
              value={
                sendMailState.medicationMail &&
                sendMailState.medicationMail.facilityPhone
              }
              style={{ width: "250px", color: "#3C3B6E", marginBottom: "10px" }}
              autoComplete="off"
              autoComplete="none"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>
              <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>Contact
              e-mail:
            </Form.Label>
            <Form.Control
              size="sm"
              name="facilityEmail"
              placeholder="Contact e-mail*"
              onChange={onChange}
              value={
                sendMailState.medicationMail &&
                sendMailState.medicationMail.facilityEmail
              }
              style={{ width: "250px", color: "#3C3B6E", marginBottom: "10px" }}
              autoComplete="off"
              autoComplete="none"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>
              <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>
              Pharmacy e-mail:
            </Form.Label>
            <Form.Control
              size="sm"
              name="pharmacyEmail"
              placeholder="Pharmacy e-mail*"
              onChange={onChange}
              value={
                sendMailState.medicationMail &&
                sendMailState.medicationMail.pharmacyEmail
              }
              style={{ width: "250px", color: "#3C3B6E", marginBottom: "10px" }}
              autoComplete="off"
              autoComplete="none"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Exclude medications from e-mail:</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              onDoubleClick={(e) => onSelectedForRemoval(e)}
              multiple
              style={{ width: "250px" }}
            >
              {sendMailState.multiSelections &&
                sendMailState.multiSelections.map((med) => {
                  return (
                    <option key={med.id} value={med.id}>
                      {med.rxCode}
                    </option>
                  );
                })}
            </Form.Control>
            <Form.Text style={{ marginBottom: "10px" }} muted>
              Double click on the medication to exclude it from the e-mail
            </Form.Text>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginTop: "10px" }}>
        <Row>
          <Col
            style={{
              display: "",
              alignContent: "left",
              verticalAlign: "top",
            }}
          >
            <Button
              variant="outline-success"
              style={{ width: "150px" }}
              onClick={() => onCloseSendMail()}
            >
              Close
            </Button>
            {"    "}
            {sendMailState.isSendMailActive ? (
              <Button variant="success" style={{ width: "100px" }} disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
                Sending...
              </Button>
            ) : (
              <Button
                variant="success"
                style={{ width: "150px" }}
                onClick={() =>
                  onSendMail(sendMailState.multiSelections, onCloseSendMail)
                }
              >
                Send e-mail
              </Button>
            )}
            {"   "}
            <Button
              variant="secondary"
              style={{ width: "150px" }}
              onClick={() => onDownloadOrder(sendMailState.multiSelections)}
            >
              Download
            </Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const downloadButtonStyle = {
  width: "100px",
  verticalAlign: "middle",
  // color: "white",
  // backgroundColor: "#3c3b6e",
};

const closeButtonStyle = {
  width: "100px",
  verticalAlign: "middle",
  color: "white",
  backgroundColor: "#FFBD44",
  borderColor: "#FFBD44",
};

const errorMsgStyle = {
  color: "#b22234",
};

export default SendMail;
