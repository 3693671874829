import FacilityDetailsClient from "../../api/billing/facilityDetailsClient";
import UtilityService from "../utilityService";

const facilityDetailsClient = new FacilityDetailsClient();

class BillingService {
  async getAll() {
    let response = "";
    try {
      response = await facilityDetailsClient.getAll();

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getCustomer() {
    let response = "";
    try {
      response = await facilityDetailsClient.getCustomer();

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getPaymentMethod() {
    let response = "";
    try {
      response = await facilityDetailsClient.getPaymentMethod();

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async createCustomer(repo) {
    let response = "";
    try {
      response = await facilityDetailsClient.createCustomer(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async createPaymentMethod(repo) {
    let response = "";
    try {
      response = await facilityDetailsClient.createPaymentMethod(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  validateBillingInfo(card) {
    if (
      card == undefined ||
      card.cardNum1 == undefined ||
      card.cardNum1.trim() == "" ||
      card.cardNum2 == undefined ||
      card.cardNum2.trim() == "" ||
      card.cardNum3 == undefined ||
      card.cardNum3.trim() == "" ||
      card.cardNum4 == undefined ||
      card.cardNum4.trim() == ""
    ) {
      return {
        isError: true,
        msg: "Card number can't be blank, please enter card number!",
      };
    } else if (card.cardCvc == undefined || card.cardCvc.trim() == "") {
      return {
        isError: true,
        msg: "Card cvv/cvc can't be blank, please enter cvv/cvc!",
      };
    } else if (card.name == undefined || card.name.trim() == "") {
      return {
        isError: true,
        msg: "Cardholder name can't be blank, please enter cardholder name!",
      };
    } else if (!UtilityService.validateEmail(card.email)) {
      return {
        isError: true,
        msg: "Contact e-mail is not valid, please enter valid e-mail address!",
      };
    }
    return { isError: false };
  }
}

export default new BillingService();
