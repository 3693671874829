import React from "react";
import { Button, Container, Modal, Row, Col, Form } from "react-bootstrap";
import AddResidentDesktop from "./addResidentDesktop";
import ResidentListDesktop from "./residentListDesktop";
import ResidentDashboardDesktop from "./residentDashboardDesktop";
import AddTodoDesktop from "../../../todo/add-todo/desktop/addtodoDesktop";
import { TiPlus } from "react-icons/ti";
import AddFormDesktop from "../compliance/desktop/addFormDesktop";
import ShareReport from "../compliance/shareReport";
import AskForSignatureDesktop from "../compliance/desktop/askForSignatureDesktop";

const MainResidentDesktop = ({
  onChangeResident,
  onChangeResidentDates,
  onChangeDoc,
  onOpenResident,
  onNewResident,
  onHideResident,
  onSaveResident,
  onGetAllResidents,
  onBackDashboard,
  onAddResidentDoctor,
  onOpenResidentForm,
  onDeleteResidentDoctor,
  onCloseResidentForm,
  onShowAddTodo,
  onAddTodo,
  onCloseTodo,
  onSetFile,
  onDownload,
  onDeleteFiles,
  onShowAddMedRecord,
  onAddMedicalRecord,
  onChangeMedHistory,
  onChangeMedRecordDate,
  onOpenResidentView,
  onSaveLegalRepInfo,
  onChangeTodo,
  onChangeTodoDates,
  onEditResident,
  onSaveEditResident,
  onHideEditResident,
  onShowForm,
  onHideForm,
  onChangeForm,
  onSaveForm,
  onExitLegRepPhone,
  onExitDoctorPhone,
  onDeleteMedicalRecord,
  onShowShareReport,
  onHideShareReport,
  onChangeGuardian,
  onSelectFormMenu,
  onDeleteGuardianShare,
  formatList,
  onShowSignFile,
  onHideSignFile,
  onSaveSignFile,
  onSelectGuardianForSignature,
  onSelectResidentMenu,
  onShowDeactivated,
  onHideGuardian,
  onShowGuardian,
  onSaveGuardian,
  onChangeGuardianField,
  onChangeGuardianDates,
  onChangePassword,
  onToggleChanged,
  onShowPassword,
  onHidePassword,
  residentState,
}) => {
  return (
    <React.Fragment>
      {residentState.showResident ? (
        <AddResidentDesktop
          onHideResident={onHideResident}
          onChangeResident={onChangeResident}
          onChangeResidentDates={onChangeResidentDates}
          onSaveResident={onSaveResident}
          residentState={residentState}
        />
      ) : residentState.showResidentDashboard ? (
        <ResidentDashboardDesktop
          onChangeResident={onChangeResident}
          onChangeDoc={onChangeDoc}
          onAddResidentDoctor={onAddResidentDoctor}
          onDeleteResidentDoctor={onDeleteResidentDoctor}
          onBackDashboard={onBackDashboard}
          onOpenResidentForm={onOpenResidentForm}
          onShowAddTodo={onShowAddTodo}
          onCloseResidentForm={onCloseResidentForm}
          onSetFile={onSetFile}
          onDownload={onDownload}
          onDeleteFiles={onDeleteFiles}
          onShowAddMedRecord={onShowAddMedRecord}
          onAddMedicalRecord={onAddMedicalRecord}
          onChangeMedHistory={onChangeMedHistory}
          onChangeMedRecordDate={onChangeMedRecordDate}
          onOpenResidentView={onOpenResidentView}
          onSaveLegalRepInfo={onSaveLegalRepInfo}
          onEditResident={onEditResident}
          onShowForm={onShowForm}
          onExitLegRepPhone={onExitLegRepPhone}
          onExitDoctorPhone={onExitDoctorPhone}
          onDeleteMedicalRecord={onDeleteMedicalRecord}
          onShowShareReport={onShowShareReport}
          onSelectFormMenu={onSelectFormMenu}
          formatList={formatList}
          onShowSignFile={onShowSignFile}
          residentState={residentState}
        />
      ) : (
        <React.Fragment>
          <Container fluid style={{ backgroundColor: "lightgray" }}>
            <Row>
              <Col
                style={{
                  textAlign: "left",
                  verticalAlign: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                  color: "black",
                }}
              >
                <Form.Check
                  name="showDeactivated"
                  type="checkbox"
                  label="Show deactivated residents"
                  onChange={(e) => onShowDeactivated(e)}
                  defaultChecked={
                    residentState ? residentState.showDeactivated : false
                  }
                />
              </Col>
              <Col style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Button
                  onClick={() => onNewResident()}
                  variant="success"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "180px",
                    float: "right",
                    color: "#28a745",
                    backgroundColor: "white",
                  }}
                >
                  <TiPlus
                    style={{
                      color: "#28a745",
                      backgroundColor: "white",
                      width: "25px",
                      height: "25px",
                      verticalAlign: "middle",
                    }}
                  />
                  {"  "}
                  Add resident
                </Button>
              </Col>
            </Row>
          </Container>
          <ResidentListDesktop
            onOpenResident={onOpenResident}
            onSelectResidentMenu={onSelectResidentMenu}
            residentState={residentState}
          />
        </React.Fragment>
      )}
      <Modal show={residentState.showTodo} onHide={onCloseTodo}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>REMINDER</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <AddTodoDesktop
              onAddTodo={onAddTodo}
              onClose={onCloseTodo}
              onChange={onChangeTodo}
              onChangeDates={onChangeTodoDates}
              todo={residentState.currTodo}
              isError={residentState.isError}
              errorMsg={residentState.errorMsg}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={residentState.showResidentEdit} onHide={onHideEditResident}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>General info</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <AddResidentDesktop
              onHideResident={onHideEditResident}
              onChangeResident={onChangeResident}
              onChangeResidentDates={onChangeResidentDates}
              onSaveResident={onSaveEditResident}
              residentState={residentState}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={residentState.showAddForm} onHide={onHideForm}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>Report</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddFormDesktop
            onSaveForm={onSaveForm}
            onHideForm={onHideForm}
            onChangeForm={onChangeForm}
            residentState={residentState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={residentState.showShareReport}
        onHide={onHideShareReport}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>
              Share {residentState.currForm && residentState.currForm.name} with
              guardian
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareReport
            onChangeGuardian={onChangeGuardian}
            onDeleteGuardianShare={onDeleteGuardianShare}
            onSaveStaff={onSaveGuardian}
            onHideGuardian={onHideGuardian}
            onShowGuardian={onShowGuardian}
            onChangeGuardianField={onChangeGuardianField}
            onChangeGuardianDates={onChangeGuardianDates}
            onChangePassword={onChangePassword}
            onToggleChanged={onToggleChanged}
            onShowPassword={onShowPassword}
            onHidePassword={onHidePassword}
            onHideShareReport={onHideShareReport}
            residentState={residentState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={residentState.showSignFile}
        onHide={onHideSignFile}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Request signature for{" "}
            <b style={{ color: "#3C3B6E" }}>
              {residentState.currFile && residentState.currFile.name}{" "}
            </b>
            from guardian
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AskForSignatureDesktop
            onHideSignFile={onHideSignFile}
            onSaveSignFile={onSaveSignFile}
            onSelectGuardianForSignature={onSelectGuardianForSignature}
            onSaveStaff={onSaveGuardian}
            onHideGuardian={onHideGuardian}
            onShowGuardian={onShowGuardian}
            onChangeGuardianField={onChangeGuardianField}
            onChangeGuardianDates={onChangeGuardianDates}
            onChangePassword={onChangePassword}
            onToggleChanged={onToggleChanged}
            onShowPassword={onShowPassword}
            onHidePassword={onHidePassword}
            residentState={residentState}
          />
        </Modal.Body>
      </Modal>
      {/* <Modal
        size="lg"
        show={residentState.showGuardian}
        onHide={onHideGuardian}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b style={{ color: "#3C3B6E" }}>Add guardian</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddUserFamMember
            onSaveStaff={onSaveGuardian}
            onHide={onHideGuardian}
            onChange={onChangeGuardianField}
            onChangeDates={onChangeGuardianDates}
            onChangePassword={onChangePassword}
            onToggleChanged={test}
            onShowPassword={onShowPassword}
            onHidePassword={onHidePassword}
            usersState={residentState}
          />
        </Modal.Body>
      </Modal> */}
    </React.Fragment>
  );
};

export const btnStyle = {
  textAlign: "center",
  borderColor: "#3c3b6e",
  borderRadius: "10px",
  color: "#3c3b6e",
  backgroundColor: "#f2f2f2",
  borderWidth: "thin",
};

export default MainResidentDesktop;
