import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { FaSortAlphaDownAlt, FaSortAlphaDown, FaFilter } from "react-icons/fa";
import { BsArrowUpDown } from "react-icons/bs";
import {
  TiArrowUnsorted,
  TiArrowSortedDown,
  TiArrowSortedUp,
} from "react-icons/ti";

const TableSort = ({ sort, type }) => {
  console.log("type", type);
  function sortLocal() {
    console.log("sort", sort);
    console.log("type", type);
    if (type == undefined || type.length == 0 || type == "desc") {
      sort("asc");
    } else {
      sort("desc");
    }
  }

  if (type == undefined || type.length == 0) {
    return (
      <React.Fragment>
        <Button
          size="sm"
          onClick={() => sortLocal()}
          style={{
            backgroundColor: "transparent",
            borderColor: "transparent",
          }}
        >
          <TiArrowUnsorted
            style={{
              color: "black",
              width: "20px",
              height: "20px",
            }}
          />
        </Button>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {type == "asc" ? (
          <Button
            size="sm"
            onClick={() => sortLocal()}
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
          >
            <TiArrowSortedDown
              style={{
                color: "black",
                width: "20px",
                height: "20px",
              }}
            />
          </Button>
        ) : (
          <Button
            size="sm"
            onClick={() => sortLocal()}
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
          >
            <TiArrowSortedUp
              style={{
                color: "black",
                width: "20px",
                height: "20px",
              }}
            />
          </Button>
        )}
      </React.Fragment>
    );
  }
};

export default TableSort;
