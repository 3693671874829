import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

const ViewBillInfo = ({ onEditBillInfo, mainPageState }) => {
  const month =
    mainPageState &&
    mainPageState.paymentMethodInfo &&
    mainPageState.paymentMethodInfo.cardMonth;

  const year =
    mainPageState &&
    mainPageState.paymentMethodInfo &&
    mainPageState.paymentMethodInfo.cardYear;
  return (
    <Container>
      <Row style={{ marginBottom: "5px" }}>
        <Col>Card number:</Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Form.Control
            disabled
            defaultValue={" **** **** **** ".concat(
              mainPageState &&
                mainPageState.paymentMethodInfo &&
                mainPageState.paymentMethodInfo.cardLast4
            )}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5px" }}>
        <Col>Expiration date: </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Form.Control disabled defaultValue={month + "/" + year} />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5px" }}>
        <Col>Cardholder name: </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Form.Control
            disabled
            defaultValue={
              mainPageState &&
              mainPageState.customerInfo &&
              mainPageState.customerInfo.name
            }
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5px" }}>
        <Col>Contact e-mail: </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Form.Control
            disabled
            defaultValue={
              mainPageState &&
              mainPageState.customerInfo &&
              mainPageState.customerInfo.email
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={() => onEditBillInfo()}>Change billing info</Button>
        </Col>
        <Col style={{ textAlign: "left" }}></Col>
      </Row>
    </Container>
  );
};

export default ViewBillInfo;
