import React from "react";
import { Button, Badge, Container, Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import { BsTrash, BsCheck } from "react-icons/bs";
import UtilityService from "../../../../services/utilityService";

const MedicationListMobile = ({
  onDeleteRxCode,
  onMarkMedicationAsShipped,
  medicationState,
}) => {
  return (
    <React.Fragment>
      <Container fluid>
        {medicationState.filterRxCodes &&
          medicationState.filterRxCodes.map((rx) => {
            const daysWaiting = moment().diff(moment(rx.orderDate), "days");
            return (
              <Row style={rowStyle}>
                <Col xs="auto">
                  <Button
                    variant="outline-light"
                    size="sm"
                    onClick={() => onMarkMedicationAsShipped(rx)}
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                    }}
                  >
                    <BsCheck
                      style={{
                        color: "#5cb85c",
                        width: "15px",
                        height: "15px",
                        verticalAlign: "top",
                      }}
                    />
                  </Button>
                  {"   "}
                  <Button
                    size="sm"
                    variant="outline-light"
                    onClick={() => onDeleteRxCode(rx)}
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                    }}
                  >
                    <BsTrash
                      style={{
                        color: "#d9534f",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </Button>
                </Col>
                {daysWaiting <= 7 ? (
                  <Col xs="auto" style={{ color: "green" }}>
                    <div style={{ fontSize: "15px" }}>{rx.rxCode}</div>
                  </Col>
                ) : (
                  <Col xs="auto" style={{ color: "#d9534f" }}>
                    <div style={{ fontSize: "15px" }}>{rx.rxCode}</div>
                  </Col>
                )}
              </Row>
            );
          })}
      </Container>
    </React.Fragment>
  );
};

const rowStyle = {
  marginBottom: "10px",
};

export default MedicationListMobile;
