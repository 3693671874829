import React from "react";
import { Tab, Row, Nav, Col, Button } from "react-bootstrap";
import LegalRepInfoDesktop from "../legal-rep/desktop/legalRepInfoDesktop";
import DoctorInfoDesktop from "../doctor/desktop/doctorInfoDesktop";
import ComplianceDesktop from "../compliance/desktop/complianceDesktop";
import MedicalHistoryDesktop from "../medical-history/desktop/medicalHistoryDesktop";

const ResidentDetailsDesktop = ({
  onOpenResidentView,
  onChangeResident,
  onChangeDoc,
  onAddResidentDoctor,
  onDeleteResidentDoctor,
  onOpenResidentForm,
  onShowAddTodo,
  onCloseResidentForm,
  onSetFile,
  onDownload,
  onDeleteFiles,
  onShowAddMedRecord,
  onAddMedicalRecord,
  onChangeMedHistory,
  onChangeMedRecordDate,
  onSaveLegalRepInfo,
  onShowForm,
  onExitLegRepPhone,
  onExitDoctorPhone,
  onDeleteMedicalRecord,
  onShowShareReport,
  onSelectFormMenu,
  formatList,
  onShowSignFile,
  residentState,
}) => {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="legal">
      <Row>
        <Col>
          <Nav onSelect={(selectedKey) => onOpenResidentView(selectedKey)}>
            <Nav.Item>
              <Nav.Link eventKey="legal">
                <Button
                  style={
                    residentState && residentState.currView == "legal"
                      ? navButtonSelectedStyle
                      : navButtonStyle
                  }
                >
                  Legal Representative
                </Button>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="doctors">
                <Button
                  style={
                    residentState && residentState.currView == "doctors"
                      ? navButtonSelectedStyle
                      : navButtonStyle
                  }
                >
                  Doctors
                </Button>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="reports">
                <Button
                  style={
                    residentState && residentState.currView == "reports"
                      ? navButtonSelectedStyle
                      : navButtonStyle
                  }
                >
                  Reports
                </Button>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="medHistory">
                <Button
                  style={
                    residentState && residentState.currView == "medHistory"
                      ? navButtonSelectedStyle
                      : navButtonStyle
                  }
                >
                  Medical History
                </Button>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            overflowY: "none",
            overflowX: "none",
            height: "70vh",
          }}
        >
          <Tab.Content>
            <Tab.Pane eventKey="legal">
              <LegalRepInfoDesktop
                onChangeResident={onChangeResident}
                onSaveLegalRepInfo={onSaveLegalRepInfo}
                onExitLegRepPhone={onExitLegRepPhone}
                residentState={residentState}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="doctors">
              <DoctorInfoDesktop
                onChangeDoc={onChangeDoc}
                onAddResidentDoctor={onAddResidentDoctor}
                onDeleteResidentDoctor={onDeleteResidentDoctor}
                onExitDoctorPhone={onExitDoctorPhone}
                residentState={residentState}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="reports">
              <ComplianceDesktop
                onOpenResidentForm={onOpenResidentForm}
                onShowAddTodo={onShowAddTodo}
                onCloseResidentForm={onCloseResidentForm}
                onSetFile={onSetFile}
                onDownload={onDownload}
                onDeleteFiles={onDeleteFiles}
                onShowForm={onShowForm}
                onShowShareReport={onShowShareReport}
                onSelectFormMenu={onSelectFormMenu}
                formatList={formatList}
                onShowSignFile={onShowSignFile}
                residentState={residentState}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="medHistory">
              <MedicalHistoryDesktop
                onShowAddMedRecord={onShowAddMedRecord}
                onAddMedicalRecord={onAddMedicalRecord}
                onChangeMedHistory={onChangeMedHistory}
                onChangeMedRecordDate={onChangeMedRecordDate}
                onDeleteMedicalRecord={onDeleteMedicalRecord}
                residentState={residentState}
              />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "200px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "200px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default ResidentDetailsDesktop;
