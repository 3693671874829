import React, { useEffect } from "react";
import useCollaborationState from "./hook/useCollaborationState";
import useWindowSize from "../common/hook/useWindowSize";
import MainCollaborationMobile from "./mobile/mainCollaborationMobile";
import MainCollaborationDesktop from "./desktop/mainCollaborationDesktop";

const MainCollaboration = () => {
  const size = useWindowSize();
  const {
    refreshPage,
    onShowMessage,
    onHideMessage,
    onViewMessage,
    onSendMessage,
    onChangeMessage,
    onChangeContent,
    onSetView,
    collaborationState,
  } = useCollaborationState({});

  useEffect(() => {
    refreshPage();
  }, []);

  return size.width > 970 ? (
    <MainCollaborationDesktop
      onShowMessage={onShowMessage}
      onHideMessage={onHideMessage}
      onViewMessage={onViewMessage}
      onSendMessage={onSendMessage}
      onChangeMessage={onChangeMessage}
      onChangeContent={onChangeContent}
      onSetView={onSetView}
      collaborationState={collaborationState}
    />
  ) : (
    <MainCollaborationMobile
      onShowMessage={onShowMessage}
      onHideMessage={onHideMessage}
      onViewMessage={onViewMessage}
      onSendMessage={onSendMessage}
      onChangeMessage={onChangeMessage}
      onChangeContent={onChangeContent}
      onSetView={onSetView}
      collaborationState={collaborationState}
    />
  );
};

export default MainCollaboration;
