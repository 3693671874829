import APIClient from "../apiClient";

const BASE_TODO = "/todo";

class TodoClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_TODO + "/" + id
    );
  }

  async update(repo, id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + BASE_TODO + "/" + id,
      repo
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_TODO,
      repo
    );
  }

  async getNotRepeated() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_TODO + "/notRepeated"
    );
  }
}

export default TodoClient;
