import axios from "axios";
import APIClient from "./apiClient";
import { API_ROOT } from "./api-config";

//const API_URL = "http://localhost:8082/api";
const API_URL = API_ROOT + "/api";

export const USER_NAME_SESSION_ATTRIBUTE_NAME = "authenticatedUser";
export const TOKEN = "token";

class AuthenticationService {
  executeBasicAuthenticationService(username, password) {
    return axios.get(`${API_URL}/basicauth`, {
      headers: { authorization: this.createBasicAuthToken(username, password) },
    });
  }

  executeCreateAccount(user) {
    return axios.post(`${API_URL}/createAccount`, user);
  }

  executeResetPassAuthenticationService(email) {
    return axios.post(`${API_URL}/reset`, {
      email,
    });
  }

  executeJwtAuthenticationService(username, password) {
    return axios.post(`${API_URL}/authenticate`, {
      username,
      password,
    });
  }

  createBasicAuthToken(username, password) {
    return "Basic " + window.btoa(username + ":" + password);
  }

  registerSuccessfulLogin(username, password) {
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
    this.setupAxiosInterceptors(this.createBasicAuthToken(username, password));
  }

  registerSuccessfulLoginForJwt(username, token, view) {
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
    sessionStorage.setItem("VIEW", view);
    const tokenForStorage = this.createJWTToken(token);
    localStorage.setItem(TOKEN, this.createJWTToken(token));
  }

  createJWTToken(token) {
    return "Bearer " + token;
  }

  logout() {
    sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    sessionStorage.removeItem(TOKEN);
    window.open("/", "_self");
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    if (user === null) return false;
    return true;
  }

  getLoggedInUserName() {
    let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    if (user === null) return "";
    return user;
  }

  setupAxiosInterceptors(token) {
    //APIClient.setupAxiosInterceptors(token);
    // super.client.interceptors.request.use(config => {
    //   console.log("exec setupAxiosInterceptors");
    //   if (this.isUserLoggedIn()) {
    //     config.headers.authorization = token;
    //   }
    //   return config;
    // });
  }
}

export default new AuthenticationService();
