import React from "react";
import { Form, Col, Button, Container, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";

const ChangePassword = ({
  onChangeChangePassword,
  onHideChangePassword,
  onSaveChangePassword,
  onShowPassword,
  onHidePassword,
  mainPageState,
  isError,
  errorMsg,
}) => {
  return (
    <Container style={{ width: "100%" }}>
      {isError ? (
        <Row>
          {" "}
          <Col style={errorMsgStyle}>
            <b>ERROR:</b> {errorMsg}
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row>
        <Col>
          <Form.Label>New password</Form.Label>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <a
            style={{
              cursor: "pointer",
              width: "30%",
              marginBottom: "30px",
              color: "#00C851",
            }}
            onMouseDown={() => onShowPassword("newPassword")}
            onMouseUp={() => onHidePassword()}
          >
            <AiFillEye></AiFillEye>
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            name="newPassword"
            onChange={(e) => onChangeChangePassword(e)}
            required
            type={
              mainPageState.showPassword &&
              mainPageState.showPassword == "newPassword"
                ? "input"
                : "password"
            }
            value={
              mainPageState.changePassword
                ? mainPageState.changePassword.newPassword
                : ""
            }
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <Form.Label>Confirm new password</Form.Label>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <a
            style={{
              cursor: "pointer",
              width: "30%",
              marginBottom: "30px",
              color: "#00C851",
            }}
            onMouseDown={() => onShowPassword("confirmNewPassword")}
            onMouseUp={() => onHidePassword()}
          >
            <AiFillEye></AiFillEye>
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            name="confirmNewPassword"
            onChange={(e) => onChangeChangePassword(e)}
            required
            type={
              mainPageState.showPassword &&
              mainPageState.showPassword == "confirmNewPassword"
                ? "input"
                : "password"
            }
            value={
              mainPageState.changePassword
                ? mainPageState.changePassword.confirmNewPassword
                : ""
            }
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <Button
            variant="outline-success"
            style={{ width: "100px" }}
            onClick={() => onHideChangePassword()}
          >
            Cancel
          </Button>
          {"   "}
          <Button
            variant="success"
            style={{ width: "100px" }}
            onClick={() => onSaveChangePassword()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default ChangePassword;
