import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import MarkAsPassedDesktop from "./desktop/markAsPassedDesktop";
//Services
import StafffTrainingList from "./desktop/stafffTrainingListDesktop";
import ViewTraining from "./viewTraining";
import useMyTrainingState from "./hook/useMyTrainingState";

const MyTraining = () => {
  const {
    refreshPage,
    onDeleteStaffTraining,
    onPrintCertificate,
    onMarkAsPassed,
    onChangePassDate,
    onChangeStartDate,
    onChangeHours,
    onHideMarkAsPassed,
    onSaveMarkAsPassed,
    onViewDetails,
    onHideTrainingDetails,
    onShowStaffTrainingView,
    myTrainingState,
  } = useMyTrainingState({});
  // const [selectedStaffTrainings, setSelectedStaffTrainings] = useState([]);
  // const [showMarkAsPassed, setShowMarkAsPassed] = useState(false);
  // const [markAsPassedDetails, setMarkAsPassedDetails] = useState({});
  // const [currentStaffTraining, setCurrentStaffTraining] = useState({});
  // const [selectedTraining, setSelectedTraining] = useState();
  // const [showTrainingDetails, setShowTrainingDetails] = useState(false);

  useEffect(() => {
    refreshPage();
    // console.log(
    //   "authenticatedUser",
    //   sessionStorage.getItem("authenticatedUser")
    // );
    // StaffService.getByUserName(
    //   sessionStorage.getItem("authenticatedUser")
    // ).then((staffUser) => {
    //   console.log("staffUser", staffUser);
    //   StaffTrainingService.getByStaffId(staffUser[0].id).then((data) =>
    //     setSelectedStaffTrainings(data)
    //   );
    // });
  }, []);

  // function onDeleteStaffTraining() {
  //   alert(
  //     "Please contact the facility administrator if you need to remove the training!"
  //   );
  // }

  // function onPrintCertificate(stfTrn) {
  //   StaffTrainingService.printCertificate(stfTrn);
  // }

  // function onMarkAsPassed(staffTraining) {
  //   console.log("staffTraining-onMarkAsPassed", staffTraining);
  //   StaffTrainingService.markAsPassed(staffTraining.id).then((data) => {
  //     setSelectedStaffTrainings(
  //       selectedStaffTrainings
  //         ? selectedStaffTrainings
  //             .filter((stfTrn) => stfTrn.id != staffTraining.id)
  //             .concat(data)
  //         : [].concat(data)
  //     );
  //   });
  // }

  // function onMarkAsPassed(stfTrn) {
  //   setShowMarkAsPassed(true);
  //   setMarkAsPassedDetails({});
  //   setCurrentStaffTraining(stfTrn);
  // }

  // function onChangePassDate(date) {
  //   setMarkAsPassedDetails({ ...markAsPassedDetails, passDate: date });
  // }

  // function onChangeStartDate(date) {
  //   setMarkAsPassedDetails({ ...markAsPassedDetails, startDate: date });
  // }

  // function onChangeHours(event) {
  //   setMarkAsPassedDetails({
  //     ...markAsPassedDetails,
  //     durationHours: event.target.value,
  //   });
  // }

  // function onHideMarkAsPassed() {
  //   setShowMarkAsPassed(false);
  //   setMarkAsPassedDetails({});
  // }

  // function onSaveMarkAsPassed() {
  //   console.log("staffTraining");
  //   StaffTrainingService.markAsPassed({
  //     id: currentStaffTraining.id,
  //     passDate: markAsPassedDetails.passDate,
  //     startDate: markAsPassedDetails.startDate,
  //     durationHours: markAsPassedDetails.durationHours,
  //   }).then((data) => {
  //     if (selectedStaffTrainings != undefined) {
  //       setSelectedStaffTrainings(
  //         selectedStaffTrainings
  //           .filter((stfTrn) => stfTrn.id != data.id)
  //           .concat(data)
  //       );
  //     } else {
  //       setSelectedStaffTrainings([].concat(data));
  //     }
  //     setShowMarkAsPassed(false);
  //   });
  // }

  // function onViewDetails(trn) {
  //   setSelectedTraining(trn);
  //   setShowTrainingDetails(true);
  // }

  // function onHideTrainingDetails() {
  //   setShowTrainingDetails(false);
  // }

  return (
    <React.Fragment>
      {/* <Header /> */}
      <StafffTrainingList
        onMarkAsPassed={onMarkAsPassed}
        onDeleteStaffTraining={onDeleteStaffTraining}
        onViewDetails={onViewDetails}
        onPrintCertificate={onPrintCertificate}
        onShowStaffTrainingView={onShowStaffTrainingView}
        trainingState={myTrainingState}
        mode="personal"
      />
      <Modal
        show={myTrainingState.showMarkAsPassed}
        onHide={onHideMarkAsPassed}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Mark{" "}
            {myTrainingState.selectedStaffTrainings &&
              myTrainingState.selectedStaffTrainings.training &&
              myTrainingState.selectedStaffTrainings.training.name}{" "}
            as completed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MarkAsPassedDesktop
            changePassDate={onChangePassDate}
            changeStartDate={onChangeStartDate}
            changeHours={onChangeHours}
            markAsPassedDetails={myTrainingState.markAsPassedDetails}
            handleClose={onHideMarkAsPassed}
            saveMarkAsPassed={onSaveMarkAsPassed}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={myTrainingState.showTrainingDetails}
        onHide={onHideTrainingDetails}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Training details for{" "}
            {myTrainingState.selectedTraining &&
              myTrainingState.selectedTraining.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewTraining training={myTrainingState.selectedTraining} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default MyTraining;
