import React, { useEffect } from "react";
import moment from "moment";
import "../../common/table.css";
import { Table, Button, Container, Row, Col, Form } from "react-bootstrap";
import useMedicationHistoryState from "../hook/useMedicationHistoryState";

const MedicationHistory = ({ onCloseMedicationHistory }) => {
  const {
    refreshPage,
    getMessage,
    onFilterMedications,
    medicaitonHistoryState,
  } = useMedicationHistoryState();

  useEffect(() => {
    refreshPage();
  }, []);

  console.log(
    "medicaitonHistoryState.filteredMedicationHistory",
    medicaitonHistoryState.filteredMedicationHistory
  );

  return (
    <React.Fragment>
      <Container fluid style={{ marginBottom: "15px" }}>
        <Row>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col xs="auto" style={{ textAlign: "right" }}>
            {true ? (
              <Form.Control
                placeholder="Filter medications"
                onChange={(e) => onFilterMedications(e)}
                style={{ width: "200px" }}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
      <div class="tableFixHead">
        <Table>
          <thead>
            <tr>
              <th>
                <small>
                  <b>MED.</b>
                </small>
              </th>
              <th>
                <small>
                  <b>LAST ORDER DATE</b>
                </small>
              </th>
              <th>
                <small>
                  <b>LAST DELIVERY DATE</b>
                </small>
              </th>
              <th>
                <small>
                  <b>DETAILS</b>
                </small>
              </th>
            </tr>
          </thead>
          <tbody>
            {medicaitonHistoryState.filteredMedicationHistory &&
              medicaitonHistoryState.filteredMedicationHistory.map((data) => {
                return (
                  <tr>
                    <td width="20%">
                      <small>{data.rxCode}</small>
                    </td>
                    <td width="15%">
                      <small>
                        {moment(data.orderDate).format("MM/DD/YYYY")}
                      </small>
                    </td>
                    <td width="15%">
                      <small>
                        {data.shippedDate &&
                          moment(data.shippedDate).format("MM/DD/YYYY")}
                      </small>
                    </td>
                    <td width="50%">
                      <small>{getMessage(data)}</small>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <hr></hr>
      <Button
        variant="outline-success"
        onClick={onCloseMedicationHistory}
        style={{
          width: "100px",
          // verticalAlign: "middle",
          // color: "white",
          // backgroundColor: "#FFBD44",
          // borderColor: "#FFBD44",
        }}
      >
        Close
      </Button>
    </React.Fragment>
  );
};

export default MedicationHistory;
