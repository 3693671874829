import React from "react";
import { Col, Container, Row, Form, Button, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import useFamilyMemberVisitState from "./hook/useFamilyMemberVisitState";

const FamilyMemberVisit = () => {
  const {
    onChangeStartDate,
    onChangeDescription,
    onClose,
    onAddTodo,
    familyMemberVisitState,
  } = useFamilyMemberVisitState();

  function getDate(days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          {familyMemberVisitState.isError ? (
            <div style={errorMsgStyle}>
              <b>ERROR:</b> {familyMemberVisitState.errorMsg}
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <h4>Schedule visit</h4>
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <DatePicker
            selected={familyMemberVisitState.todo.startDate}
            placeholderText="Visit date*"
            onChange={(e) => onChangeStartDate(e)}
            showTimeSelect
            timeIntervals={15}
            minDate={getDate(1)}
            dateFormat="MM/dd/yyyy h:mm aa"
            disabledKeyboardNavigation
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <Form.Control
            as="textarea"
            value={familyMemberVisitState.todo.description}
            rows="3"
            size="sm"
            onChange={(e) => onChangeDescription(e)}
            placeholder="Comment"
            style={{ width: "400px" }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <Button
            variant="outline-success"
            style={{ width: "100px" }}
            onClick={() => onClose()}
          >
            Close
          </Button>
          {"   "}
          {familyMemberVisitState.isProcessing ? (
            <Button variant="success" style={{ width: "100px" }} disabled>
              <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
              />
              Sending...
            </Button>
          ) : (
            <Button
              variant="success"
              style={{ width: "100px" }}
              onClick={() => onAddTodo(familyMemberVisitState.todo)}
            >
              Save
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default FamilyMemberVisit;
