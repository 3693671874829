import React from "react";
import { Button } from "react-bootstrap";
import { FaFileSignature } from "react-icons/fa";

const SignButton = ({ onSign }) => {
  return (
    <Button
      variant="success"
      style={{
        marginTop: "10px",
        color: "#28a745",
        backgroundColor: "white",
        marginBottom: "10px",
        marginLeft: "10px",
        width: "150px",
        float: "right",
      }}
      onClick={() => onSign()}
    >
      <FaFileSignature
        style={{
          color: "#28a745",
          backgroundColor: "white",
          width: "25px",
          height: "25px",
          verticalAlign: "middle",
        }}
      />
      {"  "}
      Sign report
    </Button>
  );
};

export default SignButton;
