import React from "react";
import { Container, Row, Form, Col } from "react-bootstrap";

const IncidentDesc = ({ onChangeInput, lic624State, complianceFormsState }) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <b>TYPE OF INCIDENT</b>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="unauthorizedAbsence"
            label="Unauthorized Absence"
            onChange={(e) =>
              onChangeInput("unauthorizedAbsence", e, false, true)
            }
            checked={
              lic624State != undefined && lic624State.unauthorizedAbsence
            }
          />
        </Col>
        <Col>Alleged Client Abuse</Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="rape"
            label="Rape"
            onChange={(e) => onChangeInput("rape", e, false, true)}
            checked={lic624State != undefined && lic624State.rape}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="injuryAccident"
            label="Injury-Accident"
            onChange={(e) => onChangeInput("injuryAccident", e, false, true)}
            checked={lic624State != undefined && lic624State.injuryAccident}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="medicalEmergency"
            label="Medical Emergency"
            onChange={(e) => onChangeInput("medicalEmergency", e, false, true)}
            checked={lic624State != undefined && lic624State.medicalEmergency}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="aggressiveActSelf"
            label="Aggressive Act/Self"
            onChange={(e) => onChangeInput("aggressiveActSelf", e, false, true)}
            checked={lic624State != undefined && lic624State.aggressiveActSelf}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="sexual"
            label="Sexual"
            onChange={(e) => onChangeInput("sexual", e, false, true)}
            checked={lic624State != undefined && lic624State.sexual}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="pregnancy"
            label="Pregnancy"
            onChange={(e) => onChangeInput("pregnancy", e, false, true)}
            checked={lic624State != undefined && lic624State.pregnancy}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="injuryUnknownOrigin"
            label="Injury-Unknown Origin"
            onChange={(e) =>
              onChangeInput("injuryUnknownOrigin", e, false, true)
            }
            checked={
              lic624State != undefined && lic624State.injuryUnknownOrigin
            }
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="otherSexualIncident"
            label="Other Sexual Incident"
            onChange={(e) =>
              onChangeInput("otherSexualIncident", e, false, true)
            }
            checked={
              lic624State != undefined && lic624State.otherSexualIncident
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="aggressiveActAnotherClient"
            label="Aggressive Act/Another Client"
            onChange={(e) =>
              onChangeInput("aggressiveActAnotherClient", e, false, true)
            }
            checked={
              lic624State != undefined && lic624State.aggressiveActAnotherClient
            }
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="physical"
            label="Physical"
            onChange={(e) => onChangeInput("physical", e, false, true)}
            checked={lic624State != undefined && lic624State.physical}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="suicideAttempt"
            label="Suicide Attempt"
            onChange={(e) => onChangeInput("suicideAttempt", e, false, true)}
            checked={lic624State != undefined && lic624State.suicideAttempt}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="injuryFromAnotherClient"
            label="Injury-From another Client "
            onChange={(e) =>
              onChangeInput("injuryFromAnotherClient", e, false, true)
            }
            checked={
              lic624State != undefined && lic624State.injuryFromAnotherClient
            }
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="theft "
            label="Theft"
            onChange={(e) => onChangeInput("theft", e, false, true)}
            checked={lic624State != undefined && lic624State.theft}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="aggressiveActStaff "
            label="Aggressive Act/Staff"
            onChange={(e) =>
              onChangeInput("aggressiveActStaff", e, false, true)
            }
            checked={lic624State != undefined && lic624State.aggressiveActStaff}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="psychological "
            label="Psychological"
            onChange={(e) => onChangeInput("psychological", e, false, true)}
            checked={lic624State != undefined && lic624State.psychological}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="other "
            label="Other"
            onChange={(e) => onChangeInput("other", e, false, true)}
            checked={lic624State != undefined && lic624State.other}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="injuryFromBehaviorEpisode"
            label="Injury-From behavior episode"
            onChange={(e) =>
              onChangeInput("injuryFromBehaviorEpisode", e, false, true)
            }
            checked={
              lic624State != undefined && lic624State.injuryFromBehaviorEpisode
            }
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="fire"
            label="Fire"
            onChange={(e) => onChangeInput("fire", e, false, true)}
            checked={lic624State != undefined && lic624State.fire}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="aggressiveActFamilyVisitors"
            label="Aggressive Act/Family, Visitors"
            onChange={(e) =>
              onChangeInput("aggressiveActFamilyVisitors", e, false, true)
            }
            checked={
              lic624State != undefined &&
              lic624State.aggressiveActFamilyVisitors
            }
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="financial"
            label="Financial"
            onChange={(e) => onChangeInput("financial", e, false, true)}
            checked={lic624State != undefined && lic624State.financial}
          />
        </Col>
        <Col></Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="epidemicOutbreak"
            label="Epidemic Outbreak"
            onChange={(e) => onChangeInput("epidemicOutbreak", e, false, true)}
            checked={lic624State != undefined && lic624State.epidemicOutbreak}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="propertyDamage"
            label="Property Damage"
            onChange={(e) => onChangeInput("propertyDamage", e, false, true)}
            checked={lic624State != undefined && lic624State.propertyDamage}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="allegedViolationOfRights"
            label="Alleged Violation of Rights"
            onChange={(e) =>
              onChangeInput("allegedViolationOfRights", e, false, true)
            }
            checked={
              lic624State != undefined && lic624State.allegedViolationOfRights
            }
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="neglect"
            label="Neglect"
            onChange={(e) => onChangeInput("neglect", e, false, true)}
            checked={lic624State != undefined && lic624State.neglect}
          />
        </Col>
        <Col></Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="hospitalization"
            label="Hospitalization"
            onChange={(e) => onChangeInput("hospitalization", e, false, true)}
            checked={lic624State != undefined && lic624State.hospitalization}
          />
        </Col>
        <Col>
          <Form.Check
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="otherExplain"
            label="Other (explain)"
            onChange={(e) => onChangeInput("otherExplain", e, false, true)}
            checked={lic624State != undefined && lic624State.otherExplain}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>
                DESCRIBE EVENT OR INCIDENT (INCLUDE DATE, TIME, LOCATION,
                PERPETRATOR, NATURE OF INCIDENT, ANY ANTECEDENTS LEADING UP TO
                INCIDENT AND HOW CLIENTS WERE AFFECTED, INCLUDING ANY INJURIES
              </b>
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("describeEventOrIncident", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic624State && lic624State.describeEventOrIncident}
              as="textarea"
              rows={5}
              style={{ width: "100%" }}
              maxLength={600}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>PERSON(S) WHO OBSERVED THE INCIDENT/INJURY:</b>
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("personWhoObserved", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic624State && lic624State.personWhoObserved}
              as="textarea"
              rows={5}
              style={{ width: "100%" }}
              maxLength={600}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default IncidentDesc;
