import React from "react";
import { Button } from "react-bootstrap";
import { AiOutlineClear } from "react-icons/ai";

const UnSignButton = ({ onUnSign }) => {
  return (
    <Button
      variant="danger"
      style={{
        marginTop: "10px",
        color: "#dc3545",
        backgroundColor: "white",
        marginBottom: "10px",
        marginLeft: "10px",
        width: "200px",
        float: "right",
      }}
      onClick={() => onUnSign()}
    >
      <AiOutlineClear
        style={{
          color: "#dc3545",
          backgroundColor: "white",
          width: "25px",
          height: "25px",
          verticalAlign: "middle",
        }}
      />
      {"  "}
      Clear Signatures
    </Button>
  );
};

export default UnSignButton;
