import APIClient from "../../apiClient";

const BASE_CHECH_LIST = "/checkList";

class CheckListClient extends APIClient {
  delete(id) {
    return super.perform(
      "delete",
      super.getBaseContext() + BASE_CHECH_LIST + "/" + id
    );
  }

  insert(repo) {
    return super.perform(
      "post",
      super.getBaseContext() + BASE_CHECH_LIST,
      repo
    );
  }

  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_CHECH_LIST
    );
  }

  getById(id) {
    return super.perform(
      "get",
      super.getBaseContext() + BASE_CHECH_LIST + "/" + id
    );
  }
}

export default CheckListClient;
