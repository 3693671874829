import React, { useEffect } from "react";
import "./checklist.css";
//Services
import useCheckListState from "./hook/useCheckListState";
import useWindowSize from "../../common/hook/useWindowSize";
import MainCheckListDesktop from "./desktop/mainCheckListDesktop";
import MainCheckListMobile from "./mobile/mainCheckListMobile";

const MainCheckList = ({}) => {
  const size = useWindowSize();
  const {
    refreshPage,
    onHideScheduleCheckList,
    onSelectCheck,
    onGoToSchedule,
    onSaveScheduleCheckListControl,
    onSaveCheckResult,
    onHideCheckCompleted,
    onDeleteCheckResult,
    onViewCheckCompleted,
    onSetControlStatus,
    onSetComment,
    onHideCheckDetails,
    onViewCompletedDetails,
    onShowScheduleCheckList,
    onFilterCheckList,
    onChangeTodo,
    onChangeTodoDates,
    onOpenCheckListView,
    checkListState,
  } = useCheckListState({});

  useEffect(() => {
    refreshPage();
  }, []);

  return size.width > 970 ? (
    <MainCheckListDesktop
      onHideScheduleCheckList={onHideScheduleCheckList}
      onSelectCheck={onSelectCheck}
      onGoToSchedule={onGoToSchedule}
      onSaveScheduleCheckListControl={onSaveScheduleCheckListControl}
      onSaveCheckResult={onSaveCheckResult}
      onHideCheckCompleted={onHideCheckCompleted}
      onDeleteCheckResult={onDeleteCheckResult}
      onViewCheckCompleted={onViewCheckCompleted}
      onSetControlStatus={onSetControlStatus}
      onSetComment={onSetComment}
      onHideCheckDetails={onHideCheckDetails}
      onViewCompletedDetails={onViewCompletedDetails}
      onShowScheduleCheckList={onShowScheduleCheckList}
      onFilterCheckList={onFilterCheckList}
      onChangeTodo={onChangeTodo}
      onChangeTodoDates={onChangeTodoDates}
      onOpenCheckListView={onOpenCheckListView}
      checkListState={checkListState}
    />
  ) : (
    <MainCheckListMobile
      onHideScheduleCheckList={onHideScheduleCheckList}
      onSelectCheck={onSelectCheck}
      onGoToSchedule={onGoToSchedule}
      onSaveScheduleCheckListControl={onSaveScheduleCheckListControl}
      onSaveCheckResult={onSaveCheckResult}
      onHideCheckCompleted={onHideCheckCompleted}
      onDeleteCheckResult={onDeleteCheckResult}
      onViewCheckCompleted={onViewCheckCompleted}
      onSetControlStatus={onSetControlStatus}
      onSetComment={onSetComment}
      onHideCheckDetails={onHideCheckDetails}
      onViewCompletedDetails={onViewCompletedDetails}
      onShowScheduleCheckList={onShowScheduleCheckList}
      onFilterCheckList={onFilterCheckList}
      onChangeTodo={onChangeTodo}
      onChangeTodoDates={onChangeTodoDates}
      onOpenCheckListView={onOpenCheckListView}
      checkListState={checkListState}
    />
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "200px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "200px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainCheckList;
