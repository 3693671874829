import ResidentClient from "../../../api/compliance/resident/residentClient";

const residentClient = new ResidentClient();

class ResidentService {
  async delete(id) {
    try {
      const response = await residentClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    let response = "";
    try {
      response = await residentClient.insert(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await residentClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  getFromToken() {
    return residentClient.getFromToken();
  }

  async deactivateResident(id) {
    let response = "";
    try {
      response = await residentClient.deactivateResident(id);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new ResidentService();
