import APIClient from "../../apiClient";

const RESIDENT_DOCTOR = "/residentDoctor";

class ResidentDoctorClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + RESIDENT_DOCTOR + "/" + id
    );
  }

  async update(repo, id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + RESIDENT_DOCTOR + "/" + id,
      repo
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + RESIDENT_DOCTOR,
      repo
    );
  }

  getAll() {
    return super.perform("get", super.getBaseContext() + RESIDENT_DOCTOR);
  }

  async getResidentDoctorByResidentId(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() +
        RESIDENT_DOCTOR +
        "/residentDoctorByResidentId/" +
        id
    );
  }
}

export default ResidentDoctorClient;
