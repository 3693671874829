import React from "react";
import { Button } from "react-bootstrap";
import { FaRegCopy } from "react-icons/fa";

const CopyButton = ({ onShowCopy }) => {
  return (
    <Button
      variant="warning"
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
        width: "220px",
        color: "#3e2723 ",
        backgroundColor: "white",
        borderColor: "#3e2723",
        float: "right",
      }}
      onClick={() => onShowCopy()}
    >
      <FaRegCopy
        style={{
          color: "#3e2723 ",
          backgroundColor: "white",
          width: "25px",
          height: "25px",
          verticalAlign: "middle",
        }}
      />
      {"  "}
      Copy from previous
    </Button>
  );
};

export default CopyButton;
