import React from "react";
import { Form, Button, Col } from "react-bootstrap";

const AddFacility = ({
  onChange,
  onSave,
  onClose,
  onExitPhone,
  facilityState,
}) => {
  return (
    <div style={defaultStyle}>
      <Form style={{ color: "black" }}>
        <Form.Row style={{ marginBottom: "20px" }}>
          <Col>
            {facilityState.isError ? (
              <div style={errorMsgStyle}>
                <b>ERROR:</b> {facilityState.errorMsg}
              </div>
            ) : (
              ""
            )}
          </Col>
        </Form.Row>
        <Form.Group controlId="groupFacilityName">
          <Form.Label size="sm">
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Facility
            Name:
          </Form.Label>
          <Form.Control
            name="name"
            size="sm"
            placeholder="Enter facility name"
            onChange={(e) => onChange(e)}
            value={
              facilityState.currFacility && facilityState.currFacility.name
            }
            style={{ width: 300 }}
          />
        </Form.Group>
        <Form.Group controlId="groupTelephone">
          <Form.Label size="sm">
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Phone:
          </Form.Label>
          <Form.Control
            name="telephone"
            size="sm"
            placeholder="Enter telephone"
            onChange={(e) => onChange(e)}
            onBlur={onExitPhone}
            value={
              facilityState.currFacility && facilityState.currFacility.telephone
            }
            style={{ width: 150 }}
          />
        </Form.Group>
        <Form.Group controlId="groupAddress">
          <Form.Label size="sm">
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Address:
          </Form.Label>
          <Form.Control
            name="address"
            size="sm"
            placeholder="Enter address"
            onChange={(e) => onChange(e)}
            value={
              facilityState.currFacility && facilityState.currFacility.address
            }
            style={{ width: 400 }}
          />
        </Form.Group>
        <Form.Group controlId="groupCity">
          <Form.Label size="sm">
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>City:
          </Form.Label>
          <Form.Control
            name="city"
            size="sm"
            placeholder="Enter city"
            onChange={(e) => onChange(e)}
            value={
              facilityState.currFacility && facilityState.currFacility.city
            }
            style={{ width: 150 }}
          />
        </Form.Group>
        <Form.Group controlId="groupZIPCode">
          <Form.Label size="sm">
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>ZIP CODE:
          </Form.Label>
          <Form.Control
            name="zipCode"
            size="sm"
            placeholder="Enter ZIP code"
            onChange={(e) => onChange(e)}
            value={
              facilityState.currFacility && facilityState.currFacility.zipCode
            }
            style={{ width: 100 }}
          />
        </Form.Group>
        <Form.Group controlId="groupState">
          <Form.Label size="sm">
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>State:
          </Form.Label>
          <Form.Control
            as="select"
            name="state"
            size="sm"
            placeholder="Enter ZIP code"
            onChange={(e) => onChange(e)}
            value={
              facilityState.currFacility && facilityState.currFacility.state
            }
            style={{ width: 150 }}
          >
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>{" "}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="groupState">
          <Form.Label size="sm">
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Licensee
            Name:
          </Form.Label>
          <Form.Control
            name="licenseeName"
            size="sm"
            placeholder="Enter licensee name"
            onChange={(e) => onChange(e)}
            value={
              facilityState.currFacility &&
              facilityState.currFacility.licenseeName
            }
            style={{ width: 300 }}
          />
        </Form.Group>
        <Form.Group controlId="groupState">
          <Form.Label size="sm">
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Licensee
            Telephone:
          </Form.Label>
          <Form.Control
            name="licenseeTelephone"
            size="sm"
            placeholder="Enter licensee phone"
            onChange={(e) => onChange(e)}
            onBlur={onExitPhone}
            value={
              facilityState.currFacility &&
              facilityState.currFacility.licenseeTelephone
            }
            style={{ width: 150 }}
          />
        </Form.Group>
        <Form.Group controlId="groupState">
          <Form.Label size="sm">
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>License
            Number:
          </Form.Label>
          <Form.Control
            name="licenseNumber"
            size="sm"
            placeholder="Enter license number"
            onChange={(e) => onChange(e)}
            value={
              facilityState.currFacility &&
              facilityState.currFacility.licenseNumber
            }
            style={{ width: 150 }}
          />
        </Form.Group>
        <Button
          variant="outline-success"
          style={{
            width: "100px",
          }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>{" "}
        <Button
          variant="success"
          style={{ width: "100px" }}
          onClick={() => onSave()}
        >
          Save
        </Button>{" "}
      </Form>
    </div>
  );
};

const errorMsgStyle = {
  color: "#d9534f",
};

const buttonStyle = {
  borderColor: "#3C3B6E",
  width: "100px",
  borderRadius: "10px",
  color: "#3C3B6E",
  backgroundColor: "#f2f2f2",
  margin: "3px",
};

const defaultStyle = {
  color: "#3C3B6E",
};

export default AddFacility;
