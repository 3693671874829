import React from "react";
import { Button, Form, Table } from "react-bootstrap";

const CheckListDesktop = ({
  filterCheckList,
  filteredCheckLists,
  // criteria,
  handleSelected,
  selectedCheckList,
  hideScheduleCheckList,
  goToSchedule,
}) => {
  return (
    <React.Fragment>
      <Form.Control
        name="contact"
        size="sm"
        placeholder="Filter checklist"
        onChange={(e) => filterCheckList(e)}
        // defaultValue={criteria}
        style={{ width: 300, marginBottom: "10px" }}
        autoComplete="off"
      />
      {/* <b>Select checks from the checklist</b> */}
      <div
        id=""
        style={{
          overflow: "scroll",
          overflowX: "hidden",
          height: "400px",
          width: "100%",
        }}
      >
        <Table>
          <tbody>
            {filteredCheckLists &&
              filteredCheckLists.map((checkList) => (
                <tr>
                  <td style={{ width: "50px" }}>
                    <Form.Control
                      type="checkbox"
                      onChange={(e, id) => handleSelected(e, checkList.id)}
                      defaultChecked={
                        selectedCheckList != undefined &&
                        selectedCheckList.length > 0
                          ? selectedCheckList.filter((id) => checkList.id == id)
                              .length > 0
                          : false
                      }
                      autoComplete="none"
                    ></Form.Control>
                  </td>
                  <td>
                    {checkList.code} - {checkList.name}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <Button
        variant="outline-success"
        style={{ width: "100px" }}
        onClick={() => hideScheduleCheckList()}
      >
        Close
      </Button>{" "}
      <Button
        variant="success"
        style={{ width: "100px" }}
        onClick={() => goToSchedule()}
      >
        Next
      </Button>
    </React.Fragment>
  );
};

export default CheckListDesktop;
