import APIClient from "../../apiClient";

const RESIDENT_MED_HISTORY = "/residentMedHistory";

class ResidentMedHistoryClient extends APIClient {
  async delete(id) {
    return super.performAsync(
      "delete",
      super.getBaseContext() + RESIDENT_MED_HISTORY + "/" + id
    );
  }

  async update(repo, id) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + RESIDENT_MED_HISTORY + "/" + id,
      repo
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + RESIDENT_MED_HISTORY,
      repo
    );
  }

  async getResidentMedHistoryByResidentId(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() +
        RESIDENT_MED_HISTORY +
        "/residentMedHistoryByResidentId/" +
        id
    );
  }
}

export default ResidentMedHistoryClient;
