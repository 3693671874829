import APIClient from "../../apiClient";

const RESIDENT = "/resident";

class ResidentClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + RESIDENT + "/" + id
    );
  }

  async update(repo, id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + RESIDENT + "/" + id,
      repo
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + RESIDENT,
      repo
    );
  }

  async getAll() {
    return await super.performAsync("get", super.getBaseContext() + RESIDENT);
  }

  async deactivateResident(id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + RESIDENT + "/deactivate/" + id
    );
  }
}

export default ResidentClient;
