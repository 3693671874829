import APIClient from "../apiClient";

const BASE_SHOPPING_CART_PRODUCT = "/shoppingCartProduct";

class ShoppingCartProductClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_SHOPPING_CART_PRODUCT + "/" + id
    );
  }

  async update(repo, id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + BASE_SHOPPING_CART_PRODUCT + "/" + id,
      repo
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_SHOPPING_CART_PRODUCT,
      repo
    );
  }

  async getAllByShoppingCart(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() +
        BASE_SHOPPING_CART_PRODUCT +
        "/byShoppingCart/" +
        id
    );
  }
}

export default ShoppingCartProductClient;
