import APIClient from "../apiClient";

const BASE_FACILITY_FOLDER = "/facilityFolder";

class FacilityFolder extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_FACILITY_FOLDER + "/" + id
    );
  }

  async save(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FACILITY_FOLDER,
      repo
    );
  }

  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_FACILITY_FOLDER
    );
  }
}

export default FacilityFolder;
