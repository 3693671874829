export const TODO_GROUPS = [
  { id: "0", value: "Choose..." },
  // { id: "m", value: "Medication" },
  // { id: "s", value: "Shopping" },
  { id: "l", value: "Laundry" },
  { id: "c", value: "Care" },
  { id: "o", value: "Other" },
];

export const REPEATED_CATEGORY = [
  { id: "0", value: "..." },
  { id: "1", value: "Once a Day" },
  { id: "2", value: "Once a Day(Mon-Fri)" },
  { id: "3", value: "Once a Week" },
  { id: "4", value: "Once a Month" },
  { id: "5", value: "Once a Year" },
  { id: "6", value: "Other..." },
];

export const REPEATED_CATEGORY_VALUE = [
  { id: 0, value: "..." },
  { id: 1, value: "1" },
  { id: 2, value: "2" },
  { id: 3, value: "3" },
  { id: 4, value: "4" },
  { id: 5, value: "5" },
  { id: 6, value: "6" },
  { id: 7, value: "7" },
  { id: 8, value: "8" },
  { id: 9, value: "9" },
  { id: 10, value: "10" },
  { id: 11, value: "11" },
  { id: 12, value: "12" },
  { id: 13, value: "13" },
  { id: 14, value: "14" },
  { id: 15, value: "15" },
  { id: 16, value: "16" },
  { id: 17, value: "17" },
  { id: 18, value: "18" },
  { id: 19, value: "19" },
  { id: 20, value: "20" },
  { id: 21, value: "21" },
  { id: 22, value: "22" },
  { id: 23, value: "23" },
  { id: 24, value: "24" },
  { id: 25, value: "25" },
  { id: 26, value: "26" },
  { id: 27, value: "27" },
  { id: 28, value: "28" },
  { id: 29, value: "29" },
  { id: 30, value: "30" },
  { id: 31, value: "31" },
];

export const REPEATED_CATEGORY_TYPE = [
  { id: "0", value: "..." },
  { id: "h", value: "hours" },
  { id: "d", value: "days" },
  { id: "w", value: "weeks" },
  { id: "m", value: "months" },
  { id: "y", value: "years" },
];

export const DOSAGE = [
  { id: "0", value: "..." },
  { id: "ml", value: "mililiter" },
  { id: "l", value: "liter" },
  { id: "tb", value: "tablespoon" },
  { id: "ts", value: "teaspoon" },
  { id: "mc", value: "micrograms" },
  { id: "mg", value: "milligram" },
  { id: "g", value: "gram" },
  { id: "o", value: "ounce" },
  { id: "p", value: "pound" },
];

export const PRODUCT_TYPE = [
  { id: "0", value: "..." },
  { id: "1", value: "Fresh vegetables" },
  { id: "2", value: "Fresh fruits" },
  { id: "3", value: "Condiments / Sauces" },
  { id: "4", value: "Dairy" },
  { id: "5", value: "Baked goods" },
  { id: "6", value: "Personal care" },
  { id: "7", value: "Cleaning products" },
  { id: "8", value: "Office supplies" },
  { id: "9", value: "Baking" },
  { id: "10", value: "Cheese" },
  { id: "11", value: "Various groceries" },
  { id: "12", value: "Meat" },
  { id: "13", value: "Snacks" },
  { id: "14", value: "Medicine" },
  { id: "15", value: "Refrigerated items" },
  { id: "16", value: "Canned foods" },
  { id: "17", value: "Seafood" },
  { id: "18", value: "Themed meals" },
  { id: "19", value: "Kitchen" },
  { id: "20", value: "Frozen" },
  { id: "21", value: "Spices & herbs" },
  { id: "22", value: "Beverages" },
  { id: "23", value: "Baby" },
  { id: "24", value: "Pets" },
  // { id: "0", value: "..." },
  // { id: "1", value: "Fruits & Vegetables" },
  // { id: "2", value: "Meat" },
  // { id: "3", value: "Groceries" },
  // { id: "4", value: "Others" }
];

export const PRODUCT_MEASURE = [
  { id: "0", value: "..." },
  { id: "1", value: "lb" },
  { id: "2", value: "oz" },
  { id: "3", value: "liter" },
  { id: "4", value: "gallon" },
  { id: "5", value: "piece" },
];

export const MEDICAL_HISTORY_TYPE = [
  { id: "d", value: "Diagnosis" },
  { id: "a", value: "Allergy" },
  { id: "i", value: "Immunization" },
  { id: "o", value: "Other" },
];

export const MONTHS = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 },
  { id: 6, value: 6 },
  { id: 7, value: 7 },
  { id: 8, value: 8 },
  { id: 9, value: 9 },
  { id: 10, value: 10 },
  { id: 11, value: 11 },
  { id: 12, value: 12 },
];
