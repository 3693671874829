import { useState } from "react";
import FacilityFolderService from "../../../services/mydocuments/facilityFolderService";
import FacilityFileService from "../../../services/mydocuments/facilityFileService";
import facilityFileService from "../../../services/mydocuments/facilityFileService";

const useMyDocumentsState = () => {
  const [myDocumentState, setMyDocumentState] = useState({ forms: [] });

  const refreshPage = async () => {
    const folders = await FacilityFolderService.getAll();

    setMyDocumentState({
      ...myDocumentState,
      folders: folders,
    });
  };

  const onShowFolder = () => {
    setMyDocumentState({ ...myDocumentState, showFolder: true });
  };

  const onHideFolder = () => {
    setMyDocumentState({
      ...myDocumentState,
      showFolder: false,
      currFolder: {},
      isAddFormError: false,
      errorMsg: "",
    });
  };

  const onOpenFacilityFolder = async (folder) => {
    const facilityFiles = await facilityFileService.getByFacilityFolderId(
      folder.id
    );
    setMyDocumentState({
      ...myDocumentState,
      currFolder: folder,
      showDocuments: true,
      currFiles: facilityFiles,
    });
  };

  const onSelectFormMenu = async (selectedKey, folder) => {
    console.log("selectedKey", selectedKey);
    console.log("folder", folder);
    if (selectedKey == "delete") {
      await onDeleteFolder(folder);
    }
  };

  const onDeleteFolder = async (folder) => {
    await FacilityFolderService.delete(folder.id);
    setMyDocumentState({
      ...myDocumentState,
      folders: myDocumentState.folders.filter((fld) => fld.id != folder.id),
    });
  };

  const onSaveFolder = async () => {
    if (
      myDocumentState.currFolder == undefined ||
      myDocumentState.currFolder.length == 0
    ) {
      setMyDocumentState({
        ...myDocumentState,
        isAddFormError: true,
        errorMsg: "Folder name can't be empty",
      });
      return;
    }
    const folder = await FacilityFolderService.save(myDocumentState.currFolder);
    setMyDocumentState({
      ...myDocumentState,
      folders:
        myDocumentState.folders != undefined
          ? myDocumentState.folders.concat(folder)
          : [].concat(folder),
      showFolder: false,
      currFolder: {},
    });
  };

  const onChangeFolder = (e) => {
    setMyDocumentState({
      ...myDocumentState,
      currFolder: { ...myDocumentState.currFolder, name: e.target.value },
      isAddFormError: false,
      errorMsg: "",
    });
  };

  const formatList = (folders) => {
    if (!folders) {
      return {};
    }
    let sortedFolders = folders.sort((resFormA, resFromB) => {
      if (resFormA.id > resFromB.id) {
        return 1;
      } else {
        return -1;
      }
    });

    var reportFormMap = {};

    var i,
      j,
      k,
      temparray,
      chunk = 3;
    for (i = 0, j = sortedFolders.length, k = 1; i < j; i += chunk, k++) {
      temparray = sortedFolders.slice(i, i + chunk);
      if (temparray.length < chunk) {
        var times = chunk - temparray.length;
        for (var n = 0; n < times; n++) {
          temparray.push({});
        }
      }
      reportFormMap[k] = temparray;
      // do whatever
    }

    console.log("reportFormMap", reportFormMap);

    return reportFormMap;
  };

  const onCloseDocuments = () => {
    setMyDocumentState({
      ...myDocumentState,
      currFolder: {},
      showDocuments: false,
    });
  };

  const onSetFile = async (e) => {
    const files = Array.from(e.target.files);
    console.log("files", files);

    let formData = new FormData();

    formData.append("name", files[0].name);
    formData.append(
      "facilityFolderId",
      myDocumentState.currFolder && myDocumentState.currFolder.id
    );
    formData.append("file", files[0]);

    const ifFileExists = await FacilityFileService.ifFileExists(formData);

    if (ifFileExists) {
      alert("File " + files[0].name + " already exists!");
      return;
    }

    FacilityFileService.upload(formData)
      .then((formMetadata) => {
        setMyDocumentState({
          ...myDocumentState,
          currFiles:
            myDocumentState.currFiles != undefined
              ? [...myDocumentState.currFiles].concat(formMetadata)
              : [].concat(formMetadata),
          currFile: {},
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onDownload = (files) => {
    FacilityFileService.getById(files.id).then((file) => {
      FacilityFileService.download(files.id, file.path);
    });
  };

  const onDeleteDocument = async (files) => {
    await facilityFileService.delete(files.id);
    setMyDocumentState({
      ...myDocumentState,
      currFiles: [...myDocumentState.currFiles].filter(
        (currFile) => currFile.id != files.id
      ),
    });
  };

  const onShowShareDocument = (file) => {
    setMyDocumentState({
      ...myDocumentState,
      showShareDocument: true,
      currShareFile: file,
      currMessage:
        "<p>Dear</>" +
        "<p>The documents you requested to be issued are ready and attached</p>" +
        "<p>Kind Regards</p>" +
        "<p>Aleksandar</p>",
      isContentChaged: false,
    });
  };

  const onHideShareDocument = () => {
    setMyDocumentState({
      ...myDocumentState,
      showShareDocument: false,
      currEmail: "",
      currMessage: "",
      currShareFile: undefined,
      isContentChaged: false,
    });
  };

  const onChangeEmail = (e) => {
    let value = e.target.value;

    setMyDocumentState({
      ...myDocumentState,
      currEmail: value,
      isError: false,
      errorMsg: "",
    });
  };

  const onChangeContent = (content) => {
    setMyDocumentState({
      ...myDocumentState,
      currMessage: content,
      isError: false,
      errorMsg: "",
      isContentChaged: true,
    });
  };

  const onSendEmail = async () => {
    const email = myDocumentState.currEmail;
    if (email != undefined && email.endsWith("ca.gov")) {
      //if (email != undefined && email.endsWith("@yahoo.com")) {
      if (myDocumentState.currMessage.length < 60000) {
        console.log("currShareFile", myDocumentState.currShareFile);

        let formData = new FormData();

        formData.append(
          "name",
          myDocumentState.currShareFile && myDocumentState.currShareFile.name
        );
        formData.append(
          "filePath",
          myDocumentState.currShareFile && myDocumentState.currShareFile.path
        );
        formData.append("email", myDocumentState.currEmail);
        formData.append("subject", myDocumentState.currMessage);
        FacilityFileService.shareFile(formData);
        alert(
          "Document is shared with " +
            email +
            ",please confirm with the recipient that the mail is successfully delivered"
        );
        setMyDocumentState({
          ...myDocumentState,
          showShareDocument: false,
          isError: false,
          errorMsg: "",
          currEmail: "",
          currMessage: "",
          currShareFile: undefined,
          isContentChaged: false,
        });
      } else {
        setMyDocumentState({
          ...myDocumentState,
          isError: true,
          errorMsg:
            "Message is too long maximum allowed characters is 60000, please reduce the size of the message!",
        });
      }
    } else {
      setMyDocumentState({
        ...myDocumentState,
        isError: true,
        errorMsg:
          "Invalid email address, you can only share documents with @dss.ca.gov e-mails",
      });
    }
  };

  const onBlurEmail = (e) => {
    if (!myDocumentState.isContentChaged) {
      var fullName = e.target.value
        .substr(0, e.target.value.indexOf("@"))
        .replace(".", " ");
      let names = fullName.split(" ");
      let fullNameFormatted = "";
      for (var i = 0; i < names.length; i++) {
        fullNameFormatted = fullNameFormatted.concat(
          names[i].charAt(0).toUpperCase() + names[i].slice(1) + " "
        );
      }
      setMyDocumentState({
        ...myDocumentState,
        currMessage:
          "<p>Dear " +
          fullNameFormatted +
          ",</p>" +
          "<p>The documents you requested to be issued are ready and attached</p>" +
          "<p>Kind Regards</p>" +
          "<p>Aleksandar</p>",
      });
    }
  };

  return {
    refreshPage,
    onShowFolder,
    onHideFolder,
    onOpenFacilityFolder,
    onSelectFormMenu,
    onSaveFolder,
    onChangeFolder,
    formatList,
    onCloseDocuments,
    onSetFile,
    onDownload,
    onDeleteDocument,
    onShowShareDocument,
    onHideShareDocument,
    onChangeEmail,
    onChangeContent,
    onSendEmail,
    onBlurEmail,
    myDocumentState,
  };
};

export default useMyDocumentsState;
