import React from "react";
import { Table, Form } from "react-bootstrap";

const Functioning = ({ onChangeInput, lic625State, complianceFormsState }) => {
  return (
    <Table>
      <tbody>
        <tr>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Needs</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("functioningNeeds", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.functioningNeeds}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Objective/Plan</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("functioningPlan", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.functioningPlan}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td>
            <Form.Group>
              <Form.Label>
                <b>Time frame</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("functioningTimeFrame", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.functioningTimeFrame}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Person(s) responsible for implementation</b>
              </Form.Label>
              <Form.Control
                onChange={(e) =>
                  onChangeInput("functioningPersonResponsible", e)
                }
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.functioningPersonResponsible}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Method of evaluating progress</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("functioningProgress", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.functioningProgress}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default Functioning;
