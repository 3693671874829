import AnnouncementClient from "../../api/collaboration/announcementClient";

const announcementClient = new AnnouncementClient();

class AnnouncementService {
  async notify() {
    try {
      const response = await announcementClient.notify();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    let response = "";
    try {
      if (repo.hasOwnProperty("id") && repo.id != undefined) {
        response = await announcementClient.update(repo, repo.id);
      } else {
        response = await announcementClient.insert(repo);
      }

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await announcementClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  validateMessage(message) {
    if (message.subject == undefined || message.subject.trim() === "") {
      return {
        isValid: false,
        msg: "Subject is empty, please enter subject!",
      };
    }

    if (message.description == undefined || message.description.trim() === "") {
      return {
        isValid: false,
        msg: "Message is empty, please enter message!",
      };
    }

    if (message.description.length > 60000) {
      return {
        isValid: false,
        msg:
          "Message is too long maximum allowed characters is 60000, please reduce the size of the message!",
      };
    }

    return {
      isValid: true,
    };
  }
}

export default new AnnouncementService();
