import APIClient from "./apiClient";

const BASE_VIEW = "/view";

class ViewClient extends APIClient {
  getStaffFacilityByUserName = async (username) => {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_VIEW + "/staffFacility/" + username
    );
  };
}

export default ViewClient;
