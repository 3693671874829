import React from "react";
import { Button, Accordion } from "react-bootstrap";
import walmart from "../../../../img/Walmart.png";
import costco from "../../../../img/costco.png";
import sprouts from "../../../../img/sprouts.png";
import aldi from "../../../../img/aldi.png";
import ralphs from "../../../../img/Ralphs.png";
import albertson from "../../../../img/albertson.png";

const WeeklyAddsMobile = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <a
        href={"https://www.walmart.com/store/5338/san-diego-ca/weekly-ads"}
        target="_blank"
      >
        <img
          src={walmart}
          className="App-logo"
          alt="logo"
          style={{ width: "100", height: "30px" }}
        ></img>
      </a>
      <hr></hr>
      <a href={"https://www.sprouts.com/weekly-ad/"} target="_blank">
        <img
          src={sprouts}
          className="App-logo"
          alt="logo"
          style={{ width: "100px", height: "30px" }}
        ></img>
      </a>
      <hr></hr>
      <a href={"https://coupons.albertsons.com/weeklyad/"} target="_blank">
        <img
          src={albertson}
          className="App-logo"
          alt="logo"
          style={{ width: "100px", height: "30px" }}
        ></img>
      </a>
      <hr></hr>
      <a
        href={"https://www.hotcouponworld.com/costco-weekly-ad/"}
        target="_blank"
      >
        <img
          src={costco}
          className="App-logo"
          alt="logo"
          style={{ width: "100px", height: "30px" }}
        ></img>
      </a>
      <hr></hr>
      <a
        href={"https://www.aldi.us/en/weekly-specials/this-weeks-aldi-finds/"}
        target="_blank"
      >
        <img
          src={aldi}
          className="App-logo"
          alt="logo"
          style={{ width: "50px", height: "50px" }}
        ></img>
      </a>
      <hr></hr>
      <a href={"https://www.ralphs.com/weeklyad"} target="_blank">
        <img
          src={ralphs}
          className="App-logo"
          alt="logo"
          style={{ width: "100px", height: "30px" }}
        ></img>
      </a>
    </div>
  );
};

export default WeeklyAddsMobile;
