import React, { useEffect } from "react";
import AddFacility from "./addFacility";
import Header from "../header";
import useFacilityState from "./hook/useFacilityState";

const MainFacility = () => {
  const {
    refreshPage,
    onChange,
    onSave,
    onClose,
    onExitPhone,
    facilityState,
  } = useFacilityState({});

  useEffect(() => {
    refreshPage();
  }, []);

  return (
    <React.Fragment>
      {/* <Header /> */}
      <AddFacility
        onChange={onChange}
        onSave={onSave}
        onClose={onClose}
        onExitPhone={onExitPhone}
        facilityState={facilityState}
      />
    </React.Fragment>
  );
};

export default MainFacility;
