import React from "react";
import { Button, Badge } from "react-bootstrap";
import moment from "moment";

const CalendarItemDesktop = ({
  todosForTheMonth,
  i,
  selectedMonth,
  selectedYear,
  onShowTaskDetails,
  weekday,
  selectedDate,
}) => {
  const weekendIds = [0, 7, 14, 21, 28, 35, 42, 6, 13, 20, 27, 34, 41];
  const reminders =
    todosForTheMonth &&
    todosForTheMonth.filter(
      (todo) =>
        moment(todo.startDate).month() == selectedMonth &&
        moment(todo.startDate).year() == selectedYear &&
        moment(todo.startDate).format("DD") == ("0" + i).slice(-2)
    );
  return (
    <React.Fragment>
      <Button
        style={
          moment(selectedDate).year() == selectedYear &&
          moment(selectedDate).month() == selectedMonth &&
          moment(selectedDate).date() == i
            ? weekendIds.filter((id) => id == weekday).length > 0
              ? alfButtonSelectedWeekend
              : alfButtonSelected
            : moment(new Date()).year() == selectedYear &&
              moment(new Date()).month() == selectedMonth &&
              moment(new Date()).date() == i
            ? weekendIds.filter((id) => id == weekday).length > 0
              ? alfButtonTodayWeekend
              : alfButtonToday
            : weekendIds.filter((id) => id == weekday).length > 0
            ? reminders && reminders.length > 0
              ? reminders.length == 1
                ? alfButtonWith1Reminders
                : reminders.length == 2
                ? alfButtonWith2Reminders
                : reminders.length == 3
                ? alfButtonWith3Reminders
                : alfButtonWithReminders
              : alfButtonWeekend
            : reminders && reminders.length > 0
            ? reminders.length == 1
              ? alfButtonWith1Reminders
              : reminders.length == 2
              ? alfButtonWith2Reminders
              : reminders.length == 3
              ? alfButtonWith3Reminders
              : alfButtonWithReminders
            : alfButton
        }
        onClick={() => onShowTaskDetails(selectedYear, selectedMonth, i)}
      >
        <div style={{ display: "block", height: "100px" }}>
          <span style={{ fontSize: "25px" }}>{("0" + i).slice(-2)}</span>
          <hr />
          {reminders && reminders.length > 0 ? (
            <Badge
              pill
              style={{
                color: "white",
                backgroundColor:
                  reminders && reminders.length < 3 ? "#f0ad4e" : "#d9534f",
                width: "20px",
                height: "20px",
                verticalAlign: "middle",
                alignItems: "center",
              }}
              variant="light"
            >
              {reminders && reminders.length}
            </Badge>
          ) : (
            ""
          )}
        </div>
      </Button>
    </React.Fragment>
  );
};

const alfButtonWith1Reminders = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "black",
  backgroundColor: "lightgray",
  borderWidth: "thin",
};

const alfButtonWith2Reminders = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "black",
  backgroundColor: "lightgray",
  borderWidth: "thin",
};

const alfButtonWith3Reminders = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "black",
  backgroundColor: "lightgray",
  borderWidth: "thin",
};

const alfButtonWithReminders = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "black",
  backgroundColor: "lightgray",
  borderWidth: "thin",
};

const alfButton = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "black",
  backgroundColor: "lightgrey",
  borderWidth: "thin",
};

const alfButtonSelected = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "black",
  backgroundColor: "#0275d8",
  borderWidth: "thin",
};

const alfButtonSelectedWeekend = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "black",
  backgroundColor: "#0275d8",
  borderWidth: "thin",
};

const alfButtonToday = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  backgroundColor: "whitesmoke",
  borderWidth: "thin",
  color: "black",
};

const alfButtonTodayWeekend = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "black",
  backgroundColor: "whitesmoke",
  borderWidth: "thin",
};

const alfButtonWeekend = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "black",
  backgroundColor: "lightgrey",
  borderWidth: "thin",
};

export default CalendarItemDesktop;
