import React from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Button, Form } from "react-bootstrap";

const CompleteCheckDesktop = ({
  onSetControlStatus,
  onSetComment,
  onHideCheckCompleted,
  onSaveCheckResult,
  checkListState: checkListState,
}) => {
  return (
    <React.Fragment>
      {checkListState.isError ? (
        <div style={errorMsgStyle}>ERROR: {checkListState.errorMsg}</div>
      ) : (
        ""
      )}
      The result from the check are{" "}
      <BootstrapSwitchButton
        onlabel="OK"
        onstyle="success"
        offlabel="Not OK"
        offstyle="danger"
        checked={
          checkListState.currCheckResult != undefined
            ? checkListState.currCheckResult.isOk
            : true
        }
        width="100"
        onChange={(checked) => onSetControlStatus(checked)}
      />
      <br />
      <Form.Control
        as="textarea"
        rows="3"
        style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
        value={
          checkListState.currCheckResult != undefined
            ? checkListState.currCheckResult.comment
            : ""
        }
        onChange={(e) => onSetComment(e)}
        placeholder="Comment"
        autoComplete="none"
      ></Form.Control>
      <Button
        variant="outline-success"
        style={{ width: "100px" }}
        onClick={() => onHideCheckCompleted()}
      >
        Cancel
      </Button>{" "}
      <Button
        variant="success"
        style={{ width: "100px" }}
        onClick={() => onSaveCheckResult()}
      >
        Confirm
      </Button>
    </React.Fragment>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default CompleteCheckDesktop;
