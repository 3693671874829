import React from "react";
import { Col, Container, Row, Form } from "react-bootstrap";

const MedicationDestruction = ({
  onChangeInput,
  lic622State,
  complianceFormsState,
}) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  function getRow(i) {
    const medicationNameDest = "medicationNameDest" + i
    const strengthDest = "strengthDest" + i
    const dateFilledDest = "dateFilledDest" + i
    const prescriptionNumberDest = "prescriptionNumberDest" + i
    const disposalDateDest = "disposalDateDest" + i
    const nameOfPharmacyDest = "nameOfPharmacyDest" + i
    return (
        <Row style={{marginTop: "2px"}}>
          <Col md={3}>
            <Form.Control
              name={medicationNameDest}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(medicationNameDest, e)}
              value={lic622State && lic622State.[medicationNameDest]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={strengthDest}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(strengthDest, e)}
              value={lic622State && lic622State.[strengthDest]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={dateFilledDest}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(dateFilledDest, e)}
              value={lic622State && lic622State.[dateFilledDest]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={3}>
            <Form.Control
              name={prescriptionNumberDest}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(prescriptionNumberDest, e)}
              value={lic622State && lic622State.[prescriptionNumberDest]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={1}>
            <Form.Control
              name={disposalDateDest}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(disposalDateDest, e)}
              value={lic622State && lic622State.[disposalDateDest]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={3}>
            <Form.Control
              name={nameOfPharmacyDest}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput(nameOfPharmacyDest, e)}
              value={lic622State && lic622State.[nameOfPharmacyDest]}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
    )}
  return (
    <Container fluid style={{marginBottom: "60px"}}>
      <Row>
        <Col md={3}>
          <b>
            <small>MEDICATION NAME</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>STRENGTH / QUANTITY</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>DATE FILLED</small>
          </b>
        </Col>
        <Col md={3}>
          <b>
            <small>PRESCRIPTION NUMBER</small>
          </b>
        </Col>
        <Col md={1}>
          <b>
            <small>DISPOSAL DATE</small>
          </b>
        </Col>
        <Col md={3}>
          <b>
            <small>NAME OF PHARMACY</small>
          </b>
        </Col>
      </Row>
      {[...Array(8)].map((e, i) => getRow(i))}
    </Container>
  );
};

export default MedicationDestruction;
