import APIClient from "../../apiClient";

const BASE_TRAINING = "/training";

class TrainingClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_TRAINING + "/" + id
    );
  }

  async insert(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_TRAINING,
      repo
    );
  }

  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_TRAINING
    );
  }

  async getById(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_TRAINING + "/" + id
    );
  }

  staffTrainingByStaffId;
}

export default TrainingClient;
