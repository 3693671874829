import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const ViewTraining = ({ trainingState }) => {
  function openLink(url) {
    window.open(url, "_blank");
  }

  return (
    <div
      id=""
      style={{
        overflow: "scroll",
        overflowX: "hidden",
        height: "20vh",
      }}
    >
      <Container>
        {trainingState &&
          trainingState.currTraining &&
          trainingState.currTraining.trainingDetails &&
          trainingState.currTraining.trainingDetails.map((trnDtl) => (
            <Row>
              <Col>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => openLink(trnDtl.url)}
                >
                  {trnDtl.url}
                </Button>
              </Col>
            </Row>
          ))}
      </Container>
    </div>
  );
};

export default ViewTraining;
