import React, { useState, useRef } from "react";
import { Form, Button, Table, InputGroup } from "react-bootstrap";
import { BsTrash, BsXSqua, BsXSquare } from "react-icons/bs";
import { TiPlus } from "react-icons/ti";
import JoditEditor from "jodit-react";

const CreateTrainingDesktop = ({
  onSaveTraining,
  onRemoveTrainingUrl,
  onChangeTraining,
  onChangeTrainingUrl,
  onAddTrainingUrl,
  onHideCreateTraining,
  trainingState,
}) => {
  function openLink(url) {
    window.open(url, "_blank");
  }
  return (
    <React.Fragment>
      <Form>
        <Form.Row>
          <Form.Group>
            <Form.Control
              placeholder="Training title*"
              name="name"
              value={
                trainingState.currTraining && trainingState.currTraining.name
              }
              onChange={(e) => onChangeTraining(e)}
              required
              style={{ width: "400px" }}
              autoComplete="off"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Description"
              name="description"
              value={
                trainingState.currTraining &&
                trainingState.currTraining.description
              }
              onChange={(e) => onChangeTraining(e)}
              style={{ width: "400px" }}
              autoComplete="off"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Link for the training"
                name="url"
                value={trainingState.currUrl}
                onChange={(e) => onChangeTrainingUrl(e)}
                required
                style={{ width: "370px" }}
                autoComplete="off"
              />
              <InputGroup.Append>
                <Button variant="success" onClick={() => onAddTrainingUrl()}>
                  <TiPlus
                    style={{
                      color: "white",
                      width: "25px",
                      height: "25px",
                      verticalAlign: "middle",
                    }}
                  />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <div
          id=""
          style={{
            overflow: "scroll",
            overflowX: "hidden",
            // height: "300px",
            height: "20vh",
          }}
        >
          <Table>
            <tbody>
              {trainingState.currTraining != undefined &&
                trainingState.currTraining.trainingDetails != undefined &&
                trainingState.currTraining.trainingDetails
                  .sort((trnA, trnB) => {
                    if (trnA.id > trnB.id) {
                      return -1;
                    } else {
                      return 1;
                    }
                  })
                  .map((trnDtl) => {
                    console.log("trnDtl", trnDtl);
                    return (
                      <tr>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => openLink(trnDtl.url)}
                          >
                            {trnDtl.url}
                          </Button>
                          {/* <a href={trnDtl.url}>{trnDtl.url}</a> */}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <Button
                            variant="danger"
                            style={{
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                            }}
                            onClick={() => onRemoveTrainingUrl(trnDtl)}
                          >
                            <BsTrash
                              style={{
                                color: "#ff4444",
                                width: "25px",
                                height: "25px",
                              }}
                            />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </div>
        <Form.Row>
          <Form.Group>
            <Button
              variant="outline-success"
              style={{ width: "100px" }}
              onClick={() => onHideCreateTraining()}
            >
              Cancel
            </Button>{" "}
            <Button
              style={{ width: "100px" }}
              variant="success"
              onClick={() => onSaveTraining()}
            >
              Save
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    </React.Fragment>
  );
};

export default CreateTrainingDesktop;
