import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Repeated from "../../../common/repeated";

const AddTodoDesktop = ({
  onAddTodo,
  onChange,
  onChangeDates,
  onClose,
  todo,
  isError,
  errorMsg,
}) => {
  return (
    <React.Fragment>
      <Form>
        <Form.Row>
          {isError ? (
            <div style={errorMsgStyle}>
              <b>ERROR:</b> {errorMsg}
            </div>
          ) : (
            ""
          )}
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formTitle">
            <Form.Control
              name="title"
              placeholder="Title*"
              onChange={(e) => onChange(e)}
              size="sm"
              required
              value={todo ? todo.title : ""}
              autoComplete="off"
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formDescription">
          <Form.Control
            name="description"
            as="textarea"
            rows="3"
            size="sm"
            style={{ width: "100%" }}
            onChange={(e) => onChange(e)}
            placeholder="Description"
            value={todo ? todo.description : ""}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group controlId="formGridAddress1">
          <DatePicker
            name="startDate"
            selected={todo ? todo.startDate : ""}
            placeholderText="Date & Time*"
            onChange={(e) => onChangeDates(e, "startDate")}
            showTimeSelect
            timeIntervals={15}
            minDate={new Date()}
            dateFormat="MM/dd/yyyy h:mm aa"
            disabledKeyboardNavigation
            autoComplete="off"
          />
        </Form.Group>
        <Form.Row>
          <Col>
            <Form.Group id="formGridCheckbox">
              <Form.Check
                name="repeated"
                type="checkbox"
                label="Repeated reminder"
                onChange={(e) => onChange(e, true)}
                defaultChecked={todo ? todo.repeated : false}
              />
            </Form.Group>{" "}
          </Col>
          <Col className="text-right">
            <div style={{ display: todo && todo.repeated == 1 ? "" : "none" }}>
              <DatePicker
                name="endDate"
                selected={todo ? todo.endDate : ""}
                placeholderText="End Date"
                onChange={(e) => onChangeDates(e, "endDate")}
                timeIntervals={15}
                minDate={new Date()}
                dateFormat="MM/dd/yyyy"
                disabledKeyboardNavigation
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
              />
            </div>
          </Col>
        </Form.Row>
        {todo.repeated ? (
          <Repeated
            onChange={onChange}
            repeatedCategoryId={todo ? todo.repeatedCategoryId : 0}
            repeatedCategoryValue={todo.repeatedCategoryValue}
            repeatedCategoryTypeId={todo.repeatedCategoryTypeId}
          />
        ) : (
          ""
        )}
        <Button
          variant="outline-success"
          style={{
            width: "100px",
          }}
          onClick={onClose}
        >
          Close
        </Button>
        {"   "}
        <Button
          variant="success"
          style={{ width: "100px" }}
          onClick={() => onAddTodo()}
        >
          Save
        </Button>
      </Form>
    </React.Fragment>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default AddTodoDesktop;
