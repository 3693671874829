import { useState } from "react";
import ResidentService from "../../../../services/compliance/resident/residentService";
import ResidentDoctorService from "../../../../services/compliance/resident/residentDoctorService";
import ResidentMedHistoryService from "../../../../services/compliance/resident/residentMedHistoryService";
import ResidentFormService from "../../../../services/compliance/documents/residentFormService";
import ResidentFileMetadataService from "../../../../services/compliance/documents/residentFileMetadataService";
import TodoService from "../../../../services/todo/todoService";
import UtilityService from "../../../../services/utilityService";
import StaffService from "../../../../services/user/staffService";

const useResidentState = () => {
  const [residentState, setResidentState] = useState({
    residents: [],
    currResident: { isActive: true },
    errorField: "",
    errorMsg: "",
    showResidentEditGeneralInfo: false,
    // showResidentView: "GEN",
    showResidentDashboard: false,
    showResident: false,
    forms: [],
    defaultEndDate: new Date(),
    residentDoctors: [],
    currResidentDoctor: {},
    medicalHistory: [],
    currView: "legal",
    isChangeMode: false,
    showResidentEdit: false,
    currTodo: {
      repeated: false,
      repeatedCategoryId: 0,
    },
    showGuardian: false,
    currUser: { isDisabled: false, userRole: "F" },
    isChangePassword: true,
  });

  const onOpenResident = (resident) => {
    console.log("openResident", resident);
    onEditResident(resident);
    // const residentDoctors =
    //   await ResidentDoctorService.getResidentDoctorByResidentId(resident.id);
    // const residentMedicationHistory =
    //   await ResidentMedHistoryService.getResidentMedHistoryByResidentId(
    //     resident.id
    //   );

    // const forms = await ResidentFormService.getByResident(resident.id);
    // // const forms = resident.residentFormList;

    // setResidentState({
    //   ...residentState,
    //   currResident: resident,
    //   forms: forms,
    //   residentDoctors: residentDoctors,
    //   currMedHistories: residentMedicationHistory,
    //   showResidentDashboard: true,
    // });
  };

  const onNewResident = () => {
    setResidentState({
      ...residentState,
      residentDoctors: [],
      currResidentDoctor: {},
      showResident: true,
      currResident: {
        admissionDate: new Date(),
      },
    });
  };

  const onHideResident = () => {
    setResidentState({
      ...residentState,
      currResident: {},
      residentDoctors: [],
      showResident: false,
      errorField: "",
      errorMsg: "",
    });
  };

  const onSaveResident = async () => {
    if (!validateResident(residentState.currResident)) {
      return;
    }
    const savedResident = await ResidentService.save(
      residentState.currResident
    );
    const newResidents =
      residentState.residents != undefined && residentState.residents.length > 0
        ? residentState.residents
            .filter((res) => res.id != savedResident.id)
            .concat(formatResident(savedResident))
        : [].concat(formatResident(savedResident));
    setResidentState({
      ...residentState,
      residents: newResidents,
      currResident: {},
      residentDoctors: [],
      showResident: false,
      errorField: "",
      errorMsg: "",
      isChangeMode: false,
    });
  };

  // Not used
  const onRemoveResident = async (resident) => {
    await ResidentService.delete(resident.id);
    setResidents(
      residentState.resident != undefined && residentState.residents.length > 0
        ? residentState.residents.filter((res) => res.id != resident.id)
        : []
    );
  };

  const onGetAllResidents = async () => {
    const residents = await ResidentService.getAll();
    setResidents(residents);
  };

  const onChangeResident = (e) => {
    const value = e.target.value;
    var res = { ...residentState.currResident };
    res[e.target.name] = value;
    setCurrResident(res);
  };

  const onChangeResidentDates = (value, fieldName) => {
    var res = { ...residentState.currResident };
    if (fieldName == "admissionDate") {
      res["admissionDate"] = value;
    } else {
      res["dob"] = value;
    }
    setCurrResident(res);
  };

  const onBackDashboard = () => {
    setResidentState({
      ...residentState,
      showResidentDashboard: false,
      showResidentEditGeneralInfo: false,
      showResidentForm: false,
      currResident: {},
      currForm: {},
      isChangeMode: false,
      currView: "legal",
    });
  };

  const onAddResidentDoctor = async () => {
    const resDoc = { ...residentState.currResidentDoctor };
    resDoc["residentId"] = residentState.currResident.id;
    const validatedResDoc = ResidentDoctorService.validateResDoctor(resDoc);
    if (validatedResDoc.isError) {
      setResidentState({
        ...residentState,
        isErrorResDoc: true,
        errorMsg: validatedResDoc.errMsg,
      });
      return;
    }
    const savedResidentDoctor = await ResidentDoctorService.save(resDoc);

    setResidentState({
      ...residentState,
      residentDoctors: residentState.residentDoctors
        ? [...residentState.residentDoctors].concat(savedResidentDoctor)
        : [].concat(savedResidentDoctor),
      currResidentDoctor: { name: "", phone: "", email: "" },
    });
  };

  const onOpenResidentForm = async (residentForm) => {
    // const currFiles = await FileService.getAllByFormId(form.id);
    const currFiles = await ResidentFileMetadataService.getByResidentFormId(
      residentForm.id
    );
    setResidentState({
      ...residentState,
      showResidentForm: true,
      currForm: residentForm,
      currFiles: currFiles,
    });
  };

  // const onDeleteResidentForm = async (residentForm) => {
  //   // const currFiles = await FileService.getAllByFormId(form.id);
  //   const currFiles = await FileService.getByResidentFormId(residentForm.id);
  //   setResidentState({
  //     ...residentState,
  //     showResidentForm: true,
  //     currForm: residentForm,
  //     currFiles: currFiles,
  //   });
  // };

  const onChangeDoc = (event) => {
    const value = event.target.value;
    var resDoc = { ...residentState.currResidentDoctor };
    resDoc[event.target.name] = value;
    console.log("resDoc", resDoc);
    setResidentState({
      ...residentState,
      currResidentDoctor: resDoc,
      isErrorResDoc: false,
      errorMsg: "",
    });
  };

  const onDeleteResidentDoctor = async (e) => {
    const id = e.target.value;
    console.log("e", id);
    await ResidentDoctorService.delete(id);
    setResidentState({
      ...residentState,
      residentDoctors: residentState.residentDoctors.filter(
        (resDoc) => resDoc.id != id
      ),
    });
  };

  const onCloseResidentForm = () => {
    setResidentState({
      ...residentState,
      showResidentForm: false,
      currForm: {},
      currFiles: [],
      isErrorResDoc: false,
      errorMsg: "",
    });
  };

  const onShowAddTodo = (form) => {
    var todayDate = new Date();
    var year = todayDate.getFullYear();
    var month = todayDate.getMonth();
    var day = todayDate.getDate();
    var defaultEndDate = new Date(year + 10, month, day);
    setResidentState({
      ...residentState,
      showTodo: true,
      currTodo: {
        residentId: residentState.currResident.id,
        repeated: form.repeated ? form.repeated : false,
        formId: form.id,
        repeatedCategoryId: form.repeatedCategory,
        endDate: form.repeated ? defaultEndDate : undefined,
        title: "Prepare report",
        description:
          "Prepare " +
          form.name +
          " for " +
          residentState.currResident.firstName,
      },
    });
  };

  const onAddTodo = async () => {
    const validatedTodo = TodoService.validateTodo(residentState.currTodo);
    if (!validatedTodo.isValid) {
      if (validatedTodo.isError) {
        setResidentState({
          ...residentState,
          isError: true,
          errorMsg: validatedTodo.msg,
        });

        return;
      }
    }
    await TodoService.save(residentState.currTodo);
    const forms = await ResidentFormService.getByResident(
      residentState.currTodo.residentId
    );
    setResidentState({
      ...residentState,
      showTodo: false,
      forms: forms,
      currForm: forms.filter((form) => form.id == residentState.currForm.id)[0],
    });
  };

  const onCloseTodo = () => {
    setResidentState({ ...residentState, showTodo: false });
  };

  const onSetFile = async (e) => {
    const files = Array.from(e.target.files);
    console.log("files", files);

    let formData = new FormData();

    formData.append("name", files[0].name);
    formData.append(
      "residentFormId",
      residentState.currForm && residentState.currForm.id
    );
    // formData.append(
    //   "residentId",
    //   residentState.currResident && residentState.currResident.id
    // );
    formData.append("file", files[0]);

    const ifFileExists = await ResidentFileMetadataService.ifFileExists(
      formData
    );

    if (ifFileExists) {
      alert("File " + files[0].name + " already exists!");
      return;
    }

    ResidentFileMetadataService.upload(formData)
      .then((formMetadata) => {
        console.log("files-after-save", formMetadata);
        setResidentState({
          ...residentState,
          currFiles:
            residentState.currFiles != undefined
              ? [...residentState.currFiles].concat(formMetadata)
              : [].concat(formMetadata),
          currFile: {},
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onDownload = (files) => {
    ResidentFileMetadataService.getById(files.id).then((file) => {
      ResidentFileMetadataService.download(files.id, file.data.path);
      ResidentFileMetadataService.notify(files.id);
    });
  };

  const onDeleteFiles = async (files) => {
    await ResidentFileMetadataService.delete(files.id);
    setResidentState({
      ...residentState,
      currFiles: [...residentState.currFiles].filter(
        (currFile) => currFile.id != files.id
      ),
    });
  };

  const onShowAddMedRecord = () => {
    setResidentState({
      ...residentState,
      showAddMedRecord: true,
      currMedRecord: { type: "d" },
    });
  };

  const onAddMedicalRecord = async (resident, buttonType) => {
    console.log("addRecord", resident);
    if (buttonType == "S") {
      const validatedResidentMedicalRecord = validateResidentMedicalRecord(
        residentState.currMedRecord
      );
      if (validatedResidentMedicalRecord.isError) {
        setResidentState({
          ...residentState,
          isError: true,
          errorMsg: validatedResidentMedicalRecord.errorMsg,
        });
        return;
      }
      console.log("currMedHistory", residentState.currMedRecord);
      const savedMedHistory = await ResidentMedHistoryService.save({
        ...residentState.currMedRecord,
        residentId: resident.id,
        type:
          residentState.currMedRecord.type == undefined
            ? "d"
            : residentState.currMedRecord.type,
      });
      setResidentState({
        ...residentState,
        currMedHistories:
          residentState.currMedHistories != undefined
            ? [...residentState.currMedHistories].concat(savedMedHistory)
            : [].concat(savedMedHistory),
        showAddMedRecord: false,
        currMedRecord: {},
      });
    } else {
      setResidentState({ ...residentState, showAddMedRecord: false });
    }
  };

  const onDeleteMedicalRecord = async (medicalHistory) => {
    await ResidentMedHistoryService.delete(medicalHistory.id);
    setResidentState({
      ...residentState,
      currMedHistories: residentState.currMedHistories.filter(
        (medHistory) => medHistory.id != medicalHistory.id
      ),
      showAddMedRecord: false,
      currMedRecord: {},
    });
  };

  const onChangeMedHistory = (event) => {
    const value = event.target.value;
    var medHistory = { ...residentState.currMedRecord };
    medHistory[event.target.name] = value;
    setResidentState({
      ...residentState,
      isError: false,
      errorField: "",
      errorMsg: "",
      currMedRecord: medHistory,
    });
  };

  const onChangeMedRecordDate = (event) => {
    const value = event;
    var medHistory = { ...residentState.currMedRecord };
    medHistory["date"] = value;
    setResidentState({
      ...residentState,
      isError: false,
      errorField: "",
      errorMsg: "",
      currMedRecord: medHistory,
    });
  };

  const onOpenResidentView = (selectedKey) => {
    setResidentState({
      ...residentState,
      currView: selectedKey,
    });
  };

  const onSaveLegalRepInfo = async () => {
    const savedResident = await ResidentService.save(
      residentState.currResident
    );
    const newResidents =
      residentState.residents != undefined && residentState.residents.length > 0
        ? residentState.residents
            .filter((res) => res.id != savedResident.id)
            .concat(formatResident(savedResident))
        : [].concat(formatResident(savedResident));
    setResidentState({
      ...residentState,
      residents: newResidents,
      currResident: savedResident,
      errorField: "",
      errorMsg: "",
      isChangeMode: false,
    });
  };

  const onChangeTodo = (e, isCheckBox) => {
    let value = e.target.value;
    if (isCheckBox) {
      value = e.target.checked;
    }
    const todo = { ...residentState.currTodo };
    todo[e.target.name] = value;

    setResidentState({ ...residentState, currTodo: todo });
  };

  const onEditResident = (resident) => {
    setResidentState({
      ...residentState,
      currResident: resident,
      showResident: true,
    });
  };

  const onSaveEditResident = async () => {
    if (!validateResident(residentState.currResident)) {
      return;
    }
    const savedResident = await ResidentService.save(
      residentState.currResident
    );
    const newResidents =
      residentState.residents != undefined && residentState.residents.length > 0
        ? residentState.residents
            .filter((res) => res.id != savedResident.id)
            .concat(formatResident(savedResident))
        : [].concat(formatResident(savedResident));
    setResidentState({
      ...residentState,
      residents: newResidents,
      currResident: formatResident(savedResident),
      showResidentEdit: false,
      errorField: "",
      errorMsg: "",
    });
  };

  const onHideEditResident = () => {
    setResidentState({ ...residentState, showResidentEdit: false });
  };

  const onChangeTodoDates = (value, fieldName) => {
    const todo = { ...residentState.currTodo };
    if (fieldName == "startDate") {
      todo["startDate"] = value;
    } else if (fieldName == "endDate") {
      todo["endDate"] = value;
    }
    setResidentState({ ...residentState, currTodo: todo });
  };

  const onShowForm = () => {
    setResidentState({ ...residentState, showAddForm: true });
  };

  const onHideForm = () => {
    setResidentState({ ...residentState, showAddForm: false, currForm: {} });
  };

  const onChangeForm = (e) => {
    const value = e.target.value;
    var currForm = { ...residentState.currForm };
    currForm[e.target.name] = value;
    currForm["link"] = "";
    currForm["isRepeated"] = false;
    currForm["repeatedCategory"] = "1";
    setResidentState({
      ...residentState,
      currForm: currForm,
      isAddFormError: false,
      errorMsg: "",
    });
  };

  const onSaveForm = async () => {
    const validatedForm = ResidentFormService.validateForm(
      residentState.currForm
    );
    if (validatedForm.isError) {
      setResidentState({
        ...residentState,
        isAddFormError: true,
        errorMsg: validatedForm.errMsg,
      });
      return;
    }
    const savedForm = await ResidentFormService.save({
      ...residentState.currForm,
      residentId: residentState.currResident.id,
    });
    setResidentState({
      ...residentState,
      forms:
        residentState.forms != undefined
          ? residentState.forms.concat(savedForm)
          : [].concat(savedForm),
      showAddForm: false,
      currForm: {},
    });
  };

  const onExitLegRepPhone = (e) => {
    const phone = e.target.value;
    const formattedPhone = UtilityService.formatPhoneNumber(phone);
    const currResident = { ...residentState.currResident };
    currResident[e.target.name] = formattedPhone;
    setResidentState({ ...residentState, currResident: currResident });
  };

  const onExitDoctorPhone = (e) => {
    const phone = e.target.value;
    const formattedPhone = UtilityService.formatPhoneNumber(phone);
    const currResidentDoctor = { ...residentState.currResidentDoctor };
    currResidentDoctor[e.target.name] = formattedPhone;
    setResidentState({
      ...residentState,
      currResidentDoctor: currResidentDoctor,
    });
  };

  const onShowShareReport = async (form) => {
    const users = await StaffService.getAll();
    const allForms = await ResidentFormService.getByResident(
      residentState.currResident.id
    );
    setResidentState({
      ...residentState,
      guardians: users.filter((user) => user.userRole == "F"),
      currForm: allForms.filter((frm) => frm.id == form.id)[0],
      showShareReport: true,
    });
  };

  const onHideShareReport = () => {
    setResidentState({
      ...residentState,
      currForm: {},
      showShareReport: false,
    });
  };

  const onChangeGuardian = async (e) => {
    const formId = residentState.currForm.id;
    if (!e.target.checked) {
      await onDeleteGuardianShare({ id: e.target.id });
    } else {
      const staffId = e.target.id;

      if (
        residentState.currForm.staff.filter((stf) => stf.id == staffId).length >
        0
      ) {
        return;
      }
      await ResidentFormService.saveShares({
        residentFormId: formId,
        staffId: staffId,
      });
      alert(
        "Folder " + residentState.currForm.name + " is shared successfully!"
      );
    }
    const allForms = await ResidentFormService.getByResident(
      residentState.currResident.id
    );
    setResidentState({
      ...residentState,
      currForm: allForms.filter((frm) => frm.id == formId)[0],
    });
  };

  const onSelectFormMenu = async (selectedKey, residentForm) => {
    console.log("selectedKey", selectedKey);
    console.log("residentForm", residentForm);
    if (selectedKey == "share") {
      await onShowShareReport(residentForm);
    } else if (selectedKey == "delete") {
      console.log("onDeleteForm", residentForm);
      await onDeleteForm(residentForm);
    }
  };

  const onDeleteForm = async (residentForm) => {
    await ResidentFormService.delete(residentForm.id);
    await onOpenResident(residentState.currResident);
  };

  const onDeleteGuardianShare = async (staff) => {
    await ResidentFormService.deleteShares({
      residentFormId: residentState.currForm.id,
      staffId: staff.id,
    });
    const allForms = await ResidentFormService.getByResident(
      residentState.currResident.id
    );
    setResidentState({
      ...residentState,
      currForm: allForms.filter(
        (frm) => frm.id == residentState.currForm.id
      )[0],
    });
  };

  const formatList = (forms) => {
    let sortedForms = forms.sort((resFormA, resFromB) => {
      if (resFormA.id > resFromB.id) {
        return 1;
      } else {
        return -1;
      }
    });

    var reportFormMap = {};

    var i,
      j,
      k,
      temparray,
      chunk = 3;
    for (i = 0, j = sortedForms.length, k = 1; i < j; i += chunk, k++) {
      temparray = sortedForms.slice(i, i + chunk);
      if (temparray.length < chunk) {
        var times = chunk - temparray.length;
        for (var n = 0; n < times; n++) {
          temparray.push({});
        }
      }
      reportFormMap[k] = temparray;
      // do whatever
    }

    console.log("reportFormMap", reportFormMap);

    return reportFormMap;
  };

  const onShowSignFile = async (currFile) => {
    const users = await StaffService.getAll();

    setResidentState({
      ...residentState,
      guardians: users.filter((user) => user.userRole == "F"),
      currFile: currFile,
      showSignFile: true,
    });
  };

  const onHideSignFile = () => {
    setResidentState({ ...residentState, showSignFile: false });
  };

  const onSaveSignFile = async () => {
    let formData = new FormData();
    formData.append("staffId", residentState && residentState.currStaffId);
    formData.append(
      "residentFileMetadataID",
      residentState.currFile && residentState.currFile.id
    );

    await ResidentFileMetadataService.registerForSigning(formData);
    alert("Your request has been sent successfully!");
    setResidentState({ ...residentState, showSignFile: false });
  };

  const onSelectGuardianForSignature = (e) => {
    // alert(e.target.id);
    // if (e.target.value == "-1") {
    //   setResidentState({ ...residentState, showGuardian: true });
    //   return;
    // }

    setResidentState({ ...residentState, currStaffId: e.target.id });
  };

  const onShowGuardian = () => {
    setResidentState({ ...residentState, showGuardian: true });
  };

  const onHideGuardian = () => {
    setResidentState({ ...residentState, showGuardian: false });
  };

  const onSelectResidentMenu = async (selectedKey, resident) => {
    if (selectedKey == "deactivate") {
      if (
        window.confirm(
          "Are you sure you want to deactivate resident " +
            resident.firstName +
            " " +
            resident.lastName +
            "?"
        )
      ) {
        await ResidentService.deactivateResident(resident.id);
        const residents = await ResidentService.getAll();
        setResidents(residents);
      }
    } else if (selectedKey == "delete") {
      if (
        window.confirm(
          "Are you sure you want to delete resident " +
            resident.firstName +
            " " +
            resident.lastName +
            "?"
        )
      ) {
        // alert("delete");
        await ResidentService.delete(resident.id);
        const residents = await ResidentService.getAll();
        setResidents(residents);
      }
    } else if (selectedKey == "open") {
      onOpenResident(resident);
    }
  };

  const onShowDeactivated = (e) => {
    setResidentState({ ...residentState, showDeactivated: e.target.checked });
  };

  const onSaveGuardian = async () => {
    let user = residentState.currUser;
    const validation = await StaffService.validateUser(user);
    if (validation.isError) {
      setResidentState({
        ...residentState,
        isError: true,
        errorMsg: validation.msg,
      });
      return;
    }

    if (residentState.isChangePassword) {
      const validatedPassword = StaffService.validatePassword(user);
      if (validatedPassword.isError) {
        setResidentState({
          ...residentState,
          isError: true,
          errorMsg: validatedPassword.msg,
        });
        return;
      }
    }
    if (user.userRole == undefined || user.userRole == "") {
      user.userRole = "E";
    }

    if (user.id == undefined) {
      user.isFirstLogIn = true;
    }
    await StaffService.save(user);

    const users = await StaffService.getAll();

    setResidentState({
      ...residentState,
      guardians: users.filter((user) => user.userRole == "F"),
      showGuardian: false,
    });
  };

  const onChangeGuardianField = (event) => {
    let staff = residentState.currUser;
    const value = event.target.value;
    console.log("value", value);
    staff[event.target.name] = value;

    setResidentState({ ...residentState, currUser: staff, isError: false });
  };

  const onChangeGuardianDates = (value, fieldName) => {
    let staff = residentState.currUser;

    if (fieldName == "startDate") {
      staff["startDate"] = value;
    }

    setResidentState({ ...residentState, currUser: staff, isError: false });
  };

  const onChangePassword = (event) => {
    setResidentState({
      ...residentState,
      isChangePassword: event.target.checked,
      isError: false,
    });
  };
  const onToggleChanged = (isEnabled) => {
    let staff = residentState.currUser;
    staff["isDisabled"] = !isEnabled;

    setResidentState({ ...residentState, currUser: staff, isError: false });
  };

  const onShowPassword = (inputField) => {
    setResidentState({ ...residentState, showPassword: inputField });
  };

  const onHidePassword = () => {
    setResidentState({ ...residentState, showPassword: "" });
  };

  function validateResidentMedicalRecord(medRecord) {
    if (
      medRecord == undefined ||
      medRecord.type == undefined ||
      medRecord.type.length == 0
    ) {
      return {
        isError: true,
        errorMsg: "Please select type!",
      };
    }

    if (
      medRecord == undefined ||
      medRecord.description == undefined ||
      medRecord.description.length == 0
    ) {
      // alert("Please enter description!");
      // return false;
      return {
        isError: true,
        errorMsg: "Please enter description!",
      };
    }

    if (
      medRecord == undefined ||
      medRecord.date == undefined ||
      medRecord.date.length == 0
    ) {
      // alert("Please select date!");
      // return false;
      return {
        isError: true,
        errorMsg: "Please select date!",
      };
    }

    return {
      isError: false,
    };
  }

  function setCurrResident(resident) {
    setResidentState({
      ...residentState,
      currResident: formatResident(resident),
      errorField: "",
      errorMsg: "",
      isChangeMode: true,
    });
  }

  function validateResident(resident) {
    if (resident.firstName == undefined || resident.firstName.length == 0) {
      setError("firstName", "Please enter resident first name!");
      return false;
    }

    if (resident.lastName == undefined || resident.lastName.length == 0) {
      setError("lastName", "Please enter resident last name!");
      return false;
    }

    if (resident.dob == undefined || resident.dob.length == 0) {
      setError("dob", "Please enter resident date of birth!");
      return false;
    }

    if (resident.sex == undefined || resident.sex.length == 0) {
      setError("sex", "Please enter resident sex!");
      return false;
    }

    if (
      resident.admissionDate == undefined ||
      resident.admissionDate.length == 0
    ) {
      setError("admissionDate", "Please enter resident admission date!");
      return false;
    }

    return true;
  }

  function setError(fieldName, errorMessage) {
    setResidentState({
      ...residentState,
      errorField: fieldName,
      errorMsg: errorMessage,
    });
  }

  function setResidents(residents) {
    setResidentState({
      ...residentState,
      residents:
        residents &&
        residents
          .sort((r1, r2) => {
            if (r1.firstName.toLowerCase() > r2.firstName.toLowerCase())
              return 1;
            else return -1;
          })
          .map((res) => {
            return formatResident(res);
          }),
    });
  }

  function formatResident(res) {
    console.log("formatResident", res);
    const resNew = {
      ...res,
      dob: res.dob != undefined ? new Date(res.dob) : null,
      admissionDate:
        res.admissionDate != undefined ? new Date(res.admissionDate) : null,
    };
    console.log("resNew", resNew);
    return resNew;
  }

  return {
    onChangeResident,
    onChangeResidentDates,
    onChangeDoc,
    onOpenResident,
    onNewResident,
    onHideResident,
    onSaveResident,
    onRemoveResident,
    onGetAllResidents,
    onBackDashboard,
    onAddResidentDoctor,
    onOpenResidentForm,
    onDeleteResidentDoctor,
    onCloseResidentForm,
    onShowAddTodo,
    onAddTodo,
    onCloseTodo,
    onSetFile,
    onDownload,
    onDeleteFiles,
    onShowAddMedRecord,
    onAddMedicalRecord,
    onChangeMedHistory,
    onChangeMedRecordDate,
    onOpenResidentView,
    onSaveLegalRepInfo,
    onChangeTodo,
    onChangeTodoDates,
    onEditResident,
    onSaveEditResident,
    onHideEditResident,
    onShowForm,
    onHideForm,
    onChangeForm,
    onSaveForm,
    onExitLegRepPhone,
    onExitDoctorPhone,
    onDeleteMedicalRecord,
    onShowShareReport,
    onHideShareReport,
    onChangeGuardian,
    onSelectFormMenu,
    onDeleteGuardianShare,
    formatList,
    onShowSignFile,
    onHideSignFile,
    onSaveSignFile,
    onSelectGuardianForSignature,
    onSelectResidentMenu,
    onShowDeactivated,
    onHideGuardian,
    onShowGuardian,
    onSaveGuardian,
    onChangeGuardianField,
    onChangeGuardianDates,
    onChangePassword,
    onToggleChanged,
    onShowPassword,
    onHidePassword,
    residentState,
  };
};

export default useResidentState;
