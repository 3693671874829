import { useState } from "react";
import StaffService from "../../../../services/user/staffService";
import StaffTrainingService from "../../../../services/compliance/training/staffTrainingService";

const useMyTrainingState = () => {
  const [myTrainingState, setMyTrainingState] = useState({
    staffTrainingView: "active",
  });

  const refreshPage = async () => {
    console.log(
      "authenticatedUser",
      sessionStorage.getItem("authenticatedUser")
    );
    const currEmployee = await StaffService.getByUserName(
      sessionStorage.getItem("authenticatedUser")
    );

    const currEmployeeTrainings = await StaffTrainingService.getByStaffId(
      currEmployee[0].id
    );

    setMyTrainingState({
      ...myTrainingState,
      selectedStaffTrainings: currEmployeeTrainings,
    });

    // StaffService.getByUserName(
    //   sessionStorage.getItem("authenticatedUser")
    // ).then((staffUser) => {
    //   console.log("staffUser", staffUser);
    //   StaffTrainingService.getByStaffId(staffUser[0].id).then((data) =>
    //     setSelectedStaffTrainings(data)
    //   );
    // });
  };

  const onDeleteStaffTraining = () => {
    alert(
      "Please contact the facility administrator if you need to remove the training!"
    );
  };

  const onPrintCertificate = (stfTrn) => {
    StaffTrainingService.printCertificate(stfTrn);
  };

  const onMarkAsPassed = (stfTrn) => {
    setMyTrainingState({
      ...myTrainingState,
      showMarkAsPassed: true,
      markAsPassedDetails: {},
      currentStaffTraining: stfTrn,
    });
    // setShowMarkAsPassed(true);
    // setMarkAsPassedDetails({});
    // setCurrentStaffTraining(stfTrn);
  };

  const onChangePassDate = (date) => {
    setMyTrainingState({
      ...myTrainingState,
      markAsPassedDetails: {
        ...myTrainingState.markAsPassedDetails,
        passDate: date,
      },
    });
    // setMarkAsPassedDetails({ ...markAsPassedDetails, passDate: date });
  };

  const onChangeStartDate = (date) => {
    setMyTrainingState({
      ...myTrainingState,
      markAsPassedDetails: {
        ...myTrainingState.markAsPassedDetails,
        startDate: date,
      },
    });
    // setMarkAsPassedDetails({ ...markAsPassedDetails, startDate: date });
  };

  const onChangeHours = (event) => {
    setMyTrainingState({
      ...myTrainingState,
      markAsPassedDetails: {
        ...myTrainingState.markAsPassedDetails,
        durationHours: event.target.value,
      },
    });
    // setMarkAsPassedDetails({
    //   ...markAsPassedDetails,
    //   durationHours: event.target.value,
    // });
  };

  const onHideMarkAsPassed = () => {
    setMyTrainingState({
      ...myTrainingState,
      showMarkAsPassed: false,
      markAsPassedDetails: {},
    });
    // setShowMarkAsPassed(false);
    // setMarkAsPassedDetails({});
  };

  const onSaveMarkAsPassed = async () => {
    const passedTraining = await StaffTrainingService.markAsPassed({
      id: myTrainingState.currentStaffTraining.id,
      passDate: myTrainingState.markAsPassedDetails.passDate,
      startDate: myTrainingState.markAsPassedDetails.startDate,
      durationHours: myTrainingState.markAsPassedDetails.durationHours,
    });

    setMyTrainingState({
      ...myTrainingState,
      showMarkAsPassed: false,
      selectedStaffTrainings:
        myTrainingState.selectedStaffTrainings != undefined
          ? myTrainingState.selectedStaffTrainings
              .filter((stfTrn) => stfTrn.id != passedTraining.id)
              .concat(passedTraining)
          : [].concat(passedTraining),
    });

    // StaffTrainingService.markAsPassed({
    //   id: currentStaffTraining.id,
    //   passDate: markAsPassedDetails.passDate,
    //   startDate: markAsPassedDetails.startDate,
    //   durationHours: markAsPassedDetails.durationHours,
    // }).then((data) => {
    //   if (selectedStaffTrainings != undefined) {
    //     setSelectedStaffTrainings(
    //       selectedStaffTrainings
    //         .filter((stfTrn) => stfTrn.id != data.id)
    //         .concat(data)
    //     );
    //   } else {
    //     setSelectedStaffTrainings([].concat(data));
    //   }
    //   setShowMarkAsPassed(false);
    // });
  };

  const onViewDetails = (trn) => {
    setMyTrainingState({
      ...myTrainingState,
      selectedTraining: trn,
      showTrainingDetails: true,
    });
    // setSelectedTraining(trn);
    // setShowTrainingDetails(true);
  };

  const onHideTrainingDetails = () => {
    setMyTrainingState({ ...myTrainingState, showTrainingDetails: false });
    // setShowTrainingDetails(false);
  };

  const onShowStaffTrainingView = (eventKey) => {
    setMyTrainingState({ ...myTrainingState, staffTrainingView: eventKey });
  };

  return {
    refreshPage,
    onDeleteStaffTraining,
    onPrintCertificate,
    onMarkAsPassed,
    onChangePassDate,
    onChangeStartDate,
    onChangeHours,
    onHideMarkAsPassed,
    onSaveMarkAsPassed,
    onViewDetails,
    onHideTrainingDetails,
    onShowStaffTrainingView,
    myTrainingState,
  };
};

export default useMyTrainingState;
