import React from "react";
import { Form, Button } from "react-bootstrap";
import moment from "moment";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

const SelectMonthDesktop = ({
  onChangeMonth,
  onChangeYear,
  onPreviousMonth,
  onNextMonth,
  selectedMonth,
  selectedYear,
  months,
}) => {
  const currentYear = moment(new Date()).year() - 1;

  return (
    <React.Fragment>
      <div style={{ width: "200px" }}></div>
      <Form
        inline
        style={{
          alignItems: "center",
          display: "flex",
          fontWeight: "500",
          justifyContent: "center",
          verticalAlign: "middle",
        }}
      >
        <Button style={buttonStyle} onClick={onPreviousMonth}>
          {/* <img
            src={left}
            className="App-logo"
            alt="logo"
            style={{ width: "50px", height: "50px" }}
          ></img> */}
          <GoChevronLeft
            style={{
              width: "40px",
              height: "40px",
              verticalAlign: "middle",
            }}
          />
        </Button>
        <Form.Control
          style={{
            width: 140,
            fontWeight: "bold",
            fontSize: "18px",
          }}
          defaultValue={selectedMonth}
          value={selectedMonth}
          name="currentMonth"
          as="select"
          onChange={onChangeMonth}
          size="sm"
        >
          {months.map((element) => (
            <option key={element.id} value={element.id}>
              {element.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control
          style={{
            width: 100,
            fontWeight: "bold",
            fontSize: "18px",
          }}
          defaultValue={selectedYear}
          value={selectedYear}
          name="currentYear"
          as="select"
          onChange={onChangeYear}
          size="sm"
        >
          {[...Array(15)].map((element, index) => (
            <option key={currentYear + index} value={currentYear + index}>
              {currentYear + index}
            </option>
          ))}
        </Form.Control>
        <Button style={buttonStyle} onClick={onNextMonth}>
          <GoChevronRight
            style={{
              width: "40px",
              height: "40px",
              verticalAlign: "middle",
            }}
          />
        </Button>
      </Form>
      <div style={{ width: "200px" }}></div>
    </React.Fragment>
  );
};

const buttonStyle = {
  borderColor: "transparent",
  borderRadius: "0",
  backgroundColor: "transparent",
  margin: "3px",
  color: "black",
};

export default SelectMonthDesktop;
