import React from "react";
import { Button, Badge, Container, Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import { BsTrash, BsCheck } from "react-icons/bs";
import UtilityService from "../../../../services/utilityService";

const MedicationListDesktop = ({
  onDeleteRxCode,
  onMarkMedicationAsShipped,
  medicationState,
}) => {
  function getMessage(medication) {
    console.log(medication);
    const orderDate = medication.orderDate;
    const avgDeliveryTime =
      medication.medicationAnalytics &&
      medication.medicationAnalytics.avgDeliveryTime;
    var orderDateMsg = "";
    var avgDeliveryTimeMsg = "";
    const days = moment().diff(moment(orderDate), "days");
    if (days <= 0) {
      const hours = moment().diff(moment(orderDate), "hours");
      if (hours <= 0) {
        orderDateMsg = "Just ordered";
      } else {
        if (hours == 1) {
          orderDateMsg = "Ordered " + hours + " hour ago";
        } else {
          orderDateMsg = "Ordered " + hours + " hours ago";
        }
      }
    } else if (days == 1) {
      orderDateMsg = "Ordered yesterday";
    } else {
      orderDateMsg = "Ordered " + days + " days ago";
    }

    if (avgDeliveryTime > 0) {
      if (avgDeliveryTime == 1) {
        avgDeliveryTimeMsg =
          ", usually this medication is delivered in " +
          avgDeliveryTime +
          " day";
      } else {
        avgDeliveryTimeMsg =
          ", usually this medication is delivered in " +
          avgDeliveryTime +
          " days";
      }
    }

    return orderDateMsg + avgDeliveryTimeMsg;
  }

  const privacyMessage = (rxCode) => {
    const isNum = UtilityService.isOnlyNum(
      rxCode.replace("RX", "11").replace(" ", "")
    );

    if (isNum) {
      return "";
    } else {
      return (
        rxCode +
        " looks like not valid Rx code, for medication ordering we recommend using Rx codes  "
      );
    }
  };

  return (
    <React.Fragment>
      <div
        id=""
        style={{
          overflow: "scroll",
          overflowX: "hidden",
          height: "70vh",
        }}
      >
        <Container fluid>
          {medicationState.filterRxCodes &&
            medicationState.filterRxCodes.map((rx) => {
              return (
                <Row style={rowStyle}>
                  <Col xs={1}>
                    <Button
                      variant="outline-light"
                      onClick={() => onMarkMedicationAsShipped(rx)}
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                    >
                      <BsCheck
                        style={{
                          color: "#5cb85c",
                          width: "15px",
                          height: "15px",
                          verticalAlign: "top",
                        }}
                      />
                    </Button>
                  </Col>
                  <Col xs={2}>
                    <div style={{ fontSize: "15px" }}>{rx.rxCode}</div>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      width: "100px",
                    }}
                  >
                    <div style={{ fontSize: "15px" }}>
                      {rx.orderDate &&
                        moment(rx.orderDate).format("MM/DD/YYYY hh:mm A")}
                    </div>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      width: "100px",
                    }}
                  >
                    {moment().diff(moment(rx.orderDate), "days") <= 7 ? (
                      <Badge variant="success">
                        <div style={{ fontSize: "10px" }}>{getMessage(rx)}</div>
                      </Badge>
                    ) : (
                      <Badge variant="danger">
                        <div style={{ fontSize: "10px" }}>{getMessage(rx)}</div>
                      </Badge>
                    )}
                    {privacyMessage(rx.rxCode) != "" ? (
                      <Badge variant="warning">
                        <div style={{ fontSize: "10px" }}>
                          {privacyMessage(rx.rxCode)}
                        </div>
                      </Badge>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Button
                      size="sm"
                      variant="outline-light"
                      onClick={() => onDeleteRxCode(rx)}
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                    >
                      <BsTrash
                        style={{
                          color: "#d9534f",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Button>
                  </Col>
                </Row>
              );
            })}
        </Container>
      </div>
    </React.Fragment>
  );
};

const rowStyle = {
  marginBottom: "10px",
};

export default MedicationListDesktop;
