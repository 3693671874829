import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  CardDeck,
  Modal,
} from "react-bootstrap";
import AddProductMobile from "../add-product/mobile/addProductMobile";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ShoppingProductMobile from "../products/mobile/shoppingProductMobile";
import ShoppingCart from "../shopping-cart/shoppingCart";
import WeeklyAddsMobile from "../weekly-add/mobile/weeklyAddsMobile";
import useShoppingProduct from "../hook/useShoppingProduct";
import Header from "../../header";
import { Badge } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";

const MainShoppingMobile = ({
  refreshPage,
  onFilterProducts,
  onAddRemoveToCart,
  onSetView,
  onShowProduct,
  onHideProduct,
  onChangeProduct,
  onIsPurchased,
  onRemoveShoppingCartProduct,
  onSaveProduct,
  onDeleteProduct,
  onChangeProductItem,
  onTogglePrices,
  shoppingState,
}) => {
  return (
    <React.Fragment>
      {/* <Header /> */}
      <Container fluid="mb">
        <Row>
          <Col xs={4} md={4} style={{ marginLeft: "0px", marginRight: "0px" }}>
            <Button
              size="sm"
              variant="link"
              style={
                shoppingState.currView == "prd"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onSetView("prd")}
            >
              Products
            </Button>
          </Col>
          {/* </Row>
        <Row> */}
          <Col xs={4} md={4} style={{ margin: "0px" }}>
            <Button
              size="sm"
              variant="link"
              style={
                shoppingState.currView == "sc"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onSetView("sc")}
            >
              Cart{" "}
              <Badge size="sm" variant="danger">
                {shoppingState.shoppingCartProducts != undefined
                  ? shoppingState.shoppingCartProducts.length
                  : "0"}
              </Badge>
            </Button>
          </Col>
          {/* </Row>
        <Row> */}
          <Col xs={4} md={4} style={{ margin: "0px" }}>
            <Button
              size="sm"
              variant="link"
              style={
                shoppingState.currView == "wa"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onSetView("wa")}
            >
              Adds
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <Form.Control
              size="sm"
              placeholder="Filter Products"
              onChange={onFilterProducts}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </Container>
      <hr></hr>
      <Container fluid="true">
        <Row>
          <Col>
            <div
              id=""
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                // height: "300px",
                height: "50vh",
                right: "-17",
                bottom: "0",
                top: "0",
                left: "0",
              }}
            >
              {shoppingState.currView == "prd" ? (
                <CardDeck>
                  {shoppingState.filteredProducts &&
                    shoppingState.filteredProducts.map((product, index) => (
                      <ShoppingProductMobile
                        onAddRemoveToCart={onAddRemoveToCart}
                        onSaveProduct={onSaveProduct}
                        onChangeProductItem={onChangeProductItem}
                        onHideProduct={onHideProduct}
                        onShowProduct={onShowProduct}
                        onTogglePrices={onTogglePrices}
                        showPricesList={shoppingState.showPricesList}
                        product={product}
                        isIntoCart={
                          shoppingState.shoppingCartProducts != undefined
                            ? shoppingState.shoppingCartProducts.filter(
                                (shoppingCartProduct) =>
                                  shoppingCartProduct.product.id == product.id
                              ).length > 0
                            : false
                        }
                        key={index}
                      />
                    ))}
                </CardDeck>
              ) : shoppingState.currView == "wa" ? (
                <WeeklyAddsMobile />
              ) : (
                <ShoppingCart
                  onRemoveShoppingCartProduct={onRemoveShoppingCartProduct}
                  shoppingCartProducts={shoppingState.shoppingCartProducts}
                  onIsPurchased={onIsPurchased}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px", marginTop: "20px" }}>
          <Col>
            <Button
              size="sm"
              variant="success"
              style={{ width: "100%" }}
              onClick={() => onShowProduct({})}
            >
              <TiPlus
                style={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  verticalAlign: "middle",
                }}
              />
              {"   "}
              New product
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal show={shoppingState.show} onHide={onHideProduct}>
        <Modal.Header closeButton>
          <Modal.Title>Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <AddProductMobile
              onSaveProduct={onSaveProduct}
              onHideProduct={onHideProduct}
              onDeleteProduct={onDeleteProduct}
              onChangeProduct={onChangeProduct}
              shoppingState={shoppingState}
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const badgeSeletedStyle = {
  backgroundColor: "#d9534f",
  borderColor: "#d9534f",
};

const badgeStyle = {
  backgroundColor: "#d9534f",
  borderColor: "#d9534f",
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "100%",
  fontSize: "12px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "100%",
  fontStyle: "underline",
  fontWeight: "bold",
  fontSize: "12px",
};

export default MainShoppingMobile;
