import React from "react";
import { Button, Form, Table } from "react-bootstrap";
import CheckListDesktop from "./checkListDesktop";
import AddTodoDesktop from "../../../todo/add-todo/desktop/addtodoDesktop";

const ScheduleCheckDesktop = ({
  onFilterCheckList,
  onSelectCheck,
  onHideScheduleCheckList,
  onGoToSchedule,
  onSaveScheduleCheckListControl,
  onChangeTodo,
  onChangeTodoDates,
  checkListState,
}) => {
  console.log("scheduleView", checkListState.scheduleView);
  return (
    <React.Fragment>
      {checkListState.scheduleView == "todo" ? (
        <AddTodoDesktop
          onAddTodo={onSaveScheduleCheckListControl}
          onClose={onHideScheduleCheckList}
          onChange={onChangeTodo}
          onChangeDates={onChangeTodoDates}
          todo={checkListState.currTodo}
          isError={checkListState.isError}
          errorMsg={checkListState.errorMsg}
        />
      ) : (
        <CheckListDesktop
          filterCheckList={onFilterCheckList}
          filteredCheckLists={checkListState.filteredCheckLists}
          // criteria={criteria}
          handleSelected={onSelectCheck}
          selectedCheckList={checkListState.selectedCheckList}
          hideScheduleCheckList={onHideScheduleCheckList}
          goToSchedule={onGoToSchedule}
        />
      )}
    </React.Fragment>
  );
};

export default ScheduleCheckDesktop;
