import React from "react";
import AddMedicationsDesktop from "./addMedicationsDesktop";
import MedicationList from "./medicationListDesktop";
import ButtonBarDesktop from "./buttonBarDesktop";

const MedicationOrderDesktop = ({
  onAddMedication,
  onChangeRxCode,
  onFilterMedications,
  onDeleteRxCode,
  onMarkMedicationAsShipped,
  onShowMedicationOrderHistory,
  onShowSendOrder,
  onToggleFilter,
  medicationState,
}) => {
  return (
    <React.Fragment>
      <AddMedicationsDesktop
        onAddMedication={onAddMedication}
        onChangeRxCode={onChangeRxCode}
        onFilterMedications={onFilterMedications}
        onToggleFilter={onToggleFilter}
        medicationState={medicationState}
      />
      <hr></hr>
      <MedicationList
        onDeleteRxCode={onDeleteRxCode}
        onMarkMedicationAsShipped={onMarkMedicationAsShipped}
        medicationState={medicationState}
      />
      <hr></hr>
      <ButtonBarDesktop
        onShowMedicationOrderHistory={onShowMedicationOrderHistory}
        onShowSendOrder={onShowSendOrder}
      />
    </React.Fragment>
  );
};

export default MedicationOrderDesktop;
