import React, { useEffect } from "react";
import { Modal, Row, Col, Nav, Button, Tab } from "react-bootstrap";
import ScheduleTrainingDesktop from "./scheduleTrainingDesktop";
//Services
import ViewTraining from "../viewTraining";
import MarkAsPassedDesktop from "./markAsPassedDesktop";
import CreateTrainingDesktop from "./createTrainingDesktop";
import useTrainingState from "../hook/useTrainingState";
import StaffTrainingDashboardDesktop from "./staffTrainingDashboardDesktop";

const MainStaffTrainingDesktop = ({
  onDeleteStaffTraining,
  onStaffTraining,
  onSaveStaffTraining,
  onShowMarkAsPassed,
  onHideMarkAsPassed,
  onSaveMarkAsPassed,
  onChangePassDate,
  onChangeStartDate,
  onChangeHours,
  onSaveTraining,
  onDeleteTraining,
  onViewDetails,
  onHideTrainingDetails,
  onAddTraining,
  onHideSchedule,
  onChangeScheduleDate,
  onGoBack,
  onDownloadCompleted,
  onPrintCertificate,
  onCreateTraining,
  onEditTraining,
  onChangeTraining,
  onChangeTrainingUrl,
  onAddTrainingUrl,
  onRemoveTrainingUrl,
  onHideCreateTraining,
  openEmployee,
  onShowStaffTrainingView,
  trainingState,
}) => {
  console.log("trainingState.currEmployee", trainingState.currEmployee);

  return (
    <React.Fragment>
      <Tab.Container>
        <Row>
          <Col>
            <Nav onSelect={(selectedKey) => openEmployee(selectedKey)}>
              {trainingState.employees &&
                trainingState.employees.map((employee) => {
                  return (
                    <Nav.Item>
                      <Nav.Link eventKey={employee.id}>
                        <Button
                          style={
                            trainingState &&
                            trainingState.currEmployee &&
                            trainingState.currEmployee.id == employee.id
                              ? navButtonSelectedStyle
                              : navButtonStyle
                          }
                        >
                          {employee.name}
                        </Button>
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <StaffTrainingDashboardDesktop
              onAddTraining={onAddTraining}
              onMarkAsPassed={onShowMarkAsPassed}
              onDeleteStaffTraining={onDeleteStaffTraining}
              onViewDetails={onViewDetails}
              onDownloadCompleted={onDownloadCompleted}
              onPrintCertificate={onPrintCertificate}
              onCreateTraining={onCreateTraining}
              onEditTraining={onEditTraining}
              onHideCreateTraining={onHideCreateTraining}
              onStaffTraining={onStaffTraining}
              onGoBack={onGoBack}
              onSaveTraining={onSaveTraining}
              onDeleteTraining={onDeleteTraining}
              onShowStaffTrainingView={onShowStaffTrainingView}
              trainingState={trainingState}
            />
          </Col>
        </Row>
      </Tab.Container>
      {/* <Button
        onClick={() => onOpenMenu(item)}
        variant="primary"
        style={{ width: "150px", marginTop: "30px" }}
      >
        <div>
          <TiArrowBack /> Back
        </div>
      </Button> */}
      <Modal show={trainingState.showSchedule} onHide={onHideSchedule}>
        <Modal.Header closeButton>
          <Modal.Title>
            Schedule training for{" "}
            {trainingState.currEmployee && trainingState.currEmployee.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleTrainingDesktop
            onChangeScheduleDate={onChangeScheduleDate}
            onSaveStaffTraining={onSaveStaffTraining}
            onHideSchedule={onHideSchedule}
            trainingState={trainingState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={trainingState.showTrainingDetails}
        onHide={onHideTrainingDetails}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Training details for{" "}
            {trainingState.selectedTraining &&
              trainingState.selectedTraining.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewTraining training={trainingState.selectedTraining} />
        </Modal.Body>
      </Modal>
      {/* <Modal show={trainingState.showSchedule} onHide={onHideSchedule}>
        <Modal.Header closeButton>
          <Modal.Title>
            Schedule training for {trainingState.selectedStaff.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleTraining
            schedule={trainingState.schedule}
            changeScheduleDate={onChangeScheduleDate}
            saveStaffTraining={onSaveStaffTraining}
            handleClose={onHideSchedule}
          />
        </Modal.Body>
      </Modal> */}
      <Modal show={trainingState.showMarkAsPassed} onHide={onHideMarkAsPassed}>
        <Modal.Header closeButton>
          <Modal.Title>
            Mark{" "}
            {trainingState.currentStaffTraining &&
              trainingState.currentStaffTraining.training.name}{" "}
            as completed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MarkAsPassedDesktop
            onChangePassDate={onChangePassDate}
            onChangeStartDate={onChangeStartDate}
            onChangeHours={onChangeHours}
            onHideMarkAsPassed={onHideMarkAsPassed}
            onSaveMarkAsPassed={onSaveMarkAsPassed}
            trainingState={trainingState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={trainingState.showCreateTraining}
        onHide={onHideCreateTraining}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create training</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateTrainingDesktop
            onSaveTraining={onSaveTraining}
            onChangeTraining={onChangeTraining}
            onChangeTrainingUrl={onChangeTrainingUrl}
            onAddTrainingUrl={onAddTrainingUrl}
            onRemoveTrainingUrl={onRemoveTrainingUrl}
            onHideCreateTraining={onHideCreateTraining}
            trainingState={trainingState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={trainingState.showTrainingDetails}
        onHide={() => onHideTrainingDetails()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Training Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewTraining trainingState={trainingState} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "150px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "150px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainStaffTrainingDesktop;
