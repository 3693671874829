import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";

const LegalRepInfoDesktop = ({
  onChangeResident,
  onSaveLegalRepInfo,
  onExitLegRepPhone,
  residentState,
}) => {
  return (
    <Container
      style={{
        border: "solid",
        borderWidth: "1px",
        borderColor: "lightgray",
        height: "500px",
        marginBottom: "20px",
        marginTop: "20px",
      }}
    >
      <Row style={{ marginTop: "20px" }}>
        <Col style={{ width: "500px" }}>
          <Form.Group style={{ marginTop: "20px" }}>
            <Form.Label>Full name:</Form.Label>
            <FormControl
              name="legRepName"
              style={{ width: "400px" }}
              onChange={(e) => onChangeResident(e)}
              disabled={
                residentState &&
                residentState.currResident &&
                !residentState.currResident.isActive
              }
              value={
                residentState.currResident &&
                residentState.currResident.legRepName
              }
              placeholder="Legal representative full name"
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col style={{ width: "900px" }}>
          <Form.Group>
            <Form.Label>Address:</Form.Label>
            <FormControl
              name="legRepAddress"
              style={{ width: "800px" }}
              onChange={(e) => onChangeResident(e)}
              disabled={
                residentState &&
                residentState.currResident &&
                !residentState.currResident.isActive
              }
              value={
                residentState.currResident &&
                residentState.currResident.legRepAddress
              }
              placeholder="Legal representative address"
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Phone:</Form.Label>
            <FormControl
              name="legRepPhone"
              style={{ width: "400px" }}
              onBlur={onExitLegRepPhone}
              onChange={(e) => onChangeResident(e)}
              disabled={
                residentState &&
                residentState.currResident &&
                !residentState.currResident.isActive
              }
              value={
                residentState.currResident &&
                residentState.currResident.legRepPhone
              }
              placeholder="Legal representative phone"
              autoComplete="off"
              // autoComplete="nope"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col style={{ width: "500px" }}>
          <Form.Group>
            <Form.Label>Email:</Form.Label>
            <FormControl
              name="legRepEmail"
              style={{ width: "400px" }}
              onChange={(e) => onChangeResident(e)}
              disabled={
                residentState &&
                residentState.currResident &&
                !residentState.currResident.isActive
              }
              value={
                residentState.currResident &&
                residentState.currResident.legRepEmail
              }
              placeholder="Legal representative e-mail"
              autoComplete="off"
              // autoComplete="nope"
            />
          </Form.Group>
        </Col>
      </Row>
      {residentState.isChangeMode ? (
        <Row>
          <Col>
            <Button
              variant="success"
              style={{ width: "150px" }}
              onClick={() => onSaveLegalRepInfo()}
              disabled={
                residentState &&
                residentState.currResident &&
                !residentState.currResident.isActive
              }
            >
              Save changes
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Container>
  );
};

export default LegalRepInfoDesktop;
