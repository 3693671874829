import React from "react";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import DatePicker from "react-datepicker";
import { AiFillEye } from "react-icons/ai";

const AddUser = ({
  onSaveStaff,
  onHide,
  onChange,
  onChangeDates,
  onChangePassword,
  onToggleChanged,
  onShowPassword,
  onHidePassword,
  usersState,
}) => {
  return (
    <React.Fragment>
      <Container fluid>
        {usersState.isError ? (
          <Row style={{ marginBottom: "20px" }}>
            <Col style={errorMsgStyle}>
              <b>ERROR:</b> {usersState.errorMsg}
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col>
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Staff full
            name:
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Control
              name="name"
              placeholder="Staff name"
              onChange={(e) => onChange(e)}
              value={usersState.currUser && usersState.currUser.name}
              style={{ width: 500 }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Employment
            date:
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <DatePicker
              name="startDate"
              selected={
                usersState.currUser && usersState.currUser.startDate
                  ? new Date(usersState.currUser.startDate)
                  : ""
              }
              placeholderText="Employment Date"
              onChange={(e) => onChangeDates(e, "startDate")}
              dateFormat="MM/dd/yyyy"
              disabledKeyboardNavigation
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <BootstrapSwitchButton
              onlabel="ALFIS access enabled"
              onstyle="success"
              offlabel="ALFIS access disabled"
              offstyle="danger"
              checked={
                usersState.currUser != undefined
                  ? !usersState.currUser.isDisabled
                  : false
              }
              width="200"
              onChange={(checked) => onToggleChanged(checked)}
            />
          </Col>
        </Row> */}
      </Container>
      {/* {!usersState.currUser.isDisabled ? (
        <Container fluid>
          <Row>
            <Col>
              <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Username:
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <Form.Control
                name="username"
                placeholder="username"
                onChange={(e) => onChange(e)}
                value={usersState.currUser && usersState.currUser.username}
                style={{ width: 300 }}
                // disabled={
                //   usersState.currUser == undefined ||
                //   usersState.currUser.id == undefined
                //     ? false
                //     : true
                // }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>User
              role:
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <Form.Control
                value={usersState.currUser && usersState.currUser.userRole}
                name="userRole"
                as="select"
                onChange={(e) => onChange(e)}
                style={{ width: 300 }}
              >
                <option key="E" value="E">
                  Employee
                </option>
                <option key="A" value="A">
                  Administrator
                </option>
              </Form.Control>
            </Col>
          </Row>
          {usersState.currUser != undefined &&
          usersState.currUser.id != undefined ? (
            <Row style={{ marginBottom: "10px" }}>
              <Col>
                <Form.Check
                  name="isChangePassword"
                  type="checkbox"
                  onChange={(e) => onChangePassword(e)}
                  value={usersState.isChangePassword}
                  checked={usersState.isChangePassword}
                  style={{ width: 300 }}
                  inline
                  label="Change password:"
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Password:
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <InputGroup style={{ width: 300 }} className="mb-3">
                <Form.Control
                  name="password"
                  disabled={!usersState.isChangePassword}
                  type={
                    usersState.showPassword &&
                    usersState.showPassword == "newPassword"
                      ? "input"
                      : "password"
                  }
                  placeholder="password"
                  onChange={(e) => onChange(e)}
                  // style={{ width: 300 }}
                />
                <InputGroup.Append>
                  <Button
                    disabled={!usersState.isChangePassword}
                    style={{
                      cursor: "pointer",
                      // width: "30%",
                      // marginBottom: "30px",
                      color: "#3C3B6E",
                      borderColor: "lightgray",
                      borderLeft: "transparent",
                      backgroundColor: "transparent",
                    }}
                    onMouseDown={() => onShowPassword("newPassword")}
                    onMouseUp={() => onHidePassword()}
                  >
                    <AiFillEye></AiFillEye>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <b style={{ color: "#d9534f", marginRight: "5px" }}>*</b>Confirm
              password:
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <InputGroup style={{ width: 300 }} className="mb-3">
                <Form.Control
                  name="confirmPassword"
                  disabled={!usersState.isChangePassword}
                  type={
                    usersState.showPassword &&
                    usersState.showPassword == "confirmNewPassword"
                      ? "input"
                      : "password"
                  }
                  placeholder="confirm password"
                  onChange={(e) => onChange(e)}
                  // style={{ width: 300 }}
                />
                <InputGroup.Append>
                  <Button
                    disabled={!usersState.isChangePassword}
                    style={{
                      cursor: "pointer",
                      // width: "30%",
                      // marginBottom: "30px",
                      color: "#3C3B6E",
                      borderColor: "lightgray",
                      borderLeft: "transparent",
                      backgroundColor: "transparent",
                    }}
                    onMouseDown={() => onShowPassword("confirmNewPassword")}
                    onMouseUp={() => onHidePassword()}
                  >
                    <AiFillEye></AiFillEye>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )} */}
      <Container fluid>
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <Button
              variant="outline-success"
              onClick={() => onHide()}
              style={{ width: "100px" }}
            >
              Close
            </Button>
            {"   "}
            <Button
              variant="success"
              onClick={() => onSaveStaff()}
              style={{ width: "100px" }}
            >
              Save
            </Button>{" "}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const errorMsgStyle = {
  color: "#d9534f",
};

export default AddUser;
