import { useState } from "react";
import moment from "moment";
import MedicationService from "../../../services/medication/medicationService";

const useMedicationHistoryState = () => {
  const [medicaitonHistoryState, setMedicaitonHistoryState] = useState({});

  const refreshPage = async () => {
    const medicationHistory = await MedicationService.getMedicationHistoryView();
    const medications = await MedicationService.getAll();
    setLocalState(medicationHistory, medicationHistory, medications);
  };

  const getMessage = (data) => {
    const days = moment().diff(moment(data.orderDate), "days");
    var retVal = "";
    if (days == 0) {
      var retVal = "last time ordered today";
    } else if (days == 1) {
      var retVal = "last time ordered " + days + " day ago";
    } else {
      var retVal = "last time ordered " + days + " days ago";
    }

    if (data.shippedDate == undefined || data.shippedDate == null) {
      return retVal;
    } else {
      const shippeddays = moment().diff(moment(data.shippedDate), "days");
      if (shippeddays == 0) {
        return retVal + ", last time " + "delivered  today";
      } else if (shippeddays == 1) {
        return (
          retVal + ", last time " + "delivered  " + shippeddays + " day ago"
        );
      } else {
        return (
          retVal + ", last time " + "delivered  " + shippeddays + " days ago"
        );
      }
    }
  };

  const onFilterMedications = (event) => {
    const currMedication = event.target.value;
    const medicationsHistory = medicaitonHistoryState.medicationsHistory && [
      ...medicaitonHistoryState.medicationsHistory,
    ];

    const filteredMedicationsHistory = applyFilterByName(
      currMedication,
      medicationsHistory
    );

    setLocalState(
      medicationsHistory,
      filteredMedicationsHistory,
      medicaitonHistoryState.alldata
    );
  };

  const onToggleFilter = () => {
    const medicationsHistory = medicaitonHistoryState.medicationsHistory && [
      ...medicaitonHistoryState.medicationsHistory,
    ];

    setMedicaitonHistoryState({
      ...medicaitonHistoryState,
      medicationsHistory: sortData(medicationsHistory),
      filteredMedicationHistory: sortData(medicationsHistory),
      showFilter: !medicaitonHistoryState.showFilter,
    });
  };

  function sortData(data) {
    var sortedData = data == undefined ? [] : data;
    if (sortedData != undefined) {
      sortedData = sortedData.sort((medicationA, medicationB) =>
        medicationA.orderDate > medicationB.orderDate ? -1 : 1
      );
    }
    return sortedData;
  }

  function setLocalState(data, filteredData, alldata) {
    var sortedData = sortData(data);
    // var sortedData = data == undefined ? [] : data;
    // if (sortedData != undefined) {
    //   sortedData = sortedData.sort((medicationA, medicationB) =>
    //     medicationA.id > medicationB.id ? -1 : 1
    //   );
    // }
    var sortedfilteredData = sortData(filteredData);
    // var sortedfilteredData = filteredData == undefined ? [] : filteredData;
    // if (sortedfilteredData != undefined) {
    //   sortedfilteredData = sortedfilteredData.sort((medicationA, medicationB) =>
    //     medicationA.id > medicationB.id ? -1 : 1
    //   );
    // }
    setMedicaitonHistoryState({
      ...medicaitonHistoryState,
      medicationsHistory: sortedData,
      filteredMedicationHistory: sortedfilteredData,
      allRxCodes: alldata,
    });
  }

  function applyFilterByName(currMedication, rxcodes) {
    console.log("rxcodes", rxcodes);
    console.log("currMedication", currMedication);
    if (rxcodes === undefined || rxcodes === "NaN" || rxcodes.length === 0) {
      return rxcodes;
    }

    if (
      currMedication === undefined ||
      currMedication === "NaN" ||
      currMedication.length === 0
    )
      return rxcodes;
    var formattedFilter = currMedication;
    if (!currMedication.toLowerCase().startsWith("r")) {
      if (currMedication.startsWith("RX")) {
        formattedFilter = currMedication.replace("RX", "RX ");
      } else {
        formattedFilter = "RX " + currMedication;
      }
    }

    const filteredRxcodes = rxcodes.filter((medHistoryObject) =>
      medHistoryObject.rxCode
        .toString()
        .toLowerCase()
        .includes(formattedFilter.toString().toLowerCase())
    );

    return filteredRxcodes;
  }

  return {
    refreshPage,
    getMessage,
    onFilterMedications,
    onToggleFilter,
    medicaitonHistoryState,
  };
};

export default useMedicationHistoryState;
