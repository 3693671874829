import React from "react";
import { Button } from "react-bootstrap";
import TrainingListMobile from "./trainingListMobile";

const AddStaffTrainingMobile = ({
  onCreateTraining,
  onEditTraining,
  onStaffTraining,
  onDeleteTraining,
  trainingState,
}) => {
  return (
    <React.Fragment>
      <TrainingListMobile
        onStaffTraining={onStaffTraining}
        onDeleteTraining={onDeleteTraining}
        onEditTraining={onEditTraining}
        trainingState={trainingState}
      />
      <Button
        size="sm"
        variant="success"
        style={{ width: "100%", marginTop: "10px" }}
        onClick={() => onCreateTraining(true)}
      >
        New training
      </Button>
    </React.Fragment>
  );
};

export default AddStaffTrainingMobile;
