import React from "react";
import StaffDetailsMobile from "../mobile/staffDetailsMobile";
import AddStaffTrainingDesktop from "../desktop/addStaffTrainingDesktop";

const StaffTrainingDashboardMobile = ({
  onAddTraining,
  onMarkAsPassed,
  onDeleteStaffTraining,
  onViewDetails,
  onDownloadCompleted,
  onPrintCertificate,
  onCreateTraining,
  onEditTraining,
  onHideCreateTraining,
  onStaffTraining,
  onGoBack,
  onSaveTraining,
  onDeleteTraining,
  onShowStaffTrainingView,
  trainingState,
}) => {
  return trainingState.mode == "view" ? (
    <StaffDetailsMobile
      onMarkAsPassed={onMarkAsPassed}
      onDeleteStaffTraining={onDeleteStaffTraining}
      onViewDetails={onViewDetails}
      onPrintCertificate={onPrintCertificate}
      onShowStaffTrainingView={onShowStaffTrainingView}
      onCreateTraining={onCreateTraining}
      onEditTraining={onEditTraining}
      onHideCreateTraining={onHideCreateTraining}
      trainings={trainingState.trainings}
      onStaffTraining={onStaffTraining}
      onGoBack={onGoBack}
      onSaveTraining={onSaveTraining}
      onDeleteTraining={onDeleteTraining}
      trainingState={trainingState}
    />
  ) : (
    ""
  );
};

export default StaffTrainingDashboardMobile;
