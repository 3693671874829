import APIClient from "../apiClient";
import axios from "axios";

const BASE_FACILITY_FILE_METADATA = "/facilityFileMetadata";

class FacilityFileClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_FACILITY_FILE_METADATA + "/" + id
    );
  }

  async getById(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_FACILITY_FILE_METADATA + "/" + id
    );
  }

  async getByFacilityFolderId(facilityFolderId) {
    return await super.performAsync(
      "get",
      super.getBaseContext() +
        BASE_FACILITY_FILE_METADATA +
        "/byFacilityFolder/" +
        facilityFolderId
    );
  }

  async ifFileExists(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FACILITY_FILE_METADATA + "/ifFileExists",
      repo
    );
  }

  async shareFile(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FACILITY_FILE_METADATA + "/shareFile",
      repo
    );
  }

  upload(repo) {
    return axios({
      url:
        super.getBaseURI() +
        super.getBaseContext() +
        BASE_FACILITY_FILE_METADATA,
      method: "POST",
      data: repo,
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((resp) => {
      return resp.data ? resp.data : undefined;
    });
  }

  download(id) {
    return axios({
      url:
        super.getBaseURI() +
        super.getBaseContext() +
        BASE_FACILITY_FILE_METADATA +
        "/download/" +
        id,
      method: "POST",
      responseType: "blob",
      headers: { authorization: localStorage.getItem("token") },
    });
  }
}

export default FacilityFileClient;
