import TodoCommentClient from "../../api/todo/todoCommentClient";

const todoCommentClient = new TodoCommentClient();

class TodoCommentService {
  async save(repo) {
    try {
      const response = await todoCommentClient.save(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }

      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getByTodo(todoId) {
    try {
      const response = await todoCommentClient.getByTodo(todoId);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }

      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new TodoCommentService();
