import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MessagesListDesktop from "../desktop/messagesListDesktop";
import MessagesListMobile from "./messagesListMobile";

const MessagesMobile = ({
  onViewMessage,
  messagesOther,
  messagesMy,
  messagesRead,
  view,
}) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          {view == "received" ? (
            messagesOther == undefined || messagesOther.length == 0 ? (
              "You have no new messages"
            ) : (
              <MessagesListMobile
                onViewMessage={onViewMessage}
                messages={messagesOther}
                messagesRead={messagesRead}
              />
            )
          ) : messagesMy == undefined || messagesMy.length == 0 ? (
            "You have no sent messages"
          ) : (
            <MessagesListMobile
              onViewMessage={onViewMessage}
              messages={messagesMy}
              messagesRead={messagesRead}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MessagesMobile;
