let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === "localhost") {
  backendHost = "http://localhost:5000";
} else {
  backendHost = "https://api.alf-is-app.com";
}

export const config = {
  headers: {
    authorization: localStorage.getItem("token"),
  },
};

export const API_ROOT = backendHost;
