import FacilityFileClient from "../../api/mydocuments/facilityFileClient";

const facilityFileClient = new FacilityFileClient();

class FacilityFileService {
  async delete(id) {
    console.log("delete", id);
    return await facilityFileClient.delete(id);
  }

  async getById(id) {
    try {
      const response = await facilityFileClient.getById(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getByFacilityFolderId(id) {
    try {
      const response = await facilityFileClient.getByFacilityFolderId(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async ifFileExists(repo) {
    try {
      const response = await facilityFileClient.ifFileExists(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async shareFile(repo) {
    try {
      const response = await facilityFileClient.shareFile(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  upload(repo) {
    console.log("upload", repo);
    return facilityFileClient.upload(repo);
  }

  download(id, path) {
    console.log("download", id);
    facilityFileClient.download(id).then((response) => {
      console.log("response", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      var pathArr = path.split("/");
      const fileName = pathArr[pathArr.length - 1];
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default new FacilityFileService();
