import FacilityContactsEmailsClient from "../../api/compliance/facilityContactsEmailsClient";

const facilityContactsEmailsClient = new FacilityContactsEmailsClient();

class FacilityContactsEmailsService {
  async getAll() {
    console.log("Invoke getAll");
    try {
      const response = await facilityContactsEmailsClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new FacilityContactsEmailsService();
