import React from "react";
import Button from "react-bootstrap/Button";

const CalendarEmptyItemDesktop = () => {
  return (
    <Button style={alfButton}>
      <br />
    </Button>
  );
};

const alfButton = {
  textAlign: "center",
  borderColor: "white",
  borderRadius: "0",
  color: "#3c3b6e",
  backgroundColor: "white",
  borderWidth: "thin",
};

export default CalendarEmptyItemDesktop;
