import FacilityClient from "../../api/facility/facilityClient";
import UtilityService from "../utilityService";

const facilityClient = new FacilityClient();

class FacilityService {
  delete(id) {
    return facilityClient.delete(id);
  }

  async save(repo) {
    let response = "";
    try {
      if (repo.hasOwnProperty("id") && repo.id != undefined) {
        response = await facilityClient.update(repo, repo.id);
      } else {
        response = await facilityClient.insert(repo);
      }

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await facilityClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getFromToken() {
    try {
      const response = await facilityClient.getFromToken();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (err) {
      alert("Something went wrong please try again later");
    }
  }

  validateFacility(facility) {
    if (facility.name == undefined || facility.name.trim() === "") {
      return {
        isError: true,
        msg: "Name can't be blank, please enter facility name!",
      };
    } else if (
      facility.telephone == undefined ||
      facility.telephone.trim() === ""
    ) {
      return {
        isError: true,
        msg: "Telephone can't be blank, please enter telephone!",
      };
    } else if (
      facility.address == undefined ||
      facility.address.trim() === ""
    ) {
      return {
        isError: true,
        msg: "Address can't be blank, please enter facility address!",
      };
    } else if (facility.city == undefined || facility.city.trim() === "") {
      return {
        isError: true,
        msg: "City can't be blank, please enter city!",
      };
    } else if (
      facility.zipCode == undefined ||
      facility.zipCode.trim() === ""
    ) {
      return {
        isError: true,
        msg: "Zip code can't be blank, please enter zip code!",
      };
    } else if (facility.state == undefined || facility.state.trim() === "") {
      return {
        isError: true,
        msg: "State can't be blank, please enter state!",
      };
    } else if (
      facility.licenseeName == undefined ||
      facility.licenseeName.trim() === ""
    ) {
      return {
        isError: true,
        msg: "Licensee name can't be blank, please enter licensee name!",
      };
    } else if (
      facility.licenseeTelephone == undefined ||
      facility.licenseeTelephone.trim() === ""
    ) {
      return {
        isError: true,
        msg:
          "Licensee telephone can't be blank, please enter licensee telephone!",
      };
    } else if (
      facility.licenseeTelephone == undefined ||
      facility.licenseeTelephone.trim() === ""
    ) {
      return {
        isError: true,
        msg: "Licensee number can't be blank, please enter licensee number!",
      };
    }

    return { isValid: true };
  }
}

export default new FacilityService();
