import React from "react";
import { Button } from "react-bootstrap";
import AddMedicalRecordMobile from "./addMedicalRecordMobile";
import MedicalRecordsMobile from "./medicalRecordsMobile";

const MedicalHistoryMobile = ({
  onShowAddMedRecord,
  onAddMedicalRecord,
  onChangeMedHistory,
  onChangeMedRecordDate,
  onDeleteMedicalRecord,
  residentState,
}) => {
  return (
    <React.Fragment>
      {residentState.showAddMedRecord ? (
        <AddMedicalRecordMobile
          onAddMedicalRecord={onAddMedicalRecord}
          onChangeMedHistory={onChangeMedHistory}
          onChangeMedRecordDate={onChangeMedRecordDate}
          residentState={residentState}
        />
      ) : (
        <MedicalRecordsMobile
          onShowAddMedRecord={onShowAddMedRecord}
          onDeleteMedicalRecord={onDeleteMedicalRecord}
          residentState={residentState}
        />
      )}
    </React.Fragment>
  );
};

export default MedicalHistoryMobile;
