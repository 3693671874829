import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Header from "../header";
import MedicationHistory from "./medication-history/medicationHistory";
import "../../css/modal.css";
import SendMailDesktop from "./send-medication-order/desktop/sendMailDesktop";
import SendMailMobile from "./send-medication-order/mobile/sendMailMobile";
import useMedicationState from "./hook/useMedicationState";
import MedicationOrderDesktop from "./medication/desktop/medicationOrderDesktop";
import useWindowSize from "../common/hook/useWindowSize";
import MedicationOrderMobile from "./medication/mobile/medicationOrderMobile";

const MainMedication = () => {
  const size = useWindowSize();
  const {
    refreshPage,
    onChangeRxCode,
    onAddMedication,
    onFilterMedications,
    onDeleteRxCode,
    onMarkMedicationAsShipped,
    onShowMedicationOrderHistory,
    onShowSendOrder,
    onCloseMedicationHistory,
    onCloseSendMail,
    onDownloadOrder,
    onClose,
    medicationState,
  } = useMedicationState();

  useEffect(() => {
    refreshPage();
  }, []);

  return (
    <React.Fragment>
      {size.width > 970 ? (
        <MedicationOrderDesktop
          onAddMedication={onAddMedication}
          onChangeRxCode={onChangeRxCode}
          onFilterMedications={onFilterMedications}
          onDeleteRxCode={onDeleteRxCode}
          onMarkMedicationAsShipped={onMarkMedicationAsShipped}
          onShowMedicationOrderHistory={onShowMedicationOrderHistory}
          onShowSendOrder={onShowSendOrder}
          medicationState={medicationState}
        />
      ) : (
        <MedicationOrderMobile
          onAddMedication={onAddMedication}
          onChangeRxCode={onChangeRxCode}
          onFilterMedications={onFilterMedications}
          onDeleteRxCode={onDeleteRxCode}
          onMarkMedicationAsShipped={onMarkMedicationAsShipped}
          onShowMedicationOrderHistory={onShowMedicationOrderHistory}
          onShowSendOrder={onShowSendOrder}
          onClose={onClose}
          medicationState={medicationState}
        />
      )}
      <hr></hr>
      <Modal
        dialogClassName="width-90w"
        show={medicationState.showHistory}
        onHide={onCloseMedicationHistory}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <small>
              <b>Medication order history</b>
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <MedicationHistory
              onCloseMedicationHistory={onCloseMedicationHistory}
            />
          </div>
        </Modal.Body>
      </Modal>
      {size.width > 970 ? (
        <Modal
          size="lg"
          show={medicationState.showEmail}
          onHide={onCloseSendMail}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <small>
                <b>Send e-mail to pharmacy</b>
              </small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <SendMailDesktop
                medications={
                  !medicationState.filterRxCodes
                    ? []
                    : medicationState.filterRxCodes.map((medication) => {
                        return {
                          id: medication.id,
                          rxCode: medication.rxCode,
                          orderDate: medication.orderDate,
                          shippedDate: medication.shippedDate,
                          isShipped: medication.isShipped,
                        };
                      })
                }
                onCloseSendMail={onCloseSendMail}
                onDownloadOrder={onDownloadOrder}
              />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal show={medicationState.showEmail} onHide={onCloseSendMail}>
          <Modal.Header closeButton>
            <Modal.Title>
              <small>
                <b>Send e-mail to pharmacy</b>
              </small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <SendMailMobile
                medications={
                  !medicationState.filterRxCodes
                    ? []
                    : medicationState.filterRxCodes.map((medication) => {
                        return {
                          id: medication.id,
                          rxCode: medication.rxCode,
                          orderDate: medication.orderDate,
                          shippedDate: medication.shippedDate,
                          isShipped: medication.isShipped,
                        };
                      })
                }
                onCloseSendMail={onCloseSendMail}
                onDownloadOrder={onDownloadOrder}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default MainMedication;
