import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Printout = ({ base64Content }) => {
  const srcVal = "data:application/pdf;base64," + base64Content;
  return (
    <Container>
      <Row>
        <Col>
          {base64Content != "" ? (
            <React.Fragment>
              <a href={srcVal} download="report.pdf">
                <u style={{ color: "black" }}>Download report</u>
              </a>
              <iframe
                frameborder="0"
                //scrolling="yes"
                seamless="seamless"
                //style={{ display: "block", width: "100%", height: "100vh" }}
                style={{ width: "100%", height: "80vh" }}
                src={srcVal}
              />
              {/* <a
                download="report.pdf"
                href={base64Content}
                title="Download pdf document"
              /> */}
            </React.Fragment>
          ) : (
            <div>Loading preview ...</div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Printout;
