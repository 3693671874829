import React from "react";
import { Button, Badge, Table } from "react-bootstrap";
import { BsDownload, BsTrash } from "react-icons/bs";
import moment from "moment";
import "../../common/table.css";

const FamilyResidentForm = ({
  onCloseResidentForm,
  onSetFile,
  onDownload,
  documentState,
}) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <React.Fragment>
      <Button
        variant="success"
        style={{
          width: "150px",
        }}
        onClick={() => handleClick()}
      >
        Upload file
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        onChange={(e) => onSetFile(e)}
        // onClick={(e) => setFile(e)}
      />
      <hr></hr>
      <div class="tableFixHead">
        <Table>
          <thead style={{ backgroundColor: "#5bc0de" }}>
            <tr>
              <th>
                <small>
                  <b>FILE NAME</b>
                </small>
              </th>
              <th>
                <small>
                  <b>CREATION DATE</b>
                </small>
              </th>
              <th>
                <small>
                  <b>ACTIONS</b>
                </small>
              </th>
            </tr>
          </thead>
          <tbody>
            {documentState.currFiles &&
              documentState.currFiles
                .sort((a, b) => {
                  if (a.uploadDate > b.uploadDate) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((currFile) => (
                  <tr>
                    <td>{currFile.name}</td>
                    <td>{moment(currFile.uploadDate).format("DD/MM/YYYY")}</td>
                    <td>
                      <Button
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => onDownload(currFile)}
                      >
                        <b>
                          <BsDownload
                            style={{
                              color: "green",
                              width: "15px",
                              height: "15px",
                            }}
                          ></BsDownload>
                        </b>
                      </Button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
      <Button
        variant="outline-success"
        style={{
          width: "150px",
        }}
        onClick={() => onCloseResidentForm()}
      >
        Close
      </Button>
    </React.Fragment>
  );
};

export default FamilyResidentForm;
