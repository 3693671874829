import React from "react";
import ResidentFileMetadataService from "../../../../services/compliance/documents/residentFileMetadataService";
import { useState } from "react";
import StaffService from "../../../../services/user/staffService";

const useDocumentsForSigningState = () => {
  const [documentForSigningState, setDocumentForSigningState] = useState({});

  const refreshPage = async () => {
    const allStaff = await StaffService.getByUserName(
      sessionStorage.getItem("authenticatedUser")
    );

    const staff = allStaff && allStaff[0];

    const documents = await ResidentFileMetadataService.getAllForSigning(
      staff.id
    );

    setDocumentForSigningState({
      ...documentForSigningState,
      currStaff: staff,
      currDocuments: documents,
    });
  };

  const onSetFile = async (e) => {
    const files = Array.from(e.target.files);
    console.log("files", files[0].name);
    if (files[0].name != documentForSigningState.currFile.name) {
      alert(
        "The name of the signed copy needs to exactly match the name of the original, please rename signed copy into " +
          documentForSigningState.currFile.name
      );
      return;
    }

    let formData = new FormData();

    formData.append("fileId", documentForSigningState.currFile.id);
    formData.append("file", files[0]);

    ResidentFileMetadataService.uploadSigned(formData)
      .then((formMetadata) => {
        alert(
          "Signed copy is successfully uploaded, document will be removed from list!"
        );
        setDocumentForSigningState({
          ...documentForSigningState,
          currDocuments: documentForSigningState.currDocuments.filter(
            (currDoc) => currDoc.id != documentForSigningState.currFile.id
          ),
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onDownload = (currFile) => {
    ResidentFileMetadataService.download(currFile.id, currFile.path);
  };

  const onSetCurrFile = (currFile) => {
    setDocumentForSigningState({
      ...documentForSigningState,
      currFile: currFile,
    });
  };

  const onDelete = async (currFile) => {
    let formData = new FormData();

    formData.append("fileId", currFile.id);
    formData.append("staffId", documentForSigningState.currStaff.id);
    await ResidentFileMetadataService.deleteSignatureRequest(formData);

    setDocumentForSigningState({
      ...documentForSigningState,
      currDocuments: documentForSigningState.currDocuments.filter(
        (currDoc) => currDoc.id != currFile.id
      ),
    });
  };

  return {
    refreshPage,
    onSetFile,
    onDownload,
    onSetCurrFile,
    onDelete,
    documentForSigningState,
  };
};

export default useDocumentsForSigningState;
