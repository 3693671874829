import APIClient from "../../apiClient";

const FORM = "/form";

class ResidentFormClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + FORM + "/" + id
    );
  }

  async save(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + FORM,
      repo
    );
  }

  async saveShares(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + FORM + "/formShared",
      repo
    );
  }

  async deleteShares(repo) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + FORM + "/formShared",
      repo
    );
  }

  async getAll() {
    return await super.performAsync("get", super.getBaseContext() + FORM);
  }

  async getByResident(residentId) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + FORM + "/byResident/" + residentId
    );
  }

  async getById(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + FORM + "/" + id
    );
  }

  async getByResidentFileMetadata(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + FORM + "/byResidentFileMetadata/" + id
    );
  }

  async getByResidents(residentIds) {
    console.log(
      "getByResidents",
      super.getBaseContext() + FORM + "/byResidents?residentIds=" + residentIds
    );
    return await super.performAsync(
      "get",
      super.getBaseContext() + FORM + "/byResidents?residentIds=" + residentIds
    );
  }
}

export default ResidentFormClient;
