import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import ComplianceMobile from "../compliance/mobile/complianceMobile";
import MedicalHistoryDesktop from "../medical-history/desktop/medicalHistoryDesktop";
import LegalRepInfoMobile from "../legal-rep/mobile/legalRepInfoMobile";
import DoctorInfoMobile from "../doctor/mobile/doctorInfoMobile";
import MedicalHistoryMobile from "../medical-history/mobile/medicalHistoryMobile";

const ResidentDetailsMobile = ({
  onOpenResidentView,
  onChangeResident,
  onChangeDoc,
  onAddResidentDoctor,
  onDeleteResidentDoctor,
  onOpenResidentForm,
  onShowAddTodo,
  onCloseResidentForm,
  onSetFile,
  onDownload,
  onDeleteFiles,
  onShowAddMedRecord,
  onAddMedicalRecord,
  onChangeMedHistory,
  onChangeMedRecordDate,
  onSaveLegalRepInfo,
  onShowForm,
  onExitLegRepPhone,
  onExitDoctorPhone,
  onDeleteMedicalRecord,
  onShowShareReport,
  onSelectFormMenu,
  formatList,
  onShowSignFile,
  residentState,
}) => {
  return (
    <React.Fragment>
      <Container fluid="mb">
        <Row>
          <Col>
            <Button
              size="sm"
              style={
                residentState && residentState.currView == "legal"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onOpenResidentView("legal")}
            >
              Leg Rep
            </Button>
            <Button
              size="sm"
              style={
                residentState && residentState.currView == "doctors"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onOpenResidentView("doctors")}
            >
              Doctor
            </Button>
            <Button
              size="sm"
              style={
                residentState && residentState.currView == "reports"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onOpenResidentView("reports")}
            >
              Report
            </Button>
            <Button
              size="sm"
              style={
                residentState && residentState.currView == "medHistory"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onOpenResidentView("medHistory")}
            >
              Med Hist
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {residentState && residentState.currView == "legal" ? (
              <LegalRepInfoMobile
                onChangeResident={onChangeResident}
                onSaveLegalRepInfo={onSaveLegalRepInfo}
                onExitLegRepPhone={onExitLegRepPhone}
                residentState={residentState}
              />
            ) : residentState && residentState.currView == "doctors" ? (
              <DoctorInfoMobile
                onChangeDoc={onChangeDoc}
                onAddResidentDoctor={onAddResidentDoctor}
                onDeleteResidentDoctor={onDeleteResidentDoctor}
                onExitDoctorPhone={onExitDoctorPhone}
                residentState={residentState}
              />
            ) : residentState && residentState.currView == "reports" ? (
              <ComplianceMobile
                onOpenResidentForm={onOpenResidentForm}
                onShowAddTodo={onShowAddTodo}
                onCloseResidentForm={onCloseResidentForm}
                onSetFile={onSetFile}
                onDownload={onDownload}
                onDeleteFiles={onDeleteFiles}
                onShowForm={onShowForm}
                onShowShareReport={onShowShareReport}
                onSelectFormMenu={onSelectFormMenu}
                formatList={formatList}
                onShowSignFile={onShowSignFile}
                residentState={residentState}
              />
            ) : residentState && residentState.currView == "medHistory" ? (
              <MedicalHistoryMobile
                onShowAddMedRecord={onShowAddMedRecord}
                onAddMedicalRecord={onAddMedicalRecord}
                onChangeMedHistory={onChangeMedHistory}
                onChangeMedRecordDate={onChangeMedRecordDate}
                onDeleteMedicalRecord={onDeleteMedicalRecord}
                residentState={residentState}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "25%",
  fontSize: "12px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "25%",
  fontStyle: "underline",
  fontWeight: "bold",
  fontSize: "12px",
};

export default ResidentDetailsMobile;
