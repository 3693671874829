import { useState } from "react";
import ProductService from "../../../services/products/productService";
import ShoppingCartSevice from "../../../services/products/shoppingCartService";
import ShoppingCartProductService from "../../../services/products/shoppingCartProductService";
import * as Constants from "../../util/constants";

const useShoppingProduct = () => {
  const [shoppingState, setShoppingState] = useState({
    currView: "prd",
    showFilter: false,
    showPrice: false,
    showPricesList: [],
  });

  const refreshPage = async () => {
    const products = await ProductService.getAll();
    const shoppingCart = await ShoppingCartSevice.getActive();
    let shoppingCartProducts = [];
    if (shoppingCart != undefined && shoppingCart.id != undefined) {
      shoppingCartProducts = await ShoppingCartProductService.getAllByShoppingCart(
        shoppingCart.id
      );
    }

    setShoppingState({
      ...shoppingState,
      products: products,
      filteredProducts: applyFilter(
        shoppingState.filterProductCriteria,
        products
      ),
      shoppingCart: shoppingCart,
      shoppingCartProducts: shoppingCartProducts,
    });
  };

  const onFilterProducts = (event) => {
    console.log("event.target.value", event.target.value);
    const filterProductCriteria = event.target.value;
    const filteredProducts = applyFilter(
      filterProductCriteria,
      shoppingState.products
    );
    setShoppingState({
      ...shoppingState,
      filteredProducts: filteredProducts,
      filterProductCriteria: filterProductCriteria,
    });
  };

  const onRemoveShoppingCartProduct = async (shoppingCartItem) => {
    if (
      window.confirm(
        "Do you really want to remove " +
          shoppingCartItem.product.name +
          " from the shopping cart?"
      )
    ) {
      await ShoppingCartProductService.delete(shoppingCartItem.id);
      const newShoppingCartProducts = shoppingState.shoppingCartProducts.filter(
        (shoppingCartProduct) => shoppingCartProduct.id != shoppingCartItem.id
      );
      if (
        newShoppingCartProducts == undefined ||
        newShoppingCartProducts.filter(
          (shoppingCartProduct) => shoppingCartProduct.isPurchased != true
        ).length == 0
      ) {
        alert("Shopping cart is empty!");
        await ShoppingCartSevice.closeCart(shoppingCartItem.shoppingCart.id);
        setShoppingState({
          ...shoppingState,
          shoppingCart: {},
          shoppingCartProducts: [],
        });
      } else {
        setShoppingState({
          ...shoppingState,
          shoppingCartProducts: newShoppingCartProducts,
        });
      }
    }
  };

  const onAddRemoveToCart = async (prod) => {
    let id = undefined;
    const shppingCartProduct = shoppingState.shoppingCartProducts.filter(
      (shoppingCartProduct) => shoppingCartProduct.product.id == prod.id
    );
    if (shppingCartProduct.length > 0) {
      id = shppingCartProduct[0].id;
    }
    const shoppingCartProductDTO = {
      id: id,
      quantity: prod.purchaseQuantity,
      shoppingCartId: shoppingState.shoppingCart.id,
      productId: prod.id,
      productMeasureId: prod.productMeasure.id,
    };

    if (
      shoppingCartProductDTO.id === null ||
      shoppingCartProductDTO.id === undefined
    ) {
      const savedShoppingCartProduct = await ShoppingCartProductService.save(
        shoppingCartProductDTO
      );
      const newShoppingCartProducts = shoppingState.shoppingCartProducts
        .filter(
          (shoppingCartProduct) =>
            shoppingCartProduct.id != savedShoppingCartProduct.id
        )
        .concat(savedShoppingCartProduct);
      setShoppingState({
        ...shoppingState,
        shoppingCartProducts: newShoppingCartProducts,
        shoppingCart: savedShoppingCartProduct.shoppingCart,
      });
    } else {
      console.log("delete");
      if (
        window.confirm(
          "Do you really want to remove " +
            prod.name +
            " from the shopping cart?"
        )
      ) {
        await ShoppingCartProductService.delete(shoppingCartProductDTO.id);
        const newShoppingCartProducts = shoppingState.shoppingCartProducts.filter(
          (shoppingCartProduct) =>
            shoppingCartProduct.id != shoppingCartProductDTO.id
        );
        if (
          newShoppingCartProducts.filter(
            (shoppingCartProduct) => shoppingCartProduct.isPurchased != true
          ).length == 0
        ) {
          alert("All items in the shopping cart are purchased!");
          await ShoppingCartSevice.closeCart(shoppingState.shoppingCart.id);
          setShoppingState({
            ...shoppingState,
            shoppingCart: {},
            shoppingCartProducts: [],
          });
        } else {
          setShoppingState({
            ...shoppingState,
            shoppingCartProducts: newShoppingCartProducts,
          });
        }
      }
    }
  };

  const onShowProduct = (product) => {
    setShoppingState({ ...shoppingState, show: true, currProduct: product });
  };

  const onHideProduct = () => {
    setShoppingState({ ...shoppingState, show: false, currProduct: {} });
  };

  const onSetView = (view) => {
    setShoppingState({ ...shoppingState, currView: view });
  };

  const onChangeProductItem = (e, product) => {
    const name = e.target.name;
    const value = e.target.value;
    let seletedProduct = shoppingState.products.filter(
      (prod) => prod.id == product.id
    )[0];
    if (name == "productMeasure") {
      console.log(
        "Constants.PRODUCT_MEASURE",
        Constants.PRODUCT_MEASURE.filter(
          (productMeasure) => productMeasure.id == value
        )[0]
      );
      seletedProduct[name] = Constants.PRODUCT_MEASURE.filter(
        (productMeasure) => productMeasure.id == value
      )[0];
    } else {
      seletedProduct[name] = value;
    }

    const newProductsList = shoppingState.products
      .filter((prod) => prod.id != seletedProduct.id)
      .concat(seletedProduct);
    setShoppingState({
      ...shoppingState,
      products: newProductsList,
      filteredProducts: applyFilter(
        shoppingState.filterProductCriteria,
        newProductsList
      ),
    });
  };

  const onIsPurchased = async (shoppingCartItem) => {
    shoppingCartItem["isPurchased"] = true;
    const savedShoppingCartProduct = await ShoppingCartProductService.save(
      shoppingCartItem
    );
    const newShoppingCartProducts = shoppingState.shoppingCartProducts
      .filter(
        (shoppingCartProduct) =>
          shoppingCartProduct.id != savedShoppingCartProduct.id
      )
      .concat(savedShoppingCartProduct);
    if (
      newShoppingCartProducts.filter(
        (shoppingCartProduct) => shoppingCartProduct.isPurchased != true
      ).length == 0
    ) {
      alert("All items in the shopping cart are purchased!");
      await ShoppingCartSevice.closeCart(shoppingState.shoppingCart.id);
      setShoppingState({
        ...shoppingState,
        shoppingCart: {},
        shoppingCartProducts: [],
      });
    } else {
      setShoppingState({
        ...shoppingState,
        shoppingCartProducts: newShoppingCartProducts,
      });
    }
  };

  const onSaveProduct = async () => {
    const validatedProduct = ProductService.validateProduct(
      shoppingState.currProduct
    );

    if (validatedProduct.isError) {
      setShoppingState({
        ...shoppingState,
        isError: true,
        errMsg: validatedProduct.errorMessage,
      });
      return;
    }
    const productDTO = {
      id: shoppingState.currProduct.id,
      name: shoppingState.currProduct.name,
      purchaseQuantity: shoppingState.currProduct.purchaseQuantity,
      productMeasureId: shoppingState.currProduct.productMeasure.id,
    };
    console.log("onSaveProduct", shoppingState.currProduct);
    const savedProduct = await ProductService.save(productDTO);
    const newProductsList = shoppingState.products
      .filter((prod) => prod.id != savedProduct.id)
      .concat(savedProduct);
    setShoppingState({
      ...shoppingState,
      products: newProductsList,
      filteredProducts: applyFilter(
        shoppingState.filterProductCriteria,
        newProductsList
      ),
      show: false,
    });
  };

  const onDeleteProduct = async () => {
    await ProductService.delete(shoppingState.currProduct.id);
    const newProductsList = shoppingState.products.filter(
      (product) => product.id != shoppingState.currProduct.id
    );

    setShoppingState({
      ...shoppingState,
      products: newProductsList,
      filteredProducts: applyFilter(
        shoppingState.filterProductCriteria,
        newProductsList
      ),
      shoppingCartProducts: shoppingState.shoppingCartProducts.filter(
        (shoppingCartProduct) =>
          shoppingCartProduct.product.id != shoppingState.currProduct.id
      ),
      show: false,
    });
  };

  const onChangeProduct = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let currProduct = { ...shoppingState.currProduct };
    if (name == "productMeasure") {
      console.log(
        "Constants.PRODUCT_MEASURE",
        Constants.PRODUCT_MEASURE.filter(
          (productMeasure) => productMeasure.id == value
        )[0]
      );
      currProduct[name] = Constants.PRODUCT_MEASURE.filter(
        (productMeasure) => productMeasure.id == value
      )[0];
    } else {
      currProduct[name] = value;
    }

    setShoppingState({
      ...shoppingState,
      currProduct: currProduct,
      isError: false,
      errMsg: "",
    });
  };

  const onTogglePrices = (product) => {
    const showPricesList =
      shoppingState.showPricesList == undefined
        ? []
        : shoppingState.showPricesList.filter(
            (showPrices) => showPrices.id != product.id
          );
    if (showPricesList.length == shoppingState.showPricesList.length) {
      setShoppingState({
        ...shoppingState,
        showPricesList: shoppingState.showPricesList.concat(product),
      });
    } else {
      setShoppingState({
        ...shoppingState,
        showPricesList: showPricesList,
      });
    }
  };

  function applyFilter(criteria, prods) {
    if (criteria == undefined || criteria == "NaN") {
      return applySort(prods);
    }
    const filteredProds =
      prods &&
      prods.filter((product) =>
        product.name
          .toString()
          .toLowerCase()
          .includes(criteria.toString().toLowerCase())
      );
    return applySort(filteredProds);
  }

  function applySort(prods) {
    return (
      prods &&
      prods.sort((prodA, prodB) => {
        if (prodA.name.toLowerCase() > prodB.name.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      })
    );
  }

  return {
    refreshPage,
    onFilterProducts,
    onAddRemoveToCart,
    onSetView,
    onShowProduct,
    onHideProduct,
    onChangeProduct,
    onIsPurchased,
    onRemoveShoppingCartProduct,
    onSaveProduct,
    onDeleteProduct,
    onChangeProductItem,
    onTogglePrices,
    shoppingState,
  };
};

export default useShoppingProduct;
