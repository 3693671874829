import ResidentFormClient from "../../../api/compliance/documents/residentFormClient";

const residentFormClient = new ResidentFormClient();

class ResidentFormService {
  async delete(id) {
    return await residentFormClient.delete(id);
  }

  async save(repo) {
    try {
      const response = await residentFormClient.save(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async saveShares(repo) {
    try {
      const response = await residentFormClient.saveShares(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async deleteShares(repo) {
    try {
      const response = await residentFormClient.deleteShares(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await residentFormClient.getAll();

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getById(id) {
    try {
      const response = await residentFormClient.getById(id);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getByResidentFileMetadata(id) {
    try {
      const response = await residentFormClient.getByResidentFileMetadata(id);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getByResident(residentId) {
    try {
      const response = await residentFormClient.getByResident(residentId);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getByResidents(residentIds) {
    try {
      const response = await residentFormClient.getByResidents(residentIds);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  validateForm(form) {
    if (form == undefined) {
      return {
        isError: true,
        errMsg: "Please enter report name",
      };
    }

    if (form.name == undefined) {
      return {
        isError: true,
        errMsg: "Please enter report name",
      };
    }

    return {
      isError: false,
    };
  }
}

export default new ResidentFormService();
