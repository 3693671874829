import React from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const SendEmail = ({
  onSelectShared,
  onSendEmail,
  onHideSendEmail,
  complianceFormsState,
}) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  const onKeyChange = (e) => {
    if (e.key === "Enter") {
      onSelectShared([e.target.value]);
    }
  };

  return (
    <Container>
      <Row>
        <Form.Group>
          <Form.Label>
            Enter e-mail of the person you want to send this report
          </Form.Label>
          <Typeahead
            id="basic-typeahead-single"
            labelKey="name"
            allowNew
            onChange={(e) => onSelectShared(e)}
            onKeyDown={(e) => onKeyChange(e)}
            newSelectionPrefix="Add new contact: "
            autocomplete="no"
            onFocus={(event) => dummyOnFocus(event)}
            options={
              complianceFormsState &&
              complianceFormsState.emails != undefined &&
              complianceFormsState.emails.length > 0
                ? complianceFormsState.emails
                : []
            }
          />
        </Form.Group>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col style={{ paddingLeft: "0px" }}>
          <Button
            variant="outline-success"
            style={{
              width: "100px",
            }}
            onClick={onHideSendEmail}
          >
            Close
          </Button>
          {"   "}
          <Button
            variant="success"
            style={{ width: "100px" }}
            onClick={() => onSendEmail()}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SendEmail;
