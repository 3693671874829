import APIClient from "../apiClient";

const FACILITY_MODULES = "/facilityModules";

class FacilityModulesClient extends APIClient {
  async getByFacilityId() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + FACILITY_MODULES + "/byFacilityId"
    );
  }
}

export default FacilityModulesClient;
