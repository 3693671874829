import React, { useState } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import TableSort from "../../../common/tableSort";
import moment from "moment";
import TableFilter from "../../../common/tableFilter";
import PassedCheckListFilter from "../passedCheckListFilter";
import "../../../common/table.css";

const PassedCheckListControlDesktop = ({
  checkResults,
  viewCompletedDetails,
}) => {
  const [sortScheduedDateType, setSortScheduedDateType] = useState();
  const [sortCompletedDateType, setSortCompletedDateType] = useState();
  const [sortStatusType, setSortStatusType] = useState();
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [filter, setFilter] = useState({});

  const passedCheckResults =
    checkResults &&
    checkResults
      .filter((checkResult) => checkResult.isActive == false)
      .filter((checkResult) => {
        if (filter != undefined) {
          if (filter.isOk != undefined && filter.isOk != -1) {
            if (filter.isOk == 1) {
              if (checkResult.isOk == true) {
                return checkResult;
              } else {
                return null;
              }
            } else {
              if (checkResult.isOk != true) {
                return checkResult;
              } else {
                return null;
              }
            }
          } else {
            return checkResult;
          }
        }
        return checkResult;
      })
      .filter((checkResult) => {
        if (filter != undefined) {
          if (filter.scheduledDateStart != undefined) {
            if (filter.scheduledDateEnd != undefined) {
              if (
                moment(checkResult.todo.startDate).isSame(
                  filter.scheduledDateStart,
                  "day"
                ) ||
                moment(checkResult.todo.startDate).isSame(
                  filter.scheduledDateEnd,
                  "day"
                ) ||
                moment(checkResult.todo.startDate).isBetween(
                  filter.scheduledDateStart,
                  filter.scheduledDateEnd,
                  "day"
                )
              ) {
                return checkResult;
              } else {
                return null;
              }
            }

            if (
              moment(checkResult.todo.startDate).isAfter(
                filter.scheduledDateStart
              )
            ) {
              return checkResult;
            } else {
              return null;
            }
          }
        }
        return checkResult;
      })
      .filter((checkResult) => {
        if (filter != undefined) {
          if (filter.completedDateStart != undefined) {
            if (filter.completedDateEnd != undefined) {
              if (
                moment(checkResult.completedDate).isSame(
                  filter.completedDateStart,
                  "day"
                ) ||
                moment(checkResult.completedDate).isSame(
                  filter.completedDateEnd,
                  "day"
                ) ||
                moment(checkResult.completedDate).isBetween(
                  filter.completedDateStart,
                  filter.completedDateEnd,
                  "day"
                )
              ) {
                return checkResult;
              } else {
                return null;
              }
            }

            if (
              moment(checkResult.completedDate).isAfter(
                filter.completedDateStart
              )
            ) {
              return checkResult;
            } else {
              return null;
            }
          }
        }
        return checkResult;
      });

  function sortByScheduled(scheduedDate) {
    resetSortType();
    setSortScheduedDateType(scheduedDate);
  }

  function sortByCompleted(completedDate) {
    resetSortType();
    setSortCompletedDateType(completedDate);
  }

  function sortByStatus(status) {
    resetSortType();
    setSortStatusType(status);
  }

  function resetSortType() {
    setSortScheduedDateType("");
    setSortCompletedDateType("");
    setSortStatusType("");
  }

  function showFilter(isFilterOn) {
    setIsFilterOn(isFilterOn);
  }

  console.log("filter-main", filter);
  const isFilterSet =
    filter != undefined &&
    (filter.scheduledDateStart != undefined ||
      filter.scheduledDateEnd != undefined ||
      filter.completedDateStart != undefined ||
      filter.completedDateEnd != undefined ||
      (filter.isOk != undefined && filter.isOk != -1))
      ? true
      : false;

  return (
    <React.Fragment>
      {passedCheckResults != undefined && passedCheckResults.length > 0 ? (
        <div class="tableFixHead">
          <Table>
            <thead>
              <tr>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "150px",
                  }}
                >
                  <b>Schedule date</b>
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "50px",
                  }}
                >
                  <TableSort
                    sort={sortByScheduled}
                    type={sortScheduedDateType}
                  />
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "150px",
                  }}
                >
                  <b>Complete date</b>
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "50px",
                  }}
                >
                  <TableSort
                    sort={sortByCompleted}
                    type={sortCompletedDateType}
                  />
                </th>
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  <b>Description</b>
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "100px",
                    textAlign: "right",
                  }}
                >
                  <b>Status</b>
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderRightColor: "#3c3b6e",
                    width: "50px",
                  }}
                >
                  <TableSort sort={sortByStatus} type={sortStatusType} />
                </th>
                {/* <td
                  style={{
                    minWidth: "65px",
                    textAlign: "right",
                    width: "65px",
                    borderLeftColor: "transparent",
                  }}
                >
                  <TableFilter
                    showFilter={showFilter}
                    isFilterOn={isFilterOn}
                    isFilterSet={isFilterSet}
                  />
                </td> */}
              </tr>
            </thead>
            <tbody>
              {passedCheckResults
                .sort((A, B) => {
                  if (
                    sortScheduedDateType != "" &&
                    sortScheduedDateType != undefined
                  ) {
                    if (A.todo.startDate > B.todo.startDate) {
                      if (sortScheduedDateType == "asc") {
                        return 1;
                      } else {
                        return -1;
                      }
                    } else {
                      if (sortScheduedDateType == "asc") {
                        return -1;
                      } else {
                        return 1;
                      }
                    }
                  }

                  if (
                    sortCompletedDateType != "" &&
                    sortCompletedDateType != undefined
                  ) {
                    if (A.completedDate > B.completedDate) {
                      if (sortCompletedDateType == "asc") {
                        return 1;
                      } else {
                        return -1;
                      }
                    } else {
                      if (sortCompletedDateType == "desc") {
                        return -1;
                      } else {
                        return 1;
                      }
                    }
                  }

                  if (sortStatusType != "" && sortStatusType != undefined) {
                    if (A.isOk) {
                      if (sortStatusType == "asc") {
                        return 1;
                      } else {
                        return -1;
                      }
                    } else {
                      if (sortStatusType == "asc") {
                        return -1;
                      } else {
                        return 1;
                      }
                    }
                  }
                })
                .map((checkResult) => {
                  return (
                    <tr>
                      <td
                        colSpan={2}
                        // style={{ width: "215px", minWidth: "215px" }}
                      >
                        <Form.Label size="sm">
                          {moment(checkResult.todo.startDate).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </Form.Label>
                      </td>
                      <td
                        colSpan={2}
                        // style={{ width: "215px", minWidth: "215px" }}
                      >
                        <Form.Label size="sm">
                          {moment(checkResult.completedDate).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          size="sm"
                          disabled="true"
                          style={{ width: "100%" }}
                          value={checkResult.description}
                          autoComplete="none"
                        />
                      </td>
                      <td colSpan={2} style={{ textAlign: "right" }}>
                        <Button
                          size="sm"
                          variant="link"
                          style={{
                            color: checkResult.isOk != false ? "green" : "red",
                          }}
                          onClick={() => viewCompletedDetails(checkResult)}
                        >
                          {checkResult.isOk != false ? "Passed" : "Failed"}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      ) : (
        <b style={{ color: "#b22234" }}>There are no completed checks</b>
      )}
    </React.Fragment>
  );
};

const thStyle = {
  backgroundColor: "lightgray",
  color: "black",
  border: "none",
  verticalAlign: "middle",
};
export default PassedCheckListControlDesktop;
