import ShoppingCartClient from "../../api/products/shoppingCartClient";

const shoppingCartClient = new ShoppingCartClient();

class ShoppingCartService {
  async closeCart(id) {
    try {
      const response = await shoppingCartClient.closeCart(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getActive() {
    try {
      const response = await shoppingCartClient.getActive();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new ShoppingCartService();
