import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";

const LegalRepInfoMobile = ({
  onChangeResident,
  onSaveLegalRepInfo,
  onExitLegRepPhone,
  residentState,
}) => {
  return (
    <Container
      style={{
        border: "solid",
        borderWidth: "1px",
        borderColor: "lightgray",
        height: "500px",
        marginBottom: "20px",
        marginTop: "20px",
      }}
    >
      <Row style={{ marginTop: "20px" }}>
        <Col style={{ width: "500px" }}>
          <Form.Group style={{ marginTop: "20px" }}>
            <Form.Label size="sm" style={{ width: "100%" }}>
              Full name:
            </Form.Label>
            <FormControl
              size="sm"
              name="legRepName"
              style={{ width: "100%" }}
              onChange={(e) => onChangeResident(e)}
              value={
                residentState.currResident &&
                residentState.currResident.legRepName
              }
              placeholder="full name"
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col style={{ width: "900px" }}>
          <Form.Group>
            <Form.Label size="sm">Address:</Form.Label>
            <FormControl
              size="sm"
              name="legRepAddress"
              style={{ width: "100%" }}
              onChange={(e) => onChangeResident(e)}
              value={
                residentState.currResident &&
                residentState.currResident.legRepAddress
              }
              placeholder="address"
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label size="sm">Phone:</Form.Label>
            <FormControl
              size="sm"
              name="legRepPhone"
              style={{ width: "60%" }}
              onBlur={onExitLegRepPhone}
              onChange={(e) => onChangeResident(e)}
              value={
                residentState.currResident &&
                residentState.currResident.legRepPhone
              }
              placeholder="phone"
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label size="sm">Email:</Form.Label>
            <FormControl
              size="sm"
              name="legRepEmail"
              style={{ width: "60%" }}
              onChange={(e) => onChangeResident(e)}
              value={
                residentState.currResident &&
                residentState.currResident.legRepEmail
              }
              placeholder="e-mail"
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      {residentState.isChangeMode ? (
        <Row>
          <Col>
            <Button
              size="sm"
              variant="success"
              style={{ width: "100%" }}
              onClick={() => onSaveLegalRepInfo()}
            >
              Save changes
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Container>
  );
};

export default LegalRepInfoMobile;
