import React from "react";
import moment from "moment";
import SelectMonthDesktop from "./selectMonthDesktop";
import WeekDaysDesktop from "./weekDaysDesktop";
import MonthDaysDesktop from "./monthDaysDesktop";

const CalendarViewDesktop = ({
  onShowTaskDetails,
  onChangeMonth,
  onChangeYear,
  onNextMonth,
  onPreviousMonth,
  todos,
  selectedMonth,
  selectedYear,
  months,
  selectedDate,
  weekdays,
}) => {
  const todosForTheMonth =
    todos &&
    todos
      .filter((todo) => {
        return (
          moment(todo.startDate).month() === selectedMonth &&
          moment(todo.startDate).year() === selectedYear
        );
      })
      .filter((todo) => todo.status != true);

  console.log("todosForTheMonth", todosForTheMonth && todosForTheMonth.length);

  return (
    <React.Fragment>
      <SelectMonthDesktop
        onChangeMonth={onChangeMonth}
        onChangeYear={onChangeYear}
        onNextMonth={onNextMonth}
        onPreviousMonth={onPreviousMonth}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        months={months}
        weekdays={weekdays}
      />
      <hr></hr>
      <WeekDaysDesktop />
      <MonthDaysDesktop
        onShowTaskDetails={onShowTaskDetails}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        todosForTheMonth={todosForTheMonth}
        selectedDate={selectedDate}
      />
    </React.Fragment>
  );
};

export default CalendarViewDesktop;
