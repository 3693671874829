import React from "react";
import { CardColumns } from "react-bootstrap";
import Header from "../header";
import MainPageItem from "../main/mainPageItem";
import useMainCompliance from "./hook/useMainCompliance";

const MainCompliance = ({ onOpenMenu, items }) => {
  // const { onOpenMenu, items } = useMainCompliance();
  return (
    <React.Fragment>
      {/* <Header /> */}
      <div>
        <CardColumns>
          {items.map((item, index) => (
            <MainPageItem item={item} onOpenMenu={onOpenMenu} />
          ))}
        </CardColumns>
      </div>
    </React.Fragment>
  );
};

export default MainCompliance;
