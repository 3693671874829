import { useState } from "react";
import ResidentFileMetadataService from "../../../services/compliance/documents/residentFileMetadataService";
import ResidentFileMetadataSignService from "../../../services/compliance/documents/residentFileMetadataSignService";
import ResidentFormService from "../../../services/compliance/documents/residentFormService";
import ResidentFormSignService from "../../../services/compliance/documents/residentFormSignService";
import FacilityContactsEmailsService from "../../../services/compliance/facilityContactsEmailsService";
import ResidentService from "../../../services/compliance/resident/residentService";
import StaffService from "../../../services/user/staffService";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import AuthenticationService from "../../../api/authenticationService";

const useComplianceForms = () => {
  const numOfRecordsPerPage = 7;
  const [complianceFormsState, setComplianceFormsState] = useState({
    currentTab: "generalInfo",
    emails: [],
  });
  const [lic625State, setLic625State] = useState({});
  const [lic625Base64ContentState, setLic625Base64ContentState] = useState("");

  const [lic603aState, setLic603aState] = useState({});
  const [lic603aBase64ContentState, setLic603aBase64ContentState] =
    useState("");

  const [lic624State, setLic624State] = useState({});
  const [lic624Base64ContentState, setLic624Base64ContentState] = useState("");

  const [lic624aState, setLic624aState] = useState({});
  const [lic624aBase64ContentState, setLic624aBase64ContentState] =
    useState("");

  const [lic622State, setLic622State] = useState({});
  const [lic622Base64ContentState, setLic622Base64ContentState] = useState("");

  const [lic613cState, setLic613cState] = useState({});
  const [lic613cBase64ContentState, setLic613cBase64ContentState] =
    useState("");

  const refreshPage = async () => {
    const residentFileMetadataSearch = (
      await ResidentFileMetadataService.getResidentFileSearchByFacility()
    ).sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      } else {
        return 1;
      }
    });

    const currUserList = await StaffService.getByUserName(
      sessionStorage.getItem("authenticatedUser")
    );

    setLic625State({});
    setLic603aState({});
    setLic624State({});
    setLic624aState({});
    setLic622State({});
    setLic613cState({});

    setComplianceFormsState({
      ...complianceFormsState,
      residentFileMetadataSearch: residentFileMetadataSearch,
      residentFileMetadataSearchResult: residentFileMetadataSearch,
      showSelectResidentAndForm: false,
      selectedResident: undefined,
      selectedForm: undefined,
      reportDetails: false,
      currResidentFileMetadata: {},
      currResident: {},
      currReportType: "",
      currentTab: "generalInfo",
      start: 0,
      end: numOfRecordsPerPage,
      paginationButtonsCount: Math.ceil(
        residentFileMetadataSearch.length / numOfRecordsPerPage
      ),
      currentPage: 0,
      currUserRole:
        currUserList && currUserList.length > 0
          ? currUserList[0].userRole
          : "F",
      showSignButton: true,
      pendingChanges: false,
    });
  };

  const onShowSelectResidentAndForm = async () => {
    const residents = await ResidentService.getAll();
    setComplianceFormsState({
      ...complianceFormsState,
      residents: residents,
      showSelectResidentAndForm: true,
      selectedResident: "",
      selectedForm: "",
      forms: [],
    });
  };

  const onHideSelectResidentAndForm = () => {
    setComplianceFormsState({
      ...complianceFormsState,
      showSelectResidentAndForm: false,
      selectedResident: undefined,
      selectedForm: undefined,
    });
  };

  const onChangeResident = async (e) => {
    const selectedResidentId =
      e.target.value == -1 ? undefined : e.target.value;
    setComplianceFormsState({
      ...complianceFormsState,
      selectedResident: selectedResidentId,
      forms:
        selectedResidentId == -1
          ? []
          : selectedResidentId == -2
          ? -2
          : await ResidentFormService.getByResident(selectedResidentId),
    });
  };

  const onChangeForm = (e) => {
    setComplianceFormsState({
      ...complianceFormsState,
      selectedForm: e.target.value == -1 ? undefined : e.target.value,
    });
  };

  const onCreateReport = async () => {
    if (
      complianceFormsState.selectedForm == undefined ||
      complianceFormsState.selectedForm == "" ||
      complianceFormsState.selectedForm < 0
    ) {
      alert("Please select resident and form");
      return;
    }
    await ResidentFileMetadataService.createReport(
      complianceFormsState.selectedForm
    );

    await refreshPage();
  };

  const onViewPdf = () => {
    setComplianceFormsState({
      ...complianceFormsState,
      viewPdf: true,
    });
  };

  const onHidePdf = () => {
    setComplianceFormsState({
      ...complianceFormsState,
      viewPdf: false,
    });
  };

  const onShowReportDetails = async (residentFileMetadataId, reportType) => {
    setComplianceFormsState({
      ...complianceFormsState,
      isOpeningReportDetails: true,
      openingId: residentFileMetadataId,
    });
    const currResidentFileMetadata = await ResidentFileMetadataService.getById(
      residentFileMetadataId
    );

    if (reportType == "625 Appraisal/Needs and Services Plan") {
      setLic625State(JSON.parse(currResidentFileMetadata.content));
      if (currResidentFileMetadata.status == "SIGNED") {
        await onGenerateLic625Preview(false, currResidentFileMetadata.id);
      }
    }

    if (reportType == "603A Resident Appraisal") {
      setLic603aState(JSON.parse(currResidentFileMetadata.content));
      if (currResidentFileMetadata.status == "SIGNED") {
        await onGenerateLic603aPreview(false, currResidentFileMetadata.id);
      }
    }

    if (reportType == "624 Unusual Incident/Injury Report") {
      setLic624State(JSON.parse(currResidentFileMetadata.content));
      if (currResidentFileMetadata.status == "SIGNED") {
        await onGenerateLic624Preview(false, currResidentFileMetadata.id);
      }
    }

    if (reportType == "624A Death Report") {
      setLic624aState(JSON.parse(currResidentFileMetadata.content));
      if (currResidentFileMetadata.status == "SIGNED") {
        await onGenerateLic624aPreview(false, currResidentFileMetadata.id);
      }
    }

    if (
      reportType == "622 Centrally Stored Medication and Destruction Record"
    ) {
      setLic622State(JSON.parse(currResidentFileMetadata.content));
      if (currResidentFileMetadata.status == "SIGNED") {
        await onGenerateLic622Preview(false, currResidentFileMetadata.id);
      }
    }

    if (
      reportType ==
      "613C Personal Rights Residential Care Facilities For The Elderly"
    ) {
      setLic613cState(JSON.parse(currResidentFileMetadata.content));
      if (currResidentFileMetadata.status == "SIGNED") {
        await onGenerateLic613cPreview(false, currResidentFileMetadata.id);
      }
    }

    const residentFileMetadataSign =
      await ResidentFileMetadataSignService.getAllByResidentFileMetadataIdAndStaffId(
        currResidentFileMetadata.id
      );

    const currResident =
      await ResidentFileMetadataService.getResidentFromResidentFileMetadataId(
        currResidentFileMetadata.id
      );

    setComplianceFormsState({
      ...complianceFormsState,
      currResidentFileMetadata: currResidentFileMetadata,
      currResident: currResident,
      currReportType: reportType,
      reportDetails: true,
      isOpeningReportDetails: false,
      isReportSignedByMe:
        residentFileMetadataSign != undefined &&
        residentFileMetadataSign.length > 0,
    });
  };

  const onChangeLic624Input = (fieldName, e, hardcoded, isCheckBox = false) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });
    setLic624State({
      ...lic624State,
      [fieldName]: hardcoded
        ? e
        : isCheckBox
        ? e.target.checked
        : e.target.value,
    });
  };

  const onChangeLic625Input = (fieldName, e, hardcoded) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });
    setLic625State({
      ...lic625State,
      [fieldName]: hardcoded ? e : e.target.value,
    });
  };

  const onChangeLic613cInput = (fieldName, e, hardcoded) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });
    setLic613cState({
      ...lic613cState,
      [fieldName]: hardcoded ? e : e.target.value,
    });
  };

  const onChangeLic622Input = (fieldName, e, hardcoded) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });
    setLic622State({
      ...lic622State,
      [fieldName]: hardcoded ? e : e.target.value,
    });
  };

  const onChangeLic624aInput = (
    fieldName,
    e,
    hardcoded,
    isCheckBox = false
  ) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });
    setLic624aState({
      ...lic624aState,
      [fieldName]: hardcoded
        ? e
        : isCheckBox
        ? e.target.checked
        : e.target.value,
    });
  };

  const onChangeLic603aInput = (fieldName, e, hardcoded) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });
    setLic603aState({
      ...lic603aState,
      [fieldName]: hardcoded ? e : e.target.value,
    });
  };

  const onChangeLic603aDateInput = (fieldName, e) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });

    setLic603aState({
      ...lic603aState,
      [fieldName]: e,
    });
  };

  const onChangeLic625DateInput = (fieldName, e) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });

    setLic625State({
      ...lic625State,
      [fieldName]: e,
    });
  };

  const onChangeLic613cDateInput = (fieldName, e) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });

    setLic613cState({
      ...lic613cState,
      [fieldName]: e,
    });
  };

  const onChangeLic624aDateInput = (fieldName, e) => {
    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: true,
    });

    setLic624aState({
      ...lic624aState,
      [fieldName]: e,
    });
  };

  const onSaveLic625 = async (genPreview = true) => {
    const residentFileMetadataForSave = buildResidentFileMetadataFromState(
      complianceFormsState,
      lic625State
    );

    await ResidentFileMetadataService.save(residentFileMetadataForSave);

    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: false,
      showCopy: false,
    });

    setLic625Base64ContentState("");

    if (complianceFormsState.currentTab == "printout" && genPreview) {
      if (
        complianceFormsState.currReportType ==
        "625 Appraisal/Needs and Services Plan"
      ) {
        await onGenerateLic625Preview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      }
    }
  };

  const onSaveLic622 = async (genPreview = true) => {
    const residentFileMetadataForSave = buildResidentFileMetadataFromState(
      complianceFormsState,
      lic622State
    );

    await ResidentFileMetadataService.save(residentFileMetadataForSave);

    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: false,
      showCopy: false,
    });

    setLic622Base64ContentState("");

    if (complianceFormsState.currentTab == "printout" && genPreview) {
      if (
        complianceFormsState.currReportType ==
        "622 Centrally Stored Medication and Destruction Record"
      ) {
        await onGenerateLic622Preview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      }
    }
  };

  const onSaveLic603a = async (genPreview = true) => {
    const residentFileMetadataForSave = buildResidentFileMetadataFromState(
      complianceFormsState,
      lic603aState
    );

    await ResidentFileMetadataService.save(residentFileMetadataForSave);

    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: false,
      showCopy: false,
    });

    setLic603aBase64ContentState("");

    if (complianceFormsState.currentTab == "printout" && genPreview) {
      if (complianceFormsState.currReportType == "603A Resident Appraisal") {
        await onGenerateLic603aPreview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      }
    }
  };

  const onSaveLic613c = async (genPreview = true) => {
    const residentFileMetadataForSave = buildResidentFileMetadataFromState(
      complianceFormsState,
      lic613cState
    );

    await ResidentFileMetadataService.save(residentFileMetadataForSave);

    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: false,
      showCopy: false,
    });

    setLic613cBase64ContentState("");

    if (complianceFormsState.currentTab == "printout" && genPreview) {
      if (
        complianceFormsState.currReportType ==
        "613C Personal Rights Residential Care Facilities For The Elderly"
      ) {
        await onGenerateLic613cPreview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      }
    }
  };

  const onSaveLic624 = async (genPreview = true) => {
    const residentFileMetadataForSave = buildResidentFileMetadataFromState(
      complianceFormsState,
      lic624State
    );

    await ResidentFileMetadataService.save(residentFileMetadataForSave);

    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: false,
      showCopy: false,
    });

    setLic624Base64ContentState("");

    if (complianceFormsState.currentTab == "printout" && genPreview) {
      if (
        complianceFormsState.currReportType ==
        "624 Unusual Incident/Injury Report"
      ) {
        await onGenerateLic624Preview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      }
    }
  };

  const onSaveLic624a = async (genPreview = true) => {
    const residentFileMetadataForSave = buildResidentFileMetadataFromState(
      complianceFormsState,
      lic624aState
    );

    await ResidentFileMetadataService.save(residentFileMetadataForSave);

    setComplianceFormsState({
      ...complianceFormsState,
      pendingChanges: false,
      showCopy: false,
    });

    setLic624aBase64ContentState("");

    if (complianceFormsState.currentTab == "printout" && genPreview) {
      if (complianceFormsState.currReportType == "624A Death Report") {
        await onGenerateLic624aPreview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      }
    }
  };

  const onGenerateLic625Preview = async (pendingChanges, id) => {
    let base64File;
    if (pendingChanges) {
      await onSaveLic625();
    }

    base64File = await ResidentFileMetadataService.getPreview(id);

    setComplianceFormsState({ ...complianceFormsState, pendingChanges: false });
    setLic625Base64ContentState(base64File);
  };

  const onGenerateLic613cPreview = async (pendingChanges, id) => {
    let base64File;
    if (pendingChanges) {
      await onSaveLic613c();
    }

    base64File = await ResidentFileMetadataService.getPreview(id);

    setComplianceFormsState({ ...complianceFormsState, pendingChanges: false });
    setLic613cBase64ContentState(base64File);
  };

  const onGenerateLic622Preview = async (pendingChanges, id) => {
    let base64File;
    if (pendingChanges) {
      await onSaveLic622();
    }

    base64File = await ResidentFileMetadataService.getPreview(id);

    setComplianceFormsState({ ...complianceFormsState, pendingChanges: false });
    setLic622Base64ContentState(base64File);
  };

  const onGenerateLic624Preview = async (pendingChanges, id) => {
    let base64File;
    if (pendingChanges) {
      await onSaveLic624();
    }

    base64File = await ResidentFileMetadataService.getPreview(id);

    setComplianceFormsState({ ...complianceFormsState, pendingChanges: false });
    setLic624Base64ContentState(base64File);
  };

  const onGenerateLic624aPreview = async (pendingChanges, id) => {
    let base64File;
    if (pendingChanges) {
      await onSaveLic624a();
    }

    base64File = await ResidentFileMetadataService.getPreview(id);

    setComplianceFormsState({ ...complianceFormsState, pendingChanges: false });
    setLic624aBase64ContentState(base64File);
  };

  const onGenerateLic603aPreview = async (pendingChanges, id) => {
    let base64File;
    if (pendingChanges) {
      await onSaveLic603a();
    }

    base64File = await ResidentFileMetadataService.getPreview(id);

    setComplianceFormsState({ ...complianceFormsState, pendingChanges: false });
    setLic603aBase64ContentState(base64File);
  };

  const onBack = async () => {
    if (complianceFormsState.pendingChanges) {
      showConfirmAlert(
        "Pending changes?",
        "There are pending changes, do you want to save them before you leave?",
        "Yes, save it",
        onConfirmSaveChangesOnBack,
        "No, cancel",
        refreshPage
      );

      function showConfirmAlert(title, message, yesMsg, onYes, noMsg, onNo) {
        confirmAlert({
          title: title,
          message: message,
          buttons: [
            {
              label: yesMsg,
              onClick: () => onYes(),
            },
            {
              label: noMsg,
              onClick: () => onNo(),
            },
          ],
        });
      }
    } else {
      await refreshPage();
    }
  };

  const onConfirmSaveChangesOnBack = async () => {
    if (
      complianceFormsState.currReportType ==
      "625 Appraisal/Needs and Services Plan"
    ) {
      await onSaveLic625();
    } else if (
      complianceFormsState.currReportType == "603A Resident Appraisal"
    ) {
      await onSaveLic603a();
    } else if (
      complianceFormsState.currReportType ==
      "624 Unusual Incident/Injury Report"
    ) {
      await onSaveLic624();
    } else if (complianceFormsState.currReportType == "624A Death Report") {
      await onSaveLic624a();
    } else if (
      complianceFormsState.currReportType ==
      "622 Centrally Stored Medication and Destruction Record"
    ) {
      await onSaveLic622();
    } else if (
      complianceFormsState.currReportType ==
      "613C Personal Rights Residential Care Facilities For The Elderly"
    ) {
      await onSaveLic613c();
    }
    await refreshPage();
  };

  const onTabSelected = async (key) => {
    if (key == "printout") {
      if (
        complianceFormsState.currReportType ==
        "625 Appraisal/Needs and Services Plan"
      ) {
        await onSaveLic625();
        await onGenerateLic625Preview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      } else if (
        complianceFormsState.currReportType == "603A Resident Appraisal"
      ) {
        await onSaveLic603a();
        await onGenerateLic603aPreview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      } else if (
        complianceFormsState.currReportType ==
        "624 Unusual Incident/Injury Report"
      ) {
        await onSaveLic624();
        await onGenerateLic624Preview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      } else if (complianceFormsState.currReportType == "624A Death Report") {
        await onSaveLic624a();
        await onGenerateLic624aPreview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      } else if (
        complianceFormsState.currReportType ==
        "622 Centrally Stored Medication and Destruction Record"
      ) {
        await onSaveLic622();
        await onGenerateLic622Preview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      } else if (
        complianceFormsState.currReportType ==
        "613C Personal Rights Residential Care Facilities For The Elderly"
      ) {
        await onSaveLic613c();
        await onGenerateLic613cPreview(
          complianceFormsState.pendingChanges,
          complianceFormsState.currResidentFileMetadata.id
        );
      }
    }
    setComplianceFormsState({
      ...complianceFormsState,
      currentTab: key,
      pendingChanges: false,
    });
  };

  const onHideSelectSignature = () => {
    setComplianceFormsState({
      ...complianceFormsState,
      showSelectSignature: false,
    });
  };

  const onSelectSignature = (e) => {
    setComplianceFormsState({
      ...complianceFormsState,
      selectedSignature: e.target.value,
    });
  };

  const onSignLic603a = async () => {
    await onSaveLic603a(false);
    await onSign("LIC603A");
  };

  const onSignLic625 = async () => {
    await onSaveLic625(false);
    await onSign("LIC625");
  };

  const onSignLic622 = async () => {
    await onSaveLic622(false);
    await onSign("LIC622");
  };

  const onSignLic613c = async () => {
    await onSaveLic613c(false);
    await onSign("LIC613C");
  };

  const onSign = async (reportType) => {
    let signatureKey = [];
    let signatureImage = await StaffService.getSignature();
    if (signatureImage == undefined || signatureImage.length == 0) {
      onShowDepositSignature();
      return;
    }
    if (complianceFormsState.currUserRole == "F") {
      if (complianceFormsState.selectedSignature == undefined) {
        const availableSignatureKeys =
          await ResidentFormSignService.getAvailableSignatureKeys({
            residentFileMetadataId:
              complianceFormsState.currResidentFileMetadata.id,
            reportType: reportType,
          });
        if (reportType == "LIC622") {
          signatureKey = availableSignatureKeys.map((r) => r.signatureKey);
        } else {
          if (availableSignatureKeys.length > 1) {
            setComplianceFormsState({
              ...complianceFormsState,
              showSelectSignature: true,
              availableSignatureKeys: availableSignatureKeys,
            });
            return;
          } else {
            signatureKey = availableSignatureKeys.map((r) => r.signatureKey);
          }
        }
      } else {
        signatureKey = complianceFormsState.selectedSignature;
      }
    }

    const residentFileMetadata = await ResidentFileMetadataService.signReport({
      residentFileMetadataID: complianceFormsState.currResidentFileMetadata.id,
      signatureKey: signatureKey,
      reportType: reportType,
    });
    const signedBase64Content = await ResidentFileMetadataService.getPreview(
      complianceFormsState.currResidentFileMetadata.id
    );
    if (signedBase64Content == "") {
      onShowDepositSignature();
    } else {
      if (reportType == "LIC625") {
        setLic625Base64ContentState(signedBase64Content);
      } else if (reportType == "LIC603A") {
        setLic603aBase64ContentState(signedBase64Content);
      } else if (reportType == "LIC622") {
        setLic622Base64ContentState(signedBase64Content);
      } else if (reportType == "LIC613C") {
        setLic613cBase64ContentState(signedBase64Content);
      }
    }
    setComplianceFormsState({
      ...complianceFormsState,
      currResidentFileMetadata: residentFileMetadata,
      isReportSignedByMe: true,
      showSelectSignature: false,
      showDepositSignature: false,
    });
  };

  const onShowDepositSignature = () => {
    setComplianceFormsState({
      ...complianceFormsState,
      showDepositSignature: true,
    });
  };

  const onHideDepositSignature = () => {
    setComplianceFormsState({
      ...complianceFormsState,
      showDepositSignature: false,
    });
  };

  const onShowShareReport = async () => {
    const facilityContactsEmails = await FacilityContactsEmailsService.getAll();
    setComplianceFormsState({
      ...complianceFormsState,
      showShareReport: true,
      selectedUser:
        complianceFormsState.currResident &&
        complianceFormsState.currResident.legRepEmail,
      emails:
        facilityContactsEmails &&
        facilityContactsEmails.length > 0 &&
        facilityContactsEmails.map((fcs) => fcs.email),
    });
  };

  const onShareReport = async () => {
    if (
      complianceFormsState.selectedUser === undefined ||
      complianceFormsState.selectedUser == ""
    ) {
      alert("Please select/add e-mail address");
      return;
    }

    await ResidentFileMetadataService.shareReport({
      residentFileMetadataID: complianceFormsState.currResidentFileMetadata.id,
      email: complianceFormsState.selectedUser,
    });
    onHideShareReport();
  };

  const onSelectShared = (e) => {
    const selectedUser =
      e.length == 0 ? "" : e[0].name == undefined ? e[0] : e[0].name;
    if (
      selectedUser
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setComplianceFormsState({
        ...complianceFormsState,
        selectedUser: selectedUser,
        emails: [...complianceFormsState.emails]
          .filter((e) => e != selectedUser)
          .concat(selectedUser),
      });
    } else {
      if (selectedUser != "") alert("Please enter valid e-mail address");
    }
  };

  const onHideShareReport = () => {
    setComplianceFormsState({
      ...complianceFormsState,
      showShareReport: false,
      selectedUser: undefined,
    });
  };

  const onSaveSignature = async (base64Signature) => {
    if (
      base64Signature ==
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAABkCAYAAABwx8J9AAAAAXNSR0IArs4c6QAABEZJREFUeF7t1QENAAAIwzDwbxodLMXBe5LvOAIECBAgQOC9wL5PIAABAgQIECAwBt0TECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQOEexAGVgyV5WAAAAAElFTkSuQmCC"
    ) {
      alert("Signature field is empty, please write your signature!");
      return;
    }

    await StaffService.uploadSignature({
      base64Content: base64Signature,
    });

    if (
      complianceFormsState.currReportType ===
      "625 Appraisal/Needs and Services Plan"
    ) {
      await onSignLic625();
    } else if (
      complianceFormsState.currReportType === "603A Resident Appraisal"
    ) {
      await onSignLic603a();
    } else if (
      complianceFormsState.currReportType ===
      "622 Centrally Stored Medication and Destruction Record"
    ) {
      await onSignLic622();
    } else if (
      complianceFormsState.currReportType ===
      "613C Personal Rights Residential Care Facilities For The Elderly"
    ) {
      await onSignLic613c();
    } else {
      onHideDepositSignature();
    }
  };

  const onFilterResults = async (e) => {
    const filterCriteria = e.target.value;
    const result = complianceFormsState.residentFileMetadataSearch.filter((v) =>
      (
        v.residentFirstName +
        " " +
        v.residentLastName +
        " - " +
        v.reportType +
        " " +
        v.status
      )
        .toLowerCase()
        .includes(filterCriteria.toLowerCase())
    );
    if (filterCriteria == "") {
      await refreshPage();
    } else {
      setComplianceFormsState({
        ...complianceFormsState,
        residentFileMetadataSearchResult: result,
        paginationButtonsCount: Math.ceil(result.length / numOfRecordsPerPage),
      });
    }
  };

  const onDeleteRecord = async (id) => {
    showConfirmAlert(
      "Are you sure?",
      "Do you really want to delete this record? This operation cannot be undone",
      "Yes, delete it",
      onConfirmDelete,
      "No, cancel",
      onCancelDelete
    );

    function showConfirmAlert(title, message, yesMsg, onYes, noMsg, onNo) {
      confirmAlert({
        title: title,
        message: message,
        buttons: [
          {
            label: yesMsg,
            onClick: () => onYes(id),
          },
          {
            label: noMsg,
            onClick: () => onNo(),
          },
        ],
      });
    }
  };

  const onConfirmDelete = async (id) => {
    await ResidentFileMetadataService.delete(id);
    await refreshPage();
  };

  const onCancelDelete = () => {};

  const onChangePage = (page) => {
    if (page < 0) page = 0;
    if (page > complianceFormsState.paginationButtonsCount - 1)
      page = complianceFormsState.paginationButtonsCount - 1;
    setComplianceFormsState({
      ...complianceFormsState,
      start: page * numOfRecordsPerPage,
      end:
        complianceFormsState.residentFileMetadataSearch.length <
        page * numOfRecordsPerPage + numOfRecordsPerPage
          ? complianceFormsState.residentFileMetadataSearch.length
          : page * numOfRecordsPerPage + numOfRecordsPerPage,
      currentPage: page,
    });
  };

  const onUnSign = async (reportType) => {
    const residentFileMetadata = await ResidentFileMetadataService.unSignReport(
      {
        residentFileMetadataID:
          complianceFormsState.currResidentFileMetadata.id,
        signatureKey: [],
        reportType: reportType,
      }
    );
    const signedBase64Content = await ResidentFileMetadataService.getPreview(
      complianceFormsState.currResidentFileMetadata.id
    );

    if (reportType == "LIC625") {
      setLic625Base64ContentState(signedBase64Content);
    } else if (reportType == "LIC603A") {
      setLic603aBase64ContentState(signedBase64Content);
    } else if (reportType == "LIC622") {
      setLic622Base64ContentState(signedBase64Content);
    } else if (reportType == "LIC613C") {
      setLic613cBase64ContentState(signedBase64Content);
    }
    setComplianceFormsState({
      ...complianceFormsState,
      currResidentFileMetadata: residentFileMetadata,
      currentTab: "generalInfo",
      isReportSignedByMe: false,
    });
  };

  const onShowCopy = async () => {
    const residentFileMetadataList =
      await ResidentFileMetadataService.getByTemplateName({
        residentFileMetadataId:
          complianceFormsState.currResidentFileMetadata.id,
        templateName: complianceFormsState.currReportType,
      });

    setComplianceFormsState({
      ...complianceFormsState,
      showCopy: true,
      reports:
        residentFileMetadataList &&
        residentFileMetadataList.filter(
          (rfm) => rfm.id != complianceFormsState.currResidentFileMetadata.id
        ),
    });
  };

  const onHideCopy = async () => {
    setComplianceFormsState({
      ...complianceFormsState,
      showCopy: false,
      selectedReport: "",
    });
  };

  const onConfirmCopy = async () => {
    const residentFileMetadata = await ResidentFileMetadataService.copy({
      idFrom: complianceFormsState.selectedReport,
      idTo: complianceFormsState.currResidentFileMetadata.id,
    });

    if (residentFileMetadata != undefined && residentFileMetadata != null) {
      if (
        complianceFormsState.currReportType ==
        "625 Appraisal/Needs and Services Plan"
      ) {
        setLic625State(JSON.parse(residentFileMetadata.content));
        // await onSaveLic625();
        setComplianceFormsState({
          ...complianceFormsState,
          currResidentFileMetadata: residentFileMetadata,
          selectedReport: "",
          showCopy: false,
        });
      } else if (
        complianceFormsState.currReportType == "603A Resident Appraisal"
      ) {
        setLic603aState(JSON.parse(residentFileMetadata.content));
        // await onSaveLic603a();
        setComplianceFormsState({
          ...complianceFormsState,
          currResidentFileMetadata: residentFileMetadata,
          selectedReport: "",
          showCopy: false,
        });
      } else if (
        complianceFormsState.currReportType ==
        "624 Unusual Incident/Injury Report"
      ) {
        setLic624State(JSON.parse(residentFileMetadata.content));
        //await onSaveLic624();
        setComplianceFormsState({
          ...complianceFormsState,
          currResidentFileMetadata: residentFileMetadata,
          selectedReport: "",
          showCopy: false,
          pendingChanges: true,
        });
      } else if (complianceFormsState.currReportType == "624A Death Report") {
        setLic624aState(JSON.parse(residentFileMetadata.content));
        //await onSaveLic624a();
        setComplianceFormsState({
          ...complianceFormsState,
          currResidentFileMetadata: residentFileMetadata,
          selectedReport: "",
          showCopy: false,
          pendingChanges: true,
        });
      } else if (
        complianceFormsState.currReportType ==
        "622 Centrally Stored Medication and Destruction Record"
      ) {
        setLic622State(JSON.parse(residentFileMetadata.content));
        //await onSaveLic622();
        setComplianceFormsState({
          ...complianceFormsState,
          currResidentFileMetadata: residentFileMetadata,
          selectedReport: "",
          showCopy: false,
          pendingChanges: true,
        });
      } else if (
        complianceFormsState.currReportType ==
        "613C Personal Rights Residential Care Facilities For The Elderly"
      ) {
        setLic613cState(JSON.parse(residentFileMetadata.content));
        //await onSaveLic622();
        setComplianceFormsState({
          ...complianceFormsState,
          currResidentFileMetadata: residentFileMetadata,
          selectedReport: "",
          showCopy: false,
          pendingChanges: true,
        });
      }
    } else {
      alert("Unable to copy the data");
    }
  };

  const onChangeReport = (e) => {
    setComplianceFormsState({
      ...complianceFormsState,
      selectedReport: e.target.value,
    });
  };

  const onShowSendEmail = async () => {
    const facilityContactsEmails = await FacilityContactsEmailsService.getAll();
    setComplianceFormsState({
      ...complianceFormsState,
      showSendEmail: true,
      emails: facilityContactsEmails.map((fcs) => fcs.email),
    });
  };

  const onHideSendEmail = () => {
    setComplianceFormsState({
      ...complianceFormsState,
      showSendEmail: false,
    });
  };

  const onSendEmail = async () => {
    await ResidentFileMetadataService.sendReportOnEmail({
      residentFileMetadataID: complianceFormsState.currResidentFileMetadata.id,
      email: complianceFormsState.selectedUser,
    });
    onHideSendEmail();
  };

  function buildResidentFileMetadataFromState(complianceFormsState, licState) {
    var residentFileMetadata = {
      ...complianceFormsState.currResidentFileMetadata,
    };
    delete licState.base64Content;
    residentFileMetadata["content"] = JSON.stringify(licState);
    return residentFileMetadata;
  }

  return {
    onShowSelectResidentAndForm,
    onHideSelectResidentAndForm,
    onChangeResident,
    onChangeForm,
    onCreateReport,
    onViewPdf,
    onHidePdf,
    onShowReportDetails,
    onChangeLic625Input,
    onChangeLic625DateInput,
    onSaveLic625,
    onBack,
    onGenerateLic625Preview,
    onTabSelected,
    onSignLic625,
    onHideDepositSignature,
    onSelectShared,
    onShowShareReport,
    onHideShareReport,
    onShareReport,
    onSaveSignature,
    onFilterResults,
    onDeleteRecord,
    onChangePage,
    onUnSign,
    onHideSelectSignature,
    onSelectSignature,
    onShowCopy,
    onHideCopy,
    onConfirmCopy,
    onChangeReport,
    onChangeLic603aInput,
    onChangeLic603aDateInput,
    onSaveLic603a,
    onSignLic603a,
    onChangeLic624Input,
    onSaveLic624,
    onSaveLic624a,
    onChangeLic624aInput,
    onChangeLic622Input,
    onSaveLic622,
    onSignLic622,
    onChangeLic613cInput,
    onChangeLic613cDateInput,
    onSaveLic613c,
    onSignLic613c,
    onShowSendEmail,
    onHideSendEmail,
    onSendEmail,
    refreshPage,
    complianceFormsState,
    lic625State,
    lic625Base64ContentState,
    lic603aState,
    lic603aBase64ContentState,
    lic624State,
    lic624Base64ContentState,
    lic624aState,
    lic624aBase64ContentState,
    lic622State,
    lic622Base64ContentState,
    lic613cState,
    lic613cBase64ContentState,
  };
};

export default useComplianceForms;
