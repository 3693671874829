import StaffClient from "../../api/user/staffClient";

const staffClient = new StaffClient();

class StaffService {
  async delete(id) {
    try {
      const response = await staffClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async validateUser(user) {
    if (user["name"] == undefined || user.name.trim().length == 0) {
      return { isError: true, msg: "Please enter name!" };
    }

    if (user["startDate"] == undefined && user["userRole"] != "F") {
      return { isError: true, msg: "Please enter start date!" };
    }

    if (!user.isDisabled && user.isDisabled != undefined) {
      if (user["username"] == undefined || user.username.trim().length == 0) {
        return { isError: true, msg: "Please enter user name!" };
      }

      const dbUser = await this.getByUserName(user.username);
      if (
        dbUser.filter((user) => !user.isDisabled).length > 0 &&
        user.id == undefined
      ) {
        return {
          isError: true,
          msg:
            "Username " +
            user.username +
            " is already taken, please choose different user name!",
        };
      }
    }

    return { isError: false };
  }

  validatePassword(user) {
    if (!user.isDisabled && user.isDisabled != undefined) {
      if (user.password == undefined || user.password.trim().length == 0) {
        return {
          isError: true,
          msg: "The passwords is empty, please enter password",
        };
      }

      if (
        user.confirmPassword == undefined ||
        user.confirmPassword.trim().length == 0
      ) {
        return {
          isError: true,
          msg: "Please confirm the password",
        };
      }

      if (user.password != user.confirmPassword) {
        return {
          isError: true,
          msg: "Passwords doesn't match",
        };
      }

      var strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );

      if (!strongRegex.test(user.password)) {
        return {
          isError: true,
          msg: "Passwords needs to be at least 8 characters long, needs to contain at least one uppercase letter, at least one lowercase letter, at least one special character(?=.*[!@#$%^&*]) and at least one number",
        };
      }
    }
    return { isError: false };
  }

  changePassword(repo) {
    return staffClient.changePassword(repo);
  }

  async save(repo) {
    let response = "";
    try {
      if (repo.hasOwnProperty("id") && repo.id != undefined) {
        response = await staffClient.update(repo, repo.id);
      } else {
        response = await staffClient.insert(repo);
      }

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async uploadSignature(repo) {
    try {
      const response = await staffClient.uploadSignature(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await staffClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getById(id) {
    try {
      const response = await staffClient.getById(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getByUserName(userName) {
    try {
      const response = await staffClient.getByUserName(userName);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getSignature() {
    try {
      const response = await staffClient.getSignature();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async changePassword(repo) {
    try {
      const response = await staffClient.changePassword(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async validateChangePassword(changePassword) {
    if (changePassword == undefined) {
      return {
        isError: true,
        errMessage: "Please enter password",
      };
    }

    if (
      changePassword.newPassword == undefined ||
      changePassword.newPassword.length == 0
    ) {
      return {
        isError: true,
        errMessage: "Please enter new password",
      };
    }

    if (
      changePassword.confirmNewPassword == undefined ||
      changePassword.confirmNewPassword.length == 0
    ) {
      return {
        isError: true,
        errMessage: "Please confirm the new password",
      };
    }

    if (changePassword.newPassword != changePassword.confirmNewPassword) {
      return {
        isError: true,
        errMessage: "Passwords don't match",
      };
    }

    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (!strongRegex.test(changePassword.newPassword)) {
      return {
        isError: true,
        errMessage:
          "Passwords needs to be at least 8 characters long, needs to contain at least one uppercase letter, at least one lowercase letter, at least one special character(?=.*[!@#$%^&*]) and at least one number",
      };
    }

    return { isError: false };
  }

  sysAdminEmail() {
    return staffClient.sysAdminEmail();
  }

  verify(repo) {
    return staffClient.verify(repo);
  }
}

export default new StaffService();
