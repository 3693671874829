import React from "react";
import { Container, Row, Form, Col } from "react-bootstrap";

const Comment = ({ onChangeInput, lic624State, complianceFormsState }) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>LICENSEE/SUPERVISOR COMMENTS:</b>
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("licenseeComment", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic624State && lic624State.licenseeComment}
              as="textarea"
              rows={13}
              style={{ width: "100%" }}
              maxLength={1560}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>
            <b>NAME OF ATTENDING PHYSICIAN</b>
          </Form.Label>
          <Form.Control
            name="attendingPhysician"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("attendingPhysician", e)}
            value={lic624State && lic624State.attendingPhysician}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <b>REPORT SUBMITTED BY:</b>
        </Col>
        <Col md={6}>
          <Form.Label>
            <b>NAME AND TITLE</b>
          </Form.Label>
          <Form.Control
            name="submittedByNameAndTitle"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("submittedByNameAndTitle", e)}
            value={lic624State && lic624State.submittedByNameAndTitle}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col md={3}>
          <Form.Label>
            <b>DATE</b>
          </Form.Label>
          <Form.Control
            name="submittedByDate"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("submittedByDate", e)}
            value={lic624State && lic624State.submittedByDate}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <b>REPORT REVIEWED/APPROVED BY:</b>
        </Col>
        <Col md={6}>
          <Form.Label>
            <b>NAME AND TITLE</b>
          </Form.Label>
          <Form.Control
            name="reviewedByNameAndTitle"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("reviewedByNameAndTitle", e)}
            value={lic624State && lic624State.reviewedByNameAndTitle}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col md={3}>
          <Form.Label>
            <b>DATE</b>
          </Form.Label>
          <Form.Control
            name="reviewedByDate"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("reviewedByDate", e)}
            value={lic624State && lic624State.reviewedByDate}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <b>
            AGENCIES/INDIVIDUALS NOTIFIED (SPECIFY NAME AND TELEPHONE NUMBER)
          </b>
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <Form.Check
            label="LICENSING"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="licensing"
            onChange={(e) => onChangeInput("licensing", e, false, true)}
            checked={lic624State != undefined && lic624State.licensing}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="licensingComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("licensingComment", e)}
            value={lic624State && lic624State.licensingComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col xs={2}>
          <Form.Check
            label="ADULT/CHILD PROTECTIVE SERVICES"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="protectiveService"
            onChange={(e) => onChangeInput("protectiveService", e, false, true)}
            checked={lic624State != undefined && lic624State.protectiveService}
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="protectiveServiceComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("protectiveServiceComment", e)}
            value={lic624State && lic624State.protectiveServiceComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <Form.Check
            label="LONG TERM CARE OMBUDSMAN"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="longTermCare"
            onChange={(e) => onChangeInput("longTermCare", e, false, true)}
            checked={lic624State != undefined && lic624State.longTermCare}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="longTermCareComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("longTermCareComment", e)}
            value={lic624State && lic624State.longTermCareComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col xs={2}>
          <Form.Check
            label="PARENT/GUARDIAN/CONSERVATOR"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="conservator"
            onChange={(e) => onChangeInput("conservator", e, false, true)}
            checked={lic624State != undefined && lic624State.conservator}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="conservatorComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("conservatorComment", e)}
            value={lic624State && lic624State.conservatorComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "60px" }}>
        <Col xs={2}>
          <Form.Check
            label="LAW ENFORCEMENT"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="lawEnforcement"
            onChange={(e) => onChangeInput("lawEnforcement", e, false, true)}
            checked={lic624State != undefined && lic624State.lawEnforcement}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="lawEnforcementComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("lawEnforcementComment", e)}
            value={lic624State && lic624State.lawEnforcementComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col xs={2}>
          <Form.Check
            label="PLACEMENT AGENCY"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="placementAgency"
            onChange={(e) => onChangeInput("placementAgency", e, false, true)}
            checked={lic624State != undefined && lic624State.placementAgency}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            size="sm"
            name="placementAgencyComment"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("placementAgencyComment", e)}
            value={lic624State && lic624State.placementAgencyComment}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Comment;
