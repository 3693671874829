import { useState } from "react";
import TodoService from "../../../../services/todo/todoService";

const useFamilyMemberVisitState = () => {
  const [familyMemberVisitState, setFamilyMemberVisitState] = useState({
    todo: {
      title:
        "Scheduled family member visit from " +
        sessionStorage.getItem("authenticatedUser"),
      description: "",
      startDate: undefined,
      endDate: undefined,
      repeated: false,
      repeatedCategoryId: "1",
      repeatedCategoryValue: 2,
      repeatedCategoryTypeId: "d",
      residentId: undefined,
      formId: undefined,
      status: false,
    },
  });

  const onChangeStartDate = (date) => {
    setFamilyMemberVisitState({
      ...familyMemberVisitState,
      isError: false,
      errorMsg: "",
      todo: { ...familyMemberVisitState.todo, startDate: date },
    });
  };

  const onChangeDescription = (e) => {
    setFamilyMemberVisitState({
      ...familyMemberVisitState,
      isError: false,
      errorMsg: "",
      todo: { ...familyMemberVisitState.todo, description: e.target.value },
    });
  };

  const onClose = () => {
    window.open("/", "_self");
  };

  const onAddTodo = async (todo) => {
    if (familyMemberVisitState.todo.startDate === undefined) {
      setFamilyMemberVisitState({
        ...familyMemberVisitState,
        isError: true,
        errorMsg: "Please enter visit date!",
      });
      return;
    }

    await saveTodo({ ...todo, staffId: 0, residentId: 0, formId: 0 });
  };

  const saveTodo = async (todo) => {
    setFamilyMemberVisitState({
      ...familyMemberVisitState,
      isProcessing: true,
    });

    const savedTodo = await TodoService.save(todo);

    alert("Visit is successfully scheduled");
    setFamilyMemberVisitState({
      ...familyMemberVisitState,
      isProcessing: false,
      todo: {
        ...familyMemberVisitState.todo,
        description: "",
        startDate: undefined,
      },
    });
  };

  return {
    onChangeStartDate,
    onChangeDescription,
    onClose,
    onAddTodo,
    familyMemberVisitState,
  };
};

export default useFamilyMemberVisitState;
