import React from "react";
import AddMedicationsMobile from "./addMedicationsMobile";
import MedicationList from "./medicationListMobile";
import ButtonBarMobile from "./buttonBarMobile";

const MedicationOrderMobile = ({
  onAddMedication,
  onChangeRxCode,
  onFilterMedications,
  onDeleteRxCode,
  onMarkMedicationAsShipped,
  onShowMedicationOrderHistory,
  onShowSendOrder,
  onToggleFilter,
  onClose,
  medicationState,
}) => {
  return (
    <React.Fragment>
      <AddMedicationsMobile
        onAddMedication={onAddMedication}
        onChangeRxCode={onChangeRxCode}
        onFilterMedications={onFilterMedications}
        onToggleFilter={onToggleFilter}
        medicationState={medicationState}
      />
      <hr></hr>
      <MedicationList
        onDeleteRxCode={onDeleteRxCode}
        onMarkMedicationAsShipped={onMarkMedicationAsShipped}
        medicationState={medicationState}
      />
      <hr></hr>
      <ButtonBarMobile
        onShowMedicationOrderHistory={onShowMedicationOrderHistory}
        onClose={onClose}
        onShowSendOrder={onShowSendOrder}
      />
    </React.Fragment>
  );
};

export default MedicationOrderMobile;
