import React from "react";
import { Container, Form, Row, Col } from "react-bootstrap";

const GeneralInfo = ({ onChangeInput, lic603aState, complianceFormsState }) => {
  return (
    <React.Fragment>
      <Container fluid style={{ marginTop: "20px" }}>
        <Row>
          <Col md={4}>
            <Form.Label>
              <b>Resident name:</b>
            </Form.Label>
            <Form.Control
              name="residentName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentName", e)}
              value={lic603aState && lic603aState.residentName}
              autoComplete="off"
            />
          </Col>
          <Col md={2}>
            <Form.Label>
              <b>Age:</b>
            </Form.Label>
            <br />
            <Form.Control
              name="age"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              style={{ width: "50%" }}
              onChange={(e) => onChangeInput("age", e)}
              value={lic603aState && lic603aState.age}
              autoComplete="off"
            />
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GeneralInfo;
