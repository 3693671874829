import React from "react";
import HeaderCompliance from "../headerCompliance";

const MainDocuments = () => {
  return (
    <React.Fragment>
      <HeaderCompliance />
      MainDocuments
    </React.Fragment>
  );
};

export default MainDocuments;
