import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { FiPrinter } from "react-icons/fi";
import moment from "moment";
import TableSort from "../../../common/tableSort";
import "../../../common/table.css";

const PassedStaffTrainingsDesktop = ({ onPrintCertificate, trainingState }) => {
  const passedStaffTrainings =
    trainingState != undefined &&
    trainingState.selectedStaffTrainings != undefined &&
    trainingState.selectedStaffTrainings.filter(
      (staffTraining) => staffTraining.passDate != undefined
    );

  const [byNameType, setByNameType] = useState("");
  const [byDescType, setByDescType] = useState("");
  const [byScheduledType, sortByScheduledType] = useState("");
  const [byPassedType, sortByPassedType] = useState("");
  const [byStartedType, sortByStartedType] = useState("");
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [filter, setFilter] = useState({});

  function sortByName(type) {
    console.log("sortByName", type);
    resetSortType();
    setByNameType(type);
  }

  function sortByDesc(type) {
    resetSortType();
    setByDescType(type);
  }

  function sortByScheduled(type) {
    resetSortType();
    sortByScheduledType(type);
  }

  function sortByStarted(type) {
    resetSortType();
    sortByStartedType(type);
  }

  function sortByPassed(type) {
    resetSortType();
    sortByPassedType(type);
  }

  function resetSortType() {
    setByNameType("");
    setByDescType("");
    sortByScheduledType("");
    sortByPassedType("");
    sortByStartedType("");
  }

  function showFilter(isFilterOn) {
    setIsFilterOn(isFilterOn);
  }

  console.log("byNameType", byNameType);

  if (passedStaffTrainings != undefined && passedStaffTrainings.length > 0) {
    const passedStaffTrainingsSorted =
      passedStaffTrainings != undefined &&
      passedStaffTrainings.sort((A, B) => {
        if (byNameType != "" && byNameType != undefined) {
          if (A.training.name > B.training.name) {
            if (byNameType == "asc") {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (byNameType == "asc") {
              return -1;
            } else {
              return 1;
            }
          }
        }

        if (byDescType != "" && byDescType != undefined) {
          if (A.training.description > B.training.description) {
            if (byDescType == "asc") {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (byDescType == "asc") {
              return -1;
            } else {
              return 1;
            }
          }
        }

        if (byScheduledType != "" && byScheduledType != undefined) {
          if (A.scheduledDate > B.scheduledDate) {
            if (byScheduledType == "asc") {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (byScheduledType == "asc") {
              return -1;
            } else {
              return 1;
            }
          }
        }

        if (byPassedType != "" && byPassedType != undefined) {
          if (A.passDate > B.passDate) {
            if (byPassedType == "asc") {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (byPassedType == "asc") {
              return -1;
            } else {
              return 1;
            }
          }
        }

        if (byStartedType != "" && byStartedType != undefined) {
          if (A.startDate > B.startDate) {
            if (byStartedType == "asc") {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (byStartedType == "asc") {
              return -1;
            } else {
              return 1;
            }
          }
        }

        return 0;
      });

    return (
      <React.Fragment>
        <div class="tableFixHead">
          <Table>
            <thead>
              <tr>
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Name
                </th>
                {/* <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                >
                  <TableSort sort={sortByName} type={byNameType} />
                </th> */}
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Scheduled
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                >
                  <TableSort sort={sortByScheduled} type={byScheduledType} />
                </th>
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Started
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                >
                  <TableSort sort={sortByStarted} type={byStartedType} />
                </th>
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Completed
                </th>
                <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                >
                  <TableSort sort={sortByPassed} type={byPassedType} />
                </th>
                <th style={{ ...thStyle }}>Duration</th>
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                  Description
                </th>
                {/* <th
                  style={{
                    ...thStyle,
                    borderLeftColor: "#3c3b6e",
                    textAlign: "right",
                  }}
                >
                  <TableSort sort={sortByDesc} type={byDescType} />
                </th> */}
                <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}></th>
              </tr>
            </thead>
            <tbody>
              {passedStaffTrainingsSorted.map((staffTraining) => (
                <tr key={staffTraining.id}>
                  <td>{staffTraining.training.name}</td>
                  <td colSpan={2}>
                    {moment(staffTraining.scheduledDate).format("DD/MM/YYYY")}
                  </td>
                  <td colSpan={2}>
                    {moment(staffTraining.startDate).format("DD/MM/YYYY")}
                  </td>
                  <td colSpan={2}>
                    {moment(staffTraining.passDate).format("DD/MM/YYYY")}
                  </td>
                  <td>{staffTraining.durationHours} h</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: staffTraining.training.description,
                      }}
                    />
                  </td>
                  <td
                    style={{
                      width: "20px",
                      minWidth: "20px",
                      textAlign: "left",
                      borderRightColor: "transparent",
                    }}
                  >
                    <Button
                      variant="outline-light"
                      size="sm"
                      onClick={() => onPrintCertificate(staffTraining)}
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                      }}
                    >
                      <FiPrinter
                        style={{
                          color: "blue",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    );
  } else {
    return <b style={{ color: "#df4759" }}>There are no completed trainings</b>;
  }
};

const thStyle = {
  backgroundColor: "lightgray",
  color: "black",
  border: "none",
  verticalAlign: "middle",
};

export default PassedStaffTrainingsDesktop;
