import { useState } from "react";
import MedicationService from "../../../services/medication/medicationService";

const useMedicationState = () => {
  const [medicationState, setMedicationState] = useState({});

  const refreshPage = async () => {
    const medications = await MedicationService.getAll();
    //  await MedicationService.getPrefix();
    const defaultPrefix = "RX ";
    setLocalState(medications, defaultPrefix);
  };

  //Add medication
  const onChangeRxCode = (e) => {
    setMedicationState({ ...medicationState, currRxCode: e.target.value });
  };

  const onAddMedication = async () => {
    const rxCode = medicationState.currRxCode;
    console.log("rxCode", rxCode);
    if (
      rxCode == undefined ||
      rxCode == "" ||
      rxCode == medicationState.defaultPrefix
    ) {
      alert("Please enter medication!");
      return;
    }
    const codes = medicationState.allRxCodes
      ? medicationState.allRxCodes.filter((rx) => rx.rxCode === rxCode)
      : [];

    var medication = {
      rxCode: rxCode,
      orderDate: new Date(),
      isShipped: false,
    };

    if (codes == undefined || codes.length > 0) {
      medication["id"] = codes[0].id;
    }

    const savedMedication = await MedicationService.save(medication);
    const newAllMedications = !medicationState.allRxCodes
      ? []
      : medicationState.allRxCodes.filter(
          (med) => med.id != savedMedication.id
        );
    newAllMedications.push(savedMedication);
    setLocalState(newAllMedications, medicationState.defaultPrefix);
  };

  //Filter medications
  const onFilterMedications = (event) => {
    const currMedication = event.target.value;
    const newAllRxCodes =
      medicationState.allRxCodes &&
      medicationState.allRxCodes.filter((rx) => rx.isShipped != true);

    const filterRxCodes = applyFilterByName(currMedication, newAllRxCodes);

    setMedicationState({
      ...medicationState,
      filterRxCodes: filterRxCodes,
      currMedication: currMedication,
    });
  };

  //Medication order list
  const onDeleteRxCode = async (medication) => {
    if (
      window.confirm(
        "Do you really want to delete selected medication from order list?"
      )
    ) {
      const medicationForDelete = {
        id: medication.id,
        rxCode: medication.rxCode,
        orderDate: undefined,
        shippedDate: undefined,
        isShipped: false,
      };
      await MedicationService.save(medicationForDelete);
      setLocalState(
        medicationState.allRxCodes.filter(
          (medication) => medication.id != medicationForDelete.id
        ),
        medicationState.defaultPrefix
      );
    }
  };

  const onMarkMedicationAsShipped = async (rx) => {
    if (window.confirm("Is this medicine really delivered by your pharmacy?")) {
      const id = rx.id;
      const medication = medicationState.allRxCodes.filter(
        (rx) => rx.id == id
      )[0];

      medication["isShipped"] = "true";
      var medicationForUpdate = {
        id: id,
        rxCode: medication.rxCode,
        orderDate: medication.orderDate,
        isShipped: medication.isShipped,
      };
      const updatedMedication = await MedicationService.save(
        medicationForUpdate
      );
      const newAllRxCodes = medicationState.allRxCodes.map((medication) =>
        medication.id == updatedMedication.id ? updatedMedication : medication
      );
      setLocalState(newAllRxCodes, medicationState.defaultPrefix);
    }
  };

  // Button bar
  const onShowMedicationOrderHistory = () => {
    setMedicationState({ ...medicationState, showHistory: true });
  };

  const onShowSendOrder = () => {
    setMedicationState({ ...medicationState, showEmail: true });
  };

  const onCloseMedicationHistory = () => {
    setMedicationState({ ...medicationState, showHistory: false });
  };

  const onCloseSendMail = () => {
    setMedicationState({ ...medicationState, showEmail: false });
  };

  const onDownloadOrder = (meds) => {
    console.log(meds);
    MedicationService.downloadReport(
      meds.map((medication) => {
        return {
          id: medication.id,
          rxCode: medication.rxCode,
          orderDate: medication.orderDate,
          shippedDate: medication.shippedDate,
          isShipped: medication.isShipped,
        };
      })
    );
  };

  function setLocalState(data, defaultPrefix) {
    if (defaultPrefix == undefined) defaultPrefix = "RX ";
    var allRxCodes = data == undefined ? [] : data;
    if (allRxCodes != undefined) {
      allRxCodes = allRxCodes.sort((medicationA, medicationB) =>
        medicationA.id > medicationB.id ? -1 : 1
      );
    }

    setMedicationState({
      allRxCodes: allRxCodes,
      filterRxCodes:
        allRxCodes &&
        allRxCodes.filter(
          (rx) => rx.isShipped != true && rx.orderDate != undefined
        ),
      currRxCode: defaultPrefix,
    });
  }

  function applyFilterByName(currMed, rxcodes) {
    if (rxcodes === undefined || rxcodes === "NaN" || rxcodes.length === 0) {
      return rxcodes;
    }

    const filteredRxcodes = rxcodes
      .filter((e) => e.isShipped != true)
      .filter((e) => e.orderDate != undefined)
      .filter((e) => e.orderDate != null)
      .filter((e) =>
        e.rxCode
          .toString()
          .toLowerCase()
          .includes(currMed.toString().toLowerCase())
      );

    return filteredRxcodes;
  }

  const onClose = () => {
    window.open("/", "_self");
  };

  return {
    refreshPage,
    onChangeRxCode,
    onAddMedication,
    onFilterMedications,
    onDeleteRxCode,
    onMarkMedicationAsShipped,
    onShowMedicationOrderHistory,
    onShowSendOrder,
    onCloseMedicationHistory,
    onCloseSendMail,
    onDownloadOrder,
    onClose,
    medicationState,
  };
};

export default useMedicationState;
