import React from "react";
import { Form, Table } from "react-bootstrap";

const Mental = ({ onChangeInput, lic625State, complianceFormsState }) => {
  return (
    <Table>
      <tbody>
        <tr>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Needs</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("mentalNeeds", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.mentalNeeds}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Objective/Plan</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("mentalPlan", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.mentalPlan}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td>
            <Form.Group>
              <Form.Label>
                <b>Time frame</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("mentalTimeFrame", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.mentalTimeFrame}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Person(s) responsible for implementation</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("mentalPersonResponsible", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.mentalPersonResponsible}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
          <td style={{ width: "23%" }}>
            <Form.Group>
              <Form.Label>
                <b>Method of evaluating progress</b>
              </Form.Label>
              <Form.Control
                onChange={(e) => onChangeInput("mentalProgress", e)}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                value={lic625State && lic625State.mentalProgress}
                as="textarea"
                rows={20}
              />
            </Form.Group>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default Mental;
