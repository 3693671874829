import React from "react";
import { Container, Form, Row, Col, InputGroup } from "react-bootstrap";

const GeneralInfo = ({ onChangeInput, lic613cState, complianceFormsState }) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };
  return (
    <React.Fragment>
      <Container fluid style={{ marginTop: "20px" }}>
        <Row>
          <Col md={6}>
            <Form.Label>
              <b>Facility name:</b>
            </Form.Label>
            <Form.Control
              name="facilityName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityName", e)}
              value={lic613cState && lic613cState.facilityName}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={6}>
            <Form.Label>
              <b>Facility address:</b>
            </Form.Label>
            <Form.Control
              name="facilityAddress"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityAddress", e)}
              value={lic613cState && lic613cState.facilityAddress}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Label>
              <b>Resident name:</b>
            </Form.Label>
            <Form.Control
              name="residentName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentName", e)}
              value={lic613cState && lic613cState.residentName}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Label>
              <b>Title of responsible person or conservator:</b>
            </Form.Label>
            <Form.Control
              name="responsiblePerson"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("responsiblePerson", e)}
              value={lic613cState && lic613cState.responsiblePerson}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "10px",
            borderBottomColor: "black",
            borderBottomWidth: "1px",
          }}
        >
          <Col>
            <span style={{ "font-size": "20px" }}>
              <b>LICENSING AGENCY TO CONTACT REGARDING COMPLAINTS</b>
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Label>
              <b>Name:</b>
            </Form.Label>
            <Form.Control
              name="agencyName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("agencyName", e)}
              value={lic613cState && lic613cState.agencyName}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Label>
              <b>Address:</b>
            </Form.Label>
            <Form.Control
              name="agencyAddress"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("agencyAddress", e)}
              value={lic613cState && lic613cState.agencyAddress}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Form.Label>
              <b>City:</b>
            </Form.Label>
            <Form.Control
              name="agencyCity"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("agencyCity", e)}
              value={lic613cState && lic613cState.agencyCity}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={3}>
            <Form.Label>
              <b>Zip code:</b>
            </Form.Label>
            <Form.Control
              name="agencyZipCode"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("agencyZipCode", e)}
              value={lic613cState && lic613cState.agencyZipCode}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
          <Col md={4}>
            <Form.Label>
              <b>Telephone number:</b>
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="agencyAreaCode"
                style={{ width: "20%" }}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                onChange={(e) => onChangeInput("agencyAreaCode", e)}
                value={lic613cState && lic613cState.agencyAreaCode}
                autoComplete="off"
                onFocus={(event) => dummyOnFocus(event)}
              />
              <Form.Control
                name="agencyPhoneNum"
                style={{ width: "80%" }}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                onChange={(e) => onChangeInput("agencyPhoneNum", e)}
                value={lic613cState && lic613cState.agencyPhoneNum}
                autoComplete="off"
                onFocus={(event) => dummyOnFocus(event)}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Label>
              <b>Local Ombudsman’s Office Telephone Number:</b>
            </Form.Label>
            <Form.Control
              name="crisisNumber"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("crisisNumber", e)}
              value={lic613cState && lic613cState.crisisNumber}
              autoComplete="off"
              onFocus={(event) => dummyOnFocus(event)}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GeneralInfo;
