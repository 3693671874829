import React from "react";
import Button from "react-bootstrap/Button";
import * as Constants from "../../util/constants";
import { BsTrash, BsCheck } from "react-icons/bs";

const ShoppingCartItem = ({
  onIsPurchased,
  onRemoveShoppingCartProduct,
  shoppingCartItem,
}) => {
  function renderCartItem(isPurchased) {
    if (isPurchased) {
      return (
        <div style={{ color: "red" }}>
          <s>
            <b>{shoppingCartItem.product.name}</b> <br></br>
            {shoppingCartItem.quantity}{" "}
            {
              Constants.PRODUCT_MEASURE.filter((element) => {
                if (element.id === shoppingCartItem.product.productMeasure.id) {
                  return element;
                }
              })[0].value
            }
          </s>
        </div>
      );
    } else {
      return (
        <div style={{ color: "black" }}>
          <b> {shoppingCartItem.product.name} </b> <br></br>
          {shoppingCartItem.quantity}{" "}
          {
            Constants.PRODUCT_MEASURE.filter((element) => {
              if (element.id === shoppingCartItem.product.productMeasure.id) {
                return element;
              }
            })[0].value
          }
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <td style={{ width: "15px" }}>
        <Button
          variant="outline-light"
          onClick={() => onIsPurchased(shoppingCartItem)}
          size="sm"
          style={{
            backgroundColor: "transparent",
            borderColor: "transparent",
          }}
        >
          <BsCheck
            style={{
              color: "#5cb85c",
              width: "15px",
              height: "15px",
            }}
          />
        </Button>
      </td>
      <td className="text-success text-left">
        {renderCartItem(shoppingCartItem.isPurchased)}
      </td>
      <td className="text-right">
        <Button
          variant="outline-light"
          onClick={() => onRemoveShoppingCartProduct(shoppingCartItem)}
          size="sm"
          style={{
            backgroundColor: "transparent",
            borderColor: "transparent",
          }}
        >
          <BsTrash
            style={{
              color: "#d9534f",
              width: "15px",
              height: "15px",
            }}
          />
        </Button>
      </td>
    </React.Fragment>
  );
};

export default ShoppingCartItem;
