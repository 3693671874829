import AnnouncementReadClient from "../../api/collaboration/announcementReadClient";

const announcementReadClient = new AnnouncementReadClient();

class AnnouncementReadService {
  async save(repo) {
    let response = "";
    try {
      if (repo.hasOwnProperty("id") && repo.id != undefined) {
        response = await announcementReadClient.update(repo, repo.id);
      } else {
        response = await announcementReadClient.insert(repo);
      }

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await announcementReadClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new AnnouncementReadService();
