import CheckResultsClient from "../../../api/compliance/checklist/checkResultsClient";

const checkResultsClient = new CheckResultsClient();

class CheckResultsService {
  async delete(id) {
    try {
      const response = await checkResultsClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
    // return checkResultsClient.delete(id);
  }

  async save(repo) {
    try {
      const response = await checkResultsClient.insert(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async schedule(repo) {
    try {
      const response = await checkResultsClient.schedule(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await checkResultsClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  getById(id) {
    return checkResultsClient.getById(id);
  }
}

export default new CheckResultsService();
