import ShoppingCartProductClient from "../../api/products/shoppingCartProductClient";

const shoppingCartProductClient = new ShoppingCartProductClient();

class ShoppingCartProductService {
  async delete(id) {
    try {
      const response = await shoppingCartProductClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    let response = "";
    try {
      if (repo.hasOwnProperty("id") && repo.id != undefined) {
        response = await shoppingCartProductClient.update(repo, repo.id);
      } else {
        response = await shoppingCartProductClient.insert(repo);
      }

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAllByShoppingCart(id) {
    try {
      const response = await shoppingCartProductClient.getAllByShoppingCart(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new ShoppingCartProductService();
