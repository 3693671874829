import React from "react";
import { Modal, Row, Col, Tab, Form } from "react-bootstrap";
import ScheduleTrainingMobile from "./scheduleTrainingMobile";
//Services
import ViewTraining from "../viewTraining";
import MarkAsPassedMobile from "./markAsPassedMobile";
import CreateTrainingMobile from "./createTrainingMobile";
import StaffTrainingDashboardMobile from "../mobile/staffTrainingDashboardMobile";

const MainStaffTrainingMobile = ({
  onDeleteStaffTraining,
  onStaffTraining,
  onSaveStaffTraining,
  onShowMarkAsPassed,
  onHideMarkAsPassed,
  onSaveMarkAsPassed,
  onChangePassDate,
  onChangeStartDate,
  onChangeHours,
  onSaveTraining,
  onDeleteTraining,
  onViewDetails,
  onHideTrainingDetails,
  onAddTraining,
  onHideSchedule,
  onChangeScheduleDate,
  onGoBack,
  onDownloadCompleted,
  onPrintCertificate,
  onCreateTraining,
  onEditTraining,
  onChangeTraining,
  onChangeTrainingUrl,
  onAddTrainingUrl,
  onRemoveTrainingUrl,
  onHideCreateTraining,
  openEmployee,
  onShowStaffTrainingView,
  trainingState,
}) => {
  console.log("trainingState.currEmployee", trainingState.currEmployee);

  return (
    <React.Fragment>
      <Form.Control
        size="sm"
        name="employee"
        as="select"
        onChange={(e) => openEmployee(e.target.value)}
        value={
          trainingState &&
          trainingState.currEmployee &&
          trainingState.currEmployee.id
        }
        style={{ width: "100%", marginBottom: "20px" }}
      >
        {trainingState.employees &&
          trainingState.employees.map((employee) => {
            return (
              <option key={employee.id} value={employee.id}>
                {employee.name}
              </option>
            );
          })}
      </Form.Control>
      <Tab.Container>
        <Row>
          <Col>
            <StaffTrainingDashboardMobile
              onAddTraining={onAddTraining}
              onMarkAsPassed={onShowMarkAsPassed}
              onDeleteStaffTraining={onDeleteStaffTraining}
              onViewDetails={onViewDetails}
              onDownloadCompleted={onDownloadCompleted}
              onPrintCertificate={onPrintCertificate}
              onCreateTraining={onCreateTraining}
              onEditTraining={onEditTraining}
              onHideCreateTraining={onHideCreateTraining}
              onStaffTraining={onStaffTraining}
              onGoBack={onGoBack}
              onSaveTraining={onSaveTraining}
              onDeleteTraining={onDeleteTraining}
              onShowStaffTrainingView={onShowStaffTrainingView}
              trainingState={trainingState}
            />
          </Col>
        </Row>
      </Tab.Container>
      <Modal show={trainingState.showSchedule} onHide={onHideSchedule}>
        <Modal.Header closeButton>
          <Modal.Title>
            Schedule training for{" "}
            {trainingState.currEmployee && trainingState.currEmployee.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleTrainingMobile
            onChangeScheduleDate={onChangeScheduleDate}
            onSaveStaffTraining={onSaveStaffTraining}
            onHideSchedule={onHideSchedule}
            trainingState={trainingState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={trainingState.showTrainingDetails}
        onHide={onHideTrainingDetails}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Training details for{" "}
            {trainingState.selectedTraining &&
              trainingState.selectedTraining.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewTraining training={trainingState.selectedTraining} />
        </Modal.Body>
      </Modal>
      <Modal show={trainingState.showMarkAsPassed} onHide={onHideMarkAsPassed}>
        <Modal.Header closeButton>
          <Modal.Title>
            Mark{" "}
            {trainingState.currentStaffTraining &&
              trainingState.currentStaffTraining.training.name}{" "}
            as completed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MarkAsPassedMobile
            onChangePassDate={onChangePassDate}
            onChangeStartDate={onChangeStartDate}
            onChangeHours={onChangeHours}
            onHideMarkAsPassed={onHideMarkAsPassed}
            onSaveMarkAsPassed={onSaveMarkAsPassed}
            trainingState={trainingState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={trainingState.showCreateTraining}
        onHide={onHideCreateTraining}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create training</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateTrainingMobile
            onSaveTraining={onSaveTraining}
            onChangeTraining={onChangeTraining}
            onChangeTrainingUrl={onChangeTrainingUrl}
            onAddTrainingUrl={onAddTrainingUrl}
            onRemoveTrainingUrl={onRemoveTrainingUrl}
            onHideCreateTraining={onHideCreateTraining}
            trainingState={trainingState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={trainingState.showTrainingDetails}
        onHide={() => onHideTrainingDetails()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Training Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewTraining trainingState={trainingState} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "150px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "150px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainStaffTrainingMobile;
