import React from "react";
import { Form, Button } from "react-bootstrap";
import Comment from "./comment";

const TodoComments = ({
  onChangeComment,
  onSaveComment,
  todo,
  todoComments,
  todoComment,
}) => {
  return (
    <div style={defaultStyle}>
      <p>
        <b>{todo && todo.title}</b>
      </p>
      <p>{todo && todo.description}</p>
      <Form.Control
        name="comment"
        as="textarea"
        rows="2"
        size="sm"
        onChange={(e) => onChangeComment(e)}
        placeholder="New Comment"
        value={todoComment}
      />
      <br></br>
      <Button
        variant="success"
        size="sm"
        style={{ width: "150px" }}
        onClick={() => onSaveComment()}
      >
        Add comment
      </Button>
      <hr></hr>
      <div
        id=""
        style={{
          overflow: "scroll",
          overflowX: "hidden",
          height: "320px",
        }}
      >
        {todoComments != undefined && todoComments.length > 0
          ? todoComments
              .sort((comA, comB) => {
                if (comA.commentDate > comB.commentDate) {
                  return -1;
                } else {
                  return 1;
                }
              })
              .map((comm) => <Comment comm={comm} />)
          : "No comments"}
      </div>
    </div>
  );
};

const defaultStyle = {
  color: "#3C3B6E",
};

export default TodoComments;
