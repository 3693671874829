import React, { useEffect } from "react";
//Services
import useTrainingState from "./hook/useTrainingState";
import useWindowSize from "../../common/hook/useWindowSize";
import MainStaffTrainingDesktop from "./desktop/mainStaffTrainingDesktop";
import MainStaffTrainingMobile from "./mobile/mainStaffTrainingMobile";

const MainStaffTraining = ({}) => {
  const size = useWindowSize();
  const {
    refreshPage,
    // onChangeStaff,
    onDeleteStaffTraining,
    onStaffTraining,
    onSaveStaffTraining,
    onShowMarkAsPassed,
    onHideMarkAsPassed,
    onSaveMarkAsPassed,
    onChangePassDate,
    onChangeStartDate,
    onChangeHours,
    onSaveTraining,
    onDeleteTraining,
    onViewDetails,
    onHideTrainingDetails,
    onAddTraining,
    onHideSchedule,
    onChangeScheduleDate,
    onGoBack,
    onDownloadCompleted,
    onPrintCertificate,
    onCreateTraining,
    onEditTraining,
    onChangeTraining,
    onChangeTrainingUrl,
    onAddTrainingUrl,
    onRemoveTrainingUrl,
    onHideCreateTraining,
    openEmployee,
    onShowStaffTrainingView,
    trainingState,
  } = useTrainingState({});

  useEffect(() => {
    refreshPage();
  }, []);

  return size.width > 970 ? (
    <MainStaffTrainingDesktop
      onDeleteStaffTraining={onDeleteStaffTraining}
      onStaffTraining={onStaffTraining}
      onSaveStaffTraining={onSaveStaffTraining}
      onShowMarkAsPassed={onShowMarkAsPassed}
      onHideMarkAsPassed={onHideMarkAsPassed}
      onSaveMarkAsPassed={onSaveMarkAsPassed}
      onChangePassDate={onChangePassDate}
      onChangeStartDate={onChangeStartDate}
      onChangeHours={onChangeHours}
      onSaveTraining={onSaveTraining}
      onDeleteTraining={onDeleteTraining}
      onViewDetails={onViewDetails}
      onHideTrainingDetails={onHideTrainingDetails}
      onAddTraining={onAddTraining}
      onHideSchedule={onHideSchedule}
      onChangeScheduleDate={onChangeScheduleDate}
      onGoBack={onGoBack}
      onDownloadCompleted={onDownloadCompleted}
      onPrintCertificate={onPrintCertificate}
      onCreateTraining={onCreateTraining}
      onEditTraining={onEditTraining}
      onChangeTraining={onChangeTraining}
      onChangeTrainingUrl={onChangeTrainingUrl}
      onAddTrainingUrl={onAddTrainingUrl}
      onRemoveTrainingUrl={onRemoveTrainingUrl}
      onHideCreateTraining={onHideCreateTraining}
      openEmployee={openEmployee}
      onShowStaffTrainingView={onShowStaffTrainingView}
      trainingState={trainingState}
    />
  ) : (
    <MainStaffTrainingMobile
      onDeleteStaffTraining={onDeleteStaffTraining}
      onStaffTraining={onStaffTraining}
      onSaveStaffTraining={onSaveStaffTraining}
      onShowMarkAsPassed={onShowMarkAsPassed}
      onHideMarkAsPassed={onHideMarkAsPassed}
      onSaveMarkAsPassed={onSaveMarkAsPassed}
      onChangePassDate={onChangePassDate}
      onChangeStartDate={onChangeStartDate}
      onChangeHours={onChangeHours}
      onSaveTraining={onSaveTraining}
      onDeleteTraining={onDeleteTraining}
      onViewDetails={onViewDetails}
      onHideTrainingDetails={onHideTrainingDetails}
      onAddTraining={onAddTraining}
      onHideSchedule={onHideSchedule}
      onChangeScheduleDate={onChangeScheduleDate}
      onGoBack={onGoBack}
      onDownloadCompleted={onDownloadCompleted}
      onPrintCertificate={onPrintCertificate}
      onCreateTraining={onCreateTraining}
      onEditTraining={onEditTraining}
      onChangeTraining={onChangeTraining}
      onChangeTrainingUrl={onChangeTrainingUrl}
      onAddTrainingUrl={onAddTrainingUrl}
      onRemoveTrainingUrl={onRemoveTrainingUrl}
      onHideCreateTraining={onHideCreateTraining}
      openEmployee={openEmployee}
      onShowStaffTrainingView={onShowStaffTrainingView}
      trainingState={trainingState}
    />
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "150px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "150px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainStaffTraining;
