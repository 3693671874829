import React from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import UsersList from "./usersList";
import { TiPlus } from "react-icons/ti";

const Users = ({ onDelete, onUpdate, onShow, onFilterUsers, users }) => {
  return (
    <React.Fragment>
      <Container fluid style={{ marginTop: "10px" }}>
        <Row>
          <Col>
            <Button
              variant="success"
              onClick={() => onShow()}
              style={{ width: "150px", marginBottom: "20px" }}
            >
              <TiPlus
                style={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  verticalAlign: "middle",
                }}
              />
              {"   "}Add staff
            </Button>
          </Col>
          <Col xs="auto">
            <Form.Control
              placeholder="Filter staff"
              onChange={onFilterUsers}
              style={{ width: "300px" }}
            />
          </Col>
        </Row>
      </Container>

      <UsersList onDelete={onDelete} onUpdate={onUpdate} users={users} />
    </React.Fragment>
  );
};

export default Users;
