import React from "react";
import { Col, Container, Row, Form, Button, Table } from "react-bootstrap";
import "../../../../common/table.css";
import { BsTrash } from "react-icons/bs";
import AddUserFamMemberDesktop from "../../../../user/fam-member/desktop/addUserFamMemberDesktop";

const AskForSignatureDesktop = ({
  onHideSignFile,
  onSaveSignFile,
  onSelectGuardianForSignature,
  onSaveStaff,
  onHideGuardian,
  onShowGuardian,
  onChangeGuardianField,
  onChangeGuardianDates,
  onChangePassword,
  onToggleChanged,
  onShowPassword,
  onHidePassword,
  residentState,
}) => {
  return residentState && residentState.showGuardian ? (
    <AddUserFamMemberDesktop
      onSaveStaff={onSaveStaff}
      onHide={onHideGuardian}
      onChange={onChangeGuardianField}
      onChangeDates={onChangeGuardianDates}
      onChangePassword={onChangePassword}
      onToggleChanged={onToggleChanged}
      onShowPassword={onShowPassword}
      onHidePassword={onHidePassword}
      usersState={residentState}
    />
  ) : (
    <Container>
      <Row
        style={{
          marginBottom: "5px",
          borderBottom: "solid",
          borderWidth: "1px",
          borderColor: "lightgray",
        }}
      >
        <Col>
          <b>Select guardian:</b>
        </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          {residentState &&
            residentState.guardians.map((element) => (
              <Form.Check
                name="guardians"
                type="radio"
                onChange={(e) => onSelectGuardianForSignature(e)}
                size="sm"
                id={element.id}
                label={element.name}
              ></Form.Check>
            ))}
          <Button
            variant="link"
            style={{ marginLeft: "10px" }}
            onClick={() => onShowGuardian()}
          >
            Add guardian
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="outline-success"
            style={{ width: "100px" }}
            onClick={() => onHideSignFile()}
          >
            Close
          </Button>
          {"    "}
          <Button
            variant="success"
            style={{ width: "150px" }}
            onClick={() => onSaveSignFile()}
          >
            Send request
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AskForSignatureDesktop;
