import React from "react";
import { Col, Container, Row, Form, Button, Table } from "react-bootstrap";
import "../../../common/table.css";
import { BsTrash } from "react-icons/bs";
import AddUserFamMemberDesktop from "../../../user/fam-member/desktop/addUserFamMemberDesktop";

const ShareReport = ({
  onChangeGuardian,
  onDeleteGuardianShare,
  onSaveStaff,
  onHideGuardian,
  onShowGuardian,
  onChangeGuardianField,
  onChangeGuardianDates,
  onChangePassword,
  onToggleChanged,
  onShowPassword,
  onHidePassword,
  onHideShareReport,
  residentState,
}) => {
  return residentState && residentState.showGuardian ? (
    <AddUserFamMemberDesktop
      onSaveStaff={onSaveStaff}
      onHide={onHideGuardian}
      onChange={onChangeGuardianField}
      onChangeDates={onChangeGuardianDates}
      onChangePassword={onChangePassword}
      onToggleChanged={onToggleChanged}
      onShowPassword={onShowPassword}
      onHidePassword={onHidePassword}
      usersState={residentState}
    />
  ) : (
    <Container>
      <Row
        style={{
          marginBottom: "5px",
          borderBottom: "solid",
          borderWidth: "1px",
          borderColor: "lightgray",
        }}
      >
        <Col>
          <b>Select guardian:</b>
        </Col>
      </Row>
      {residentState &&
        residentState.guardians.map((element) => (
          <Row>
            <Col>
              <Form.Check
                variant={
                  residentState.currForm.staff &&
                  residentState.currForm.staff.filter(
                    (stf) => stf.id == element.id
                  ).length > 0
                    ? "danger"
                    : "success"
                }
                inline
                label={
                  residentState.currForm.staff &&
                  residentState.currForm.staff.filter(
                    (stf) => stf.id == element.id
                  ).length > 0 ? (
                    <div style={{ color: "#d9534f" }}>
                      <b>{element.name}</b> has access to{" "}
                      {residentState.currForm && residentState.currForm.name}
                    </div>
                  ) : (
                    <div style={{ color: "#0275d8" }}>{element.name}</div>
                  )
                }
                type="checkbox"
                id={element.id}
                onChange={(e) => onChangeGuardian(e)}
                checked={
                  residentState.currForm.staff &&
                  residentState.currForm.staff.filter(
                    (stf) => stf.id == element.id
                  ).length > 0
                }
              />
            </Col>
          </Row>
        ))}
      <Row>
        <Col>
          <Button
            size="sm"
            variant="link"
            style={{ marginLeft: "10px" }}
            onClick={() => onShowGuardian()}
          >
            Add guardian
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            size="sm"
            variant="outline-success"
            style={{ width: "100px" }}
            onClick={() => onHideShareReport()}
          >
            Close
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ShareReport;
