import React from "react";
import {
  Card,
  Dropdown,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
} from "react-bootstrap";
import ResidentFormDesktop from "./residentFormDesktop";
import { TiPlus } from "react-icons/ti";
import { FcOpenedFolder } from "react-icons/fc";
import { BsThreeDotsVertical } from "react-icons/bs";

const ComplianceDesktop = ({
  onOpenResidentForm,
  onShowAddTodo,
  onCloseResidentForm,
  onSetFile,
  onDownload,
  onDeleteFiles,
  onShowForm,
  onSelectFormMenu,
  formatList,
  onShowSignFile,
  residentState,
}) => {
  console.log("fileName", residentState.fileName);

  var residentFormsMap = formatList(residentState.forms);

  return (
    <React.Fragment>
      {residentState.showResidentForm ? (
        <ResidentFormDesktop
          onShowAddTodo={onShowAddTodo}
          onCloseResidentForm={onCloseResidentForm}
          onSetFile={onSetFile}
          onDownload={onDownload}
          onDeleteFiles={onDeleteFiles}
          onShowSignFile={onShowSignFile}
          residentState={residentState}
        />
      ) : (
        <React.Fragment>
          <Container fluid>
            <Row
              style={{
                borderBottom: "solid",
                borderBottomColor: "black",
                borderBottomWidth: "1px",
                marginBottom: "10px",
              }}
            >
              <Col>
                <Button
                  variant="success"
                  style={{ marginBottom: "10px", width: "150px" }}
                  disabled={
                    residentState &&
                    residentState.currResident &&
                    !residentState.currResident.isActive
                  }
                  onClick={() => onShowForm()}
                >
                  <TiPlus
                    style={{
                      color: "white",
                      width: "25px",
                      height: "25px",
                      verticalAlign: "middle",
                    }}
                  />
                  {"  "}
                  New report
                </Button>
              </Col>
            </Row>
          </Container>
          <Container
            fluid
            style={{
              overflowY: "scroll",
              overflowX: "none",
              height: "60vh",
            }}
          >
            {residentFormsMap &&
              Object.entries(residentFormsMap).map((resFormMap, i) => {
                console.log("resFormMap", resFormMap);
                return (
                  <Row style={{ marginBottom: "20px" }}>
                    {Object.values(resFormMap[1]).map((residentForm) => {
                      console.log("residentForm", residentForm);
                      return residentForm.name ? (
                        <Col style={{ width: "30%" }}>
                          <Container>
                            <Row>
                              <Col>
                                <Button
                                  onClick={() =>
                                    onOpenResidentForm(residentForm)
                                  }
                                  style={{
                                    backgroundColor: "transparent",
                                    borderColor: "transparent",
                                    color: "black",
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                  }}
                                >
                                  <div style={{ maxWidth: "100%" }}>
                                    <FcOpenedFolder
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        verticalAlign: "middle",
                                      }}
                                    />
                                    {"  "}
                                    {residentForm.name.length > 20
                                      ? residentForm.name.substring(0, 20) +
                                        "..."
                                      : residentForm.name}
                                  </div>
                                </Button>
                              </Col>
                              <Col
                                style={{
                                  verticalAlign: "bottom",
                                  textAlign: "right",
                                }}
                              >
                                <Dropdown
                                  onSelect={(selectedKey) =>
                                    onSelectFormMenu(selectedKey, residentForm)
                                  }
                                  style={{ verticalAlign: "bottom" }}
                                >
                                  <Dropdown.Toggle
                                    variant="success"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "black",
                                      borderColor: "transparent",
                                    }}
                                    id="dropdown-basic"
                                  >
                                    <BsThreeDotsVertical
                                      style={{
                                        color: "black",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item eventKey="share">
                                      Share
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="delete">
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      ) : (
                        <Col></Col>
                      );
                    })}
                  </Row>
                );
              })}
            <Row></Row>
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ComplianceDesktop;
