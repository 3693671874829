import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as Constants from "../util/constants";
import moment from "moment";

const EditBillInfo = ({
  onChange,
  onCancelEdit,
  onSaveCard,
  mainPageState,
}) => {
  const year = moment().year();
  return (
    <Container style={{ width: "600px" }}>
      <Row>
        {mainPageState.isError ? (
          <Col style={errorMsgStyle}>
            <b>ERROR:</b> {mainPageState.errorMsg}
          </Col>
        ) : (
          ""
        )}
      </Row>
      <Row>
        <Col style={{ marginBottom: "20px" }}>
          <h3>Credit card information</h3>
        </Col>
      </Row>
      <Row style={{ marginBottom: "5px" }}>
        <Col>Card number:</Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col style={{ width: "100px" }}>
          <Form.Control
            name="cardNum1"
            onChange={(e) => onChange(e)}
            value={
              mainPageState && mainPageState.card
                ? mainPageState.card.cardNum1
                : ""
            }
            style={{ width: "100px" }}
            maxLength="4"
            autoComplete="off"
          />
        </Col>
        <Col style={{ width: "100px" }}>
          <Form.Control
            name="cardNum2"
            onChange={(e) => onChange(e)}
            style={{ width: "100px" }}
            value={
              mainPageState && mainPageState.card
                ? mainPageState.card.cardNum2
                : ""
            }
            maxLength="4"
            autoComplete="off"
          />
        </Col>
        <Col>
          <Form.Control
            name="cardNum3"
            onChange={(e) => onChange(e)}
            value={
              mainPageState && mainPageState.card
                ? mainPageState.card.cardNum3
                : ""
            }
            maxLength="4"
            autoComplete="off"
          />
        </Col>
        <Col>
          <Form.Control
            name="cardNum4"
            onChange={(e) => onChange(e)}
            value={
              mainPageState && mainPageState.card
                ? mainPageState.card.cardNum4
                : ""
            }
            maxLength="4"
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5px" }}>
        <Col>CVC/CVV number:</Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Form.Control
            name="cardCvc"
            // placeholder="CVC/CVV number *"
            onChange={(e) => onChange(e)}
            style={{ width: "100px" }}
            value={
              mainPageState && mainPageState.card
                ? mainPageState.card.cardCvc
                : ""
            }
            maxLength="4"
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5px" }}>
        <Col>Expiration date: </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Form.Control
            name="cardMonth"
            as="select"
            onChange={(e) => onChange(e)}
            value={
              mainPageState && mainPageState.card
                ? mainPageState.card.cardMonth
                : ""
            }
            autoComplete="off"
          >
            {Constants.MONTHS.map((element) => (
              <option key={element.id} value={element.id}>
                {element.value}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col>
          <Form.Control
            name="cardYear"
            as="select"
            onChange={(e) => onChange(e)}
            value={
              mainPageState && mainPageState.card
                ? mainPageState.card.cardMonth
                : ""
            }
            autoComplete="off"
          >
            {[...Array(20)].map((e, i) => (
              <option key={i + year} value={i + year}>
                {i + year}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <Row style={{ marginBottom: "5px" }}>
        <Col>Cardholder name: </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Form.Control
            name="name"
            // placeholder="Cardholder name*"
            onChange={(e) => onChange(e)}
            value={
              mainPageState && mainPageState.card ? mainPageState.card.name : ""
            }
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5px" }}>
        <Col>Contact e-mail: </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Form.Control
            name="email"
            // placeholder="Contact e-mail*"
            onChange={(e) => onChange(e)}
            value={
              mainPageState && mainPageState.card
                ? mainPageState.card.email
                : ""
            }
            autoComplete="off"
            autoComplete="no"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="outline-success"
            style={{ width: "100px" }}
            onClick={() => onCancelEdit()}
          >
            Cancel
          </Button>
          {"   "}
          <Button
            variant="success"
            style={{ width: "100px" }}
            onClick={() => onSaveCard()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default EditBillInfo;
