import React from "react";
import { Container, Modal, Button, Col, Row } from "react-bootstrap";
import AddTodoMobile from "../add-todo/mobile/addtodoMobile";
import "react-datepicker/dist/react-datepicker.css";
import TodoListMobile from "./todoListMobile";
import moment from "moment";
import CalendarViewMobile from "../calendar/mobile/calendarViewMobile";
import ScheduleMobile from "../calendar/mobile/scheduleMobile";
import { TiArrowBack, TiPlus } from "react-icons/ti";
import TodoComments from "../comments/todoComments";
import TodoReschedule from "../todoReschedule";

const MainTodosMobile = ({
  onDeleteTodo,
  onAddTodo,
  onShowTaskDetails,
  onBackToCalendar,
  onNextMonth,
  onPreviousMonth,
  onChangeView,
  onSetCurrentDate,
  onChangeMonth,
  onChangeYear,
  onRescheduleTodo,
  onShowReminder,
  onChange,
  onChangeDates,
  onCloseTodoReschedule,
  onCloseTodoDetails,
  onShowTodoDetails,
  onShowTodoReschedule,
  onChangeComment,
  onSaveComment,
  months,
  weekdays,
  todoState,
}) => {
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col>
            {todoState.view != "off" ? (
              <Button
                size="sm"
                onClick={() => onShowReminder(true)}
                variant="success"
                style={{
                  width: "100%",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TiPlus
                  style={{
                    color: "white",
                    width: "25px",
                    height: "25px",
                    verticalAlign: "middle",
                  }}
                />
                {"  "}New reminder
              </Button>
            ) : (
              ""
            )}
          </Col>
        </Row>
        {/* <Row>
          <Col className="text-center">
            <Button
              variant="link"
              style={
                todoState.view == "cal"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onChangeView("cal")}
            >
              Calendar
            </Button>
            <Button
              variant="link"
              style={
                todoState.view == "sch"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onChangeView("sch")}
            >
              Schedule
            </Button>
          </Col>
          <Col className="text-right" style={{ fontWeight: "bold" }}>
            <Button
              variant="link"
              style={{ fontSize: "18px", fontWeight: "bold", color: "black" }}
              onClick={() => onSetCurrentDate()}
            >
              {weekdays.filter(
                (weekday) =>
                  weekday.id ==
                  moment(
                    new Date().getFullYear() +
                      "-" +
                      ("0" + (new Date().getMonth() + 1)).slice(-2) +
                      "-" +
                      ("0" + new Date().getDate()).slice(-2),
                    "YYYY-MM-DD"
                  ).weekday()
              )[0].name +
                ", " +
                months.filter((month) => month.id == new Date().getMonth())[0]
                  .name +
                " " +
                ("0" + new Date().getDate()).slice(-2) +
                " " +
                new Date().getFullYear()}
            </Button>
          </Col>
        </Row> */}
      </Container>
      <hr></hr>
      <div>
        {todoState.view === "cal" ? (
          todoState.openTask ? (
            <React.Fragment>
              <TodoListMobile
                onDeleteTodo={onDeleteTodo}
                onShowTodoDetails={onShowTodoDetails}
                onShowTodoReschedule={onShowTodoReschedule}
                todos={todoState.todos
                  .filter(
                    (todo) =>
                      moment(todo.startDate).year() ==
                        moment(todoState.selectedDate).year() &&
                      moment(todo.startDate).month() ==
                        moment(todoState.selectedDate).month() &&
                      moment(todo.startDate).date() ==
                        moment(todoState.selectedDate).date()
                  )
                  .filter((todo) => todo.status != true)}
                selectedDate={todoState.selectedDate}
                months={months}
                weekdays={weekdays}
              />
              <Button
                onClick={onBackToCalendar}
                size="sm"
                style={{
                  width: "100%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div>
                  <TiArrowBack /> Back
                </div>
              </Button>
            </React.Fragment>
          ) : (
            <CalendarViewMobile
              onShowTaskDetails={onShowTaskDetails}
              onChangeMonth={onChangeMonth}
              onChangeYear={onChangeYear}
              onNextMonth={onNextMonth}
              onPreviousMonth={onPreviousMonth}
              todos={todoState.todos}
              selectedMonth={todoState.selectedMonth}
              selectedYear={todoState.selectedYear}
              months={months}
              selectedDate={todoState.selectedDate}
              weekdays={weekdays}
            />
          )
        ) : todoState.view === "sch" ? (
          <ScheduleMobile
            onDeleteTodo={onDeleteTodo}
            onShowTodoDetails={onShowTodoDetails}
            onShowTodoReschedule={onShowTodoReschedule}
            todos={
              todoState.todos &&
              todoState.todos.filter((todo) => todo.status != true)
            }
          />
        ) : (
          ""
        )}
      </div>
      <div>
        <Modal show={todoState.show} onHide={() => onShowReminder(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b style={{ color: "black" }}>New reminder</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <AddTodoMobile
                onAddTodo={onAddTodo}
                onClose={() => onShowReminder(false)}
                onChange={onChange}
                onChangeDates={onChangeDates}
                todo={todoState.currTodo}
                isError={todoState.isError}
                errorMsg={todoState.errorMsg}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Modal show={todoState.showTodoDetails} onHide={onCloseTodoDetails}>
          <Modal.Header closeButton>
            <b>Reminder details</b>
          </Modal.Header>
          <Modal.Body>
            <div>
              <TodoComments
                onChangeComment={onChangeComment}
                onSaveComment={onSaveComment}
                todo={todoState.currTodo}
                todoComments={todoState.currTodoComments}
                todoComment={todoState.currTodoComment}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Modal
          show={todoState.showTodoReschedule}
          onHide={onCloseTodoReschedule}
        >
          <Modal.Header closeButton>
            <b>Reschedule reminder</b>
          </Modal.Header>
          <Modal.Body>
            <div>
              <TodoReschedule
                onChangeDates={onChangeDates}
                onRescheduleTodo={onRescheduleTodo}
                todoState={todoState}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div style={{ display: "none", borderBottomWidth: "3px" }}></div>
    </React.Fragment>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "200px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "200px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainTodosMobile;
