import TodoClient from "../../api/todo/todoClient";

const todoClient = new TodoClient();

class TodoService {
  async delete(id) {
    try {
      const response = await todoClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    let response = "";
    try {
      if (repo.hasOwnProperty("id") && repo.id != undefined) {
        response = await todoClient.update(repo, repo.id);
      } else {
        response = await todoClient.insert(repo);
      }

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getNotRepeated() {
    try {
      const response = await todoClient.getNotRepeated();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  validateTodo(todo) {
    if (todo.title.trim() === "") {
      return {
        isValid: false,
        isError: true,
        msg: "Title can't be blank, please enter title!",
      };
    } else if (todo.startDate === undefined) {
      return {
        isValid: false,
        isError: true,
        msg: "Date can't be blank, please enter date!",
      };
    } else if (todo.repeated && todo.endDate === undefined) {
      return {
        isValid: false,
        isError: true,
        msg: "End date can't be blank, please enter end date!",
      };
    }

    return { isValid: true };
  }
}

export default new TodoService();
