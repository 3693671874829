import React, { Component } from "react";
import {Spinner, Col, Form, Button} from "react-bootstrap";
import AuthenticationService from "../../api/authenticationService";

class CreateAccount extends Component {
  state = {
    user: {},
    accept: false,
    isProcessingRequest: false
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    var user = this.state.user;
    user[name] = value;
    this.setState({
      user,
    });
  };

  handleAccept = () => {
    this.setState({ accept: !this.state.accept });
  };

  terms = () => { };

  sendCreateAccountRequest = () => {
    if (
      this.state.user == undefined ||
      this.state.user.fullName == undefined ||
      this.state.user.fullName.trim() === ""
    ) {
      alert("Please enter full name!");
      return;
    }

    if (
      this.state.user == undefined ||
      this.state.user.phone == undefined ||
      this.state.user.phone.trim() === ""
    ) {
      alert("Please enter contact phone!");
      return;
    }

    if (
      this.state.user == undefined ||
      this.state.user.email == undefined ||
      this.state.user.email.trim() === ""
    ) {
      alert("Please enter e-mail!");
      return;
    }

    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.user.email))) {
      alert("Invalid e-mail address, please enter valid e-mail address!");
      return;
    }

    if (
      this.state.user == undefined ||
      this.state.user.password == undefined ||
      this.state.user.password.trim() === ""
    ) {
      alert("Please enter password!");
      return;
    }

    if (
      this.state.user == undefined ||
      this.state.user.confirmPassword == undefined ||
      this.state.user.confirmPassword.trim() === ""
    ) {
      alert("Please confirm password!");
      return;
    }

    if (this.state.user.password.length < 6) {
      alert("The passwords should be at least 6 characters");
      return;
    }

    if (this.state.user.password != this.state.user.confirmPassword) {
      alert("The passwords doesn't match");
      return;
    }
    this.setState({isProcessingRequest: true})
    AuthenticationService.executeCreateAccount(this.state.user)
      .then((response) => {
        this.setState({isProcessingRequest: false})
        if (response.status == "200") {
          alert("Thanks for submitting the request, your account is created!");
          this.props.handleClose();
        } else {
          if (response.status == "208") {
            alert(
              "User with email address " +
              this.state.user.email +
              " already exists!"
            );
          } else {
            alert("Service is not available!");
          }
        }
      })
      .catch(() => {
        this.setState({isProcessingRequest: false})
        alert(
          "Sorry something went wrong please contact support on contact@alf-is.com"
        );
      });
  };

  render() {
    return (
      <React.Fragment>
        <Form size="sm" style={{ paddingBottom: "1px" }}>
          <Form.Group>
            <Form.Label
              style={{ paddingTop: "1px", paddingBottom: "1px" }}
              column="sm"
            >
              Full name:
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                style={{ paddingTop: "1px", paddingBottom: "1px" }}
                name="fullName"
                placeholder="full name*"
                onChange={this.handleChange}
                defaultValue={this.state.user && this.state.user.fullName}
              />
            </Col>
          </Form.Group>
          <Form.Group>
            <Form.Label
              column="sm"
              style={{ paddingTop: "1px", paddingBottom: "1px" }}
            >
              Contact phone:
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                style={{ paddingTop: "1px", paddingBottom: "1px" }}
                name="phone"
                sm="6"
                placeholder="phone number*"
                onChange={this.handleChange}
                defaultValue={this.state.user && this.state.user.phone}
              />
            </Col>
          </Form.Group>
          <Form.Group>
            <Form.Label
              column="sm"
              style={{ paddingTop: "1px", paddingBottom: "1px" }}
            >
              Email address(e-mail address will be your username):
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                style={{ paddingTop: "1px", paddingBottom: "1px" }}
                name="email"
                sm="6"
                placeholder="e-mail address*"
                onChange={this.handleChange}
                defaultValue={this.state.user && this.state.user.email}
              />
            </Col>
          </Form.Group>
          <Form.Group>
            <Form.Label
              column="sm"
              style={{ paddingTop: "1px", paddingBottom: "1px" }}
            >
              Password (at least 6 characters):
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                style={{ paddingTop: "1px", paddingBottom: "1px" }}
                name="password"
                type="password"
                sm="6"
                placeholder="password*"
                onChange={this.handleChange}
                defaultValue={this.state.user && this.state.user.password}
              />
            </Col>
          </Form.Group>
          <Form.Group>
            <Form.Label
              column="sm"
              style={{ paddingTop: "1px", paddingBottom: "1px" }}
            >
              Confirm password:
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                style={{ paddingTop: "1px", paddingBottom: "1px" }}
                name="confirmPassword"
                type="password"
                sm="6"
                placeholder="confirm password*"
                onChange={this.handleChange}
                defaultValue={
                  this.state.user && this.state.user.confirmPassword
                }
              />
            </Col>
          </Form.Group>
          <Col style={{ display: "flex", fontSize: 14, paddingRight: 50 }}>
            <Form.Check
              type="checkbox"
              checked={this.state.accept}
              onChange={this.handleAccept}
            />
            <span>
              Accept{" "}
              <a
                href="https://www.alf-is.com/license-agreement"
                target="_blank"
              >
                Terms of Service
              </a>{" "}
              &{" "}
              <a href="https://www.alf-is.com/privacy" target="_blank">
                Privacy Policy
              </a>
            </span>
          </Col>
          <div style={{ textAlign: "center" }}>
            {!this.state.isProcessingRequest ? 
            <Button
              size="sm"
              disabled={!this.state.accept}
              variant="success"
              onClick={this.sendCreateAccountRequest}
              style={buttonStyle}
            >
              Register
            </Button>
            :
            <Button
              disabled={true}
              style={buttonStyle}
            >
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
                  Processing request...
        </Button>}
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

const buttonStyle = {
  borderColor: "#3C3B6E",
  width: 300,
  borderRadius: "10px",
  color: "#3C3B6E",
  backgroundColor: "#f2f2f2",
  margin: "3px",
};

export default CreateAccount;
