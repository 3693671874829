import TrainingClient from "../../../api/compliance/training/trainingClient";

const trainingClient = new TrainingClient();

class TrainingService {
  async delete(id) {
    try {
      const response = await trainingClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    try {
      const response = await trainingClient.insert(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await trainingClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getById(id) {
    try {
      const response = await trainingClient.getById(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new TrainingService();
