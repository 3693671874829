import React from "react";
import { Button, Card } from "react-bootstrap";
import moment from "moment";

const formattedHeader = (onViewMessage, message, messagesRead) => {
  const label = (
    <div>
      <Button size="sm" variant="link" onClick={() => onViewMessage(message)}>
        <small
          style={
            messagesRead &&
            messagesRead.filter(
              (messageRead) => messageRead.announcement.id == message.id
            ).length == 0
              ? isNewStyle
              : isRegular
          }
        >
          {message.facility.name}
        </small>
        ,{"   "}
        <small
          style={
            messagesRead &&
            messagesRead.filter(
              (messageRead) => messageRead.announcement.id == message.id
            ).length == 0
              ? isNewStyle
              : isRegular
          }
        ></small>
      </Button>
    </div>
  );
  return label;
};

const formattedBody = (onViewMessage, message, messagesRead) => {
  const formttedDesc = removeHTML(message.description);
  const label = message.subject ? (
    <Button
      size="sm"
      variant="link"
      onClick={() => onViewMessage(message)}
      style={
        messagesRead.filter(
          (messageRead) => messageRead.announcement.id == message.id
        ).length == 0
          ? isNewStyle
          : isRegular
      }
    >
      <h6
        style={
          messagesRead.filter(
            (messageRead) => messageRead.announcement.id == message.id
          ).length == 0
            ? isNewStyle
            : isRegular
        }
      >
        {message.subject},{" "}
        {formttedDesc.length > 150
          ? formttedDesc.substring(0, 150).concat("...")
          : formttedDesc}
      </h6>
    </Button>
  ) : (
    <Button
      size="sm"
      variant="link"
      onClick={() => onViewMessage(message)}
      style={message.isNew ? isNewStyle : isRegular}
    >
      <h6>
        {" "}
        {formttedDesc.length > 150
          ? formttedDesc.substring(0, 150).concat("...")
          : formttedDesc}
      </h6>
    </Button>
  );

  return label;
};

const removeHTML = (str) => {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
};

const MessagesListDesktop = ({ onViewMessage, messages, messagesRead }) => (
  <React.Fragment>
    <div
      id=""
      style={{
        overflow: "scroll",
        overflowX: "hidden",
        height: "80vh",
      }}
    >
      {messages
        .sort((messageA, messageB) => {
          if (moment(messageA.datePublished).isAfter(messageB.datePublished)) {
            return -1;
          } else {
            return 1;
          }
        })
        .map((message) => {
          return (
            <Card
              className="text-left"
              onDoubleClick={() => onViewMessage(message)}
            >
              <Card.Body>
                {formattedHeader(onViewMessage, message, messagesRead)}
                {formattedBody(onViewMessage, message, messagesRead)}
              </Card.Body>
            </Card>
          );
        })}
    </div>
  </React.Fragment>
);

const isRegular = {
  color: "#3c3b6e",
  opacity: 0.7,
};

const isNewStyle = {
  color: "#3c3b6e",
  fontWeight: "bold",
  fontStyle: "italic",
  fontSize: "15px",
};

export default MessagesListDesktop;
