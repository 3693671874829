import React from "react";
import { Table, Button, td } from "react-bootstrap";
import EditBillInfo from "./editBillInfo";
import ViewBillInfo from "./viewBillInfo";

const BillInfo = ({
  onChange,
  onEditBillInfo,
  onCancelEdit,
  onSaveCard,
  mainPageState,
}) => {
  return (
    <React.Fragment>
      {mainPageState.editBillInfo ||
      mainPageState.paymentMethodInfo == undefined ||
      JSON.stringify(mainPageState.paymentMethodInfo) == "{}" ? (
        <EditBillInfo
          onChange={onChange}
          onCancelEdit={onCancelEdit}
          onSaveCard={onSaveCard}
          mainPageState={mainPageState}
        />
      ) : (
        <ViewBillInfo
          onEditBillInfo={onEditBillInfo}
          mainPageState={mainPageState}
        />
      )}
    </React.Fragment>
  );
};

export default BillInfo;
