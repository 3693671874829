import React from "react";
import { Container, Form, Row, Col, InputGroup } from "react-bootstrap";

const Description = ({ onChangeInput, lic624aState, complianceFormsState }) => {
  const dummyOnFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };
  return (
    <Container fluid style={{ marginTop: "20px" }}>
      <Row>
        <Col md={6}>
          <Form.Label>
            <b>Date and time of death:</b>
          </Form.Label>
          <Form.Control
            name="dateAndTimeOfDeath"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("dateAndTimeOfDeath", e)}
            value={lic624aState && lic624aState.dateAndTimeOfDeath}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
        <Col md={6}>
          <Form.Label>
            <b>Place of death:</b>
          </Form.Label>
          <Form.Control
            name="placeOfDeath"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("placeOfDeath", e)}
            value={lic624aState && lic624aState.placeOfDeath}
            autoComplete="off"
            onFocus={(event) => dummyOnFocus(event)}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>
                DESCRIBE IMMEDIATE CAUSE OF DEATH(IF CORNER REPORT MADE, SEND
                COPY WITHIN 30 DAYS)
              </b>
            </Form.Label>
            <Form.Control
              onChange={(e) =>
                onChangeInput("describeImmediateCauseOfDeath", e)
              }
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic624aState && lic624aState.describeImmediateCauseOfDeath}
              as="textarea"
              rows={4}
              style={{ width: "100%" }}
              maxLength={480}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>DESCRIBE CONDITIONS PRIOR TO OR CONTRIBUTING TO DEATH:</b>
            </Form.Label>
            <Form.Control
              onChange={(e) =>
                onChangeInput("describeConditionsPriorToDeath", e)
              }
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={
                lic624aState && lic624aState.describeConditionsPriorToDeath
              }
              as="textarea"
              rows={4}
              style={{ width: "100%" }}
              maxLength={480}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>EXPLAIN WHAT IMMEDIATE ACTION WAS TAKEN:</b>
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("explainImmediateAction", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic624aState && lic624aState.explainImmediateAction}
              as="textarea"
              rows={4}
              style={{ width: "100%" }}
              maxLength={480}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>
            <Form.Label>MEDICAL TREATMENT NECESSARY?</Form.Label>
            <div style={{ display: "inline-flex" }}>
              {"  "}
              <Form.Check
                style={{ marginRight: "20px", marginLeft: "20px" }}
                type="radio"
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                id="medicalTreatment"
                label="YES"
                onChange={(e) => onChangeInput("medicalTreatment", "1", "true")}
                checked={
                  lic624aState != undefined &&
                  lic624aState.medicalTreatment == "1"
                }
              />
              {"  "}
              <Form.Check
                style={{ marginRight: "20px" }}
                type="radio"
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                id="medicalTreatment"
                label="NO"
                onChange={(e) => onChangeInput("medicalTreatment", "2", "true")}
                checked={
                  lic624aState != undefined &&
                  lic624aState.medicalTreatment == "2"
                }
              />
              {"   "}
            </div>
            <Form.Label>IF YES, GIVE NATURE OF TREATMENT:</Form.Label>
          </b>
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Control
            onChange={(e) => onChangeInput("natureOfTreatment", e)}
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            value={lic624aState && lic624aState.natureOfTreatment}
            as="textarea"
            rows={4}
            style={{ width: "100%" }}
            maxLength={480}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Description;
