import React from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";

const AddResidentDesktop = ({
  onHideResident,
  onChangeResident,
  onChangeResidentDates,
  onSaveResident,
  residentState,
}) => {
  return (
    <React.Fragment>
      <React.Fragment>
        <Container fluid>
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <Form.Group style={{ marginTop: "20px" }}>
                <Form.Label>
                  <b style={{ color: "#b22234" }}>
                    <sup>*</sup>
                  </b>
                  First name:
                </Form.Label>
                <Form.Control
                  name="firstName"
                  style={{
                    width: "400px",
                    borderColor:
                      residentState.errorField &&
                      residentState.errorField == "firstName"
                        ? "#b22234"
                        : "",
                  }}
                  onChange={(e) => onChangeResident(e)}
                  value={
                    residentState.currResident &&
                    residentState.currResident.firstName
                  }
                  placeholder="First name"
                  autoComplete="off"
                />
                {residentState.errorField &&
                residentState.errorField == "firstName" ? (
                  <div style={{ color: "#b22234" }}>
                    {residentState.errorMsg}
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <b style={{ color: "#b22234" }}>
                    <sup>*</sup>
                  </b>
                  Last name:
                </Form.Label>
                <Form.Control
                  name="lastName"
                  style={{
                    width: "400px",
                    borderColor:
                      residentState.errorField &&
                      residentState.errorField == "lastName"
                        ? "#b22234"
                        : "",
                  }}
                  onChange={(e) => onChangeResident(e)}
                  value={
                    residentState.currResident &&
                    residentState.currResident.lastName
                  }
                  placeholder="Last name"
                  autoComplete="off"
                />
                {residentState.errorField &&
                residentState.errorField == "lastName" ? (
                  <div style={{ color: "#b22234" }}>
                    {residentState.errorMsg}
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <b style={{ color: "#b22234" }}>
                    <sup>*</sup>
                  </b>
                  Date of birth:
                </Form.Label>
                <br></br>
                <DatePicker
                  name="dob"
                  selected={
                    residentState.currResident && residentState.currResident.dob
                  }
                  placeholderText="Date of birth"
                  onChange={(e) => onChangeResidentDates(e, "dob")}
                  dateFormat="MM/dd/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  style={{
                    borderColor:
                      residentState.errorField &&
                      residentState.errorField == "dob"
                        ? "#b22234"
                        : "",
                  }}
                />
                {residentState.errorField &&
                residentState.errorField == "dob" ? (
                  <div style={{ color: "#b22234" }}>
                    {residentState.errorMsg}
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <b style={{ color: "#b22234" }}>
                    <sup>*</sup>
                  </b>
                  Sex
                </Form.Label>
                <Form.Check
                  name="sex"
                  type="radio"
                  label="male"
                  id="male"
                  value="m"
                  checked={
                    residentState.currResident &&
                    residentState.currResident.sex == "m"
                  }
                  onChange={(e) => onChangeResident(e)}
                />
                <Form.Check
                  name="sex"
                  type="radio"
                  label="female"
                  id="female"
                  value="f"
                  checked={
                    residentState.currResident &&
                    residentState.currResident.sex == "f"
                  }
                  onChange={(e) => onChangeResident(e)}
                />
                {residentState.errorField &&
                residentState.errorField == "sex" ? (
                  <div style={{ color: "#b22234" }}>
                    {residentState.errorMsg}
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <Form.Group>
                <Form.Label>
                  <b style={{ color: "#b22234" }}>
                    <sup>*</sup>
                  </b>
                  Admission date:
                </Form.Label>
                <br></br>
                <DatePicker
                  name="admissionDate"
                  selected={residentState.currResident.admissionDate}
                  placeholderText="Admission date*"
                  onChange={(e) => onChangeResidentDates(e, "admissionDate")}
                  dateFormat="MM/dd/yyyy"
                  disabledKeyboardNavigation
                  autoComplete="off"
                  style={{
                    borderColor:
                      residentState.errorField &&
                      residentState.errorField == "admissionDate"
                        ? "#b22234"
                        : "",
                  }}
                />
                {residentState.errorField &&
                residentState.errorField == "admissionDate" ? (
                  <div style={{ color: "#b22234" }}>
                    {residentState.errorMsg}
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Guardian e-mail:</Form.Label>
                <Form.Control
                  name="legRepEmail"
                  style={{
                    width: "400px",
                    borderColor:
                      residentState.errorField &&
                      residentState.errorField == "legRepEmail"
                        ? "#b22234"
                        : "",
                  }}
                  onChange={(e) => onChangeResident(e)}
                  value={
                    residentState.currResident &&
                    residentState.currResident.legRepEmail
                  }
                  placeholder="First name"
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginTop: "20px" }} fluid>
          <Row>
            <Col>
              <Button
                variant="outline-success"
                style={{ width: "100px" }}
                onClick={() => onHideResident()}
              >
                Close
              </Button>
              {"    "}
              <Button
                variant="success"
                style={{ width: "100px" }}
                onClick={() => onSaveResident()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

export default AddResidentDesktop;
