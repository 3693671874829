import React from "react";
import { Tab, Row, Col, Nav, Button } from "react-bootstrap";
import ActiveStaffTrainingsDesktop from "./activeStaffTrainingsDesktop";
import PassedStaffTrainingsDesktop from "./passedStaffTrainingsDesktop";
import AddStaffTrainingDesktop from "./addStaffTrainingDesktop";

const StafffTrainingListDesktop = ({
  onMarkAsPassed,
  onDeleteStaffTraining,
  onViewDetails,
  onPrintCertificate,
  onShowStaffTrainingView,
  onCreateTraining,
  onEditTraining,
  onStaffTraining,
  onDeleteTraining,
  trainingState,
  mode,
}) => {
  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="active"
      style={{ marginLeft: "0px", marginRight: "0px" }}
    >
      <Row>
        <Col>
          <Nav onSelect={(selectedKey) => onShowStaffTrainingView(selectedKey)}>
            <Nav.Item>
              <Nav.Link eventKey="active">
                <Button
                  style={
                    trainingState &&
                    trainingState.staffTrainingView &&
                    trainingState.staffTrainingView == "active"
                      ? navButtonSelectedStyle
                      : navButtonStyle
                  }
                >
                  Upcoming
                </Button>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="passed">
                <Button
                  style={
                    trainingState &&
                    trainingState.staffTrainingView &&
                    trainingState.staffTrainingView == "passed"
                      ? navButtonSelectedStyle
                      : navButtonStyle
                  }
                >
                  Completed
                </Button>
              </Nav.Link>
            </Nav.Item>
            {mode != "personal" ? (
              <Nav.Item>
                <Nav.Link eventKey="schedule">
                  <Button
                    style={
                      trainingState &&
                      trainingState.staffTrainingView &&
                      trainingState.staffTrainingView == "schedule"
                        ? navButtonSelectedStyle
                        : navButtonStyle
                    }
                  >
                    Schedule
                  </Button>
                </Nav.Link>
              </Nav.Item>
            ) : (
              ""
            )}
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tab.Content style={{ marginLeft: "0px", marginRight: "0px" }}>
            <Tab.Pane eventKey="active">
              <ActiveStaffTrainingsDesktop
                onMarkAsPassed={onMarkAsPassed}
                onDeleteStaffTraining={onDeleteStaffTraining}
                onViewDetails={onViewDetails}
                trainingState={trainingState}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="passed">
              <PassedStaffTrainingsDesktop
                onPrintCertificate={onPrintCertificate}
                trainingState={trainingState}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="schedule">
              <AddStaffTrainingDesktop
                onCreateTraining={onCreateTraining}
                onEditTraining={onEditTraining}
                onStaffTraining={onStaffTraining}
                onDeleteTraining={onDeleteTraining}
                trainingState={trainingState}
              />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "150px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "150px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default StafffTrainingListDesktop;
