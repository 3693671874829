import { useState } from "react";
import FacilityService from "../../../services/facility/facilityService";
import UtilityService from "../../../services/utilityService";

const useFacilityState = () => {
  const [facilityState, setFacilityState] = useState({});

  const refreshPage = async () => {
    let currFacility = await FacilityService.getFromToken();
    currFacility["timezoneOffset"] = new Date().getTimezoneOffset();
    setFacilityState({
      currFacility,
    });
  };

  const onChange = (event) => {
    let currFacility = facilityState.currFacility;
    currFacility[event.target.name] = event.target.value;
    setFacilityState({ currFacility, isError: false });
  };

  const onSave = async () => {
    const validatedFacility = FacilityService.validateFacility(
      facilityState.currFacility
    );
    if (validatedFacility.isError) {
      setFacilityState({
        ...facilityState,
        isError: true,
        errorMsg: validatedFacility.msg,
      });
      return;
    }
    const savedFacility = await FacilityService.save(
      facilityState.currFacility
    );
    setFacilityState({ currFacility: savedFacility, isError: false });
    onClose();
  };

  const onClose = () => {
    window.open("/", "_self");
  };

  const onExitPhone = (e) => {
    const phone = e.target.value;
    const formattedPhone = UtilityService.formatPhoneNumber(phone);
    const currFacility = { ...facilityState.currFacility };
    currFacility[e.target.name] = formattedPhone;
    setFacilityState({ ...facilityState, currFacility: currFacility });
  };

  return { refreshPage, onChange, onSave, onClose, onExitPhone, facilityState };
};

export default useFacilityState;
