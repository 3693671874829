import React from "react";
import { Container, Form, Row, Col, InputGroup } from "react-bootstrap";

const GeneralInfo = ({ onChangeInput, lic625State, complianceFormsState }) => {
  return (
    <React.Fragment>
      <Container fluid style={{ marginTop: "20px" }}>
        <Row>
          <Col md={4}>
            <Form.Label>
              <b>Resident name:</b>
            </Form.Label>
            <Form.Control
              name="residentName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("residentName", e)}
              value={lic625State && lic625State.residentName}
              autoComplete="off"
            />
          </Col>
          <Col md={2}>
            <Form.Label>
              <b>DOB:</b>
            </Form.Label>
            <Form.Control
              name="dob"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("dob", e)}
              value={lic625State && lic625State.dob}
              placeholder="DD/MM/YYYY"
              autoComplete="off"
            />
          </Col>
          <Col md={2}>
            <Form.Label>
              <b>Age:</b>
            </Form.Label>
            <br />
            <Form.Control
              name="age"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              style={{ width: "50%" }}
              onChange={(e) => onChangeInput("age", e)}
              value={lic625State && lic625State.age}
              autoComplete="off"
            />
          </Col>
          <Col md={2}>
            <Form.Label>
              <b>Sex:</b>
            </Form.Label>
            <Form.Check
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="MALE"
              label="MALE"
              onChange={(e) => onChangeInput("sex", "M", "true")}
              checked={lic625State != undefined && lic625State.sex == "M"}
            />
            <Form.Check
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="FEMALE"
              label="FEMALE"
              onChange={(e) => onChangeInput("sex", "F", "true")}
              checked={lic625State != undefined && lic625State.sex == "F"}
            />
          </Col>
          <Col md={2} style={{ justifyContent: "left" }}>
            <Form.Label>
              <b>Date:</b>
            </Form.Label>
            <Form.Control
              name="date"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("date", e)}
              value={lic625State && lic625State.date}
              placeholder="DD/MM/YYYY"
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col md={4}>
            <Form.Label>
              <b>Facility name:</b>
            </Form.Label>
            <Form.Control
              name="facilityName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityName", e)}
              value={lic625State && lic625State.facilityName}
              autoComplete="off"
            />
          </Col>
          <Col md={4}>
            <Form.Label>
              <b>Address:</b>
            </Form.Label>
            <Form.Control
              name="facilityAddress"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityAddress", e)}
              value={lic625State && lic625State.facilityAddress}
              autoComplete="off"
            />
          </Col>
          <Col md={4}>
            <Form.Label>
              <b>Check type of needs and service plan:</b>
            </Form.Label>
            <Form.Check
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="admission"
              label="Admission"
              onChange={(e) => onChangeInput("servicePlanType", "A", "true")}
              checked={
                lic625State != undefined && lic625State.servicePlanType == "A"
              }
            />
            <Form.Check
              type="radio"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              id="update"
              label="Update"
              onChange={(e) => onChangeInput("servicePlanType", "U", "true")}
              checked={
                lic625State != undefined && lic625State.servicePlanType == "U"
              }
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col md={4}>
            <Form.Label>
              <b>
                Person(s) or Agency(ies) referring client/resident for
                placement:
              </b>
            </Form.Label>
            <Form.Control
              name="agencyName"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("agencyName", e)}
              value={lic625State && lic625State.agencyName}
              autoComplete="off"
            />
          </Col>
          <Col md={4}>
            <Form.Label>
              <b>Facility license number:</b>
            </Form.Label>
            <Form.Control
              name="facilityLicenseNum"
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              onChange={(e) => onChangeInput("facilityLicenseNum", e)}
              value={lic625State && lic625State.facilityLicenseNum}
              autoComplete="off"
            />
          </Col>
          <Col md={4}>
            <Form.Label>
              <b>Telephone number:</b>
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="areaCode"
                style={{ width: "20%" }}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                onChange={(e) => onChangeInput("areaCode", e)}
                value={lic625State && lic625State.areaCode}
                autoComplete="off"
              />
              <Form.Control
                name="phoneNum"
                style={{ width: "80%" }}
                disabled={
                  complianceFormsState.currResidentFileMetadata &&
                  complianceFormsState.currResidentFileMetadata.status ==
                    "SIGNED"
                }
                onChange={(e) => onChangeInput("phoneNum", e)}
                value={lic625State && lic625State.phoneNum}
                autoComplete="off"
              />
            </InputGroup>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GeneralInfo;
