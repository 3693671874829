import React from "react";
import moment from "moment";
import CalendarItemMobile from "./calendarItemMobile";
import CalendarEmptyItemMobile from "./calendarEmptyItemMobile";

const MonthDaysMobile = ({
  selectedYear,
  selectedMonth,
  todosForTheMonth,
  onShowTaskDetails,
  selectedDate,
}) => {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)" }}>
      {[...Array(42)].map((e, i) => {
        const weekday = moment(
          selectedYear +
            "-" +
            ("0" + (selectedMonth + 1).toString()).slice(-2) +
            "-01",
          "YYYY-MM-DD"
        ).weekday();
        const daysInMonth = moment(
          selectedYear + "-" + ("0" + (selectedMonth + 1).toString()).slice(-2),
          "YYYY-MM"
        ).daysInMonth();
        if (i < weekday) {
          return <CalendarEmptyItemMobile />;
        } else {
          if (i - weekday >= daysInMonth) {
            return <CalendarEmptyItemMobile />;
          } else {
            return (
              <CalendarItemMobile
                todosForTheMonth={todosForTheMonth}
                i={i + 1 - weekday}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                onShowTaskDetails={onShowTaskDetails}
                weekday={i}
                selectedDate={selectedDate}
              />
            );
          }
        }
      })}
    </div>
  );
};

export default MonthDaysMobile;
