import APIClient from "../../apiClient";

const RESIDENT_FILE_METADATA_SIGN = "/residentFileMetadataSign";

class ResidentFileMetadataSignClient extends APIClient {
  async getAllByResidentFileMetadataIdAndStaffId(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + RESIDENT_FILE_METADATA_SIGN + "/" + id
    );
  }
}

export default ResidentFileMetadataSignClient;
