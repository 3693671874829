import MedicationClient from "../../api/medication/medicationClient";
import UtilityService from "../utilityService";

const medicationClient = new MedicationClient();

class MedicationService {
  async save(repo) {
    try {
      const response = await medicationClient.save(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getAll() {
    try {
      const response = await medicationClient.getAll();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getPrefix() {
    try {
      const response = await medicationClient.getPrefix();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async getMedicationHistoryView() {
    try {
      const response = await medicationClient.getMedicationHistoryView();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
        return response.data;
      }
      return response.data;
    } catch (err) {
      alert("Something went wrong please try again later");
    }
  }

  async lastMedicationMail() {
    try {
      const response = await medicationClient.lastMedicationMail();
      if (response.status != "200") {
        alert("Something went wrong please try again later");
        return response.data;
      }
      return response.data;
    } catch (err) {
      alert("Something went wrong please try again later");
    }
  }

  downloadReport(repo) {
    console.log("downloadReport", repo);
    medicationClient.download(repo).then((response) => {
      console.log("response", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "MedicationOrder.pdf");
      document.body.appendChild(link);
      link.click();
    });
  }

  async sendMail(repo) {
    try {
      const response = await medicationClient.sendMail(repo);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (err) {
      alert("Something went wrong please try again later");
    }
  }

  validateMedicationMail(medicationMail) {
    if (
      medicationMail.facilityPhone == undefined ||
      medicationMail.facilityPhone.trim() == ""
    ) {
      return {
        isValid: false,
        msg: "Contact phone can't be blank, please enter contact phone!",
      };
    } else if (
      medicationMail.facilityEmail == undefined ||
      medicationMail.facilityEmail.trim() == ""
    ) {
      return {
        isValid: false,
        msg: "Contact e-mail can't be blank, please enter contact e-mail!",
      };
    } else if (
      medicationMail.pharmacyEmail == undefined ||
      medicationMail.pharmacyEmail.trim() == ""
    ) {
      return {
        isValid: false,
        msg: "Pharmacy e-mail can't be blank, please enter pharmacy e-mail!",
      };
    } else if (!UtilityService.validateEmail(medicationMail.facilityEmail)) {
      return {
        isValid: false,
        msg: "Contact e-mail is not valid, please enter valid e-mail address!",
      };
    } else if (!UtilityService.validateEmail(medicationMail.pharmacyEmail)) {
      return {
        isValid: false,
        msg: "Pharmacy e-mail is not valid, please enter valid e-mail address!",
      };
    }
    return { isValid: true };
  }
}

export default new MedicationService();
