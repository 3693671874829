import APIClient from "../../apiClient";
import axios from "axios";

const BASE_FILE_METADATA = "/fileMetadata";

class ResidentFileMetadataClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_FILE_METADATA + "/" + id
    );
  }

  async getById(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_FILE_METADATA + "/" + id
    );
  }

  async copy(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/copy",
      repo
    );
  }

  async getByTemplateName(templateName) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/getByTemplateName",
      templateName
    );
  }

  async getAllForSigning(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_FILE_METADATA + "/forSigning/" + id
    );
  }

  async registerForSigning(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/registerForSigning",
      repo
    );
  }

  async save(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA,
      repo
    );
  }

  async generatePreview(id) {
    return await super.performAsync(
      "put",
      super.getBaseContext() + BASE_FILE_METADATA + "/generatePreview/" + id
    );
  }

  async getPreview(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_FILE_METADATA + "/getPreview/" + id
    );
  }

  async getReportForCurrentUser(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_FILE_METADATA + "/getReportForCurrentUser"
    );
  }

  async getResidentFromResidentFileMetadataId(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() +
        BASE_FILE_METADATA +
        "/getResidentFromResidentFileMetadataId/" +
        id
    );
  }

  async shareReport(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/shareReport",
      repo
    );
  }

  async sendReportOnEmail(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/sendReportOnEmail",
      repo
    );
  }

  async signReport(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/signReport",
      repo
    );
  }

  async unSignReport(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/unSignReport",
      repo
    );
  }

  async createReport(residentFormId) {
    return await super.performAsync(
      "put",
      super.getBaseContext() +
        BASE_FILE_METADATA +
        "/createReport/" +
        residentFormId
    );
  }

  async getAllByFormId(id) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_FILE_METADATA + "/form/" + id
    );
  }

  async getResidentFileSearchByFacility() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_FILE_METADATA + "/search"
    );
  }

  async getByResidentFormId(residentFormId) {
    return await super.performAsync(
      "get",
      super.getBaseContext() +
        BASE_FILE_METADATA +
        "/byResidentForm/" +
        residentFormId
    );
  }

  async ifFileExists(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/ifFileExists",
      repo
    );
  }

  async uploadSigned(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/uploadSigned",
      repo
    );
  }

  async deleteSignatureRequest(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/deleteSignatureRequest",
      repo
    );
  }

  getAll() {
    return super.perform("get", super.getBaseContext() + BASE_FILE_METADATA);
  }

  upload(repo) {
    return axios({
      url:
        super.getBaseURI() +
        super.getBaseContext() +
        BASE_FILE_METADATA +
        "/upload",
      method: "POST",
      data: repo,
      headers: {
        authorization: localStorage.getItem("token"),
      },
    }).then((resp) => {
      return resp.data ? resp.data : undefined;
    });
  }

  register(repo) {
    return super.perform(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/register",
      repo
    );
  }

  download(id) {
    return axios({
      url:
        super.getBaseURI() +
        super.getBaseContext() +
        BASE_FILE_METADATA +
        "/download/" +
        id,
      method: "POST",
      responseType: "blob",
      headers: { authorization: localStorage.getItem("token") },
    });
  }

  async notify(id) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_FILE_METADATA + "/notify/" + id
    );
  }
}

export default ResidentFileMetadataClient;
