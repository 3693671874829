import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./App.css";
import MainTodos from "./component/todo/mainTodos";
import MainResident from "./component/compliance/resident/mainResident";
import MainCompliance from "./component/compliance/mainCompliance";
import MainStaffTraining from "./component/compliance/training/mainStaffTraining";
import MainShopping from "./component/products/mainShopping";
import MainUser from "./component/user/mainUser";
import MainFacility from "./component/facility/mainFacility";
import MainPage from "./component/main/mainPage";
import MainMedication from "./component/medication/mainMedication";
import AuthenticatedRoute from "./component/main/authenticatedRoute";
import LogoutComponent from "./component/main/logoutComponent";
import MainCollaboration from "./component/collaboration/mainCollaboration";
import MyTraining from "./component/compliance/training/myTraining";
import MainCheckList from "./component/compliance/checklist/mainCheckList";
import MainDocuments from "./component/compliance/documents/mainDocuments";

class App extends Component {
  state = {
    activeKey: 0,
  };

  getInitialState() {
    return { activeKey: 1 };
  }

  handleSelect = (selectedKey) => {
    alert("selected " + selectedKey);
    this.setState({ activeKey: selectedKey });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handeleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handeleScroll);
  }

  handeleScroll() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("myDiv").className = "fixed-bottom";
      document.getElementById("myDiv").style.textAlign = "right";
      document.getElementById("myDiv").style.display = "block";
    } else {
      document.getElementById("myDiv").className = "fixed-bottom";
      document.getElementById("myDiv").style.textAlign = "right";
      document.getElementById("myDiv").style.display = "none";
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <main
            className="container-fluid"
            style={{
              top: 1000,
              color: "#3C3B6E",
              // fontFamily: "Helvetica",
            }}
          >
            <Switch>
              <AuthenticatedRoute path="/" exact component={MainPage} />
              <AuthenticatedRoute path="/todo" exact component={MainTodos} />
              <AuthenticatedRoute
                path="/medication"
                exact
                component={MainMedication}
              />
              <AuthenticatedRoute
                path="/product"
                exact
                component={MainShopping}
              />
              <AuthenticatedRoute path="/staff" exact component={MainUser} />
              <AuthenticatedRoute
                path="/facility"
                exact
                component={MainFacility}
              />
              <AuthenticatedRoute
                path="/collaboration"
                exact
                component={MainCollaboration}
              />

              <AuthenticatedRoute
                path="/compliance"
                exact
                component={MainCompliance}
              />
              <AuthenticatedRoute
                path="/resident"
                exact
                component={MainResident}
              />
              <AuthenticatedRoute
                path="/training"
                exact
                component={MainStaffTraining}
              />
              <AuthenticatedRoute
                path="/mytraining"
                exact
                component={MyTraining}
              />
              <AuthenticatedRoute
                path="/checklist"
                exact
                component={MainCheckList}
              />
              <AuthenticatedRoute
                path="/documents"
                exact
                component={MainDocuments}
              />
              <AuthenticatedRoute
                path="/logout"
                exact
                component={LogoutComponent}
              />
            </Switch>
          </main>
        </Router>
        <div
          id="myDiv"
          className="fixed-bottom"
          style={{ textAlign: "right", display: "none" }}
        >
          <Button onClick={this.topFunction} id="myBtn" title="Go to top">
            Top
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
