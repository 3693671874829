import React, { useEffect } from "react";
import Users from "./users";
import AddUser from "./addUser";
import Header from "../header";
import useUserState from "./hook/useStaffState";

const MainUser = () => {
  const {
    refreshPage,
    onDelete,
    onUpdate,
    onShow,
    onHide,
    onSaveStaff,
    onChange,
    onChangeDates,
    onChangePassword,
    onToggleChanged,
    onFilterUsers,
    onShowPassword,
    onHidePassword,
    usersState,
  } = useUserState({});

  useEffect(() => {
    refreshPage();
  }, []);

  if (!usersState.show)
    return (
      <React.Fragment>
        <Users
          onDelete={onDelete}
          onUpdate={onUpdate}
          onShow={onShow}
          onFilterUsers={onFilterUsers}
          users={
            usersState.filteredUsers &&
            usersState.filteredUsers.filter((user) => user.role != "admin")
          }
        />
      </React.Fragment>
    );
  else
    return (
      <React.Fragment>
        <AddUser
          onSaveStaff={onSaveStaff}
          onHide={onHide}
          onChange={onChange}
          onChangeDates={onChangeDates}
          onChangePassword={onChangePassword}
          onToggleChanged={onToggleChanged}
          onShowPassword={onShowPassword}
          onHidePassword={onHidePassword}
          usersState={usersState}
        />
      </React.Fragment>
    );
};

export default MainUser;
