import axios from "axios";
import AuthenticationService from "../api/authenticationService";
import { API_ROOT } from "./api-config";

const BASE_URI = API_ROOT;
const BASE_CONTEXT = "/api";

const client = axios.create({
  baseURL: BASE_URI,
  json: true,
});

class APIClient {
  constructor() {
    client.interceptors.response.use(undefined, (err) => {
      //TODO handle session expired
      // if (err.toString().includes("Error: Network Error")) {
      //   console.log("HANDLE 401", err);
      //   alert("your session has expired!");
      //   AuthenticationService.logout();
      // }
    });
  }
  getBaseContext() {
    return BASE_CONTEXT;
  }

  getBaseURI() {
    return BASE_URI;
  }

  async performAsync(method, resource, data) {
    try {
      const respone = await client({
        method,
        url: resource,
        data,
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      if (respone == undefined) {
        alert("Your session has expired");
        AuthenticationService.logout();
        return { data: "", status: "200" };
      }

      return respone;
    } catch (err) {
      console.log(err);
      return { data: "", status: "200" };
    }
  }

  getReponseData(response) {
    if (response.status != "200") {
      alert("Something went wrong please try again later");
    }
    return response.data;
  }

  perform(method, resource, data) {
    console.log("method", method);
    console.log("resource", resource);
    console.log("data", data);
    return client({
      method,
      url: resource,
      data,
      headers: {
        authorization: localStorage.getItem("token"),
      },
    })
      .then((resp) => {
        return resp.data ? resp.data : undefined;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default APIClient;
