import ResidentFileMetadataSignClient from "../../../api/compliance/documents/residentFileMetadataSignClient";

const residentFileMetadataSignClient = new ResidentFileMetadataSignClient();

class ResidentFileMetadataSignService {
  async getAllByResidentFileMetadataIdAndStaffId(id) {
    try {
      const response =
        await residentFileMetadataSignClient.getAllByResidentFileMetadataIdAndStaffId(
          id
        );

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }
}

export default new ResidentFileMetadataSignService();
