import React from "react";
import { Container, Form, Row, Col } from "react-bootstrap";

const HealthInfo = ({ onChangeInput, lic603aState, complianceFormsState }) => {
  return (
    <Container fluid>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>HEALTH</b>(Describe overall health condition including any
              dietary limitations)
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("health", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic603aState && lic603aState.health}
              as="textarea"
              rows={2}
              style={{ width: "100%" }}
              maxLength={240}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>PHYSICAL DISABILITIES</b>(Describe any physical limitations
              including vision, hearing or speech)
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("physicalDisabilities", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic603aState && lic603aState.physicalDisabilities}
              as="textarea"
              rows={5}
              style={{ width: "100%" }}
              maxLength={600}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>MENTAL CONDITION</b>(Specify extent of any symptoms of
              confusion, forgetfulness: participation in social activities
              (i.e., active or withdrawn))
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("mentalCondition", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic603aState && lic603aState.mentalCondition}
              as="textarea"
              rows={4}
              style={{ width: "100%" }}
              maxLength={480}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>HEALTH HISTORY</b>(List currently prescribed medications and
              major illnesses, surgery, accidents; specify whether hospitalized
              and length of hospitalization in last 5 years)
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("healthHistory", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic603aState && lic603aState.healthHistory}
              as="textarea"
              rows={5}
              style={{ width: "100%" }}
              maxLength={600}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>SOCIAL FACTORS</b>(Describe likes and dislikes, interests and
              activities)
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("socialFactors", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic603aState && lic603aState.socialFactors}
              as="textarea"
              rows={3}
              style={{ width: "100%" }}
              maxLength={360}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default HealthInfo;
