import React, { useEffect } from "react";
import { Tab, Row, Col, Nav, Modal, Button } from "react-bootstrap";
import ScheduleCheckDesktop from "./scheduleCheckDesktop";
import "../checklist.css";
//Services
import CheckListControlListDesktop from "./checkListControlListDesktop";
import CompleteCheckDesktop from "./completeCheckDesktop";
import PassedCheckListControlDesktop from "./passedCheckListControlDesktop";
import ViewCheckResult from "../viewCheckResult";
import useCheckListState from "../hook/useCheckListState";
import { TiPlus } from "react-icons/ti";

const MainCheckListDesktop = ({
  onHideScheduleCheckList,
  onSelectCheck,
  onGoToSchedule,
  onSaveScheduleCheckListControl,
  onSaveCheckResult,
  onHideCheckCompleted,
  onDeleteCheckResult,
  onViewCheckCompleted,
  onSetControlStatus,
  onSetComment,
  onHideCheckDetails,
  onViewCompletedDetails,
  onShowScheduleCheckList,
  onFilterCheckList,
  onChangeTodo,
  onChangeTodoDates,
  onOpenCheckListView,
  checkListState,
}) => {
  return (
    <React.Fragment>
      <Tab.Container id="left-tabs-example" defaultActiveKey="active">
        <Row>
          <Col xs="auto">
            <Button
              variant="success"
              style={{
                width: "180px",
              }}
              onClick={onShowScheduleCheckList}
            >
              <TiPlus
                style={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  verticalAlign: "middle",
                }}
              />{" "}
              Schedule check
            </Button>
          </Col>
          <Col>
            <Nav
              style={{ justifyContent: "center" }}
              onSelect={(selectedKey) => onOpenCheckListView(selectedKey)}
            >
              <Nav.Item>
                <Nav.Link eventKey="active">
                  <Button
                    style={
                      checkListState && checkListState.checkListView == "active"
                        ? navButtonSelectedStyle
                        : navButtonStyle
                    }
                  >
                    Upcoming
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="passed">
                  <Button
                    style={
                      checkListState && checkListState.checkListView == "passed"
                        ? navButtonSelectedStyle
                        : navButtonStyle
                    }
                  >
                    Completed
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xs="auto">
            <div style={{ width: "150px" }}></div>
          </Col>
        </Row>
        {checkListState.checkResults != undefined &&
        checkListState.checkResults.length > 0 ? (
          <Row style={{ height: "80vh" }}>
            <Col>
              <Tab.Content style={{ height: "80vh" }}>
                <Tab.Pane style={{ height: "80vh" }} eventKey="active">
                  <CheckListControlListDesktop
                    checkResults={checkListState.checkResults}
                    viewCheckCompleted={onViewCheckCompleted}
                    deleteCheckResult={onDeleteCheckResult}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="passed">
                  <PassedCheckListControlDesktop
                    checkResults={checkListState.checkResults}
                    viewCompletedDetails={onViewCompletedDetails}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        ) : (
          <b style={{ color: "#b22234" }}>Please schedule check!</b>
        )}
      </Tab.Container>
      <Modal
        size="lg"
        animation="true"
        show={checkListState.showScheduleCheckList}
        onHide={onHideScheduleCheckList}
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule check</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleCheckDesktop
            onFilterCheckList={onFilterCheckList}
            onSelectCheck={onSelectCheck}
            onHideScheduleCheckList={onHideScheduleCheckList}
            onGoToSchedule={onGoToSchedule}
            onSaveScheduleCheckListControl={onSaveScheduleCheckListControl}
            onChangeTodo={onChangeTodo}
            onChangeTodoDates={onChangeTodoDates}
            checkListState={checkListState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        show={checkListState.showCheckCompleted}
        onHide={onHideCheckCompleted}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark check as completed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompleteCheckDesktop
            onSetControlStatus={onSetControlStatus}
            onSetComment={onSetComment}
            onHideCheckCompleted={onHideCheckCompleted}
            onSaveCheckResult={onSaveCheckResult}
            checkListState={checkListState}
          />
        </Modal.Body>
      </Modal>
      <Modal show={checkListState.showCheckDetails} onHide={onHideCheckDetails}>
        <Modal.Header closeButton>
          <Modal.Title>Check details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewCheckResult checkResult={checkListState.currCheckResult} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "200px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "200px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainCheckListDesktop;
