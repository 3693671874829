import React, { useRef, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import "./css/sigCanvas.css";

const DepositSignature = ({ onHideDepositSignature, onSaveSignature }) => {
  const sigCanvas = useRef({});

  const clear = () => sigCanvas.current.clear();

  const save = () => {
    const base64Signature = sigCanvas.current.toDataURL("image/png");
    onSaveSignature(base64Signature);
  };

  return (
    <Container fluid>
      <Row>
        <Col
          style={{
            paddingLeft: "0px",
            marginBottom: "0px",
            paddingBottom: "0px",
          }}
        >
          Place your signature here:
        </Col>
      </Row>
      <Row>
        <Col
          style={{ paddingLeft: "0px", marginTop: "0px", paddingTop: "0px" }}
        >
          <small>(the signature will be used for signing documents)</small>
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingLeft: "0px" }}>
          <SignaturePad
            ref={sigCanvas}
            penColor="black"
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingLeft: "0px" }}>
          <Button
            variant="outline-danger"
            onClick={clear}
            style={{ width: "100px" }}
          >
            Clear
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingLeft: "0px", marginTop: "10px" }}>
          <Button
            variant="outline-success"
            onClick={onHideDepositSignature}
            style={{ width: "100px" }}
          >
            Cancel
          </Button>
          {"    "}
          <Button variant="success" onClick={save} style={{ width: "100px" }}>
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default DepositSignature;
