import React from "react";
import DatePicker from "react-datepicker";
import { Button, Form } from "react-bootstrap";

const ScheduleTrainingMobile = ({
  onChangeScheduleDate,
  onSaveStaffTraining,
  onHideSchedule,
  trainingState,
}) => {
  console.log("schedule", trainingState.schedule);
  return (
    <React.Fragment>
      <Form>
        <Form.Row>
          {trainingState.isError ? (
            <div style={errorMsgStyle}>
              <b>ERROR:</b> {trainingState.errorMsg}
            </div>
          ) : (
            ""
          )}
        </Form.Row>
        <Form.Row>
          <Form.Label size="sm">Schedule date:</Form.Label>
        </Form.Row>
        <Form.Row>
          <DatePicker
            selected={
              trainingState.schedule && trainingState.schedule.scheduleDate
            }
            placeholderText="Schedule date"
            name="scheduleDate"
            onChange={(e) => onChangeScheduleDate(e)}
            minDate={new Date()}
            dateFormat="MM/dd/yyyy"
            disabledKeyboardNavigation
          />
        </Form.Row>
        <Form.Row>
          <br />
        </Form.Row>
        <Form.Row>
          <Button
            size="sm"
            style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}
            variant="success"
            onClick={() => onSaveStaffTraining()}
          >
            Save
          </Button>
        </Form.Row>
        <Form.Row>
          <Button
            variant="outline-success"
            size="sm"
            style={{ width: "100%" }}
            variant="primary"
            onClick={() => onHideSchedule()}
          >
            Cancel
          </Button>
        </Form.Row>
      </Form>
    </React.Fragment>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default ScheduleTrainingMobile;
