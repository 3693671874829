import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

const SelectMonthMobile = ({
  onChangeMonth,
  onChangeYear,
  onPreviousMonth,
  onNextMonth,
  selectedMonth,
  selectedYear,
  months,
}) => {
  const currentYear = moment(new Date()).year() - 1;

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <Form.Control
              style={{
                fontWeight: "bold",
                fontSize: "12px",
              }}
              defaultValue={selectedMonth}
              value={selectedMonth}
              name="currentMonth"
              as="select"
              onChange={onChangeMonth}
              size="sm"
            >
              {months.map((element) => (
                <option key={element.id} value={element.id}>
                  {element.name}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              style={{
                fontWeight: "bold",
                fontSize: "12px",
              }}
              defaultValue={selectedYear}
              value={selectedYear}
              name="currentYear"
              as="select"
              onChange={onChangeYear}
              size="sm"
            >
              {[...Array(15)].map((element, index) => (
                <option key={currentYear + index} value={currentYear + index}>
                  {currentYear + index}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Container>
      {/* <Form
        inline
        style={{
          alignItems: "center",
          display: "flex",
          fontWeight: "500",
          justifyContent: "center",
          verticalAlign: "middle",
        }}
      >
       
       
      </Form> */}
      {/* <div style={{ width: "200px" }}></div> */}
    </React.Fragment>
  );
};

const buttonStyle = {
  borderColor: "transparent",
  borderRadius: "0",
  backgroundColor: "transparent",
  margin: "3px",
  color: "black",
};

export default SelectMonthMobile;
