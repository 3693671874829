import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { GiMedicalPack } from "react-icons/gi";
import { TiPlus } from "react-icons/ti";

const AddMedicationsDesktop = ({
  onAddMedication,
  onChangeRxCode,
  onFilterMedications,
  medicationState,
}) => {
  return (
    <Container fluid>
      <Row>
        <Col xs="auto">
          <Form.Control
            placeholder="Enter RX CODE"
            onChange={(e) => onChangeRxCode(e)}
            style={{ width: "200px", color: "#3C3B6E" }}
            value={medicationState && medicationState.currRxCode}
          />
        </Col>
        <Col>
          <Button
            variant="success"
            style={{
              width: "170px",
              verticalAlign: "middle",
              color: "white",
            }}
            onClick={() => onAddMedication()}
          >
            <TiPlus
              style={{
                color: "white",
                width: "25px",
                height: "25px",
                verticalAlign: "middle",
              }}
            />
            {"   "}
            Add medication
          </Button>
        </Col>
        <Col xs="auto">
          <Form.Control
            placeholder="Filter medications"
            onChange={(e) => onFilterMedications(e)}
            style={{ width: "200px" }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AddMedicationsDesktop;
