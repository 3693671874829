import APIClient from "../apiClient";

const BASE_TODO_COMMENT = "/todoComment";

class TodoCommentClient extends APIClient {
  async save(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_TODO_COMMENT,
      repo
    );
  }

  async getByTodo(todoId) {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_TODO_COMMENT + "/getByTodo?todoId=" + todoId
    );
  }
}

export default TodoCommentClient;
