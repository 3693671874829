import ResidentClient from "../../../api/compliance/resident/residentClient";
import ResidentDoctorClient from "../../../api/compliance/resident/residentDoctorClient";

const residentDoctorClient = new ResidentDoctorClient();

class ResidentDoctorService {
  async delete(id) {
    try {
      const response = await residentDoctorClient.delete(id);
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  async save(repo) {
    let response = "";
    try {
      response = await residentDoctorClient.insert(repo);

      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  getAll() {
    return residentDoctorClient.getAll();
  }

  getFromToken() {
    return residentDoctorClient.getFromToken();
  }

  async getResidentDoctorByResidentId(id) {
    try {
      const response = await residentDoctorClient.getResidentDoctorByResidentId(
        id
      );
      if (response.status != "200") {
        alert("Something went wrong please try again later");
      }
      return response.data;
    } catch (error) {
      alert("Something went wrong please try again later");
    }
  }

  validateResDoctor(resDoc) {
    if (resDoc == undefined) {
      return {
        isError: true,
        errMsg: "Please enter doctor full name",
      };
    }

    if (resDoc.name == undefined || resDoc.name.length == 0) {
      return {
        isError: true,
        errMsg: "Please enter doctor full name",
      };
    }

    return {
      isError: false,
    };
  }
}

export default new ResidentDoctorService();
