import React, { useState } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { BsCheck, BsTrash } from "react-icons/bs";
import TableSort from "../../../common/tableSort";
import moment from "moment";
import TableFilter from "../../../common/tableFilter";
import CheckListControlListFilter from "../checkListControlListFilter";
import "../../../common/table.css";

const CheckListControlListMobile = ({
  checkResults,
  viewCheckCompleted,
  deleteCheckResult,
}) => {
  const [sortScheduedDateType, setSortScheduedDateType] = useState();
  // const [isFilterOn, setIsFilterOn] = useState(false);
  const [filter, setFilter] = useState({});

  const completedCheckResults =
    checkResults &&
    checkResults
      .filter((checkResult) => checkResult.isActive != false)
      .filter((checkResult) => {
        if (filter != undefined) {
          if (filter.scheduledDateStart != undefined) {
            if (filter.scheduledDateEnd != undefined) {
              if (
                moment(checkResult.todo.startDate).isSame(
                  filter.scheduledDateStart,
                  "day"
                ) ||
                moment(checkResult.todo.startDate).isSame(
                  filter.scheduledDateEnd,
                  "day"
                ) ||
                moment(checkResult.todo.startDate).isBetween(
                  filter.scheduledDateStart,
                  filter.scheduledDateEnd,
                  "day"
                )
              ) {
                return checkResult;
              } else {
                return null;
              }
            }

            if (
              moment(checkResult.todo.startDate).isAfter(
                filter.scheduledDateStart
              )
            ) {
              return checkResult;
            } else {
              return null;
            }
          }
        }
        return checkResult;
      });

  function sortByScheduled(scheduedDate) {
    resetSortType();
    setSortScheduedDateType(scheduedDate);
  }

  function resetSortType() {
    setSortScheduedDateType("");
  }

  // function showFilter(isFilterOn) {
  //   setIsFilterOn(isFilterOn);
  // }

  console.log("filter-main", filter);
  const isFilterSet =
    filter != undefined &&
    (filter.scheduledDateStart != undefined ||
      filter.scheduledDateEnd != undefined)
      ? true
      : false;

  return (
    <React.Fragment>
      <div class="tableFixHead">
        <Table style={{ fontSize: "12px" }}>
          <thead>
            <tr>
              <th
                style={{
                  ...thStyle,
                  borderRightColor: "#3c3b6e",
                  width: "150px",
                }}

                // style={{
                //   width: "150px",
                //   minWidth: "150px",
                //   textAlign: "left",
                //   borderRightColor: "transparent",
                // }}
              >
                <b>Schedule date</b>
              </th>
              <th
                style={{
                  ...thStyle,
                  borderLeftColor: "#3c3b6e",
                  textAlign: "right",
                  width: "50px",
                }}
              >
                <TableSort sort={sortByScheduled} type={sortScheduedDateType} />
              </th>
              <th style={{ ...thStyle, borderRightColor: "#3c3b6e" }}>
                <b>Description</b>
              </th>
              <th
                colSpan={2}
                style={{
                  ...thStyle,
                  borderRightColor: "#3c3b6e",
                  textAlign: "right",
                  width: "200px",
                }}
              >
                <b>Actions</b>
              </th>
              {/* <td
                style={{
                  minWidth: "75px",
                  textAlign: "center",
                  width: "75px",
                }}
              >
                <TableFilter
                  showFilter={showFilter}
                  isFilterOn={isFilterOn}
                  isFilterSet={isFilterSet}
                />
              </td> */}
            </tr>
          </thead>
          <tbody>
            {completedCheckResults != undefined &&
            completedCheckResults.length > 0 ? (
              completedCheckResults
                .sort((A, B) => {
                  if (
                    sortScheduedDateType != "" &&
                    sortScheduedDateType != undefined
                  ) {
                    if (A.todo.startDate > B.todo.startDate) {
                      if (sortScheduedDateType == "asc") {
                        return 1;
                      } else {
                        return -1;
                      }
                    } else {
                      if (sortScheduedDateType == "asc") {
                        return -1;
                      } else {
                        return 1;
                      }
                    }
                  }
                })
                .map((checkResult) => {
                  return (
                    <tr>
                      <td
                        colSpan={2}
                        style={{ width: "200px", minWidth: "200px" }}
                      >
                        <Form.Label size="sm">
                          {moment(checkResult.todo.startDate).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          size="sm"
                          disabled="true"
                          style={{ width: "100%" }}
                          value={checkResult.description}
                          autoComplete="none"
                        />
                      </td>
                      <td
                        style={{
                          width: "20px",
                          minWidth: "20px",
                          textAlign: "left",
                          borderLeftColor: "transparent",
                        }}
                      >
                        <Button
                          variant="outline-light"
                          size="sm"
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                          }}
                          onClick={() => viewCheckCompleted(checkResult)}
                        >
                          <BsCheck
                            style={{
                              color: "green",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </Button>
                      </td>
                      <td
                        style={{
                          width: "20px",
                          minWidth: "20px",
                          textAlign: "left",
                          borderLeftColor: "transparent",
                        }}
                      >
                        <Button
                          variant="outline-light"
                          size="sm"
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                          }}
                          onClick={() => deleteCheckResult(checkResult)}
                        >
                          <BsTrash
                            style={{
                              color: "#b22234",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        </Button>
                      </td>
                    </tr>
                  );
                })
            ) : (
              <b style={{ color: "#b22234" }}>There are no scheduled checks!</b>
            )}
          </tbody>
        </Table>
        {/* {isFilterOn ? (
          <CheckListControlListFilter setFilter={setFilter} />
        ) : isFilterSet ? (
          <p style={{ color: "#b22234" }}>Filter is on</p>
        ) : (
          ""
        )} */}
      </div>
    </React.Fragment>
  );
};

const thStyle = {
  backgroundColor: "lightgray",
  color: "black",
  border: "none",
  verticalAlign: "middle",
};

export default CheckListControlListMobile;
