import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  CardDeck,
  Modal,
} from "react-bootstrap";
import AddProductDesktop from "../add-product/desktop/addProductDesktop";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ShoppingProductDesktop from "../products/desktop/shoppingProductDesktop";
import ShoppingCart from "../shopping-cart/shoppingCart";
import WeeklyAddsDesktop from "../weekly-add/desktop/weeklyAddsDesktop";
import useShoppingProduct from "../hook/useShoppingProduct";
import Header from "../../header";
import { Badge } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";

const MainShoppingDesktop = ({
  refreshPage,
  onFilterProducts,
  onAddRemoveToCart,
  onSetView,
  onShowProduct,
  onHideProduct,
  onChangeProduct,
  onIsPurchased,
  onRemoveShoppingCartProduct,
  onSaveProduct,
  onDeleteProduct,
  onChangeProductItem,
  onTogglePrices,
  shoppingState,
}) => {
  return (
    <React.Fragment>
      {/* <Header /> */}
      <Container fluid="true">
        <Row style={{ marginBottom: "15px" }}>
          <Col xs="auto">
            <Button
              variant="success"
              style={{ width: "170px" }}
              onClick={() => onShowProduct({})}
            >
              <TiPlus
                style={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  verticalAlign: "middle",
                }}
              />
              {"   "}
              New product
            </Button>
          </Col>
          <Col className="text-center">
            <Button
              variant="link"
              style={
                shoppingState.currView == "prd"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onSetView("prd")}
            >
              Products
            </Button>
            <Button
              variant="link"
              style={
                shoppingState.currView == "sc"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onSetView("sc")}
            >
              Shopping Cart{" "}
              <Badge variant="danger">
                {shoppingState.shoppingCartProducts != undefined
                  ? shoppingState.shoppingCartProducts.length
                  : "0"}
              </Badge>
            </Button>
            <Button
              variant="link"
              style={
                shoppingState.currView == "wa"
                  ? navButtonSelectedStyle
                  : navButtonStyle
              }
              onClick={() => onSetView("wa")}
            >
              Weekly Adds
            </Button>
          </Col>
          <Col xs="auto">
            <Form.Control
              placeholder="Filter Products"
              onChange={onFilterProducts}
              style={{ width: "300px" }}
            />
          </Col>
        </Row>
      </Container>
      <hr></hr>
      <Container fluid="true">
        <Row>
          <Col>
            <div
              id=""
              style={{
                overflow: "scroll",
                overflowX: "hidden",
                // height: "300px",
                height: "80vh",
              }}
            >
              {shoppingState.currView == "prd" ? (
                <CardDeck>
                  {shoppingState.filteredProducts &&
                    shoppingState.filteredProducts.map((product, index) => (
                      <ShoppingProductDesktop
                        onAddRemoveToCart={onAddRemoveToCart}
                        onSaveProduct={onSaveProduct}
                        onChangeProductItem={onChangeProductItem}
                        onHideProduct={onHideProduct}
                        onShowProduct={onShowProduct}
                        onTogglePrices={onTogglePrices}
                        showPricesList={shoppingState.showPricesList}
                        product={product}
                        isIntoCart={
                          shoppingState.shoppingCartProducts != undefined
                            ? shoppingState.shoppingCartProducts.filter(
                                (shoppingCartProduct) =>
                                  shoppingCartProduct.product.id == product.id
                              ).length > 0
                            : false
                        }
                        key={index}
                      />
                    ))}
                </CardDeck>
              ) : shoppingState.currView == "wa" ? (
                <WeeklyAddsDesktop />
              ) : (
                <ShoppingCart
                  onRemoveShoppingCartProduct={onRemoveShoppingCartProduct}
                  shoppingCartProducts={shoppingState.shoppingCartProducts}
                  onIsPurchased={onIsPurchased}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={shoppingState.show} onHide={onHideProduct}>
        <Modal.Header closeButton>
          <Modal.Title>Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <AddProductDesktop
              onSaveProduct={onSaveProduct}
              onHideProduct={onHideProduct}
              onDeleteProduct={onDeleteProduct}
              onChangeProduct={onChangeProduct}
              shoppingState={shoppingState}
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const badgeSeletedStyle = {
  backgroundColor: "#d9534f",
  borderColor: "#d9534f",
};

const badgeStyle = {
  backgroundColor: "#d9534f",
  borderColor: "#d9534f",
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "200px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "200px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainShoppingDesktop;
