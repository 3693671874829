import APIClient from "../apiClient";

const BASE_PRODUCT = "/product";

class ProductClient extends APIClient {
  async delete(id) {
    return await super.performAsync(
      "delete",
      super.getBaseContext() + BASE_PRODUCT + "/" + id
    );
  }

  async save(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + BASE_PRODUCT,
      repo
    );
  }

  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_PRODUCT
    );
  }
}

export default ProductClient;
