import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import * as Constants from "../util/constants";

const Repeated = ({
  onChange,
  repeatedCategoryId,
  repeatedCategoryValue,
  repeatedCategoryTypeId,
}) => {
  return (
    <React.Fragment>
      <Form.Row>
        <Form.Group as={Col} controlId="formRepeatStep">
          <Form.Control
            value={repeatedCategoryId}
            name="repeatedCategoryId"
            as="select"
            onChange={(e) => onChange(e)}
            size="sm"
          >
            {Constants.REPEATED_CATEGORY.map((element) => (
              <option key={element.id} value={element.id}>
                {element.value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="formOtherDays"
          style={{
            display:
              repeatedCategoryId && repeatedCategoryId == "6"
                ? "inline"
                : "none",
          }}
        >
          <Form.Control
            value={repeatedCategoryValue}
            name="repeatedCategoryValue"
            as="select"
            // onChange={handleRepeatedCategoryValue}
            onChange={(e) => onChange(e)}
            size="sm"
          >
            {Constants.REPEATED_CATEGORY_VALUE.map((element) => (
              <option key={element.id} value={element.id}>
                {element.value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="formOtherMonths"
          style={{
            display:
              repeatedCategoryId && repeatedCategoryId == "6"
                ? "inline"
                : "none",
          }}
        >
          <Form.Control
            value={repeatedCategoryTypeId}
            name="repeatedCategoryTypeId"
            as="select"
            onChange={(e) => onChange(e)}
            size="sm"
          >
            {Constants.REPEATED_CATEGORY_TYPE.map((element) => (
              <option key={element.id} value={element.id}>
                {element.value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form.Row>
    </React.Fragment>
  );
};

export default Repeated;
