import { useState } from "react";
import FacilityService from "../../../services/facility/facilityService";
import StaffService from "../../../services/user/staffService";
import MedicationService from "../../../services/medication/medicationService";
import UtilityService from "../../../services/utilityService";

const useSendMailState = () => {
  const [sendMailState, setSendMailState] = useState({});

  const refreshPage = async (medications) => {
    const lastMedicationMail = await MedicationService.lastMedicationMail();
    let medicationMail = { medications: medications };
    if (lastMedicationMail == undefined || lastMedicationMail.length == 0) {
      const facility = await FacilityService.getFromToken();
      const staff = await StaffService.getByUserName(
        sessionStorage.getItem("authenticatedUser")
      );
      medicationMail = {
        ...medicationMail,
        facilityName: facility.name,
        facilityAddress: facility.address,
        facilityPhone: facility.telephone,
        facilityEmail: staff.email,
        pharmacyEmail: "",

        facilityId: facility.id,
      };
    } else {
      medicationMail = {
        ...medicationMail,
        id: lastMedicationMail.id,
        facilityName: lastMedicationMail.facilityName,
        facilityAddress: lastMedicationMail.facilityAddress,
        facilityPhone: lastMedicationMail.facilityPhone,
        facilityEmail: lastMedicationMail.facilityEmail,
        pharmacyEmail: lastMedicationMail.pharmacyEmail,
        facilityId: lastMedicationMail.facilityId,
      };
    }
    setSendMailState({
      ...sendMailState,
      medicationMail: medicationMail,
      multiSelections: medications,
    });
  };

  const onSendMail = async (multiSelections, onHide) => {
    const medicationMail = { ...sendMailState.medicationMail };
    const validateMedicationMail = MedicationService.validateMedicationMail(
      medicationMail
    );
    if (!validateMedicationMail.isValid) {
      setSendMailState({
        ...sendMailState,
        isError: true,
        errorMsg: validateMedicationMail.msg,
      });
      return;
    }
    setSendMailState({ ...sendMailState, isSendMailActive: true });
    medicationMail["medications"] = multiSelections;
    await MedicationService.sendMail(medicationMail);
    alert(
      "Medication order is sent, you will recive copy of the order on " +
        medicationMail.facilityEmail
    );
    onHide();
  };

  const onChange = (event) => {
    const value = event.target.value;
    const medicationMail = { ...sendMailState.medicationMail };
    medicationMail[event.target.name] = value;
    console.log("event.target.name", event.target.name);
    console.log("value", value);
    console.log("medicationMail", medicationMail);
    setSendMailState({
      ...sendMailState,
      medicationMail,
      isError: false,
      errorMsg: "",
    });
  };

  const onSelectedForRemoval = (e) => {
    const medId = e.target.value;
    setSendMailState({
      ...sendMailState,
      multiSelections: sendMailState.multiSelections.filter(
        (med) => med.id != medId
      ),
    });
  };

  const onExitPhone = (e) => {
    const phone = e.target.value;
    const formattedPhone = UtilityService.formatPhoneNumber(phone);
    const medicationMail = { ...sendMailState.medicationMail };
    medicationMail[e.target.name] = formattedPhone;
    setSendMailState({ ...sendMailState, medicationMail: medicationMail });
  };

  return {
    refreshPage,
    onSendMail,
    onChange,
    onExitPhone,
    onSelectedForRemoval,
    sendMailState,
  };
};

export default useSendMailState;
