import React from "react";
import Button from "react-bootstrap/Button";
import del from "../../img/delete.png";
import add from "../../img/add.png";

const AddToCart = ({ onAddRemoveToCart, isIntoCart, product }) => {
  const displayAddToCardButton = isIntoCart
    ? { display: "none" }
    : { width: "100%", marginTop: "20px" };
  const displayRemoveFromCardButton = !isIntoCart
    ? { display: "none" }
    : {
        width: "100%",
        marginTop: "20px",
      };

  return (
    <React.Fragment>
      <Button
        variant="warning"
        style={displayAddToCardButton}
        size="sm"
        onClick={() => onAddRemoveToCart(product)}
      >
        Add to Cart
      </Button>
      <Button
        variant="danger"
        style={displayRemoveFromCardButton}
        size="sm"
        onClick={() => onAddRemoveToCart(product)}
      >
        Remove from Cart
      </Button>
    </React.Fragment>
  );
};

export default AddToCart;
