import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthenticationService from "../../api/authenticationService";
import LoginComponent from "../main/loginComponent";

class AuthenticatedRoute extends Component {
  render() {
    if (AuthenticationService.isUserLoggedIn()) {
      return <Route {...this.props} />;
    } else {
      return <Route to="/login" exact component={LoginComponent} />;
    }
  }
}

export default AuthenticatedRoute;
