import React, { useEffect } from "react";
import {
  Tab,
  Container,
  Row,
  Col,
  Nav,
  Button,
  CardColumns,
  Card,
} from "react-bootstrap";
import FamilyResidentForm from "./familyResidentForm";
import useDocumentState from "./hook/useDocumentState";
import { FcOpenedFolder } from "react-icons/fc";

const MainDocuments = () => {
  const {
    refreshPage,
    openResident,
    onOpenResidentForm,
    onCloseResidentForm,
    onSetFile,
    onDownload,
    documentState,
  } = useDocumentState({});

  useEffect(() => {
    refreshPage();
  }, []);

  const sharedFiles =
    documentState.currResident &&
    documentState.currResident.residentFormList &&
    documentState.currResident.residentFormList.filter(
      (resFormList) =>
        resFormList.staff.filter((stf) => stf.id == documentState.currStaff.id)
          .length > 0
    );

  return (
    <Tab.Container>
      <Row>
        <Col>
          <Nav onSelect={(selectedKey) => openResident(selectedKey)}>
            {documentState.residents &&
              documentState.residents.map((resident) => {
                return (
                  <Nav.Item>
                    <Nav.Link eventKey={resident.id}>
                      <Button
                        style={
                          documentState &&
                          documentState.currResident &&
                          documentState.currResident.id == resident.id
                            ? navButtonSelectedStyle
                            : navButtonStyle
                        }
                      >
                        {resident.firstName} {resident.lastName}
                      </Button>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          {!documentState.showResidentForm ? (
            sharedFiles && sharedFiles.length > 0 ? (
              <CardColumns>
                {sharedFiles.map((residentForm) => {
                  return (
                    <Card>
                      <Card.Body>
                        <Container fluid>
                          <Row>
                            <Col>
                              <Button
                                onClick={() => onOpenResidentForm(residentForm)}
                                style={{
                                  backgroundColor: "transparent",
                                  borderColor: "transparent",
                                  color: "black",
                                  verticalAlign: "middle",
                                  textAlign: "left",
                                }}
                              >
                                <div style={{ maxWidth: "100%" }}>
                                  <FcOpenedFolder
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  {"  "}
                                  {residentForm.name.length > 20
                                    ? residentForm.name.substring(0, 20) + "..."
                                    : residentForm.name}
                                </div>
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Card>
                  );
                })}
              </CardColumns>
            ) : (
              "There are no shared files"
            )
          ) : (
            <FamilyResidentForm
              onCloseResidentForm={onCloseResidentForm}
              onSetFile={onSetFile}
              onDownload={onDownload}
              documentState={documentState}
            />
          )}
        </Col>
      </Row>
    </Tab.Container>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "150px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "150px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default MainDocuments;
