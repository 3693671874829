import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AuthenticationService from "../../api/authenticationService";

class ResetPassword extends Component {
  state = {
    user: {},
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    var user = this.state.user;
    user[name] = value;
    this.setState({
      user,
    });
  };

  sendResetRequest = () => {
    if (
      this.state.user == undefined ||
      this.state.user.username == undefined ||
      this.state.user.username.trim() === ""
    ) {
      alert("Please enter username!");
      return;
    }
    AuthenticationService.executeResetPassAuthenticationService(
      this.state.user.username
    ).then((data) => {
      alert(
        "Password reset was sent successfully, we will send you the new password in the next 24 hours"
      );
      this.props.handleClose();
    });
  };
  render() {
    return (
      <Container
        style={{
          textAlign: "center",
          align: "center",
        }}
        fluid
      >
        <Row style={{ paddingBottom: "5px" }}>
          <Col>
            <div align="center">
              <Form.Control
                size="sm"
                name="username"
                sm="6"
                placeholder="e-mail*"
                onChange={this.handleChange}
                defaultValue={this.state.username}
                style={{ width: 300 }}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "10px", textAlign: "center" }}>
          <Col>
            <Button
              size="sm"
              variant="success"
              onClick={this.sendResetRequest}
              style={buttonStyle}
            >
              Reset Password
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const buttonStyle = {
  borderColor: "#3C3B6E",
  width: 300,
  borderRadius: "10px",
  color: "#3C3B6E",
  backgroundColor: "#f2f2f2",
  margin: "3px",
};

export default ResetPassword;
