import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

const FunctionalCapabilities = ({
  onChangeInput,
  lic603aState,
  complianceFormsState,
}) => {
  return (
    <Container fluid>
      <Row>
        <Col xs="auto">
          <b>YES</b>
        </Col>
        <Col xs="auto">
          <b>NO</b>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="active"
            onChange={(e) => onChangeInput("active", "1", "true")}
            checked={lic603aState != undefined && lic603aState.active == "1"}
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="active"
            onChange={(e) => onChangeInput("active", "2", "true")}
            checked={lic603aState != undefined && lic603aState.active == "2"}
          />
        </Col>
        <Col>
          Active, requires no personal help of any kind - able to go up and down
          stairs easily
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="activeWithDifficulty"
            onChange={(e) => onChangeInput("activeWithDifficulty", "1", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.activeWithDifficulty == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="activeWithDifficulty"
            onChange={(e) => onChangeInput("activeWithDifficulty", "2", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.activeWithDifficulty == "2"
            }
          />
        </Col>
        <Col>Active, but has difficulty climbing or descending stairs</Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="usesBrace"
            onChange={(e) => onChangeInput("usesBrace", "1", "true")}
            checked={lic603aState != undefined && lic603aState.usesBrace == "1"}
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="usesBrace"
            onChange={(e) => onChangeInput("usesBrace", "2", "true")}
            checked={lic603aState != undefined && lic603aState.usesBrace == "2"}
          />
        </Col>
        <Col>Uses brace or crutch</Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="frail"
            onChange={(e) => onChangeInput("frail", "1", "true")}
            checked={lic603aState != undefined && lic603aState.frail == "1"}
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="frail"
            onChange={(e) => onChangeInput("frail", "2", "true")}
            checked={lic603aState != undefined && lic603aState.frail == "2"}
          />
        </Col>
        <Col>Frail or slow</Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="usesWalker"
            onChange={(e) => onChangeInput("usesWalker", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.usesWalker == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="usesWalker"
            onChange={(e) => onChangeInput("usesWalker", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.usesWalker == "2"
            }
          />
        </Col>
        <Col xs="auto">Uses walker.If Yes can get in and out unassisted</Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            label="Yes"
            id="usesWalkerUnassisted"
            onChange={(e) => onChangeInput("usesWalkerUnassisted", "1", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.usesWalkerUnassisted == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            label="No"
            id="usesWalkerUnassisted"
            onChange={(e) => onChangeInput("usesWalkerUnassisted", "2", "true")}
            checked={
              lic603aState != undefined &&
              lic603aState.usesWalkerUnassisted == "2"
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="usesWheelchair"
            onChange={(e) => onChangeInput("usesWheelchair", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.usesWheelchair == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="usesWheelchair"
            onChange={(e) => onChangeInput("usesWheelchair", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.usesWheelchair == "2"
            }
          />
        </Col>
        <Col xs="auto">
          Uses wheelchair. If Yes, can get in and out unassisted?
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            label="Yes"
            id="usesWheelchairUnassisted"
            onChange={(e) =>
              onChangeInput("usesWheelchairUnassisted", "1", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.usesWheelchairUnassisted == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            label="No"
            id="usesWheelchairUnassisted"
            onChange={(e) =>
              onChangeInput("usesWheelchairUnassisted", "2", "true")
            }
            checked={
              lic603aState != undefined &&
              lic603aState.usesWheelchairUnassisted == "2"
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="requiresGrab"
            onChange={(e) => onChangeInput("requiresGrab", "1", "true")}
            checked={
              lic603aState != undefined && lic603aState.requiresGrab == "1"
            }
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="requiresGrab"
            onChange={(e) => onChangeInput("requiresGrab", "2", "true")}
            checked={
              lic603aState != undefined && lic603aState.requiresGrab == "2"
            }
          />
        </Col>
        <Col>Requires grab bars in bathroom</Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="other"
            onChange={(e) => onChangeInput("other", "1", "true")}
            checked={lic603aState != undefined && lic603aState.other == "1"}
          />
        </Col>
        <Col xs="auto">
          <Form.Check
            type="radio"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            id="other"
            onChange={(e) => onChangeInput("other", "2", "true")}
            checked={lic603aState != undefined && lic603aState.other == "2"}
          />
        </Col>
        <Col>Other: (Describe)</Col>
      </Row>
      <Row>
        <Col xs="auto"></Col>
        <Col xs="auto"></Col>
        <Col>
          <Form.Control
            style={{ width: "500px" }}
            name="otherDescribe"
            disabled={
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status == "SIGNED"
            }
            onChange={(e) => onChangeInput("otherDescribe", e)}
            value={lic603aState && lic603aState.otherDescribe}
            autoComplete="off"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FunctionalCapabilities;
