import React from "react";
import {
  Card,
  Dropdown,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
} from "react-bootstrap";
import ResidentFormDesktop from "../desktop/residentFormDesktop";
import { TiPlus } from "react-icons/ti";
import { FcOpenedFolder } from "react-icons/fc";
import { BsThreeDotsVertical } from "react-icons/bs";
import ResidentFormMobile from "./residentFormMobile";

const ComplianceMobile = ({
  onOpenResidentForm,
  onShowAddTodo,
  onCloseResidentForm,
  onSetFile,
  onDownload,
  onDeleteFiles,
  onShowForm,
  onSelectFormMenu,
  formatList,
  onShowSignFile,
  residentState,
}) => {
  return (
    <React.Fragment>
      {residentState.showResidentForm ? (
        <ResidentFormMobile
          onShowAddTodo={onShowAddTodo}
          onCloseResidentForm={onCloseResidentForm}
          onSetFile={onSetFile}
          onDownload={onDownload}
          onDeleteFiles={onDeleteFiles}
          onShowSignFile={onShowSignFile}
          residentState={residentState}
        />
      ) : (
        <Container fluid>
          <Row
            style={{
              borderBottom: "solid",
              borderBottomColor: "black",
              borderBottomWidth: "1px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <Col>
              <Button
                size="sm"
                variant="success"
                style={{ marginBottom: "10px", width: "100%" }}
                onClick={() => onShowForm()}
              >
                <TiPlus
                  style={{
                    color: "white",
                    width: "25px",
                    height: "25px",
                    verticalAlign: "middle",
                  }}
                />
                {"  "}
                New report
              </Button>
            </Col>
          </Row>
          {residentState.forms.map((residentForm) => {
            return (
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => onOpenResidentForm(residentForm)}
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      color: "black",
                      verticalAlign: "middle",
                      textAlign: "left",
                    }}
                  >
                    <div style={{ maxWidth: "100%" }}>
                      <FcOpenedFolder
                        style={{
                          width: "15px",
                          height: "15px",
                          verticalAlign: "middle",
                        }}
                      />
                      {"  "}
                      {residentForm &&
                      residentForm.name &&
                      residentForm.name.length > 15
                        ? residentForm.name.substring(0, 12) + "..."
                        : residentForm.name}
                    </div>
                  </Button>
                </Col>
                <Col
                  style={{
                    width: "80%",
                    verticalAlign: "bottom",
                    textAlign: "center",
                  }}
                >
                  <Dropdown
                    size="sm"
                    onSelect={(selectedKey) =>
                      onSelectFormMenu(selectedKey, residentForm)
                    }
                    style={{ verticalAlign: "bottom" }}
                  >
                    <Dropdown.Toggle
                      size="sm"
                      variant="success"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        borderColor: "transparent",
                      }}
                      id="dropdown-basic"
                    >
                      <BsThreeDotsVertical
                        size="sm"
                        style={{
                          color: "black",
                          width: "10px",
                          height: "10px",
                        }}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu size="sm">
                      <Dropdown.Item size="sm" eventKey="share">
                        Share
                      </Dropdown.Item>
                      <Dropdown.Item size="sm" eventKey="delete">
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            );
          })}
          <Row></Row>
        </Container>
      )}
    </React.Fragment>
  );
};

export default ComplianceMobile;
