import React from "react";
import GeneralInfo from "./generalInfo";
import {
  Row,
  Col,
  Tab,
  Nav,
  Container,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import BackButton from "../../common/backButton";
import SaveButton from "../../common/saveButton";
import Printout from "../printout";
import ResidentsInvolved from "./residentsInvolved";
import IncidentDesc from "./incidentDesc";
import ActionTaken from "./actionTaken";
import Comment from "./comment";
import SendEmail from "../../common/sendEmail";

const Lic624 = ({
  onChangeInput,
  onSave,
  onBack,
  onTabSelected,
  onShowCopy,
  onShowSendEmail,
  lic624State,
  lic624Base64ContentState,
  complianceFormsState,
}) => {
  return (
    <React.Fragment>
      <Container fluid style={{ backgroundColor: "lightgray" }}>
        <Row>
          <Col xs="auto">
            <BackButton onBack={onBack} />
          </Col>
          <Col
            style={{
              textAlign: "left",
              color: "black",
              marginTop: "10px",
              marginBottom: "10px",
              fontSize: "26px",
            }}
          >
            <b>LIC624 - UNUSUAL INCIDENT/INJURY REPORT</b>
          </Col>
          <Col>
            <ButtonGroup style={{ float: "right" }}>
              {complianceFormsState &&
              complianceFormsState.currResidentFileMetadata &&
              complianceFormsState.currResidentFileMetadata.status &&
              complianceFormsState.currResidentFileMetadata.status ===
                "SIGNED" ? (
                ""
              ) : (
                <React.Fragment>
                  <SaveButton onSave={onSave} />
                </React.Fragment>
              )}
              <SendEmail onSendEmail={onShowSendEmail} />
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
      {complianceFormsState &&
      complianceFormsState.currResidentFileMetadata &&
      complianceFormsState.currResidentFileMetadata.status == "SIGNED" ? (
        <Printout base64Content={lic624Base64ContentState} />
      ) : (
        <Tab.Container
          defaultActiveKey="generalInfo"
          onSelect={(e) => onTabSelected(e)}
        >
          <Row>
            <Col>
              <Nav>
                <Nav.Item>
                  <Nav.Link eventKey="generalInfo">
                    <Button
                      variant="success"
                      style={
                        complianceFormsState &&
                        complianceFormsState.currentTab &&
                        complianceFormsState.currentTab == "generalInfo"
                          ? navButtonSelectedStyle
                          : navButtonStyle
                      }
                    >
                      General Info
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="residentsInvolved">
                    <Button
                      variant="success"
                      style={
                        complianceFormsState &&
                        complianceFormsState.currentTab &&
                        complianceFormsState.currentTab == "residentsInvolved"
                          ? navButtonSelectedStyle
                          : navButtonStyle
                      }
                    >
                      Residents Involved
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="incidentDesc">
                    <Button
                      variant="success"
                      style={
                        complianceFormsState &&
                        complianceFormsState.currentTab &&
                        complianceFormsState.currentTab == "incidentDesc"
                          ? navButtonSelectedStyle
                          : navButtonStyle
                      }
                    >
                      Description
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="actionTaken">
                    <Button
                      variant="success"
                      style={
                        complianceFormsState &&
                        complianceFormsState.currentTab &&
                        complianceFormsState.currentTab == "actionTaken"
                          ? navButtonSelectedStyle
                          : navButtonStyle
                      }
                    >
                      Actions
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="comment">
                    <Button
                      variant="success"
                      style={
                        complianceFormsState &&
                        complianceFormsState.currentTab &&
                        complianceFormsState.currentTab == "comment"
                          ? navButtonSelectedStyle
                          : navButtonStyle
                      }
                    >
                      Comment
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="printout">
                    <Button
                      variant="success"
                      style={
                        complianceFormsState &&
                        complianceFormsState.currentTab &&
                        complianceFormsState.currentTab == "printout"
                          ? navButtonSelectedStyle
                          : navButtonStyle
                      }
                    >
                      Printout
                    </Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Tab.Content style={{ width: "100%" }}>
              <Tab.Pane eventKey="generalInfo">
                <GeneralInfo
                  onChangeInput={onChangeInput}
                  lic624State={lic624State}
                  complianceFormsState={complianceFormsState}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="residentsInvolved">
                <ResidentsInvolved
                  onChangeInput={onChangeInput}
                  lic624State={lic624State}
                  complianceFormsState={complianceFormsState}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="incidentDesc">
                <IncidentDesc
                  onChangeInput={onChangeInput}
                  lic624State={lic624State}
                  complianceFormsState={complianceFormsState}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="actionTaken">
                <ActionTaken
                  onChangeInput={onChangeInput}
                  lic624State={lic624State}
                  complianceFormsState={complianceFormsState}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="comment">
                <Comment
                  onChangeInput={onChangeInput}
                  lic624State={lic624State}
                  complianceFormsState={complianceFormsState}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="printout">
                <Printout base64Content={lic624Base64ContentState} />
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      )}
    </React.Fragment>
  );
};

const navButtonStyle = {
  borderColor: "transparent",
  borderBottomColor: "transparent",
  color: "gray",
  backgroundColor: "transparent",
  width: "150px",
};

const navButtonSelectedStyle = {
  borderColor: "transparent",
  borderBottomColor: "green",
  borderBottomWidth: "3px",
  borderRadius: "0px",
  color: "black",
  backgroundColor: "transparent",
  width: "150px",
  fontStyle: "underline",
  fontWeight: "bold",
};

export default Lic624;
