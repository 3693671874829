import APIClient from "../apiClient";

const BASE_FAC_CON_EMAIL_METADATA = "/facilityContactsEmails";

class FacilityContactsEmailsClient extends APIClient {
  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + BASE_FAC_CON_EMAIL_METADATA
    );
  }
}

export default FacilityContactsEmailsClient;
