import React from "react";
import Form from "react-bootstrap/Form";
import * as Constants from "../../../util/constants";
import { Button } from "react-bootstrap";

const AddProductDesktop = ({
  onSaveProduct,
  onHideProduct,
  onDeleteProduct,
  onChangeProduct,
  shoppingState,
}) => {
  return (
    <React.Fragment>
      <Form>
        {shoppingState.isError ? (
          <Form.Row>
            <div style={errorMsgStyle}>
              <b>ERROR:</b> {shoppingState.errMsg}
            </div>
          </Form.Row>
        ) : (
          ""
        )}
        <Form.Group controlId="groupName">
          <Form.Label>
            <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>Name:
          </Form.Label>
          <Form.Control
            name="name"
            placeholder="Name"
            onChange={(e) => onChangeProduct(e)}
            value={shoppingState.currProduct && shoppingState.currProduct.name}
            style={{ width: "300px" }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group controlId="groupPurchaseQuantity">
          <Form.Label>
            <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>Default
            purchase quantity:
          </Form.Label>
          <Form.Control
            name="purchaseQuantity"
            type="number"
            placeholder="Quantity"
            onChange={(e) => onChangeProduct(e)}
            value={
              shoppingState.currProduct &&
              shoppingState.currProduct.purchaseQuantity
            }
            style={{ width: "150px" }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b style={{ color: "#b22234", marginRight: "5px" }}>*</b>Measure:
          </Form.Label>
          <Form.Control
            name="productMeasure"
            as="select"
            onChange={(e) => onChangeProduct(e)}
            defaultValue={
              shoppingState.currProduct &&
              shoppingState.currProduct.productMeasure &&
              shoppingState.currProduct.productMeasure.id
            }
            style={{ width: "150px" }}
          >
            {Constants.PRODUCT_MEASURE.map((element) => (
              <option key={element.id} value={element.id}>
                {element.value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button
          variant="outline-success"
          style={{
            width: "100px",
            marginRight: "5px",
          }}
          onClick={onHideProduct}
        >
          Close
        </Button>
        {shoppingState.currProduct != undefined &&
        Object.keys(shoppingState.currProduct).length != 0 ? (
          <Button
            variant="danger"
            style={{
              width: "100px",
              marginRight: "5px",
            }}
            onClick={onDeleteProduct}
          >
            Delete
          </Button>
        ) : (
          ""
        )}
        <Button
          variant="success"
          onClick={onSaveProduct}
          style={{ width: "100px", marginRight: "5px" }}
        >
          Save
        </Button>
      </Form>
    </React.Fragment>
  );
};

const errorMsgStyle = {
  color: "#b22234",
  marginBottom: "20px",
};

export default AddProductDesktop;
