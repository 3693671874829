import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import moment from "moment";

const GeneralInfoMobile = ({ residentState, onEditResident }) => {
  return (
    <Container className="bg-warning" fluid>
      <Row style={{ verticalAlign: "middle" }}>
        <Col
          className="bg-warning"
          style={{
            fontWeight: "bold",
            width: "100%",
            fontSize: "12px",
            verticalAlign: "middle",
            marginTop: "15px",
            color: "black",
            textAlign: "center",
          }}
        >
          {residentState.currResident.firstName.toUpperCase()}{" "}
          {residentState.currResident.lastName.toUpperCase()}
        </Col>
      </Row>
      <Row>
        <Col className="bg-warning" style={{ textAlign: "right" }}>
          <Button
            size="sm"
            variant="warning"
            style={{
              width: "100%",
              marginTop: "5px",
              marginBottom: "5px",
              backgroundColor: "white",
              color: "#f0ad4e",
            }}
            onClick={() => onEditResident(residentState.currResident)}
          >
            Edit
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneralInfoMobile;
