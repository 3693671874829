import APIClient from "../apiClient";

const FACILITY_DETAILS = "/facilityDetails";

class FacilityDetailsClient extends APIClient {
  async getAll() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + FACILITY_DETAILS
    );
  }

  async getCustomer() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + FACILITY_DETAILS + "/customer"
    );
  }

  async getPaymentMethod() {
    return await super.performAsync(
      "get",
      super.getBaseContext() + FACILITY_DETAILS + "/paymentMethod"
    );
  }

  async createCustomer(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + FACILITY_DETAILS + "/createCustomer",
      repo
    );
  }

  async createPaymentMethod(repo) {
    return await super.performAsync(
      "post",
      super.getBaseContext() + FACILITY_DETAILS + "/createPaymentMethod",
      repo
    );
  }
}

export default FacilityDetailsClient;
