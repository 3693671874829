import React from "react";
import { Container, Form, Row, Col } from "react-bootstrap";

const BackgroundInfo = ({
  onChangeInput,
  lic625State,
  complianceFormsState,
}) => {
  return (
    <Container fluid>
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>Background Information</b>
            </Form.Label>
            <Form.Control
              onChange={(e) => onChangeInput("backgroundInfo", e)}
              disabled={
                complianceFormsState.currResidentFileMetadata &&
                complianceFormsState.currResidentFileMetadata.status == "SIGNED"
              }
              value={lic625State && lic625State.backgroundInfo}
              as="textarea"
              rows={25}
              style={{ width: "100%" }}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default BackgroundInfo;
