import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const ButtonBarMobile = ({
  onClose,
  onShowMedicationOrderHistory,
  onShowSendOrder,
}) => {
  return (
    <Container fluid>
      <Row style={{ marginBottom: "15px" }}>
        <Col style={{ textAlign: "center" }}>
          <Button
            size="sm"
            variant="success"
            style={buttonStyle}
            onClick={() => onShowSendOrder()}
          >
            Send order
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <Button
            variant="outline-success"
            size="sm"
            variant="primary"
            style={buttonStyle}
            onClick={() => onClose()}
          >
            Close
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const buttonStyle = {
  width: "100%",
};

export default ButtonBarMobile;
